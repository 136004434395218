import firebase from './firebase'
import DeviceDetector from "device-detector-js";

const isInPhoneOrTablet = () => window.matchMedia("(max-width: 768px)").matches ? true : false;

function getBrowser(){
                 
  let userAgent = navigator.userAgent;
  let browserName;
  
  if(userAgent.match(/chrome|chromium|crios/i)){
      browserName = "chrome";
    }else if(userAgent.match(/firefox|fxios/i)){
      browserName = "firefox";
    }  else if(userAgent.match(/safari/i)){
      browserName = "safari";
    }else if(userAgent.match(/opr\//i)){
      browserName = "opera";
    } else if(userAgent.match(/edg/i)){
      browserName = "edge";
    }else{
      browserName="No browser detection";
    }
  
   return browserName         
}

const handleEventLogs = async (eventName, description, data = {}) => {
    if (
      window.location.protocol === "https:" ||
      window.location.host === "localhost:3000"
    ) {
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(navigator.userAgent);
      const analytics = firebase.analytics();
      let commonData = {
        device: device.client.name,
        model: device.device.brand + device.os.version + device.os.name,
        manufacturer: device.device.brand,
        browser: getBrowser(),
        web: true,
        web_resposive: isInPhoneOrTablet(),
        time_in: new Date().toISOString(),
      };
      const _data = { ...commonData, ...data};
      await analytics.logEvent(eventName, _data);
    }
};

export { handleEventLogs }
