import cx from 'classnames'

import AAKASH_LOGO from 'assets/pdf/aakash-anthe-logo.png'
import BIGGER_STRONGER_BETTER from 'assets/pdf/bigger-stronger-better.png'
import EARLY_ADMISSION from 'assets/pdf/early-admission.png'
import TESTIMONIAL_NEET from 'assets/pdf/testimonial-neet.png'
import RESULT_ANTHE from 'assets/pdf/result-figure-anthe.png'
import ANTHE_MAP from 'assets/pdf/anthe-map.png'

import styles from './cms.module.scss'
import './cms.scss'

function CmsComponents({ data, isPrinting, isSafariBrowser, isMobileBrowser }) {
    const CheckList = () => (
        <div
            className={cx(
                styles.page,
                { [styles.safari]: isSafariBrowser },
                { [styles.mobile]: isMobileBrowser },
                { [styles.print]: isPrinting },
            )}
        >
            <div style={{ pageBreakAfter: 'always' }}></div>
            <div className={styles.headerHtml}>
                <img
                    alt="anthe-logo"
                    src="https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/iacst-pdf-logo.png"
                    width="190"
                />
            </div>
            <div style={{ textAlign: 'center' }}>
                <div>
                    <div className="checklist-deatils-section">
                        <div className="checklist-box">
                            <div className="checklist-section">
                                <h5 className="checklist-section-h5">
                                    CHECK LIST FOR COMPLETING ADMISSION FORMALITIES
                                    (OFFLINE VISIT)
                                </h5>
                                <div className="checklist-option">
                                    <p className="checklist-p">
                                        Please note that the following documents
                                        should be carried along for completing the
                                        admission formalities at any centre of Aakash
                                        BYJU’S in India:
                                    </p>
                                    <div className="checklist-four">
                                        <div>
                                            <div
                                                className="checklist-text"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div className="square">&nbsp;</div>
                                                <p style={{ margin: '0px' }}>
                                                    Latest Mark sheet
                                                </p>
                                            </div>
                                            <div
                                                className="checklist-text"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div className="square">&nbsp;</div>
                                                <p
                                                    style={{
                                                        margin: '-3px 0px 0px',
                                                        marginRight: '6px',
                                                    }}
                                                >
                                                    Two recent passport size colour
                                                    photographs
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="checklist-text"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div className="square">&nbsp;</div>
                                                <p
                                                    style={{
                                                        margin: '0px 0px 0px',
                                                    }}
                                                >
                                                    A valid Photo-ID (Passport,
                                                    School I-Card etc.)
                                                </p>
                                            </div>
                                            <div
                                                className="checklist-text"
                                                style={{
                                                    paddingLeft: '0px',
                                                    marginBottom: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div className="square">&nbsp;</div>
                                                <p style={{ margin: '0px' }}>
                                                    A cheque book in case to avail
                                                    installment payment mode.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="special-section">
                                <p className="special-section-p">
                                    SPECIAL ASSISTANCE AVAILABLE FOR COMPLETING
                                    ADMISSION FORMALITIES ONLINE
                                </p>
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: '0px',
                                display: 'flex',
                                background: '#fff',
                            }}
                        >
                            <h4 className="please-note">Please Note:</h4>
                            <ul className="please-note-ul">
                                <li style={{ margin: '0px 0px 7px' }}>
                                    If at any point of time, the Govt. Charges
                                    GST/Service Tax or any other tax on Scholarship
                                    granted, it will be borne by the Student only.
                                </li>
                                <li style={{ margin: '0px 0px 7px' }}>
                                    Technology Fee &amp; Examination Fee will be
                                    charged from students as per the prospectus
                                    except for Top 500 students in Class VII, VIII,
                                    IX &amp; X studying (Medical &amp; Engineering)
                                    and 200 students in Class XI &amp; XII Studying
                                    (Medical &amp; Engineering), who are exempted
                                    from this fee as a special beneﬁt.
                                </li>
                                <li style={{ marginBottom: '0px' }}>
                                    The highest amount of scholarship will not exceed
                                    100% of the overall fee in any case whatsoever.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="visit-section-aakash">
                        <div className="text-visit-main">
                            <p>
                                Visit{' '}
                                <a
                                    rel="noreferrer"
                                    href="https://centers.aakash.ac.in"
                                    style={{ color: '#00a6e5' }}
                                    target="_blank"
                                >
                                    https://centers.aakash.ac.in
                                </a>
                            </p>
                            <p>
                                for contact and location details of Aakash BYJU's
                                Centers
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const BiggerStrongerBetter = () => (
        <>
            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                {isPrinting ? (
                    <img
                        className={styles.logo}
                        src={AAKASH_LOGO}
                        alt="anthe logo"
                    />
                ) : null}
                <img
                    className={cx(styles.biggerStrongerImg, {
                        [styles.print]: isPrinting,
                    })}
                    src={BIGGER_STRONGER_BETTER}
                    alt="bigger stronger better"
                />
            </div>
        </>
    )

    const AdmissionFormalities = () => (
        <>
            <div className={styles.break} />
            <div
                className={cx(
                    cx(
                        styles.page,
                        { [styles.safari]: isSafariBrowser },
                        { [styles.mobile]: isMobileBrowser },
                        { [styles.print]: isPrinting },
                    ),
                    styles.earlyAdmission,
                )}
            >
                {isPrinting ? (
                    <img
                        className={styles.logo}
                        src={AAKASH_LOGO}
                        alt="anthe logo"
                    />
                ) : null}
                <img
                    className={cx(styles.img, {
                        [styles.print]: isPrinting,
                    })}
                    src={EARLY_ADMISSION}
                    alt="early admission"
                />
                <div className={styles.concession}>
                    <div className={styles.registration}>
                        <p>
                            Complete your admission formalities by 31st Jan. to avail
                            attractive{' '}
                            <span className={styles.yellow}>
                                Early Admission Benefits
                            </span>
                        </p>
                    </div>
                    <p className={styles.secured}>
                        The secured scholarship is applicable on classroom courses at
                        any Aakash BYJU'S Branches and Centre throughout India.
                    </p>
                </div>
                <div className={styles.recorded}>
                    <p>
                        *Recorded Video Lectures & Daily Practice Tests are not
                        available for students studying in Class VII.
                    </p>
                    <p>
                        <strong>Note:</strong>
                        The above mentioned enrollment benefits will vary class-wise.
                    </p>
                </div>
            </div>
        </>
    )

    const Testimonials = () => (
        <>
            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                {isPrinting ? (
                    <img
                        className={styles.logo}
                        src={AAKASH_LOGO}
                        alt="anthe logo"
                    />
                ) : null}
                <img
                    className={cx(styles.testimonialNeet, {
                        [styles.print]: isPrinting,
                    })}
                    src={TESTIMONIAL_NEET}
                    alt="neet testimonial"
                />
            </div>
        </>
    )

    const Graph = () => (
        <>
            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                {isPrinting ? (
                    <img
                        className={cx(styles.logo, styles.abs)}
                        src={AAKASH_LOGO}
                        alt="anthe logo"
                    />
                ) : null}
                <img
                    className={cx(styles.graph, {
                        [styles.print]: isPrinting,
                    })}
                    src={RESULT_ANTHE}
                    alt="result anthe"
                />
            </div>
        </>
    )

    const Map = () => (
        <>
            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                <img
                    className={cx(styles.map, {
                        [styles.print]: isPrinting,
                    })}
                    src={ANTHE_MAP}
                    alt="anthe map"
                />
            </div>
        </>
    )

    return {
        BiggerStrongerBetter,
        AdmissionFormalities,
        Testimonials,
        Graph,
        Map,
        CheckList,
    }
}

export default CmsComponents
