import config from "config"
export const aboutCard = [
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/scholarship-test-details.png',
        cardbodyitem:
            '<p>Take the test at a date and time of your choice </br><b>Timings </b>: 9AM to 10PM Daily | <b>Duration</b> : 60&nbsp;mins</br><b>Mode </b>: Online(from home)</p></p>',
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/avail-scholarship.png',
        cardbodyitem:
            "<p className='integrated'>1 Year Integrated Classroom Courses <br /> for JEE<br /></p>",
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/how_can_appear.png',
        cardbodyitem: ' <p>Class 12th passed students</p>',
    },

    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/how-to-take.png',
        cardbodyitem:
            '<ul><li>Verify your mobile number with OTP and complete the registration </li><li> Get your login details, roll number and select an exam date &amp; time slot </li> <li>Take the Aakash iACST online from your home</li></ul>',
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/test-highlight.png',
        cardbodyitem:
            ' <ul><li>Completely online ensuring the safety and convenience of all candidates </li> <li>Flexible timings - students can choose and select any time slot between 9AM to 10PM </li> <li>Conducted everyday so that students can schedule the test as per their convenience</li></ul>',
    },
]

export const details = [
    {
        details_title:'Take the JEE Repeater Course if you',
        details_para: [
        {
            cardbodyitem:
                'Appeared in JEE but did not qualify.',
        },
        {
            cardbodyitem:
                'Qualified JEE but did not get admission in the college of your choice.',
        },
        {
            cardbodyitem: 'Did not get your expected rank.',
        },
        {
            cardbodyitem:
                'Did not even once appear in JEE and now wish to prepare for JEE',
        },
        ]
    },
    {
        details_title:'Why JEE Repeater Course?',
        details_para: [
        {
            cardbodyitem:
                'Better performance at JEE Mains and JEE Advanced improves chances of admission in IITs/NITs',
        },
        {
            cardbodyitem:
                '47% of freshers prefer to make another attempt at JEE to take admission in a better engineering college',
        },
        {
            cardbodyitem:
                'Better awareness of exam pattern and the ability to handle the pressure of D-Day provides an edge over the freshers',
        },
        {
            cardbodyitem:
                'One can focus on JEE alone, without any compulsion of maintaining school attendance and stress of class 12th',
        },
     ]
    },
]

export const successStories = [
    {
        headingImage:
            `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-success-stroies-from-repeater-2023-lg.webp?v=${config.VERSION_BANNER}`,
    },
    {
        headingImage:
            `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-main-2024-results-lp_banner-270424.webp?v=${config.VERSION_BANNER}`,
    },
    {
        headingImage:
            `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-main-result-2024-session1-lpbanner.webp?v=${config.VERSION_BANNER}`,
    },
    {
        headingImage:
            `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-advanced-2023-topper-banner-1260x300.webp?v=${config.VERSION_BANNER}`,
    },
]

export const scholarshipDetails = [
    {
        scholarshipTitle: 'JEE Repeater Course for JEE Droppers',
        scholarshipPara:
            "The Aakash JEE Repeater Course is specifically designed for JEE droppers who are repeating the JEE exam 2025. It is a comprehensive program that provides students with the necessary guidance, resources, and support to succeed in the exam.",
        scholarshipData: [
            {
                scholarshipDataTitle:
                    'Benefits of Aakash JEE Repeater Course for JEE Droppers',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'Here are some of the benefits of the Aakash JEE Repeater Course for JEE droppers:',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Personalized Learning Experience:</strong>The Aakash JEE Repeater Course provides a personalized learning experience to every student. The course is designed to cater to the individual needs of each student, taking into consideration their strengths and weaknesses. The faculty at Aakash Institute provides personalized attention to each student, ensuring that they understand the concepts thoroughly.',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Comprehensive Study Material:</strong>Aakash Institute provides comprehensive study material to its students. The study material includes books, assignments, practice papers, and mock tests. The study material is designed to help students cover the entire syllabus and practice extensively.',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Regular Doubt Solving Sessions:</strong>faculty at Aakash Institute conducts regular doubt-solving sessions to help students clear their doubts and understand the concepts better.',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Mock Tests and Practice Papers:</strong>Aakash Institute conducts regular mock tests and practice papers to help students assess their performance and identify their strengths and weaknesses. The mock tests and practice papers are designed to simulate the actual JEE exam, providing students with a real-time experience.',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Regular Feedback and Performance Analysis:</strong> Aakash Institute provides regular feedback and performance analysis to its students. The faculty at Aakash Institute analyzes the performance of each student and provides feedback on their strengths and weaknesses. This helps students identify the areas where they need to improve and work on them.',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Motivational Sessions:</strong> Preparing for JEE can be mentally and emotionally draining. Aakash Institute understands this and provides motivational sessions to its students. These sessions help students stay motivated and focused on their goal.',
                    },
                ],
            },
            {
                scholarshipDataTitle: 'About Aakash Institute',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'Aakash Institute is a leading educational organization that has been helping students achieve their academic goals for more than three decades. Founded in 1988 by J.C. Chaudhry, the institute has since then grown to become one of the most trusted names in the field of education in India.',
                    },
                    {
                        scholarshipDataParagraph:
                            "The institute offers coaching programs for students preparing for various competitive exams such as NEET, JEE Main and Advanced, AIIMS, NTSE, KVPY, and Olympiads. With a team of highly qualified and experienced faculty members, Aakash Institute has been able to consistently produce top rankers in these exams.",
                    },
                    {
                        scholarshipDataParagraph:
                            'Apart from the coaching programs, Aakash Institute also offers digital learning solutions, including online live classes, recorded video lectures, and online test series. These digital learning solutions have been designed to provide students with the flexibility to learn at their own pace and convenience, without compromising on the quality of education.',
                    },
                    {
                        scholarshipDataParagraph:"Aakash Institute has a strong presence across India, with over 200 centers spread across 130 cities. The institute has also expanded its reach internationally with centers in Dubai and Nepal. The institute's extensive network ensures that students have access to quality education regardless of their location.",
                    }
                ],
            },
            {
                scholarshipDataTitle: 'JEE Coaching @ Aakash Institute',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'JEE (Joint Entrance Examination) is one of the most competitive exams in India that students take after completing their 12th grade. It is a gateway for students to get admission in the top engineering colleges in India. However, the exam is notoriously difficult and requires extensive preparation. This is where JEE coaching centers come in. One of the most popular coaching centers for JEE preparation is Aakash Institute.',
                    },
                    {
                        scholarshipDataParagraph:
                            "Aakash Institute provides coaching for both JEE Main and JEE Advanced exams. The institute has experienced faculty who are experts in their respective fields and have years of experience in coaching students for JEE exams. They use a combination of traditional classroom teaching and modern teaching techniques such as online classes, recorded video lectures, and online tests to help students prepare for the exams.",
                    },
                ],
            },
        ],
    },
]
export const banner_title=[
    {
        head_title: "JEE 2025 Repeater Courses <br/>for 12th Passed Batches",
        sub_head_title:"Up to 90%* Scholarship",
        type:"Online Instant Admission Cum Scholarship Test",
        registration:"Register For FREE"
    }
]