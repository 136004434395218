import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyCdN92ZEUgdn-zOnwZUOaddQnj1cBBWD4U",
    authDomain: "test-project---test-player.firebaseapp.com",
    projectId: "test-project---test-player",
    storageBucket: "test-project---test-player.appspot.com",
    messagingSenderId: "41056294694",
    appId: "1:41056294694:web:bc18f16a155967c71553e7",
    measurementId: "G-4Z6JMF08LY"
};

firebase.initializeApp(firebaseConfig);

export default firebase