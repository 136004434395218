const SIT_BASE_URL = `https://antheapi-qa.aakash.ac.in/api`
const PROD_BASE_URL = `https://antheapi.aakash.ac.in/api`

let production_base_url = {
    LOGIN_API: `${PROD_BASE_URL}/iacst_login_access`,
    DASHBOARD_API: `${PROD_BASE_URL}/iacst_dashboard`,
    EXAM_INFO_API: `${PROD_BASE_URL}/exam_info`,
    MASTER_API: `${PROD_BASE_URL}/master_data`,
    SCHOOL_API: `${PROD_BASE_URL}/get-school-list/`,
    EXAM_DATES_API: `${PROD_BASE_URL}/exam_dates_slots`,
    GENERATE_OTP_API: `${PROD_BASE_URL}/iacst_generate_otp_login`,
    VALIDATE_OTP_API: `${PROD_BASE_URL}/iacst_verify_otp_login`,
    UPDATE_EXAM_INFO_API: `${PROD_BASE_URL}/exam_info_update`,
    START_MAIN_EXAM_API: `${PROD_BASE_URL}/iacst-start-main-journey`,
    RESET_SAMPLE_EXAM_API: `${PROD_BASE_URL}/reset-sample`,
    RESULT_API: `${PROD_BASE_URL}/user_result`,
    IACST_RESULT_API: `https://anthe-cms.aakash.ac.in/api/get-iacst-result`,
    PROFILE_API: `${PROD_BASE_URL}/iacst_profile`,
    PROFILE_UPDATE_API: `${PROD_BASE_URL}/profile-update`,
    MOCK_PAYMENT_API: `${PROD_BASE_URL}/payment-options`,
    FORGOT_PASSWORD: `${PROD_BASE_URL}/anthe_forgot_password`,
    EXIGENCY_DATE_TIME_API: `${PROD_BASE_URL}/exigency_registration_date_time_check`,
    EXIGENCY_GENERATE_OTP_API: `${PROD_BASE_URL}/exigency_generate_otp`,
    EXIGENCY_VERIFY_OTP_API: `${PROD_BASE_URL}/exigency_validate_otp`,
    EXIGENCY_USER_REGISTER_API: `${PROD_BASE_URL}/exigency_user_register`,

    FETCH_BRANCHES: `${PROD_BASE_URL}/iacst-nearest-center/`,

    FETCH_COURSE_DATA: 'https://aakashapi.aakash.ac.in/api/course/list',
    PINCODE_API: 'https://aakashapi.aakash.ac.in/online-form/address',
    COURSE_PAYMENT_API: 'https://aakashapi.aakash.ac.in/api/get-course-payment-data',
    STATE_API: 'https://aakashapi.aakash.ac.in/online-form/state',
    TP_TOKEN_API:
        'https://testplayerapi-prod.aakash.ac.in/Prod/test_player/websiteToken',
    TP_RESULT_DATA:
        'https://testplayerapi-prod.aakash.ac.in/Prod/test_player/studentResult',

    TP_TOKEN_API_TOKEN: 'scholarship-website_WXD3ZnuMa5k4IEE4',

    FETCH_SCHOOL_BOARD: `${PROD_BASE_URL}/iacst-get-exam-board`,
    FETCH_CLASS_STREAM: `${PROD_BASE_URL}/iacst-get-class-stream`,
    FETCH_EXAM_STATE: `${PROD_BASE_URL}/iacst-get-exam-states`,
    FETCH_EXAM_CENTRE: `${PROD_BASE_URL}/iacst-get-exam-centers`,
    FETCH_EXAM_DATE: `${PROD_BASE_URL}/get-iacst-exam-dates`,

    SEND_OTP_API: `${PROD_BASE_URL}/iacst_generate_otp`,
    VERIFY_OTP_API: `${PROD_BASE_URL}/iacst_validate_otp`,

    SIGNUP_API: `${PROD_BASE_URL}/iacst-user-registration`,
    VERSION_BANNER:"1.0"
}

let uat_base_url = {
    LOGIN_API: `${SIT_BASE_URL}/iacst_login_access`,
    DASHBOARD_API: `${SIT_BASE_URL}/iacst_dashboard`,
    EXAM_INFO_API: `${SIT_BASE_URL}/exam_info`,
    MASTER_API: `${SIT_BASE_URL}/master_data`,
    SCHOOL_API: `${SIT_BASE_URL}/get-school-list/`,
    EXAM_DATES_API: `${SIT_BASE_URL}/exam_dates_slots`,
    GENERATE_OTP_API: `${SIT_BASE_URL}/iacst_generate_otp_login`,
    VALIDATE_OTP_API: `${SIT_BASE_URL}/iacst_verify_otp_login`,
    UPDATE_EXAM_INFO_API: `${SIT_BASE_URL}/exam_info_update`,
    START_MAIN_EXAM_API: `${SIT_BASE_URL}/iacst-start-main-journey`,
    RESET_SAMPLE_EXAM_API: `${SIT_BASE_URL}/reset-sample`,
    RESULT_API: `${SIT_BASE_URL}/user_result`,
    IACST_RESULT_API: `https://anthe-kell-cms.aakash.ac.in/api/get-iacst-result`,
    FORGOT_PASSWORD: `${SIT_BASE_URL}/anthe_forgot_password`,
    PROFILE_API: `${SIT_BASE_URL}/iacst_profile`,
    PROFILE_UPDATE_API: `${SIT_BASE_URL}/profile-update`,
    MOCK_PAYMENT_API: `${SIT_BASE_URL}/payment-options`,
    EXIGENCY_DATE_TIME_API: `${SIT_BASE_URL}/exigency_registration_date_time_check`,
    EXIGENCY_GENERATE_OTP_API: `${SIT_BASE_URL}/exigency_generate_otp`,
    EXIGENCY_VERIFY_OTP_API: `${SIT_BASE_URL}/exigency_validate_otp`,
    EXIGENCY_USER_REGISTER_API: `${SIT_BASE_URL}/exigency_user_register`,

    FETCH_BRANCHES: `${SIT_BASE_URL}/iacst-nearest-center/`,

    FETCH_COURSE_DATA: 'https://cms-sit.aakash.ac.in/api/course/list',
    PINCODE_API: 'https://cms-sit.aakash.ac.in/online-form/address',
    COURSE_PAYMENT_API: 'https://cms-sit.aakash.ac.in/api/get-course-payment-data',
    STATE_API: 'https://cms-sit.aakash.ac.in/online-form/state',
    TP_TOKEN_API:
        'https://testplayerapi-qa.aakash.ac.in/stage/test_player/websiteToken',
    TP_RESULT_DATA:
        'https://testplayerapi-qa.aakash.ac.in/stage/test_player/studentResult',

    TP_TOKEN_API_TOKEN: 'scholarship-website_R4tOTJq72gwx2Rex',

    FETCH_SCHOOL_BOARD: `${SIT_BASE_URL}/iacst-get-exam-board`,
    FETCH_CLASS_STREAM: `${SIT_BASE_URL}/iacst-get-class-stream`,
    FETCH_EXAM_STATE: `${SIT_BASE_URL}/iacst-get-exam-states`,
    FETCH_EXAM_CENTRE: `${SIT_BASE_URL}/iacst-get-exam-centers`,
    FETCH_EXAM_DATE: `${SIT_BASE_URL}/get-iacst-exam-dates`,

    SEND_OTP_API: `${SIT_BASE_URL}/iacst_generate_otp`,
    VERIFY_OTP_API: `${SIT_BASE_URL}/iacst_validate_otp`,

    SIGNUP_API: `${SIT_BASE_URL}/iacst-user-registration`,
    VERSION_BANNER:"1.0"
}

let config = {}

if (process.env.REACT_APP_ENVIRONMENT === `production`) {
    config = production_base_url
} else if (process.env.REACT_APP_ENVIRONMENT === `uat`) {
    config = uat_base_url
} else {
    config = uat_base_url
}

export default config
