import Icon from '../components/shared/IconBase'

const RightSlideIcon = (props) => (
    <Icon width="69" height="82" viewBox="0 0 69 82" fill="none" {...props}>
        <g filter="url(#filter0_d_4017_4159)">
            <circle cx="41" cy="40" r="21" fill="white" fillOpacity="0.9" />
            <path
                d="M37.6572 49.5244C37.3295 49.5252 37.009 49.4285 36.7364 49.2468C36.4637 49.065 36.2512 48.8064 36.1258 48.5037C36.0005 48.2009 35.9679 47.8678 36.0321 47.5465C36.0964 47.2252 36.2547 46.9302 36.487 46.6991L42.9239 40.2621L36.487 33.8251C36.3333 33.6715 36.2114 33.489 36.1282 33.2882C36.0451 33.0875 36.0022 32.8723 36.0022 32.6549C36.0022 32.4376 36.0451 32.2224 36.1282 32.0216C36.2114 31.8208 36.3333 31.6384 36.487 31.4847C36.6406 31.331 36.8231 31.2091 37.0239 31.126C37.2246 31.0428 37.4398 31 37.6572 31C37.8745 31 38.0897 31.0428 38.2905 31.126C38.4913 31.2091 38.6737 31.331 38.8274 31.4847L46.4345 39.0919C46.5884 39.2455 46.7104 39.4279 46.7937 39.6287C46.877 39.8295 46.9198 40.0447 46.9198 40.2621C46.9198 40.4795 46.877 40.6947 46.7937 40.8955C46.7104 41.0963 46.5884 41.2787 46.4345 41.4323L38.8274 49.0395C38.6739 49.1934 38.4915 49.3155 38.2907 49.3987C38.0898 49.4819 37.8746 49.5247 37.6572 49.5244Z"
                fill="#545454"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_4017_4159"
                x="0"
                y="0"
                width="82"
                height="82"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4017_4159"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4017_4159"
                    result="shape"
                />
            </filter>
        </defs>
    </Icon>
)

export default RightSlideIcon
