import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";

// const enHanceCreateStore = compose(
//   applyMiddleware(thunk),
//   reduxReset()
// )(createStore);

// const persistedReducer = persistReducer(persistConfig, appReducer);
// export const store = enHanceCreateStore(persistedReducer);
// export const persistor = persistStore(store);

const getMiddleware = () => {
  return process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : applyMiddleware(thunk);
};

export const store = createStore(
  rootReducer,
  composeWithDevTools(getMiddleware())
);
export const persistor = persistStore(store);
export default { store, persistor };
