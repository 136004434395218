import Login from './views/Login/login'
import Dashboard from './Layout/Dashboard/dashboard'
import IacstResults from './components/Result/IacstResults/result'
import { BrowserRouter, Switch, Route, useHistory, Redirect } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import EventContext from './eventContext'
import { handleEventLogs } from './events'

import './sass/custom.scss'
import ToastMessage from 'components/shared/ToastMessage'
import { connect } from 'react-redux'
import {
    SET_TOKEN,
    SET_ROLLNO,
    SET_DOB,
    SET_TOAST_DATA,
} from 'constants/actionTypes'
import ForgotPassword from 'views/Login/ForgotPassword'
import Exigency from './Layout/exigency/index'
import PageNotFound from 'views/404'
import Signup from 'views/signup'
import IacstNeetRepeaterScholarship from 'views/signup/NeetRepeaterScholarship'
import IacstMedicalEngineeringRepeaterCourse from 'views/signup/MedicalEngineeringRepeaterCourse'
import { usePutPostApi } from 'api/usePutPostApi'
import config from 'config'
import {
    extractQueryParams,
    filterParamsStartingWithUtm,
} from 'utils/getAllURLParameters'
import IacstNeetRepeaterHindi from 'views/signup/NeetRepeaterHindi'
import IacstJeeRepeaterScholarship from 'views/signup/JeeRepeaterScholarship'
import IacstMedicalEngineeringRepeaterCourssHindi from 'views/signup/MedicalEngineeringRepeaterCoursesHindi'
import Terms from 'views/terms/TermsandCondition'
import { SunspotLoader } from 'react-awesome-loaders'

const mapStateToProps = (state) => ({
    toastData: state.toastMsgs,
    token: state?.token,
})

const mapDispatchToProps = (dispatch) => ({
    setToken: (payload) => dispatch({ type: SET_TOKEN, payload }),
    setRollNo: (payload) => dispatch({ type: SET_ROLLNO, payload }),
    setDOB: (payload) => dispatch({ type: SET_DOB, payload }),
    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
})

const App = (props) => {
    // Auto Login
    const history = useHistory()
    const [rollno, setRollno] = useState('')
    const [dob, setDob] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    const { data, loading, setPutEnabled } = usePutPostApi(
        config.LOGIN_API,
        '',
        'post',
        { roll_number: rollno, date_of_birth: dob },
        false,
    )

    const queryString = window.location.search

    useEffect(() => {
        // auto-login
        let parameters = {}

        const queryParamsArray = queryString.replace('?', '').split(/&|=/)
        parameters = extractQueryParams(queryParamsArray)

        if (
            !queryParamsArray.includes('roll_no') &&
            !queryParamsArray.includes('dob') &&
            !queryParamsArray.includes('status') &&
            props.token !== null
        )
            history.replace('/dashboard')
        else if (
            !queryParamsArray.includes('roll_no') &&
            !queryParamsArray.includes('dob') &&
            props.token === null
        )
            props.setToken(null)

        if (queryParamsArray.includes('roll_no')) {
            setRollno(parameters['roll_no'])
        }
        if (queryParamsArray.includes('dob')) {
            setRollno(parameters['roll_no'])
            setDob(parameters['dob'])
            setPutEnabled(true)
        }
        // loginUser(parameters['roll_no'], parameters['dob'])
        else setIsLoading(loading)

        const utmParameters = filterParamsStartingWithUtm(parameters)
        localStorage.setItem('utmParams', JSON.stringify(utmParameters))

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (data && data.success === 'true') {
            props.setToken(data.token)
            props.setRollNo(rollno)
            props.setDOB(dob)

            history.push({
                pathname: '/dashboard',
            })
            setIsLoading(false)
        } else if (data && data.success === 'false') {
            props.setToastMsgsData({
                isToastOpen: true,
                toastType: 'danger',
                toastMessage: data?.msg || 'Something went wrong.',
            })
            setIsLoading(false)
        }

        // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        let timer
        if (props.toastData.isToastOpen)
            timer = setTimeout(
                () => {
                    props.setToastMsgsData({
                        isToastOpen: false,
                        toastMessage: '',
                    })
                },
                props.toastData.toastType === 'warn' ? 1500 : 5000,
            )

        return () => {
            clearTimeout(timer)
        }

        // eslint-disable-next-line
    }, [props.toastData.isToastOpen])

    useEffect(() => {
        // document.addEventListener("contextmenu", (event) => {
        //     alert("Right click is disabled for this site");
        //     event.preventDefault();
        //   });
        document.onkeydown = function (e) {
            if (e.keyCode === 123) {
                return false
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
                return false
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
                return false
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
                return false
            }
            if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
                return false
            }
        }
    }, [])

    return (
        <Fragment>
            {isLoading ? (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                    }}
                >
                    <SunspotLoader
                        gradientColors={['#4039d4', '#E0E7FF']}
                        size={'10px'}
                        desktopSize={'30px'}
                        mobileSize={'20px'}
                        className="m-auto"
                    />
                </div>
            ) : (
                <BrowserRouter>
                    <EventContext.Provider value={handleEventLogs}>
                        <Switch>
                            <Route
                                exact
                                path="/iacst-result"
                                render={() => <IacstResults />}
                            />
                            <Route exact path="/">
                                {props.token ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <Login />
                                )}
                            </Route>
                            <Route exact path="/exam/login">
                                {props.token ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <Login />
                                )}
                            </Route>
                            <Route exact path="/iacstexam">
                                {props.token ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <Signup />
                                )}
                            </Route>

                            <Route
                                exact
                                path="/recover-credentials"
                                render={() => <ForgotPassword />}
                            />

                            <Route
                                exact
                                path="/dashboard"
                                render={() => <Dashboard />}
                            />

                            <Route path="/anthe-exigency">
                                <Exigency />
                            </Route>

                            <Route path="/neet-repeater-scholarship">
                                {props.token ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <IacstNeetRepeaterScholarship />
                                )}
                            </Route>
                            <Route path="/iacst-medical-engineering-repeater-courses">
                                {props.token ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <IacstMedicalEngineeringRepeaterCourse />
                                )}
                            </Route>
                            <Route path="/neet-repeater-scholarship-hindi">
                                {props.token ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <IacstNeetRepeaterHindi />
                                )}
                            </Route>
                            <Route path="/jee-repeater-scholarship">
                                {props.token ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <IacstJeeRepeaterScholarship />
                                )}
                            </Route>
                            <Route path="/iacst-medical-engineering-repeater-courses-hindi">
                                {props.token ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <IacstMedicalEngineeringRepeaterCourssHindi />
                                )}
                            </Route>

                            <Route
                                path="/iacst-scholarship/terms-conditions"
                                render={() => <Terms />}
                            />
                            <Route path="/404" render={() => <PageNotFound />} />
                            <Route path="*" render={() => <PageNotFound />} />
                        </Switch>
                    </EventContext.Provider>

                    <ToastMessage
                        isToastOpen={props.toastData.isToastOpen}
                        toastType={props.toastData.toastType}
                        toastMessage={props.toastData.toastMessage}
                    />
                </BrowserRouter>
            )}
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
