import ResultAnalysisTable from '../resultTable/result-analysis-table'
import Footer from './footer'

import styles from './analysis-table.module.scss'

function AnalysisTable({ data, isPrinting }) {
    return (
        <div className={styles.wrapper}>
            <ResultAnalysisTable
                title={`FOR CLASS ${data?.result.ClassStudying} STUDYING STUDENT`}
                rollNo={`${data?.result.rollno} / ${data?.result.PSID}`}
                name={`${data?.result.Name}`}
                fname={`${data?.result.Fname}`}
                class={`${data?.result.stream} / ${data?.result.ClassOpted}`}
                result={`${data?.result.DisplayRemarks}`}
                rank={`${data?.rank_to_check_in_template}`}
                data={data}
            />
            <div className={styles.guidelines}>
                <p className={styles.header}>
                    ** As per the guidelines specified in the Anthe 2023 Brochure.
                </p>
                <div className={styles.notes}>
                    <h4 className={styles.heading}>Please Note:</h4>
                    <ul className={styles.list}>
                        <li>
                            If at any point of time, the Govt. Charges GST/Service
                            Tax or any other tax on Scholarship granted, it will be
                            borne by the Students only.
                        </li>
                        <li>
                            Technology Fee &amp; Examination Fee will be charged from
                            students as per the prospectus except for Top 500
                            students in Class VII, VIII, IX &amp; X studying (Medical
                            &amp; Engineering) and 200 students in Class XI &amp; XII
                            Studying (Medical &amp; Engineering), who are exempted
                            from this fee as a special beneﬁt. .
                        </li>
                        <li>
                            The highest amount of scholarship will not exceed 100% of
                            the overall fee in any case whatsoever.
                        </li>
                    </ul>
                </div>
                <div className={styles.box}>
                    <div className={styles.left}>
                        <p>
                            Complete your admission formalities by 31st Jan. to avail
                            attractive <span>Early Admission Benefits</span>
                        </p>
                    </div>
                    <div className={styles.right}>
                        <p>
                            The secured scholarship is applicable on classroom
                            courses at any Aakash BYJU'S Branches and Centre
                            throughout India.
                        </p>
                    </div>
                </div>
            </div>
            {isPrinting ? <Footer /> : null}
        </div>
    )
}

export default AnalysisTable
