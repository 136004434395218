import React from 'react'
import styles from './footer.module.scss'

function Footer() {
    return (
        <div className={styles.wrapper}>
            <p className={styles.left}>
                <strong>Corporate Office : </strong>Aakash Tower, 8, Pusa Road, New
                Delhi-110005.
                <br />
                <strong>Ph.</strong>: (011) 47623456 | <strong>E-mail: care</strong>
                @aesl.in
            </p>

            <div className={styles.right}>
                <img
                    alt="phone-icon"
                    src="https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/phone.png"
                />
                <div>
                    <small className={styles.number}>TOLL FREE NUMBER</small>
                    <br />
                    1800-102-2727
                </div>
            </div>
        </div>
    )
}

export default Footer
