import React from 'react'
import COMING_SOON from 'assets/pdf/coming-soon-image.png'
import styles from './coming-soon.module.scss'

function ComingSoon() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.text}>
                <p className={styles.header}>Your result would be available soon.</p>
                <p className={styles.description}>
                    Please connect at{' '}
                    <a href="mailto:anthesupport@aesl.in"> anthesupport@aesl.in</a>{' '}
                    for any query regarding your result.
                </p>
            </div>
            <img className={styles.img} src={COMING_SOON} alt="coming-so" />
        </div>
    )
}

export default ComingSoon
