import React from 'react'

import PHONE from 'assets/pdf/phone.png'
import styles from './footer.module.scss'

function Footer() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <p className={styles.content}>
                    <strong>Corporate office : </strong>
                    Aakash Tower, 8, Pusa Road, New Delhi-110005.
                    <br />
                    <strong>Ph.</strong>: (011) 47623456 |<strong>E-mail:</strong>
                    care@aesl.in
                </p>
                <div className={styles.contact}>
                    <img src={PHONE} alt="phone" />
                    <div className={styles.phone}>
                        <small className={styles.small}>TOLL FREE NUMBER</small>
                        <span className={styles.number}>1800-102-2727</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
