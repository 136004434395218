import styles from './paymentForm.module.css'
import { FormGroup, Input, Label } from 'reactstrap'
import Paytm from '../../assets/paytm.png'
import CCavenue from '../../assets/ccavenue-logo.png'

function MobilePaymentForm({
    paymentMethod,
    handlePaymentMethod,
    logoBgColor = '#fff',
}) {
    return (
        <>
            <FormGroup className={`mx-2 ${styles.fitContent}`} check>
                <Label check className="d-flex align-items-center">
                    <Input
                        type="radio"
                        name="paytm"
                        key={Math.random(5)}
                        checked={paymentMethod === 'paytm'}
                        onChange={handlePaymentMethod}
                    />
                    <img
                        src={Paytm}
                        width={70}
                        alt="paytm-logo"
                        className={styles.paymentMethodLogo}
                        style={{ backgroundColor: logoBgColor }}
                    />
                </Label>
            </FormGroup>

            <FormGroup className={styles.fitContent} check>
                <Label check className="d-flex align-items-center">
                    <Input
                        type="radio"
                        name="hdfc"
                        key={Math.random(5)}
                        checked={paymentMethod === 'hdfc'}
                        onChange={handlePaymentMethod}
                    />
                    {/* <img
                        src={CCavenue}
                        alt="cc-avenue-logo"
                        style={{ backgroundColor: logoBgColor }}
                        className={styles.paymentMethodLogo}
                        width={90}
                        height={25}
                    /> */}
                    <span className={`${styles.paymentMethodLogo} font-size-xs fw-lighter`} style={{ backgroundColor: logoBgColor }}>All Others</span>
                </Label>
            </FormGroup>
        </>
    )
}

export default MobilePaymentForm
