import React from 'react'
import cx from 'classnames'

import styles from './scholarship-awards.module.scss'

function ScholarshipAwards({ isPrinting }) {
    return (
        <div className={cx(styles.wrapper, { [styles.print]: isPrinting })}>
            <div className={styles.box}>
                <div className={styles.header}>
                    <h6>For Class VII, VIII &amp; IX Studying Students</h6>
                </div>
                <div className={styles.table}>
                    <div className={styles.awardBox}>
                        <div className={styles.icon}>
                            <img
                                alt="icon"
                                data-entity-type="file"
                                data-entity-uuid="8d23417b-b878-4c7c-b7b3-7c15141fde7c"
                                src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/cash-awards-icon-2022.png"
                            />
                            <h6>Cash Awards*</h6>
                        </div>
                        <p>
                            Top 100 ranking students in each class will also be
                            eligible for Cash Awards*. This table represents the
                            rank-wise Cash Awards details for each class category
                            (i.e. for Class VII, VIII &amp; IX studying students).
                        </p>
                    </div>
                    <div className={styles.awardsTable}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Rank</th>
                                    <th>No. of Students</th>
                                    <th>Classroom Course (Type-A)</th>
                                    <th>
                                        Classroom / Live / <br /> Tab / DLP Course
                                        (Type-B){' '}
                                    </th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>₹ 1,00,000/-</td>
                                    <td>₹ 50,000/-</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1</td>
                                    <td>₹ 75,000/-</td>
                                    <td>₹ 40,000/-</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1</td>
                                    <td>₹ 50,000/-</td>
                                    <td>₹ 30,000/-</td>
                                </tr>
                                <tr>
                                    <td>4 to 10</td>
                                    <td>7</td>
                                    <td>₹ 25,000/-</td>
                                    <td>₹ 15,000/-</td>
                                </tr>
                                <tr>
                                    <td>11 to 50</td>
                                    <td>40</td>
                                    <td>₹ 15,000/-</td>
                                    <td>₹ 10,000/-</td>
                                </tr>
                                <tr>
                                    <td>51 to 100</td>
                                    <td>50</td>
                                    <td>₹ 10,000/-</td>
                                    <td>₹ 5,000/-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.resultTypes}>
                    <p>
                        <strong>Type-A:</strong> These Cash Awards are for those
                        students who will be joining Aakash BYJU’S for Two Year /
                        Three Year / Four Year Integrated Classroom Courses for Class
                        IX &amp;X / Class VIII to X / Class X to XII / Class IX to
                        XII respectively.
                    </p>
                    <p>
                        <strong>Type-B:</strong>These Cash Awards are for those
                        students who will be joining Aakash BYJU’S for One Year
                        Integrated Classroom Courses for Class VIII, Class IX or
                        Class X or Long Term Aakash BYJU’s Live / Tab / DLPCourse.
                    </p>
                </div>
                <div className={styles.terms}>
                    <h4>
                        <strong>
                            *Necessary Information/ Conditions about Cash Awards:
                        </strong>
                    </h4>
                    <ul>
                        <li>
                            A student will be eligible for Cash Award in only one
                            category out of the two programs either Classroom Course
                            or Long Term Aakash BYJU’s Live / Tab / DLP Course and
                            not in both.
                        </li>
                        <li>
                            To be eligible for Cash Award, it is mandatory for top
                            300 students{' '}
                            <strong>
                                {' '}
                                (100 students from each class i.e. moving to Class
                                VIII, IX &amp;X){' '}
                            </strong>{' '}
                            to join our Long Term Aakash BYJU’s Live / Tab / DLP
                            Course before <strong> 1st March 2023 </strong>{' '}
                            irrespective of whether they join our Classroom Course or
                            not. Once the Long Term Aakash BYJU’s Live / Tab / DLP
                            Course Enrollment is done, it will not be cancelled in
                            any case. The students will fill separate Enrollment Form
                            for Long Term Aakash BYJU’s Live / Tab / DLP Course. No
                            fee will be charged for Long Term Aakash BYJU’s Live /
                            Tab / DLP Course. It will be 100% Free.{' '}
                        </li>
                        <li>
                            Students joining Two Year Integrated Classroom / Aakash
                            BYJU’s Live / Tab / DLP Course for Class IX &amp;X or
                            Three Year Integrated Course for Class VIII to X / Class
                            X to XII or Four Year Integrated Course for Class IX to
                            XII will get 40% of total Cash Awards in Academic Year
                            2023-2024 which will be disbursed in two equal
                            installments (1st award installment by 15th June 2023
                            &amp; 2nd award installment by 15th November 2023) and
                            remaining 60% of Cash Awards will be disbursed in
                            Academic Year 2024-2025 in 2 equal installments (3rd
                            award installment by 15th June 2024 &amp;4th award
                            installment by 15th November 2024).
                        </li>
                        <li>
                            Students joining One Year Integrated Classroom Course for
                            Class VIII / IX / X or Long Term Aakash BYJU’s Live / Tab
                            / DLP Courses will get total Cash Awards disbursed in two
                            equal installments (1st award installment by 15th June
                            2023 &amp;2nd award installment by 15th November 2023).
                        </li>
                    </ul>
                    <div className={styles.break} />
                    <ul className={styles.margin}>
                        <li>
                            Cash Awards shall be paid by cheque. Installments will be
                            paid by 15th June &amp;15th November of respective
                            academic years as detailed above, by the respective
                            centre where the student appeared for ANTHE or enrolled
                            in AESL.
                        </li>
                        <li>
                            To be eligible for Cash Awards, a student needs to
                            maintain a minimum of 85% attendance in classes and tests
                            conducted by AESL failing which it will lead to
                            discontinuation of that installment of Cash Awards.
                        </li>
                        <li>
                            A student should maintain good performance i.e. score a
                            minimum average of 65% marks in all Assessment Tests
                            conducted by AESL(including All India Aakash Test Series
                            - AIATS) or maintain an AIR in Top 300 in every AIATS as
                            part of Classroom Course or Long Term Aakash BYJU’s Live
                            / Tab / DLP Course failing which will lead to
                            discontinuation of that installment of Cash Awards.
                        </li>
                        <li>
                            The final decision in both cases i.e. clause (6) and (7)
                            vests with the Managing Director, AESL and his decision
                            will be final &amp; binding on the student(s) /
                            parent(s).
                        </li>
                        <li>
                            Students studying in Class VII, VIII &amp;IX who take
                            admission with AESL and pay their Registration Fee,
                            Admission Kit &amp; Digital Access Fee and Tuition Fee
                            (1st Installment) on or before{' '}
                            <strong> 1st March 2023 </strong> will be allowed
                            all-features access to Recorded Video Lectures till{' '}
                            <strong> 31st March 2023.</strong>
                        </li>
                        <li>
                            The scholarship criteria mentioned in{' '}
                            <strong> Table-1 &amp;2 </strong> is applicable for
                            Regular Classroom Courses only.{' '}
                        </li>
                    </ul>
                    <p className={styles.resultTypes}>
                        <strong>NOTE</strong>: In case a student is found to have
                        used unfair means during the online/offline ANTHE test,
                        he/she shall be disqualified from the ANTHE Scholarship
                        Scheme &amp;Cash Awards.
                    </p>
                </div>
                <div className={styles.feeStructure}>
                    <span>
                        A. Fee Information for One Year / Two Year / Three Year /
                        Four Year Classroom Course for Class VIII, IX &amp; X.
                    </span>
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <strong>Fee Components</strong>
                                </th>
                                <th>
                                    <strong>
                                        <strong>Classroom Course</strong>
                                    </strong>
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        <strong>Registration Fee</strong>
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        <strong>
                                            ₹ 8000/- (inclusive of GST @ 18%)
                                        </strong>
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        <strong>Others</strong>
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        <strong>
                                            Please check with the counsellor
                                        </strong>
                                    </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.footer}>
                    <p>
                        <strong>Note:</strong> For more information, visit:{' '}
                        <strong>
                            <a href="https://anthe.aakash.ac.in/home">
                                www.anthe.aakash.ac.in
                            </a>
                        </strong>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ScholarshipAwards
