import styles from "./footer.module.css";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div style={{ width: "90%" }}>
        <div className={styles.footerContent}>
          <div>
            <span>Contact Us</span>
            <ul style={{ display: "block" }}>
              <li>
                <a
                  href="https://anthe.aakash.ac.in/exam/tel:9818-543-543"
                  target="_blank"
                >
                  <img
                    src="https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/call.png"
                    style={{ height: "20px" }}
                  />
                </a>
                1800-103-2727, 7303-529-494
              </li>
              <li>
                <a href="mailto:acstsupport@aesl.in" target="_blank">
                  <img
                    src="https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/msg.png"
                    style={{ height: "20px" }}
                  />
                </a>
                anthesupport@aesl.in
              </li>
              <li>
                <a>
                  <img
                    src="https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/location.png "
                    style={{ height: "20px" }}
                  />
                </a>
                8, Aakash Tower, Pusa Road,
                <br />
                New Delhi, 110005, India
              </li>
            </ul>
          </div>
          <div>
            <span>Follow Us On</span>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/aakasheducation"
                  target="_blank"
                >
                  <img
                    alt
                    src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/facebook.png"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://in.linkedin.com/company/aakash-educational-services-limited"
                  target="_blank"
                >
                  <img src="https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/linkedin.png" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/aakasheducation/"
                  target="_blank"
                >
                  <img src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/instagram.png" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/AESL_Official" target="_blank">
                  <img src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/twitter.png" />
                </a>
              </li>
              <li>
                <a href="https://blog.aakash.ac.in/" target="_blank">
                  <img src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/bloger.png" />
                </a>
              </li>
            </ul>
          </div>
          <div>
            <span>Download Student App</span>
            <ul>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mobicule.aakash.aakashstudentapp.aakshaapp&hl=en_IN"
                  target="_blank"
                >
                  <img src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/google-play.png" />
                </a>
              </li>
              <li>
                <a
                  href="https://apps.apple.com/in/app/aakash-student-app/id1479972599"
                  target="_blank"
                >
                  <img src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/themes/anthe/images/html-images/app-store.png" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ height: "1px", backgroundColor: "#fff" }}></div>
        <div
          style={{
            padding: "10px 0px 100px 0px",
            textAlign: "center",
            color: "#adadad",
          }}
        >
          Copyright 2021 |{" "}
          <a
            target="_blank"
            href="https://www.aakash.ac.in/"
            style={{ color: "#adadad" }}
          >
            www.aakash.ac.in
          </a>{" "}
          | Disclaimer
        </div>
      </div>
    </div>
  );
};
export default Footer;
