import logo from "../../../../assets/aakash-byjus-logo.svg";
import style from "../../resultNotFound/resultNotFound.module.css";

const IacstResultNotFound = (props) => {
  return (
    <div className={style.result_coming_soon}>
      <div className={style.coming_logo}>
        <img src={logo} />
      </div>

      <div style={{ marginTop: "5%" }} className={style.coming_section}>
        <div className={style.comimg_text}>
          <h3>Your result would be available soon.</h3>

          <p>
            Please connect at{" "}
            <a href="mailto:acstsupport@aesl.in">acstsupport@aesl.in</a> for any
            query regarding your result.
          </p>
          {!props?.apiError ? (
            <p className={style.noResultText}>
              Your results would be available in the Exam/Result sections after
              4 hours of test submisson once you log into your iacst account.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className={style.anthe_coming_img}>
          <img
            alt="img"
            className={style.img_responsive_coming}
            src="https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/coming-soon-image-result-2021.png"
          />
        </div>
      </div>
    </div>
  );
};

export default IacstResultNotFound;
