/* eslint-disable no-console */
export const COUNTRY_ISO_CODES = {
    INDIA: 'IN',
    UAE: 'AE',
}

export default function isPhoneNumber({ phone = '', countryIsoCode }) {
    if (!phone) {
        return false
    }

    if (!countryIsoCode) {
        console.error('Country ISO code is not provided')
        return false
    }
    phone = phone + ''
    const re = /^[0-9]+$/g
    let isDigitRangeMatched = false

    switch (countryIsoCode) {
        case COUNTRY_ISO_CODES.INDIA:
            isDigitRangeMatched = phone.length === 10
            break

        case COUNTRY_ISO_CODES.UAE:
            isDigitRangeMatched = phone.length <= 10
            break

        default:
            // For other countries
            isDigitRangeMatched = phone.length > 0
            break
    }

    return isDigitRangeMatched && re.test(phone)
}
