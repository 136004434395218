import React from 'react'
import { format, isToday } from 'date-fns'
import { Button } from 'reactstrap'

import { ReactComponent as SuccessConfetti } from '../../assets/successConfetti.svg'
import { ReactComponent as CopyIcon } from '../../assets/copyIcon.svg'
import { ReactComponent as ClockIcon } from '../../assets/clockIcon.svg'
import styles from './signupStyles.module.css'

import { connect } from 'react-redux'
import { SET_TOAST_DATA } from 'constants/actionTypes'

const mapDispatchToProps = (dispatch) => ({
    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
})

const SignupSuccessful = ({ signupSuccessDetails, setToastMsgsData }) => {
    React.useEffect(() => {
        window.scroll({
            top: window.innerWidth > 768 ? 130 : 375,
            behavior: 'smooth',
        })
    }, [])

    React.useEffect(() => {
        const urlWithParams = new URL(window.location.href);
        urlWithParams.searchParams.set('pmstatus', 'success');
        window.history.replaceState({}, '', urlWithParams.href);
	window.dataLayer.push({
            event: 'iacst_complete_registration_v1',
        }) 
        // Clean up function to revert the URL back to its original state
        return () => {
          window.history.replaceState({}, '', window.location.href);
        };
    }, []);

    const handleCopyRollNum = () => {
        navigator.clipboard
            .writeText(signupSuccessDetails.roll_number)
            .then(function () {
                setToastMsgsData({
                    isToastOpen: true,
                    toastType: 'warn',
                    toastMessage: 'Copied to clipboard !',
                })
            })
            .catch((err) => {
                setToastMsgsData({
                    isToastOpen: true,
                    toastType: 'error',
                    toastMessage: 'Could not copy text.',
                })
            })
    }

    const formattedDate = () => {
        return format(new Date(signupSuccessDetails.exam_date), 'dd MMM yyyy')
    }

    const isExamToday = isToday(new Date(signupSuccessDetails.exam_date))

    return (
        <>
            <div className="text-center">
                <SuccessConfetti />
                <p className="fw-lighter font-size-lg text-dark-green">
                    Registration Successful
                </p>
            </div>

            <div className="py-4 px-3 my-4 bg-light text-light-grey-200">
                <div>
                    <p className="fw-lighter mb-2">Login details</p>
                    <div className="d-flex align-item-center justify-content-between">
                        <p className="font-size-sm mb-0">
                            Roll number:{' '}
                            <span className="fw-500">
                                {signupSuccessDetails.roll_number}
                            </span>
                        </p>

                        <p
                            className="text-aqua fw-lighter font-size-xs mb-0 cursor-pointer"
                            onClick={handleCopyRollNum}
                        >
                            Copy
                            <span className="ms-2">
                                <CopyIcon />
                            </span>
                        </p>
                    </div>

                    <div className="d-flex align-item-center justify-content-between mt-1">
                        <p className="font-size-sm">
                            Password:{' '}
                            <span className="fw-500">
                                {signupSuccessDetails.dob}
                            </span>
                        </p>
                        <p className="text-grey font-size-xs">Date of birth</p>
                    </div>
                </div>

                <div style={{ borderTop: '2px dotted #757575' }}>
                    <p className="fw-lighter mt-3 mb-2">Exam schedule</p>
                    {signupSuccessDetails.exam_date && (
                        <p className="font-size-sm mb-1">
                            {formattedDate()}, 9 AM - 10 PM
                        </p>
                    )}

                    <div className="d-flex align-item-center">
                        <ClockIcon />

                        <p className="font-size-xs mb-0 ms-2">
                            Exam duration - 60 mins
                        </p>
                    </div>
                </div>
            </div>

            <div className="p-3 pb-0 shadow-top">
                <p className="text-center fw-500 font-size-sm text-grey-black">
                    {isExamToday ? 'To begin the test' : `For test prep guidance`}
                </p>
                <Button
                    className={`btn btn-aqua text-white w-100 fw-bold font-size-sm ${styles.sendOtpButton}`}
                    onClick={() => {
                        window.location.href = signupSuccessDetails.dashboard_link
                    }}
                >
                    Go to dashboard
                </Button>
            </div>
        </>
    )
}

export default connect(null, mapDispatchToProps)(SignupSuccessful)
