import cx from 'classnames'
import Parser from 'html-react-parser'

import Header from './header'
import ScholarshipAwards from './scholarship-awards'
import AAKASH_LOGO from 'assets/pdf/aakash-anthe-logo.png'
import BIGGER_STRONGER_BETTER from 'assets/pdf/bigger-stronger-better.png'
import EARLY_ADMISSION from 'assets/pdf/early-admission.png'
import TESTIMONIAL_NEET from 'assets/pdf/testimonial-neet.png'
import RESULT_ANTHE from 'assets/pdf/result-figure-anthe.png'
import ANTHE_MAP from 'assets/pdf/anthe-map.png'

import styles from './anthe-cms.module.scss'

const AntheCmsData = (props) => {
    const { data, isPrinting, isSafariBrowser, isMobileBrowser } = props

    return (
        <>
            {data?.result?.Status === 'Qualified' && !isMobileBrowser ? (
                <div
                    className={cx(
                        styles.page,
                        { [styles.safari]: isSafariBrowser },
                        { [styles.mobile]: isMobileBrowser },
                        { [styles.print]: isPrinting },
                    )}
                >
                    <div style={{ pageBreakAfter: 'always' }}></div>
                    {/* <div className={styles.headerHtml}>
                        {data?.cms_data.header_html
                            ? Parser(`${data?.cms_data.header_html}`)
                            : null}
                    </div> */}
                    <Header />
                    <div style={{ textAlign: 'center' }}>
                        {data?.cms_data.admission_formalities
                            ? Parser(`${data?.cms_data.admission_formalities}`)
                            : null}
                    </div>
                </div>
            ) : (
                <></>
            )}
            {data?.result.Status === 'Qualified' ? (
                <ScholarshipAwards isPrinting={isPrinting} />
            ) : null}

            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                {isPrinting ? (
                    <img
                        className={styles.logo}
                        src={AAKASH_LOGO}
                        alt="anthe logo"
                    />
                ) : null}
                <img
                    className={cx(styles.biggerStrongerImg, {
                        [styles.print]: isPrinting,
                    })}
                    src={BIGGER_STRONGER_BETTER}
                    alt="bigger stronger better"
                />
            </div>

            <div className={styles.break} />
            <div
                className={cx(
                    cx(
                        styles.page,
                        { [styles.safari]: isSafariBrowser },
                        { [styles.mobile]: isMobileBrowser },
                        { [styles.print]: isPrinting },
                    ),
                    styles.earlyAdmission,
                )}
            >
                {isPrinting ? (
                    <img
                        className={styles.logo}
                        src={AAKASH_LOGO}
                        alt="anthe logo"
                    />
                ) : null}
                <img
                    className={cx(styles.img, {
                        [styles.print]: isPrinting,
                    })}
                    src={EARLY_ADMISSION}
                    alt="early admission"
                />
                <div className={styles.concession}>
                    <div className={styles.registration}>
                        <p>
                            Complete your admission formalities by 31st Jan. to avail
                            attractive{' '}
                            <span className={styles.yellow}>
                                Early Admission Benefits
                            </span>
                        </p>
                    </div>
                    <p className={styles.secured}>
                        The secured scholarship is applicable on classroom courses at
                        any Aakash BYJU'S Branches and Centre throughout India.
                    </p>
                </div>
                <div className={styles.recorded}>
                    <p>
                        *Recorded Video Lectures & Daily Practice Tests are not
                        available for students studying in Class VII.
                    </p>
                    <p>
                        <strong>Note:</strong>
                        The above mentioned enrollment benefits will vary class-wise.
                    </p>
                </div>
            </div>

            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                {isPrinting ? (
                    <img
                        className={styles.logo}
                        src={AAKASH_LOGO}
                        alt="anthe logo"
                    />
                ) : null}
                <img
                    className={cx(styles.testimonialNeet, {
                        [styles.print]: isPrinting,
                    })}
                    src={TESTIMONIAL_NEET}
                    alt="neet testimonial"
                />
            </div>

            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                {isPrinting ? (
                    <img
                        className={cx(styles.logo, styles.abs)}
                        src={AAKASH_LOGO}
                        alt="anthe logo"
                    />
                ) : null}
                <img
                    className={cx(styles.graph, {
                        [styles.print]: isPrinting,
                    })}
                    src={RESULT_ANTHE}
                    alt="result anthe"
                />
            </div>

            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                <img
                    className={cx(styles.map, {
                        [styles.print]: isPrinting,
                    })}
                    src={ANTHE_MAP}
                    alt="anthe map"
                />
            </div>
        </>
    )
}

export default AntheCmsData
