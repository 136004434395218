import { Modal, ModalBody, ModalFooter } from 'reactstrap'

function ConfirmationModal({ closeTheModal, notWantToConductExigency }) {
    /**
     * if clicked on NO, redirect to '/not-conducted'
     * if YES, close the modal
     */

    return (
        <Modal
            isOpen={true}
            className="w-8 w-md-4 w-xl-3 mx-auto"
            contentClassName="bg-light-blue py-3"
            centered
        >
            <ModalBody className="font-size-md fw-lighter text-center">
                Do you want to take ANTHE exam at this Branch?
            </ModalBody>

            <ModalFooter className="border-0 w-9 w-lg-7 mx-auto d-flex justify-content-between">
                <button
                    className={`w-4 btn btn-aqua opacity-50 text-white font-size-sm fw-lighter rounded-10px`}
                    style={{ padding: '11px' }}
                    onClick={notWantToConductExigency}
                >
                    No
                </button>
                <button
                    className={`w-4 btn btn-aqua text-white font-size-sm fw-lighter rounded-10px`}
                    style={{ padding: '11px' }}
                    onClick={closeTheModal}
                >
                    Yes
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal
