import { useState, useEffect } from 'react'
import { FormGroup, Input, Label, Modal, ModalBody, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import SelectCreatable from 'utils/SelectDropdown'
import MobilePaymentForm from 'components/shared/MobilePaymentForm'
import { coursePurchase } from 'api/api'
import PaymentForm from 'components/Card/paymentForm'
import {
    SET_ECOMMERCE_FAILED,
    SET_INPUT_FIELD_DATA,
    SET_VISIBLE_COURSE_DESC,
    SET_VISIBLE_COURSE_REGISTER,
    SET_RETRY_PAYMENT,
} from 'constants/actionTypes'
import FixedBottomPrice from './FixedBottomPrice'
import backArrow from '../../assets/backArrow.png'
import ArrowNext from '../../assets/ArrowNext'
import AlertIcon from 'assets/AlertIcon'
import { getPincode, getState } from 'api/api'
import { useMediaQuery } from 'react-responsive'
import MaskString from 'utils/MaskString'

const mapStateToProps = (state) => {
    return {
        token: state?.token,
        profileInfo: state?.profileData,
        ecommerce: state?.ecommerce,
        dashboardData: state?.dashboardData,
    }
}

const mapDispatchToProps = (dispatch) => ({
    setInputFieldData: (payload) => {
        dispatch({ type: SET_INPUT_FIELD_DATA, payload })
    },
    setCourseRegisterVisible: (payload) => {
        dispatch({ type: SET_VISIBLE_COURSE_REGISTER, payload })
    },
    setCourseDescVisible: (payload) => {
        dispatch({ type: SET_VISIBLE_COURSE_DESC, payload })
    },
    setecommerceFailed: (payload) => {
        dispatch({ type: SET_ECOMMERCE_FAILED, payload })
    },
    setRetryPayment: (payload) => {
        dispatch({ type: SET_RETRY_PAYMENT, payload })
    },
})

const CheckOutForm = ({
    profileInfo,
    ecommerce,
    setInputFieldData,
    dashboardData,
    setCourseRegisterVisible,
    setCourseDescVisible,
    setecommerceFailed,
    setRetryPayment,
}) => {
    const isSmallDevice = useMediaQuery({ query: '(max-width: 992px)' })

    const {
        mobile_number,
        roll_number,
        student_dob,
        student_psid,
        exam_type,
        class: division,
        classtream,
        stream,
        branch_code,
    } = profileInfo

    const { product_title, product_id, product_course_term, registration_fees } =
        ecommerce?.finalCourseDetailsPayload

    const { scholarship_msg, overall_percentage, scholarship_percentage } =
        dashboardData

    const focusAndScrollToField = (fieldName) => {
        const fieldId = `${fieldName.toLowerCase()}`
        const fieldElement = document.getElementById(fieldId)
        const addressDetails =
            document.getElementById('addressDetails').getBoundingClientRect().top +
            window.scrollY
        if (fieldElement) {
            fieldElement.focus()
            window.scrollTo({ top: addressDetails - 80, behavior: 'smooth' })
        }
    }

    const handleCheckout = () => {
        const newErrors = {}
        let firstErrorFound = false

        if (!addressFormData.billingAddress.trim()) {
            if (!firstErrorFound) {
                focusAndScrollToField('address')
                firstErrorFound = true // Update the flag to indicate the first error field is found
            }
            newErrors.billingAddress = 'Billing address is required'
        }
        if (!addressFormData.pincode.trim()) {
            if (!firstErrorFound) {
                focusAndScrollToField('pincode')
                firstErrorFound = true
            }
            newErrors.pincode = 'Pincode is required'
        } else if (addressFormData.pincode.length !== 6) {
            if (!firstErrorFound) {
                focusAndScrollToField('pincode')
                firstErrorFound = true
            }
            newErrors.pincode = 'Pincode must be 6 characters long'
        }
        if (addressFormData.city.length === 0) {
            if (!firstErrorFound) {
                focusAndScrollToField('city')
                firstErrorFound = true
            }
            newErrors.city = 'City is required'
        }
        if (addressFormData.userSelected.state.value === '') {
            if (!firstErrorFound) {
                focusAndScrollToField('state')
                firstErrorFound = true
            }
            newErrors.state = 'State must be selected'
        }
        if (!isTandCChecked) {
            if (!firstErrorFound) {
                focusAndScrollToField('termsAndConditions')
                firstErrorFound = true
            }
            newErrors.TAC = 'Please agree to the terms and conditions.'
        }

        setErrors(newErrors)

        // Webengage Call for Proceed to Payment
        window.webengage.track('iACST_db_pr_user_details_proceed_to_payment', {
            'mobile number': mobile_number,
            'roll number': roll_number,
            psid: student_psid ? student_psid : 'NA',
            exam_type: exam_type,
            class_studying: division.toString(),
            class_opted: classtream.split('&')[0],
            stream: stream,
            center_code: branch_code,
            course_name: product_title,
            course_id: product_id,
            course_term: product_course_term,
            scholarship_waiver_message: scholarship_msg,
            score_percent: overall_percentage,
            scholarship_percentage: scholarship_percentage,
            billing_pincode: addressFormData.pincode,
            billing_State: addressFormData.userSelected.state.value,
            billing_city: addressFormData.city,
            billing_address: addressFormData.billingAddress,
            course_registration_fee: registration_fees,
        })

        if (Object.keys(newErrors).length === 0) {
            setIsLoading(true)

            const InputFieldAddressAndPaymentData = {
                ...addressFormData,
                paymentMethod: paymentMethod,
            }

            setInputFieldData(InputFieldAddressAndPaymentData)
            setRetryPayment(false)

            coursePurchase(checkoutPayload)
                .then((res) => {
                    if (res.data.success === 'true') {
                        setfilteredFormValues(res.data.data)
                        localStorage.setItem(
                            'iacst_order_id',
                            res.data.data.order_id || res.data.data.ORDER_ID,
                        )
                        return res.data.data.order_id || res.data.data.ORDER_ID
                    } else {
                        console.log('Error occurred: ', res.data.msg)
                    }
                })
                .catch((error) => {
                    console.error('Error occurred during course purchase:', error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const defaultSelectedPayment = ecommerce?.inputFieldData?.paymentMethod
        ? ecommerce?.inputFieldData?.paymentMethod
        : 'hdfc'
    const [paymentMethod, setPaymentMethod] = useState(defaultSelectedPayment)
    const [isLoadingPayment, setIsLoading] = useState(false)
    const [filteredFormValues, setfilteredFormValues] = useState({})
    const [isTandCChecked, setIsTandCChecked] = useState(true)
    const [addressFormData, setAddressFormData] = useState({
        city: ecommerce?.inputFieldData?.city ? ecommerce?.inputFieldData?.city : '',
        pincode: ecommerce?.inputFieldData?.pincode
            ? ecommerce?.inputFieldData?.pincode
            : '',
        billingAddress: ecommerce?.inputFieldData?.billingAddress
            ? ecommerce?.inputFieldData?.billingAddress
            : '',
        userSelected: {
            state: ecommerce?.inputFieldData?.userSelected?.state
                ? {
                      label: ecommerce?.inputFieldData?.userSelected?.state.label,
                      value: ecommerce?.inputFieldData?.userSelected?.state.value,
                  }
                : '',
        },
    })
    const [errors, setErrors] = useState({})
    const [modalopen, setModalOpen] = useState(true)

    const getSelectDefaultValue = (value, array) => {
        if (value === '') return false
        return array.find(
            (a) =>
                a.value.toString().toLowerCase() ===
                value?.value.toString().toLowerCase(),
        )
    }

    const handlePaymentMethod = (e) => {
        let name = e.target.name.toLowerCase()
        setPaymentMethod(name)
    }

    const handleRetry = () => {
        setModalOpen(false)
        setRetryPayment(true)
        setecommerceFailed(false)

        // Webengage Call For Retry Payment
        window.webengage.track('iACST_db_pr_course_payment_retry_click', {
            'mobile number': mobile_number,
            'roll number': roll_number,
            psid: student_psid ? student_psid : 'NA',
            exam_type: exam_type,
            class_studying: division.toString(),
            class_opted: classtream.split('&')[0],
            stream: stream,
            center_code: branch_code,
            course_name: product_title,
            course_id: product_id,
            course_term: product_course_term,
            scholarship_waiver_message: scholarship_msg,
            score_percent: overall_percentage,
            scholarship_percentage: scholarship_percentage,
            dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
        })
    }

    const tncCheckbox = () => {
        setIsTandCChecked(!isTandCChecked)

        // Webengage Call For Terms and Condition CheckBox
        window.webengage.track('iACST_db_pr_user_details_tnc_checkbox', {
            'mobile number': mobile_number,
            'roll number': roll_number,
            psid: student_psid ? student_psid : 'NA',
            exam_type: exam_type,
            class_studying: division.toString(),
            class_opted: classtream.split('&')[0],
            stream: stream,
            center_code: branch_code,
            tnc_checkbox: !isTandCChecked,
            course_name: product_title,
            course_id: product_id,
            course_term: product_course_term,
            scholarship_waiver_message: scholarship_msg,
            score_percent: overall_percentage,
            scholarship_percentage: scholarship_percentage,
            dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
        })
    }

    const isDisabled = !profileInfo.is_edit_allowed

    const [masterData, setMasterData] = useState({
        state: [],
    })
    const [pincodeResponse, setPincodeResponse] = useState()
    const [stateResponse, setSateResponse] = useState()

    const handlePrevious = () => {
        // handleBack()
        setCourseRegisterVisible(!ecommerce?.courseRegisterVisible)
    }

    const handleBreadCrumbs = () => {
        if (ecommerce?.courseDescVisible) {
            setCourseDescVisible(!ecommerce?.courseDescVisible)
        }
        setCourseRegisterVisible(!ecommerce?.courseRegisterVisible)
    }

    const checkoutPayload = {
        roll_number: profileInfo?.roll_number,
        student_stream: profileInfo?.stream,
        sf_id: profileInfo?.sf_id,
        payment_method: paymentMethod,
        billing_name:
            profileInfo?.student_firstname + ' ' + profileInfo?.student_lastname,
        billing_address: addressFormData.billingAddress,
        billing_city: addressFormData.city,
        billing_state: addressFormData.userSelected.state.value,
        billing_zip: addressFormData.pincode,
        billing_email: profileInfo?.email,
        product_id: product_id,
        product_course_term: product_course_term,
        center_code: profileInfo?.branch_code,
        mobile_no: profileInfo?.mobile_number,
    }

    const displayProfileData = [
        {
            key: 'First Name',
            value: profileInfo.student_firstname,
        },
        {
            key: 'Last Name',
            value:
                profileInfo.student_lastname != null
                    ? profileInfo.student_lastname
                    : '.',
        },
        {
            key: 'Mobile number',
            value: MaskString({
                inputString: profileInfo.mobile_number,
                n: 2,
                maskCharacter: '*',
            }),
        },
        {
            key: 'Email',
            value: MaskString({
                inputString: profileInfo.email,
                n: 2,
                maskCharacter: '*',
            }),
        },
    ]

    useEffect(() => {
        getState().then((res) => {
            if (res.data) {
                setSateResponse(res.data)
            }
        })
    }, [])

    useEffect(() => {
        if (stateResponse !== null && stateResponse !== undefined) {
            const availableStates = stateResponse.map((element) => ({
                label: element,
                value: element,
            }))

            setMasterData({
                ...masterData,
                state: availableStates,
            })
        }
    }, [stateResponse])

    useEffect(() => {
        if (addressFormData.pincode.length === 6) {
            getPincode(`?pincode=${addressFormData.pincode}`).then((res) => {
                if (res.data) {
                    setPincodeResponse(res.data)
                }
            })
        }
    }, [addressFormData.pincode])

    useEffect(() => {
        setAddressFormData((prevState) => ({
            ...prevState,
            city: pincodeResponse?.city || '',
        }))

        setAddressFormData((prevState) => ({
            ...prevState,
            userSelected: {
                ...prevState.userSelected,
                state: {
                    label: pincodeResponse?.state_desc || '',
                    value: pincodeResponse?.state_desc || '',
                },
            },
        }))
    }, [pincodeResponse])

    return (
        <div className="p-3">
            {/* BREADCRUMB SECTION  */}
            <div
                className={`d-flex align-items-center py-3 p-lg-3 checkout-back ${
                    isSmallDevice ? 'bg-light' : 'bg-white'
                }`}
            >
                <div className="me-2 cu-pointer" onClick={handlePrevious}>
                    <img src={backArrow} alt="back" />
                </div>
                <div className="d-flex ms-2">
                    <span
                        className="font-size-sm me-3 text-grey-50 cu-pointer"
                        onClick={handleBreadCrumbs}
                    >
                        Dashboard
                    </span>
                    <span className="font-size-sm me-3 text-grey-40">
                        <ArrowNext />
                    </span>
                    {ecommerce?.courseDescVisible ? (
                        <>
                            <span
                                className="font-size-sm me-3 text-grey-50 cu-pointer"
                                onClick={handlePrevious}
                            >
                                Course
                            </span>
                            <span className="font-size-sm me-3">
                                <ArrowNext fill="black" />
                            </span>
                        </>
                    ) : (
                        <></>
                    )}
                    <span className="font-size-sm cu-pointer" onClick={''}>
                        Checkout
                    </span>
                </div>
            </div>
            <Row className="g-0 mt-lg-3 mt-2 checkbox-form">
                <Col lg={8}>
                    <div className="p-3 bg-white rounded">
                        <h5 className="d-flex align-items-start mb-3">
                            Contact details
                        </h5>
                        <Row className="mb-3">
                            {displayProfileData.map((el, ind) => {
                                return (
                                    <Col lg={6} md={6} key={ind}>
                                        <Label
                                            className="font-size-xs fw-500"
                                            for={el.key}
                                        >
                                            {el.key}*
                                        </Label>
                                        <Input
                                            className="inputheight"
                                            type="text"
                                            disabled={isDisabled}
                                            name={el.key}
                                            id={el.key}
                                            value={el.value}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                        {/* Address Details */}
                        <h5 id="addressDetails">Address</h5>
                        <Row>
                            <Col lg={6} md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-size-xs fw-500"
                                        for="address"
                                    >
                                        Street address*
                                    </Label>
                                    <Input
                                        className="inputheight"
                                        type="text"
                                        name="address"
                                        placeholder="Enter your address"
                                        id="address"
                                        value={addressFormData.billingAddress}
                                        onChange={(e) => {
                                            setErrors((prevErrors) => ({
                                                ...prevErrors,
                                                billingAddress: '',
                                            }))
                                            setAddressFormData({
                                                ...addressFormData,
                                                billingAddress: e.target.value,
                                            })
                                        }}
                                    />
                                    {errors.billingAddress && (
                                        <span className="font-size-xs text-danger">
                                            {errors.billingAddress}
                                        </span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg={6} md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-size-xs fw-500"
                                        for="pincode"
                                    >
                                        Pin code*
                                    </Label>
                                    <Input
                                        className="inputheight"
                                        type="number"
                                        placeholder="Enter PIN"
                                        name="pincode"
                                        id="pincode"
                                        value={addressFormData.pincode}
                                        onChange={(e) => {
                                            let inputPin = e.target.value
                                            if (inputPin.length > 6) {
                                                inputPin = inputPin.slice(0, 6)
                                            }
                                            setErrors((prevErrors) => ({
                                                ...prevErrors,
                                                pincode: '',
                                                city: '',
                                                state: '',
                                            }))
                                            setAddressFormData({
                                                ...addressFormData,
                                                pincode: inputPin,
                                            })
                                        }}
                                    />
                                    {errors.pincode && (
                                        <span className="font-size-xs text-danger">
                                            {errors.pincode}
                                        </span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg={6} md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-size-xs fw-500"
                                        for="city"
                                    >
                                        City*
                                    </Label>
                                    <Input
                                        className="inputheight"
                                        type="text"
                                        placeholder="Enter City"
                                        name="city"
                                        id="city"
                                        value={addressFormData.city}
                                        onChange={(e) => {
                                            setErrors((prevErrors) => ({
                                                ...prevErrors,
                                                city: '',
                                            }))
                                            setAddressFormData({
                                                ...addressFormData,
                                                city: e.target.value,
                                            })
                                        }}
                                    />
                                    {errors.city && (
                                        <span className="font-size-xs text-danger">
                                            {errors.city}
                                        </span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg={6} md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-size-xs fw-500"
                                        for="state"
                                    >
                                        State*
                                    </Label>
                                    <SelectCreatable
                                        outerDivStyles="m-0 p-0 border rounded"
                                        onChange={({ name, value }) => {
                                            setErrors((prevErrors) => ({
                                                ...prevErrors,
                                                state: '',
                                            }))
                                            setAddressFormData((prevState) => ({
                                                ...prevState,
                                                userSelected: {
                                                    ...prevState.userSelected,
                                                    [name]: value,
                                                },
                                            }))
                                        }}
                                        id="state"
                                        menuPlacement="top"
                                        isSearchable={false}
                                        options={masterData.state}
                                        value={getSelectDefaultValue(
                                            addressFormData.userSelected.state,
                                            masterData.state,
                                        )}
                                        name={'state'}
                                        placeholder={'Select state'}
                                    />
                                    {errors.state && (
                                        <span className="font-size-xs text-danger">
                                            {errors.state}
                                        </span>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* Terms and Conditions */}
                        <FormGroup check>
                            <Label
                                className="font-size-md-sm font-size-11 fw-400 mt-2"
                                check
                            >
                                <Input
                                    id="termsAndConditions"
                                    type="checkbox"
                                    name="termsAndConditions"
                                    checked={isTandCChecked}
                                    onChange={tncCheckbox}
                                />
                                By submitting this form, I agree to receive all the
                                Whatsapp communication on my registered number and
                                Aakash{' '}
                                <a
                                    className="fw-500 text-aqua"
                                    href={
                                        ecommerce?.courseFlagsData
                                            ?.term_condition_link
                                    }
                                    target="_blank"
                                >
                                    terms and conditions{' '}
                                </a>
                                and{' '}
                                <a
                                    className="fw-500 text-aqua"
                                    href={
                                        ecommerce?.courseFlagsData
                                            ?.privacy_policy_link
                                    }
                                    target="_blank"
                                >
                                    privacy policy
                                </a>
                                .
                            </Label>
                            {errors.TAC && (
                                <span className="font-size-xs text-danger">
                                    {errors.TAC}
                                </span>
                            )}
                        </FormGroup>
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="ms-lg-4">
                        {/* <div className="p-3 mb-lg-3 mb-5 mt-lg-2 mt-3 bg-white rounded">
                            <h5>Payment Methods</h5>
                            <Row className="d-flex justify-content-between w-lg-8 w-10">
                                <MobilePaymentForm
                                    paymentMethod={paymentMethod}
                                    handlePaymentMethod={handlePaymentMethod}
                                    logoBgColor="#eee"
                                />
                            </Row>
                        </div> */}
                        <FixedBottomPrice
                            isLoadingPayment={isLoadingPayment}
                            handleCheckout={handleCheckout}
                        />
                    </div>
                </Col>
            </Row>
            {Object.keys(filteredFormValues).length > 0 && (
                <>
                    <PaymentForm filteredFormValues={filteredFormValues} />
                </>
            )}

            {ecommerce?.ecommerceFailure && modalopen ? (
                <Modal
                    isOpen={modalopen}
                    className="modal-dialog-centered px-3"
                    contentClassName="bg-white mx-4"
                    centered
                >
                    <ModalBody>
                        <button
                            type="button"
                            className="btn-close float-end"
                            aria-label="Close"
                            onClick={handleRetry}
                        ></button>
                        <br />
                        <div className="m-2">
                            <div className="d-flex align-items-center justify-content-center">
                                <AlertIcon />
                            </div>
                            <p className="text-center font-size-md fw-lighter text-error-red mb-3">
                                Payment failed!
                            </p>
                            <p className="text-center text-dark-grey font-size-xs fw-normal mb-3">
                                We're sorry, but it seems there was an issue
                                processing your payment.
                            </p>
                            <div className="d-grid">
                                {/* Center the button */}
                                <button
                                    className="btn text-white bg-aqua font-size-sm fw-lighter custom-try-again"
                                    onClick={handleRetry}
                                >
                                    Try again
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutForm)
