import config from "config"
export const aboutCard = [
    {
        headingImage:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/scholarship-test-details.png',
        cardbodyitem:
            '<p>अपनी पसंद की तारीख और समय पर परीक्षा दें ऑनलाइन घर बैठे </br><b>अवधि </b>: सुबह 9 बजे से रात 10 बजे तक | <b>Duration</b> : 60 मिनट |</br><b>तरीका  </b>: ऑनलाइन घर बैठे</p></p>',
    },
    {
        headingImage:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/avail-scholarship.png',
        cardbodyitem:
            "<p className='integrated'>एक वर्षीय इंटीग्रेटेड क्लासरूम नीट/जेईई कोर्सेज पर<br /></p>",
    },
    {
        headingImage:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/how_can_appear.png',
        cardbodyitem: ' <p>12वीं पास स्टूडेंट्स</p>',
    },

    {
        headingImage:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/how-to-take.png',
        cardbodyitem:
            '<ul><li>अपने मोबाइल नंबर ओटीपी द्वारा वेरीफाई करें और रजिस्ट्रेशन पूरा करें </li><li>अपना लॉगिन विवरण, रोल नंबर प्राप्त करें और परीक्षा तिथि और समय स्लॉट चुनें </li> <li>टेस्ट की तारीख एवं समय चुनें और आकाश ऑनलाइन स्कॉलर्शिप टेस्ट घर बैठे दें</li></ul>',
    },
    {
        headingImage:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/test-highlight.png',
        cardbodyitem:
            ' <ul><li>सभी उम्मीदवारों की सुरक्षा सुनिश्चित करने के लिए पूरी तरह से ऑनलाइन</li> <li>सुविधजनक समय - छात्र सुबह 9 बजे से रात 10 बजे के बीच किसी भी समय स्लॉट का चयन कर सकते हैं</li> <li>प्रतिदिन आयोजित किया जाता है ताकि छात्र अपनी सुविधा के अनुसार परीक्षा का समय निर्धारित कर सकें</li></ul>',
    },
]

export const coursehighlight = [
    {
        hightlighticon:
        'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Expert%20Faculty.jpg',
        listingcourse:'एक्सपर्ट टीचर्स',
    },
    {
        hightlighticon:
        'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/iTutor%20Lab%20Facility.jpg',
        listingcourse:'डिजिटल प्रोडक्ट्स',
    },
    {
        hightlighticon:
        'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Tests%20on%20NEET_JEE%20pattern.jpg',
        listingcourse:'नीट/ जेईई पैटर्न पर टेस्ट',
    },
    {
        hightlighticon:
        'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Test%20Series%E2%80%9A%20%20AIATS%2C%20SMTS%2C%20etc.jpg',
        listingcourse:'टेस्ट सीरीज़‚ AIATS, SMTS आदि',
    },
    {
        hightlighticon:
        'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Audiprep_Icon_White.jpg',
        listingcourse:'नीट ऑडीओबुक्स',
    },
    {
        hightlighticon:
        'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Exhaustive%20Study%20material.jpg',
        listingcourse:'विस्तृत अध्ययन सामग्री',
    },
    {
        hightlighticon:
        'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/ic-questions-bank.webp',
        listingcourse:'प्रश्न बैंक',
    }
]
export const successStories = [
    {
        headingImage:
            `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/combined-success-stroies-from-repeater-hindi-2023-lg.webp?v=${config.VERSION_BANNER}`
    },
    {
        headingImage:
            `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-main-result-2024-session1-lpbanner.webp?v=${config.VERSION_BANNER}`,
    },
    {
        headingImage:
            `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/neet-2023-topper-banner-1260x300.webp?v=${config.VERSION_BANNER}`,
    },
    {
        headingImage:
            `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-advanced-2023-topper-banner-1260x300.webp?v${config.VERSION_BANNER}`,
    }
]

export const scholarshipDetails = [
    {
        scholarshipTitle: 'ड्रॉपर के लिए NEET और JEE रिपीटर कोर्स',
        scholarshipPara:
            "NEET और JEE भारत में सबसे लोकप्रिय प्रतियोगी परीक्षाएं हैं जो शीर्ष इंजीनियरिंग और मेडिकल कॉलेजों में प्रवेश का निर्धारण करती हैं। जो छात्र अपने पहले प्रयास में इन परीक्षाओं को पास नहीं कर पाते हैं वे अक्सर एक साल का गैप लेने और फिर से तैयारी करने का विकल्प चुनते हैं। इन छात्रों को आमतौर पर ड्रॉपर के रूप में जाना जाता है। हालांकि इन परीक्षाओं के लिए फिर से तैयारी करना चुनौतीपूर्ण हो सकता है, ऐसे कई कोचिंग संस्थान हैं जो NEET और JEE ड्रॉपर के लिए विशेष पाठ्यक्रम प्रदान करते हैं। ऐसा ही एक कोचिंग संस्थान है आकाश इंस्टीट्यूट, जो वर्षों से ड्रॉपर के बीच एक लोकप्रिय विकल्प रहा है।.",
        scholarshipData: [
            {
                scholarshipDataTitle:
                    'एनईईटी/जेईई ड्रॉपर के लिए आकाश रिपीटर कोर्स चुनने के कुछ लाभ यहां दिए गए हैं',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            '<strong>अनुभवी फैकल्टी: </strong>आकाश इंस्टीट्यूट में अत्यधिक अनुभवी फैकल्टी सदस्यों की एक टीम है जो कई वर्षों से छात्रों को पढ़ा रही है। उन्हें परीक्षा पैटर्न की गहरी समझ है और वे अपने-अपने क्षेत्र के विशेषज्ञ हैं। यह सुनिश्चित करता है कि एनईईटी/जेईई ड्रापर्स को व्यवसाय में सर्वश्रेष्ठ से सर्वश्रेष्ठ मार्गदर्शन और कोचिंग मिले।',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>व्यापक पाठ्यक्रम सामग्री:</strong>आकाश संस्थान अपने छात्रों को व्यापक पाठ्यक्रम सामग्री प्रदान करता है जो एनईईटी/जेईई पाठ्यक्रम में सभी विषयों को शामिल करता है। पाठ्यक्रम सामग्री को इस तरह से डिज़ाइन किया गया है कि इसे समझना और याद रखना आसान हो, जिससे छात्रों को दोहराने और अभ्यास करने में आसानी हो।',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>व्यक्तिगत शिक्षा:</strong>आकाश रिपीटर कोर्स को इस तरह से डिजाइन किया गया है कि यह प्रत्येक छात्र की व्यक्तिगत जरूरतों को पूरा करता है। पाठ्यक्रम को छात्र की ताकत और कमजोरियों के आधार पर अनुकूलित किया गया है, जो उन्हें उन क्षेत्रों पर ध्यान केंद्रित करने में मदद करता है जिन पर उन्हें सुधार करने की आवश्यकता है।',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>नियमित मूल्यांकन:</strong> छात्रों को उनकी प्रगति का मूल्यांकन करने में मदद करने के लिए आकाश नियमित मूल्यांकन और मॉक टेस्ट आयोजित करता है। इससे छात्रों को उनकी कमजोरियों की पहचान करने और उनके समग्र स्कोर को बेहतर बनाने के लिए उन पर काम करने में मदद मिलती है।',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>डाउट क्लियरिंग सेशन:</strong>आकाश रिपीटर कोर्स छात्रों को कॉन्सेप्ट को बेहतर ढंग से समझने में मदद करने के लिए डाउट-क्लियरिंग सेशन प्रदान करता है। इससे छात्रों को अपनी शंकाओं को दूर करने और अपनी क्षमताओं में विश्वास हासिल करने में मदद मिलती है।',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>समय प्रबंधन:</strong>आकाश संस्थान परीक्षा के दौरान अपने समय को प्रभावी ढंग से प्रबंधित करने के लिए नीट/जेईई ड्रॉपर को टिप्स और ट्रिक्स प्रदान करता है। इससे छात्रों को अपनी गति और सटीकता में सुधार करने में मदद मिलती है, जो एनईईटी/जेईई में अच्छा स्कोर करने के लिए आवश्यक है।',
                    },
                ],
            },
            {
                scholarshipDataTitle: 'जानिये आकाश के बारे में',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'आकाश एजुकेशनल सर्विसेज लिमिटेड (AESL), जिसे आकाश संस्थान के नाम से भी जाना जाता है, पिछले 34 वर्षों से प्रतियोगी परीक्षा की तैयारी में अग्रणी रहा है। हमारा उद्देश्य छात्रों को मेडिकल और इंजीनियरिंग प्रवेश परीक्षाओं को क्रैक करने के उनके लक्ष्यों को प्राप्त करने में मदद करना है, और हमारे पास हर साल NEET, IIT-JEE, ओलंपियाड NTSE, KVPY, 10वीं और 12वीं परीक्षाओं में शीर्ष रैंक हासिल करने की विरासत है।',
                    },
                    {
                        scholarshipDataParagraph:
                            "हमारी सफलता का श्रेय हमारे विशेषज्ञ फैकल्टी और बेजोड़ अध्ययन सामग्री को दिया जा सकता है, साथ ही हर छात्र की व्यक्तिगत जरूरतों को पूरा करने वाले शिक्षण मॉड्यूल को तैयार करने के लिए हमारे सावधानीपूर्वक दृष्टिकोण को भी जिम्मेदार ठहराया जा सकता है। हम पूरे देश में भावुक और प्रतिभाशाली छात्रों को पहचानने और पुरस्कृत करने के लिए उपलब्ध छात्रवृत्ति परीक्षणों की एक श्रृंखला के साथ उन्नत पाठ्यक्रमों की नींव प्रदान करते हैं। ये परीक्षण 100% तक की छात्रवृत्ति सुरक्षित करने और अपनी पसंद के पाठ्यक्रम में प्रवेश पाने का अवसर प्रदान करते हैं, जिससे उनकी सफलता की यात्रा शुरू हो जाती है।",
                    },
                    {
                        scholarshipDataParagraph:
                            'हमारे स्कॉलरशिप टेस्ट कई माध्यमों से आयोजित किए जाते हैं, जिसमें आकाश इंस्टीट्यूट की क्षेत्रीय शाखाओं में ऐप या वेबसाइट आधारित ऑनलाइन परीक्षाएं घर से और ऑफलाइन शामिल हैं। हम आकाश हाइब्रिड प्रोग्राम जैसे कक्षा और ऑनलाइन शिक्षण पाठ्यक्रमों का मिश्रण भी प्रदान करते हैं, जिसे सभी छात्रों के लिए एक सहज सीखने का अनुभव प्रदान करने के लिए डिज़ाइन किया गया है।',
                    },
                    {
                        scholarshipDataParagraph:
                            'हमारे पाठ्यक्रमों के बारे में अधिक जानने के लिए और हम आपके शैक्षणिक लक्ष्यों को प्राप्त करने में आपकी सहायता कैसे कर सकते हैं, बस हमारी वेबसाइट पर एक फॉर्म भरें या आकाश संस्थान की अपनी निकटतम शाखा पर जाएँ/कॉल करें। हमारे विशेषज्ञों की टीम को आपका मार्गदर्शन और सहायता करने में खुशी होगी।',
                    },
                ],
            },
            {
                scholarshipDataTitle: 'नीट कोचिंग @ आकाश ',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'आकाश 35 से अधिक वर्षों से अग्रणी एनईईटी कोचिंग सेंटर रहा है। इसने लगातार भारत के अन्य सभी केंद्रों के बीच सर्वश्रेष्ठ NEET कोचिंग सेंटर का खिताब अपने नाम किया है। देश भर में फैली कई शाखाओं और ऑनलाइन पाठ्यक्रमों के साथ, आकाश ने अपनी नीट कोचिंग को भारत के सभी कोनों से इच्छुक छात्रों के लिए सुलभ बना दिया है। आकाश इष्टतम परीक्षण तैयारी सुनिश्चित करने के लिए प्रत्येक छात्र की व्यक्तिगत आवश्यकताओं को पूरा करने के लिए विशेष रूप से डिज़ाइन किए गए पाठ्यक्रमों की एक श्रृंखला प्रदान करता है। आकाश एनईईटी कोचिंग फीस के लिए छात्रवृत्ति प्रदान करता है, और उनके अनुभवी संकाय का उद्देश्य छात्रों को उनकी एनईईटी यात्रा के हर कदम पर समर्थन देना है।',
                    }
                ],
            },
            {
                scholarshipDataTitle: 'जेईई कोचिंग @ आकाश  ',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'हर साल, आकाश जेईई के लिए सर्वश्रेष्ठ कोचिंग सेंटर के रूप में अपनी स्थिति को ऊंचा करते हुए शीर्ष रैंकर्स तैयार करता है। हम छात्रों को उनकी जेईई कोचिंग में बढ़त हासिल करने के लिए बहुत जरूरी प्रेरणा प्रदान करते हैं। कोई आश्चर्य नहीं, आकाश संस्थान सर्वश्रेष्ठ आईआईटी जेईई कोचिंग सेंटर के रूप में उभरा है और आईआईटी जेईई कोचिंग क्षेत्र में एक विश्वसनीय नाम बना हुआ है। सर्वश्रेष्ठ आईआईटी कोचिंग सेंटर की तलाश कर रहे छात्रों और अभिभावकों के लिए, आकाश से संपर्क करने से उनके सभी प्रशनों का जवाब मिल जाएगा। आकाश में हम छात्रों को जेईई कोचिंग कोर्सेज में प्रवेश पाने में सक्षम बनाने के लिए विभिन्न स्कालरशिप प्रदान करते हैं.',
                    }
                ],
            },
        ],
    },
]
export const banner_title=[
    {
        head_title: "नीट/जेईई 2025 रिपीटर कोर्सेज",
        sub_head_title:"90%* तक कुल स्कॉलरशिप पाएं",
        type:"ऑनलाइन इंस्टेंट एडमिशन व स्कॉलरशिप टेस्ट",
        registration:"रजिस्ट्रेशन प्रारंभ",
    }
]