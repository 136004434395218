import cmsComponents from './cms-components'

const CmsData = (props) => {
    const { data, isPrinting, isSafariBrowser, isMobileBrowser } = props

    const {
        CheckList,
        BiggerStrongerBetter,
        AdmissionFormalities,
        Testimonials,
        Graph,
        Map,
    } = cmsComponents({ data, isPrinting, isSafariBrowser, isMobileBrowser })

    var cmsData

    switch (data.result.ClassStudying + '-' + data.result.stream) {
        case 'VII-Foundations':
        case 'VIII-Foundations':
        case 'IX-Foundations':
        case 'X-Medical':
        case 'XI-Medical':
        case 'XII-Medical':
        case 'X-Engineering':
        case 'XI-Engineering':
        case 'XII-Engineering':
        default:
            cmsData = (
                <>
                    {data?.result?.Status === 'Qualified' ? <CheckList /> : null}
                    <BiggerStrongerBetter />
                    {/* <AdmissionFormalities /> */}
                    <Testimonials />
                    <Graph />
                    <Map />
                </>
            )
    }

    return cmsData
}

export default CmsData
