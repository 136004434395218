import React from 'react'
import { useSwipeable } from 'react-swipeable'
import styles from './carousel.module.css'
import MockCard from 'components/Card/MockCard'
import arrow_prev  from '../../assets/angle-left.png'
import arrow_next  from '../../assets/next.png'

export const NEXT = 'NEXT'
export const PREV = 'PREV'

const getOrder = (index, pos, numItems) => {
    return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos
}
const getInitialState = (numItems) => ({
    pos: numItems - 1,
    sliding: false,
    dir: NEXT,
})

function MockCarousel({ response, staticData }) {
    const numItems = response.length
    const [state, dispatch] = React.useReducer(reducer, getInitialState(numItems))

    const slide = (dir) => {
        dispatch({ type: dir, numItems })
        setTimeout(() => {
            dispatch({ type: 'stopSliding' })
        }, 50)
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => slide(NEXT),
        onSwipedRight: () => slide(PREV),
        swipeDuration: 500,
        preventScrollOnSwipe: false,
        trackMouse: true,
    })

    return (
        <div {...handlers} className={`${styles.carouselCard} p-3 pb-0`}>
            <h5 className="text-start fw-lighter">{staticData.mock_heading}</h5>
            <div {...handlers} className={`${styles.main_slide}`}>
                <div className={`${styles.wrapper}`}>
                    <div className="d-flex">
                        {response.map((cardDetails, index) => (
                            <div
                                className={`${styles.carouselSlot} col-9 col-md-5 col-lg-5 col-xl-4 my-2`}
                                key={index}
                                style={{
                                    transition: state.sliding
                                        ? 'none'
                                        : 'transform 1s ease',
                                    transform: !state.sliding
                                        ? 'translateX(calc(-97% - 20px))'
                                        : state.dir === PREV
                                        ? 'translateX(calc(2 * (-90% -50px)))'
                                        : 'translateX(calc(2 * (0% - 20px)))',
                                    order: getOrder(index, state.pos, numItems),
                                }}
                            >
                                <MockCard
                                    cardData={cardDetails}
                                    serialNumber={index + 1}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex d-md-none">
                        {response.map((_, index) => {
                            const activeSlide =
                                index % 2 === 0
                                    ? numItems - (state.pos + 1)
                                    : state.pos + 1
                            return (
                                <span
                                    key={index}
                                    style={{
                                        height: '6px',
                                        width:
                                            activeSlide !== index ? '6px' : '24px',
                                    }}
                                    className={`d-block me-1 bg-aqua rounded`}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className={`${styles.slide_btn} d-flex justify-content-between d-none d-xl-block`}>
                <button onClick={() => slide(PREV)} className={`${styles.slide_prev}`}><img src={arrow_prev}/></button>
                <button onClick={() => slide(NEXT)} className={`${styles.slide_next}`}><img src={arrow_next}/></button>
                </div>
            </div>
        </div>
    )
}

function reducer(state, action) {
    switch (action.type) {
        case PREV:
            return {
                ...state,
                dir: PREV,
                sliding: true,
                pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1,
            }
        case NEXT:
            return {
                ...state,
                dir: NEXT,
                sliding: true,
                pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1,
            }
        case 'stopSliding':
            return { ...state, sliding: false }
        default:
            return state
    }
}

export default MockCarousel
