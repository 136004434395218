import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap'
import DangerIcon from '../../assets/Danger.jsx'
import styles from './exam-details-edit.module.css'
import ClockIcon from '../../assets/clockIcon.svg'
import { ReactComponent as WarningIcon } from '../../assets/warningIcon.svg'

import SelectCreatable from 'utils/SelectDropdown'
import useGetApi from 'api/useGetApi'
import CONFIG from '../../config'
import { usePutPostApi } from 'api/usePutPostApi'
import MobilePaymentForm from 'components/shared/MobilePaymentForm'
import { mockPayment } from 'api/api'
import PaymentForm from 'components/Card/paymentForm'
import { SET_TOAST_DATA } from 'constants/actionTypes'
import { SunspotLoader } from 'react-awesome-loaders'

const mapStateToProps = (state) => {
    return {
        token: state?.token,
        profileInfo: state?.profileData,
    }
}

const mapDispatchToProps = (dispatch) => ({
    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
})

function EditExamDetails({
    modal,
    toggle,
    token,
    profileInfo,
    setToastMsgsData,
    fetchDashboardData,
    fetchProfileData,
}) {
    const defaultSelectedExam = profileInfo.exam_mode_and_slot
        .toLowerCase()
        .includes('offline')
        ? 'Offline'
        : 'Online'

    const isDisabled = !profileInfo.is_edit_allowed

    const [examMode, setExamMode] = React.useState(defaultSelectedExam)
    const [masterData, setMasterData] = React.useState({
        examSlots: [],
        state: [],
        centre: [],
        day: [],
        class: [],
    })
    const [isFormTouched, setFormTouched] = React.useState(false)

    const [userSelected, setUserSelected] = React.useState({
        examSlot:
            examMode === defaultSelectedExam
                ? {
                      label: profileInfo.exam_mode_and_slot,
                      value: profileInfo.exam_mode_and_slot,
                  }
                : '',

        state: profileInfo.exam_state
            ? { label: profileInfo.exam_state, value: profileInfo.exam_state_id }
            : '',

        centre:
            examMode === defaultSelectedExam && profileInfo.exam_center
                ? {
                      label: profileInfo.exam_center,
                      value: profileInfo.exam_center_id,
                  }
                : '',
        day:
            examMode === defaultSelectedExam && profileInfo.exam_date
                ? {
                      label: profileInfo.exam_date,
                      value: profileInfo.exam_date_id,
                  }
                : '',
        class:
            examMode === defaultSelectedExam &&
            profileInfo.class_stream_id.toString()
                ? {
                      label: profileInfo.class_stream_name,
                      value: profileInfo.class_stream_id,
                  }
                : '',
    })

    const [paymentMethod, setPaymentMethod] = React.useState('paytm')
    const [isLoadingPayment, setIsLoading] = React.useState(false)
    const [filteredFormValues, setfilteredFormValues] = React.useState({})

    const { data: responseData } = useGetApi(
        CONFIG.MASTER_API +
            '?masterKey=exammode_and_time_master,state_master,class_and_stream_master',
        token,
        true,
    )

    const { data: centreResponse, setEnabled } = useGetApi(
        CONFIG.MASTER_API +
            `?masterKey=exam_center_master&masterKeyId=${userSelected.state?.value}&exam_mode=${examMode}`,
        token,
        false,
    )
    const examTimePayload = {
        exam_mode: userSelected.examSlot.value,
        branch_code: userSelected.centre?.value,
        class_and_stream: userSelected.class?.value,
    }
    const { data: examTimeResponse, setPutEnabled } = usePutPostApi(
        CONFIG.EXAM_DATES_API,
        token,
        'post',
        examTimePayload,
        false,
    )

    const { examSlot, state, centre, day } = userSelected

    const finalEditDetailsPayload = {
        state_id: state.value,
        exam_date: day.value,
        exam_mode: examSlot.value,
        exam_center: centre.value,
        class_and_stream: userSelected.class?.value,
    }

    const { data: updateDetailsResponse, setPutEnabled: handleEditDetailsSubmit } =
        usePutPostApi(
            CONFIG.UPDATE_EXAM_INFO_API,
            token,
            'post',
            finalEditDetailsPayload,
            false,
        )

    useEffect(() => {
        if (
            updateDetailsResponse &&
            updateDetailsResponse.success &&
            updateDetailsResponse.success === 'true'
        ) {
            setIsLoading(false)
            toggle()
            const originalProfileData = {
                'mobile number': profileInfo.mobile_number
                    ? profileInfo.mobile_number
                    : 'NA',
                dashbord_password: profileInfo.student_dob,

                dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${profileInfo.roll_number}&dob=${profileInfo.student_dob}`,
                'roll number': profileInfo.roll_number
                    ? profileInfo.roll_number
                    : 'NA',
                psid: profileInfo.student_psid ? profileInfo.student_psid : 'NA',
                'exam type': profileInfo.exam_type ? profileInfo.exam_type : 'NA',
                'original exam mode': defaultSelectedExam
                    ? defaultSelectedExam
                    : 'NA',
                'original exam date': profileInfo.exam_date
                    ? new Date(profileInfo.exam_date_id + 'T03:30:00.000Z')
                    : 'NA',
                original_class_studying_stream: profileInfo.class_stream_name,
                'original exam time': profileInfo.exam_mode_and_slot
                    ? profileInfo.exam_mode_and_slot
                    : 'NA',
                'original state': profileInfo.exam_state
                    ? profileInfo.exam_state
                    : 'NA',
                'original center': profileInfo.exam_center
                    ? profileInfo.exam_center
                    : 'NA',
                'updated exam mode': examMode ? examMode : 'NA',
                'updated exam date': userSelected.day.value
                    ? userSelected.day.value
                    : 'NA',
                updated_class_studying_stream: userSelected.class.label,
                'updated exam time': userSelected.examSlot.value
                    ? userSelected.examSlot.value
                    : 'NA',
                'updated state': userSelected.state.label
                    ? userSelected.state.label
                    : 'NA',
                'updated center': userSelected.centre.label
                    ? userSelected.centre.label
                    : 'NA',
                'exam fee': 'NA',
                'payment method': 'NA',
                'payment status': 'NA',
                class_studying: profileInfo.class ? profileInfo.class : 'NA',
                stream: profileInfo.stream ? profileInfo.stream : 'NA',
            }
            window.webengage.track(
                'iACST_db_confirm_exam_detail_update',
                originalProfileData,
            )
            fetchDashboardData(true)
            fetchProfileData(true)
            setToastMsgsData({
                isToastOpen: true,
                toastType: 'Success',
                toastMessage: updateDetailsResponse.msg,
            })
        } else if (
            updateDetailsResponse &&
            updateDetailsResponse.success &&
            updateDetailsResponse.success === 'false'
        ) {
            setIsLoading(false)
            toggle()

            setToastMsgsData({
                isToastOpen: true,
                toastType: 'danger',
                toastMessage: 'Something went wrong. Please try again later.',
            })
        }

        // eslint-disable-next-line
    }, [updateDetailsResponse])

    function updateCentres() {
        setEnabled(true)

        if (centre !== '' && examSlot !== '') setPutEnabled(true)
    }

    const getSelectDefaultValue = (value, array) => {
        if (value === '') return false
        return array.find(
            (a) =>
                a.value.toString().toLowerCase() ===
                value?.value.toString().toLowerCase(),
        )
    }

    useEffect(() => {
        const examTimeArray = []
        let isExamDatePresent = false
        if (examTimeResponse) {
            Object.entries(examTimeResponse.data).forEach((el) => {
                if (el[0] === profileInfo.exam_date_id) isExamDatePresent = true
                examTimeArray.push({ label: el[1], value: el[0] })
            })
        }
        if (!isExamDatePresent && profileInfo.is_offline_sf_exam_date) {
            examTimeArray.push({
                label: 'Day of Exam - ' + profileInfo.exam_date,
                value: profileInfo.exam_date_id,
            })
        }
        setMasterData({ ...masterData, day: examTimeArray })
        // eslint-disable-next-line
    }, [examTimeResponse])

    useEffect(() => {
        const filteredCentre = []

        if (centreResponse) {
            Object.entries(centreResponse.data.exam_center_master).forEach((el) => {
                filteredCentre.push({ label: el[1].label, value: el[0] })
            })
        }

        setEnabled(false)
        setMasterData({ ...masterData, centre: filteredCentre })
        // eslint-disable-next-line
    }, [centreResponse])

    useEffect(() => {
        if (
            responseData !== null &&
            responseData.data &&
            responseData.data !== null
        ) {
            let filteredSlots = []
            responseData?.data?.exammode_and_time_master.forEach((el) => {
                if (
                    el.name.toLowerCase().includes('offline') &&
                    examMode === 'Offline'
                ) {
                    filteredSlots.push({ value: el.name, label: el.name })
                }
                if (
                    el.name.toLowerCase().includes('online') &&
                    examMode === 'Online'
                ) {
                    filteredSlots.push({ value: el.name, label: el.name })
                }
            })

            const availableStates = Object.entries(
                responseData?.data?.state_master,
            ).map((element) => ({ label: element[1], value: element[0] }))

            const availableClass = Object.entries(
                responseData?.data?.class_and_stream_master,
            ).map((element) => ({
                label: element[1].display_name,
                value: element[0],
            }))
            setMasterData({
                ...masterData,
                examSlots: filteredSlots,
                state: availableStates,
                class: availableClass,
            })
            updateCentres()
            if (filteredSlots[0]?.label.toLowerCase().includes('online')) {
                setUserSelected({
                    ...userSelected,
                    examSlot: {
                        label: filteredSlots[0].label,
                        value: filteredSlots[0].value,
                    },
                })
            }
        }

        // eslint-disable-next-line
    }, [responseData, examMode])

    const resetForm = () => {
        const prefilledExamSlot =
            {
                label: profileInfo.exam_mode_and_slot,
                value: profileInfo.exam_mode_and_slot,
            } || ''

        const prefilledState = profileInfo.exam_state
            ? { label: profileInfo.exam_state, value: profileInfo.exam_state_id }
            : ''

        const prefilledCentre = profileInfo.exam_center
            ? {
                  label: profileInfo.exam_center,
                  value: profileInfo.exam_center_id,
              }
            : ''
        const prefilledExamDay = profileInfo.exam_date
            ? {
                  label: profileInfo.exam_date,
                  value: profileInfo.exam_date_id,
              }
            : ''

        setUserSelected({
            examSlot: prefilledExamSlot,
            state: prefilledState,
            centre: prefilledCentre,
            day: prefilledExamDay,
        })

        setFormTouched(false)
    }

    const handlePaymentMethod = (e) => {
        let name = e.target.name.toLowerCase()
        setPaymentMethod(name)
    }

    const handleExamMode = (e) => {
        let name = e.target.name
        setExamMode(name)
        // if (name !== defaultSelectedExam) {
        setUserSelected({
            ...userSelected,
            day: '',
            state: '',
            centre: '',
            examSlot: '',
            // })
        })
    }

    const handleOnChange = ({ name, value }) => {
        if (userSelected[name] !== value) setFormTouched(true)
        setUserSelected({ ...userSelected, [name]: value })
    }
    const handlePaymentSubmit = async () => {
        setIsLoading(true)

        localStorage.setItem(
            'editExamDetails',
            JSON.stringify({
                state_id: state.value,
                exam_date: day.value,
                exam_mode: examSlot.value,
                exam_center: centre.value,
                class_and_stream: userSelected.class?.value,
            }),
        )

        // API call
        await mockPayment(
            token,
            `${CONFIG.MOCK_PAYMENT_API}?payment_mode=${paymentMethod}&payment_type=exam&branch_code=${userSelected.centre.value}`,
        ).then((res) => {
            if (res.data.success === 'true') {
                setfilteredFormValues(res.data.data)
                localStorage.setItem(
                    'anthe_card_amount',
                    res.data.data.amount || res.data.data.TXN_AMOUNT,
                )
                const profilePayload = {
                    'mobile number': profileInfo.mobile_number
                        ? profileInfo.mobile_number
                        : 'NA',
                    'roll number': profileInfo.roll_number
                        ? profileInfo.roll_number
                        : 'NA',
                    psid: profileInfo.student_psid ? profileInfo.student_psid : 'NA',
                    'exam type': profileInfo.exam_type
                        ? profileInfo.exam_type
                        : 'NA',
                    'original exam mode': defaultSelectedExam
                        ? defaultSelectedExam
                        : 'NA',

                    original_class_studying_stream: profileInfo.class_stream_name,
                    'original exam date': profileInfo.exam_date
                        ? profileInfo.exam_date
                        : 'NA',
                    'original exam time': profileInfo.exam_mode_and_slot
                        ? profileInfo.exam_mode_and_slot
                        : 'NA',
                    'original state': profileInfo.exam_state
                        ? profileInfo.exam_state
                        : 'NA',
                    'original center': profileInfo.exam_center
                        ? profileInfo.exam_center
                        : 'NA',
                    'updated exam mode': examMode ? examMode : 'NA',
                    updated_class_studying_stream: userSelected.class.label,
                    'updated exam date': userSelected.day.value
                        ? userSelected.day.value
                        : 'NA',
                    'updated exam time': userSelected.examSlot.value
                        ? userSelected.examSlot.value
                        : 'NA',
                    'updated state': userSelected.state.label
                        ? userSelected.state.label
                        : 'NA',
                    'updated center': userSelected.centre.label
                        ? userSelected.centre.label
                        : 'NA',
                    'exam fee':
                        res.data.data.amount.toString() ||
                        res.data.data.TXN_AMOUNT.toString(),
                    'payment method': paymentMethod ? paymentMethod : 'NA',
                    'payment status': 'NA',
                    class_studying: profileInfo.class ? profileInfo.class : 'NA',
                    stream: profileInfo.stream ? profileInfo.stream : 'NA',
                }
                const originalProfileData = JSON.stringify(profilePayload)
                localStorage.setItem('originalProfileData', originalProfileData)
                return res.data.data.amount || res.data.data.TXN_AMOUNT
            } else {
                console.log('Error occurred: ', res.data.msg)
            }

            setIsLoading(false)
        })
    }

    const examDayInputDisabled =
        isDisabled || userSelected.examSlot === '' || userSelected.centre === ''

    const isUpdateDetailsBtnEnabled =
        !isDisabled &&
        userSelected.centre !== '' &&
        userSelected.day !== '' &&
        userSelected.examSlot !== '' &&
        userSelected.state !== '' &&
        isFormTouched

    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            className="w-9 mx-auto"
            contentClassName="bg-light-blue"
            centered
        >
            <ModalHeader
                toggle={toggle}
                className={`${styles.modalHeader} border-0`}
            >
                <span className="font-size-md font-size-lg-lg fw-lighter">
                    Edit exam details
                </span>
            </ModalHeader>
            <ModalBody className="p-0">
                <div className="p-3 pt-0">
                    {!profileInfo.is_offline_sf_exam_date && (
                        <div className="p-2 font-size-xs d-flex justify-content-center align-items-center rounded-10px mb-4 bg-warning bg-mustard">
                            <DangerIcon />
                            &nbsp;
                            <span className="ms-1">
                                {profileInfo.exam_attempts_left || 0}/
                                {profileInfo.max_exam_edit_allowed} attempts left to
                                edit exam details
                            </span>
                        </div>
                    )}
                    <SelectCreatable
                        classNamePrefix={'reactSelect'}
                        onChange={({ name, value }) => {
                            // updateCentres()
                            userSelected.examSlot !== '' && setPutEnabled(true)
                            setUserSelected({
                                ...userSelected,
                                [name]: value,
                                day: '',
                                state: '',
                                centre: '',
                            })
                        }}
                        disabled={isDisabled}
                        options={masterData.class}
                        value={getSelectDefaultValue(
                            userSelected.class,
                            masterData.class,
                        )}
                        name={'class'}
                        placeholder={'Select Class'}
                    />
                    {/* radio buttons to choose btw Online & Offline Exam mode */}
                    <Form className="d-flex justify-content-around">
                        <FormGroup check>
                            <Label
                                check
                                className={`d-flex align-items-center font-size-xs ${
                                    examMode === 'Online' && 'fw-lighter'
                                } ${isDisabled && 'opacity-50'}`}
                            >
                                <Input
                                    type="radio"
                                    name="Online"
                                    key={Math.random(5)}
                                    disabled={isDisabled}
                                    checked={examMode === 'Online'}
                                    onChange={handleExamMode}
                                    className={`${styles.modeInputCheckbox} me-2 mt-0`}
                                />
                                <span className="font-size-xs font-size-md-sm">
                                    Online Exam
                                </span>
                                {!profileInfo.register_from_third_party && (
                                    <Button
                                        type="button"
                                        size="sm"
                                        className={`bg-dark-green text-white d-flex align-items-center border-0 font-size-xxs ms-1 ${styles.freeTextBtn}`}
                                    >
                                        <span>Free</span>
                                    </Button>
                                )}
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label
                                check
                                className={`d-flex align-items-center font-size-xs ${
                                    examMode === 'Offline' && 'fw-lighter'
                                } ${isDisabled && 'opacity-50'}`}
                            >
                                <Input
                                    type="radio"
                                    name="Offline"
                                    key={Math.random(5)}
                                    disabled={isDisabled}
                                    checked={examMode === 'Offline'}
                                    onChange={handleExamMode}
                                    className={`${styles.modeInputCheckbox} me-2 mt-0`}
                                />
                                <span className="font-size-xs font-size-md-sm">
                                    {profileInfo.register_from_third_party
                                        ? 'Offline exam'
                                        : 'Offline exam - ₹100'}
                                </span>
                            </Label>
                        </FormGroup>
                    </Form>
                    <SelectCreatable
                        // classNamePrefix={'reactSelect'}
                        onChange={({ name, value }) => {
                            handleOnChange({ name, value })
                            userSelected.centre !== '' && setPutEnabled(true)
                        }}
                        disabled={isDisabled}
                        options={masterData.examSlots}
                        value={userSelected.examSlot}
                        name={'examSlot'}
                        placeholder={'Select exam time'}
                    />
                    <p className="d-flex align-items-center mx-2 mb-0 font-size-sm">
                        <img
                            src={ClockIcon}
                            className={`${styles.callingIcon} me-1`}
                            alt="calling-icon"
                        />
                        Exam duration - 60 mins
                    </p>
                </div>

                <div className="bg-sky-blue p-3">
                    {examMode === 'Offline' ? (
                        <p className="text-center font-size-sm fw-lighter m-auto">
                            Select exam centre
                        </p>
                    ) : (
                        <p className="text-center font-size-sm fw-lighter m-auto">
                            Preferred Aakash Centre for Coaching
                        </p>
                    )}
                    <SelectCreatable
                        classNamePrefix={'reactSelect'}
                        onChange={({ name, value }) => {
                            updateCentres()
                            setUserSelected({
                                ...userSelected,
                                [name]: value,
                                centre: '',
                            })
                        }}
                        disabled={isDisabled}
                        isSearchable={false}
                        options={masterData.state}
                        value={getSelectDefaultValue(
                            userSelected.state,
                            masterData.state,
                        )}
                        name={'state'}
                        placeholder={'Select state'}
                    />
                    <SelectCreatable
                        classNamePrefix={'reactSelect'}
                        onChange={({ name, value }) => {
                            handleOnChange({ name, value })

                            userSelected.examSlot !== '' && setPutEnabled(true)
                        }}
                        disabled={isDisabled}
                        isSearchable={false}
                        options={masterData.centre}
                        value={getSelectDefaultValue(
                            userSelected.centre,
                            masterData.centre,
                        )}
                        placeholder={'Select centre'}
                        name={'centre'}
                    />
                    {examMode === 'Offline' && (
                        <p className="font-size-xs text-center">
                            Some centers available only for online exam mode
                        </p>
                    )}
                </div>
                {/* Select day of Exam */}
                <div className="p-3">
                    <SelectCreatable
                        classNamePrefix={'reactSelect'}
                        onChange={handleOnChange}
                        disabled={examDayInputDisabled}
                        isSearchable={false}
                        options={masterData.day}
                        name={'day'}
                        value={getSelectDefaultValue(
                            userSelected.day,
                            masterData.day,
                        )}
                        placeholder={'Day of exam (dd/mm/yyyy)'}
                    />

                    {!profileInfo.is_payment_done &&
                        profileInfo.is_edit_allowed &&
                        examMode === 'Offline' && (
                            <div className="m-3 mb-0">
                                <div className="d-flex justify-content-between align-items-center fw-lighter">
                                    <div className="font-size-md font-size-lg-lg">
                                        Exam fees
                                    </div>
                                    <div className="font-size-xxl">₹ 100</div>
                                </div>
                                <p className="font-size-xs">
                                    <WarningIcon />
                                    &nbsp;Exam fees is non-refundable
                                </p>
                            </div>
                        )}
                </div>

                {!profileInfo.is_payment_done &&
                    profileInfo.is_edit_allowed &&
                    examMode === 'Offline' && (
                        <>
                            <Form>
                                <Col className="w-100 px-3 py-3 bg-white text-grey-black rounded">
                                    <p className="fw-lighter ps-3 font-size-xs font-size-md-sm">
                                        Payment Methods
                                    </p>

                                    <Row className="d-flex justify-content-around">
                                        <MobilePaymentForm
                                            paymentMethod={paymentMethod}
                                            handlePaymentMethod={handlePaymentMethod}
                                            logoBgColor="#eee"
                                        />
                                    </Row>
                                </Col>
                            </Form>

                            {Object.keys(filteredFormValues).length > 0 && (
                                <PaymentForm
                                    filteredFormValues={filteredFormValues}
                                />
                            )}
                        </>
                    )}

                {/* show msg to offline user who is willing to switch to online mode */}
                {examMode === 'Online' && defaultSelectedExam === 'Offline' && (
                    <div className="p-2 font-size-xs d-flex justify-content-center text-center align-items-center rounded-10px mb-4 mx-3 bg-warning bg-mustard">
                        <span className="ms-1">
                            Exam fees ₹100 is non-refundable. In case if you switch
                            back to offline you won’t be charged again.
                        </span>
                    </div>
                )}

                {/* Submit Form Button */}
                <Button
                    disabled={!isUpdateDetailsBtnEnabled}
                    className={`${
                        isUpdateDetailsBtnEnabled ? 'bg-aqua' : 'bg-grey-50'
                    } border-0 fw-lighter ${styles.formSubmitUpdateBtn}`}
                    onClick={() => {
                        if (!profileInfo.is_payment_done && examMode === 'Offline')
                            handlePaymentSubmit()
                        else {
                            handleEditDetailsSubmit(true)
                            setIsLoading(true)
                        }
                    }}
                >
                    {isLoadingPayment ? (
                        <SunspotLoader
                            gradientColors={['#4039d4', '#E0E7FF']}
                            size={'10px'}
                            desktopSize={'10px'}
                            className="m-auto"
                        />
                    ) : profileInfo.is_payment_done || examMode === 'Online' ? (
                        'Update details'
                    ) : (
                        'Update & pay exam fees'
                    )}
                </Button>
            </ModalBody>
        </Modal>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditExamDetails)
