import styles from './terms.module.css'
import ConvertHTMLString from '../../components/shared/ConvertHTMLString'
import { termscond } from 'constants/termscond'
import { Helmet } from 'react-helmet'
import logo from '../../assets/AakashSignupLogo.png'
import Header from 'components/Header/header'
import { FALSE } from 'sass'

const TermsandCondition = () => {

  return (
    <>
    <Helmet>
    <title>Terms &amp; Conditions iACST | Scholarships Exam</title>
    </Helmet>
      {/* --------------------------------------- */} 
      <div className={`dialog-off-canvas-main-canvas ${styles.head_sec_item_top}`} data-off-canvas-main-canvas="">
        <Header showmobile={"false"}/>
        {/* ______________________ HEADER _______________________ */}
        {/* <div className="header-wrapper">
          <a className="menu-toggle" href="javascript:;" />
          <header id="header_top">
            <div>
              <div id="header-top-region">
                <div className={`${styles.head_sec}`} >
                  <div id="block-anthe-branding" className={`${styles.icon_top}`}>
                    <a href="/" title="Home" rel="home">
                      <img
                        src={logo}
                        alt="Home"
                      />
                    </a>
                  </div>
                  <div className={`${styles.login_btn}`}>
                    <button type="button" className={`${styles.login_btn_tx}`} ><a className={`${styles.login_link}`}
                      href="https://iacst.aakash.ac.in/user/logout"
                    >
                      Log in
                    </a></button>
                  </div> 
                </div>
              </div>
            </div>
          </header> */}
          {/* /#header  */}
        {/* </div> */}
        {/* ______________________ MAIN _______________________ */}
        <div id="main" className='container allitem'>
          <div id="content-wrapper">
            <section id="content">
              <div id="content-header">
                <div id="highlighted">
                  <div>
                    <div data-drupal-messages-fallback="" className="hidden" />
                  </div>
                </div>
              </div>
              <div id="content-area">
                <div>
                  <div id="block-anthe-content">
                    <article
                      role="article"
                      about="/iacst-scholarship/terms-conditions"
                      className="node"
                    >
                      <div className="content">
                        <div>
                          <div>
                            <h4 className={`${styles.head_sec_item}`}
                            >
                              <b>Terms and Conditions for Instant Admission Cum
                                Scholarship Test (iACST)</b>
                            </h4>
                            <ol>
                              {termscond.map((obj, index) => {
                                return (
                                  <li className={`${styles.terms_point}`}>
                                  <ConvertHTMLString
                                  content={obj.cardbodyitem}
                              />
                              </li>
                                )
                              }
                              )
                              }
                            </ol>
                            <p>
                              The student/ parent/ guardian hereby agree that the
                              Company may use name, photographs, videos of me and/or
                              my child for publicity in all kinds of media. In
                              addition, AESL also reserves the right to record
                              video-audio testimonial of me and/or my child for the
                              purpose of referencing or promotion, as required by the
                              Company before or after the iACST exam is conducted for
                              the purpose of publicity in all kinds of media.
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
export default TermsandCondition