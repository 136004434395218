import { AxiosError } from 'axios'
import { useState, useEffect } from 'react'

interface ApiResponse<T> {
    data: T | null
    loading: boolean
    error: ApiError | null
    setPutEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

interface ApiError {
    // Define the structure of the API error response here
    // Example: Assuming the error response has 'message' property
    msg: string
}

function usePutPostApi<T>(
    url: string,
    token: string,
    methodType: 'post' | 'put',
    payload?: any,
    enabledCall = true,
): ApiResponse<T> {
    const [data, setData] = useState<T | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<ApiError | null>(null)
    const [enabled, setPutEnabled] = useState<boolean>(enabledCall)

    useEffect(() => {
        if (enabled) {
            const fetchData = async (): Promise<void> => {
                setLoading(true)

                try {
                    const response = await fetch(url, {
                        method: methodType,
                        headers: {
                            'Content-Type': 'application/json',
                            token,
                        },
                        body: payload ? JSON.stringify(payload) : null,
                    })

                    const responseData = await response.json()

                    if (!response.ok) {
                        throw new Error(
                            responseData.message ||
                                responseData.msg ||
                                'Something went wrong',
                        )
                    }

                    setData(responseData)
                } catch (error) {
                    const apiError = (error as AxiosError<ApiError>).response
                        ?.data || {
                        msg: "Sorry, couldn't process request. Please try again.",
                    }
                    setError(apiError)
                } finally {
                    setLoading(false)
                }
            }
            fetchData()
            setPutEnabled(false)
        }
        // eslint-disable-next-line
    }, [url, payload, enabled])

    return { data, loading, error, setPutEnabled }
}

export { usePutPostApi }
