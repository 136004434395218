import React, { useState } from 'react'
import cx from 'classnames'
import CallingIcon from '../../assets/Calling.png'
import {
    Card,
    CardTitle,
    CardText,
    Col,
    Row,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap'
import ConvertHTMLString from '../../components/shared/ConvertHTMLString'
import styles from '../../views/signup/signupStyles.module.css'
import SignupSuccessful from 'views/signup/SignupSuccessful'
import StepTwoUserInfo from 'views/signup/StepTwoUserInfo'
import StepOneOTP from 'views/signup/StepOneOTP'
import QuestionMarkIcon from 'assets/iconComp/QuestionMark'
import { SUPPORT_PHONE_NUMBER } from 'components/Result/constants'
import AakashLogo from '../../assets/AakashSignupLogo.png'

const IacstLandingPageTemplate = ({
    scholarships,
    banner_title,
    registration_page,
    aboutCard,
    details,
    coursehighlight,
    successStories,
    scholarshipDetails,
}) => {
    // const history = useNavigate()
    const [currentStep, setCurrentStep] = React.useState(1)
    const [signupSuccessDetails, setSignupSuccessDetails] = React.useState({
        isSignupSuccessful: false,
    })

    const [defaultUserInfo, setDefaultUserInfo] = React.useState({
        studentName: '',
        fatherName: '',
        email: '',
        dob: '',
        mobileNumber: '',
        prevSignupAttempt: 0,
    })
    const [isNewUser, setIsNewUser] = React.useState(true)
    const [userMethod, setUserMethod] = React.useState({ isPsid: false, psid: '' })

    {
        /* React.useEffect(() => {
        if (token !== null) history.replace('/dashboard')
    }, []) */
    }

    const [open, setOpen] = useState('1')
    const toggle = (id) => {
        if (open === id) {
            setOpen()
        } else {
            setOpen(id)
        }
    }

    const length = aboutCard ? aboutCard.length : 0
    const middleIndex = Math.floor((length - 1) / 2)

    return (
        <div>
            {/* ---------clasher */}
            <div className="max-vw-100">
                <div className={`px-2 px-md-4 px-lg-5 ${styles.bgColor}`}>
                    {/* blue bg */}
                    <div className="container pt-4 d-flex justify-content-between align-items-center">
                        <a
                            href="https://www.aakash.ac.in/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {/* Header - Aakash's logo */}
                            <img
                                src={AakashLogo}
                                alt="Aakash-white-logo"
                                className="w-6 w-lg-10"
                            />
                        </a>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className='mb-0'>
                            <a
                                href={`tel:${SUPPORT_PHONE_NUMBER}`}
                                className="font-size-md font-size-xs text-white text-decoration-none d-flex"
                            >
                                <img src={CallingIcon} alt="calling-icon" />
                                <span>{SUPPORT_PHONE_NUMBER}</span>
                            </a>
                            <br/>
                            <p className={`font-size-xs font-size-md-sm text-white mb-0 ${styles.calling_time}`}> (7am to 10pm)</p>
                            </p>
                          
                           
                        </div>
                        
                    </div>
                    
                    <div
                        className={`container py-3 d-flex flex-column flex-md-row justify-content-between align-items-center ${styles.formWrapper}`}
                    >
                        {banner_title &&
                            banner_title.map((item, key) => (
                                <div className="text-center text-md-start mt-2">
                                    { item.top_head &&
                                    <h1 className="d-flex align-items-center justify-content-md-start justify-content-center text-white font-size-md-44 font-size-lg-4xl">iACST<span className="font-size-md-xxl font-size-md">{item.top_head}</span></h1>
                                    }
                                    <h4
                                        className={`text-yellow-100 fw-bold font-size-md-4xl font-size-lg-44 text-md-start ${styles.fontFamilyChange} mb-lg-4`}
                                    >
                                         <ConvertHTMLString
                                                    content= {item.head_title}
                                                />
                                    </h4>
                                    <h4
                                        className="text-white fw-bold font-size-mds font-size-lg-30 text-md-start"
                                        style={{ marginBottom: '0.75rem' }}
                                    >
                                        {item.sub_head_title}
                                    </h4>
                                    {item.class_applicable &&
                                    <p className="text-white font-size-sm font-size-lg-md">
                                       <ConvertHTMLString
                                                    content= {item.class_applicable}
                                                />
                                    </p>
                                    }
                                    { item.type && (
                                    <p className="font-size-sm font-size-lg-mds text-grass-green fw-lighter mb-0 mt-lg-4">
                                        {item.type}
                                    </p>
                                    )
                                    }
                                    <p className="text-uppercase font-size-24 font-size-md-xxl font-size-lg-30 fw-bold text-yellow-100">
                                        {item.registration}
                                    </p>
                                </div>
                            ))}

                        <div
                            className={cx(
                                'py-4 bg-white rounded-20px shadow-2xl w-10 w-md-330px w-lg-350px maxW-370 m-auto mx-lg-0',
                            )}
                            style={{ zIndex: '234' }}
                        >
                            {!signupSuccessDetails.isSignupSuccessful ? (
                                <>
                                    {/* registration- form */}
                                    <div className="px-3 d-flex justify-content-between align-items-center mb-4">
                                        <p className="text-dark-grey fw-bold mb-0">
                                            Register Now
                                        </p>
                                        <a
                                            href="#how-it-works"
                                            className="text-decoration-none"
                                        >
                                            {' '}
                                            <div className="d-flex align-items-center">
                                                <QuestionMarkIcon />
                                                <p className="text-aqua fw-lighter font-size-xs mb-0 ms-1 ms-md-0 ms-lg-1">
                                                    How it works
                                                </p>
                                            </div>
                                        </a>
                                    </div>
                                    {currentStep === 1 ? (
                                        <StepOneOTP
                                            goToNextStep={() => setCurrentStep(2)}
                                            defaultUserInfo={defaultUserInfo}
                                            setDefaultUserInfo={setDefaultUserInfo}
                                            setIsNewUser={setIsNewUser}
                                            setUserMethod={setUserMethod}
                                        />
                                    ) : (
                                        <StepTwoUserInfo
                                            registration_page={registration_page}
                                            defaultUserInfo={defaultUserInfo}
                                            isNewUser={isNewUser}
                                            userMethod={userMethod}
                                            setSignupSuccessDetails={
                                                setSignupSuccessDetails
                                            }
                                        />
                                    )}
                                </>
                            ) : (
                                <SignupSuccessful
                                    signupSuccessDetails={signupSuccessDetails}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* -------------clasher */}
            <section className={`py-5 ${styles.backgroung_gredient}`}>
                <div className={`${styles.parent_container}`}>
                    <div className="container">
                        <Row className="justify-content-center">
                            {aboutCard && aboutCard.map((obj, index) => {
                                const isMiddle =
                                    index === middleIndex && length % 2 !== 0
                                return (
                                    <Col
                                        key={index}
                                        sm="6"
                                        className={`text-center ${
                                            isMiddle
                                                ? `${styles.card_middle_elem} mx-1`
                                                : registration_page=='default'? styles.cont_str:styles.cont_str_subpages
                                        }`}
                                        id={index === 4 ? 'how-it-works' : ''}
                                    >
                                        <Card
                                            body
                                            className={`text-center ${styles.cont_str_shadow}`}
                                            style={{
                                                boxShadow:
                                                    '4px 4px 8px rgba(0, 0, 0, 0.1),-4px -4px 8px rgba(0, 0, 0, 0)',
                                                border: '0.5px #ccc',
                                            }}
                                        >
                                            <CardTitle tag="h5">
                                                <img
                                                    width={'100%'}
                                                    className="imgbanner"
                                                    alt="scholarship-test-details-heading"
                                                    src={obj.headingImage}
                                                />
                                            </CardTitle>
                                            <CardText
                                                className={`text-center lh-17 mb-0 ${styles.content_card}`}
                                            >
                                                <ConvertHTMLString
                                                    className={`text-center mb-0 ${styles.removeli}`}
                                                    content={obj.cardbodyitem}
                                                />
                                            </CardText>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>

                        {/*     ACCORDION */}
                        {scholarships && (
                            <Accordion open={open} toggle={toggle} className="mt-5">
                                {scholarships.map((obj, index) => {
                                    return (
                                        <AccordionItem
                                            className="item rounded-4px"
                                            key={index}
                                            style={{ marginBottom: '2%' }}
                                        >
                                            <AccordionHeader
                                                targetId={obj.targetId}
                                                className="rounded-top bg-royal-blue d-flex justify-content-center align-items-center accordionBtnChange"
                                            >
                                                <h5 className="ms-2 text-white fw-500 mb-0">
                                                    <b>{obj.heading}</b>
                                                </h5>
                                                <h6
                                                    className={`mb-0 ${styles.accordionsubHead}`}
                                                >
                                                    {obj.subheading}
                                                </h6>
                                            </AccordionHeader>
                                            <AccordionBody
                                                // style={{ border: '1px solid #0482e6' }}
                                                accordionId={obj.targetId}
                                                className={styles.tabularAccordion}
                                            >
                                                <div className="d-block bg-white">
                                                    <div>
                                                        <ul className={` ms-3 px-2`}>
                                                            {obj.bulletpoints.map(
                                                                (bullet, index) => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                index
                                                                            }
                                                                            className={`${styles.bullethead}`}
                                                                        >
                                                                            <div
                                                                                className={`${styles.bulletpoint}`}
                                                                            >
                                                                                {
                                                                                    bullet.point
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                },
                                                            )}
                                                        </ul>
                                                        <table
                                                            className={`${styles.tablehead} table`}
                                                            id="elegibility"
                                                        >
                                                            <tbody>
                                                                <tr
                                                                    style={{
                                                                        background:
                                                                            '#f0f8ff',
                                                                    }}
                                                                >
                                                                    <td
                                                                        className={`${styles.bordertable}`}
                                                                    >
                                                                        <b>
                                                                            Course
                                                                            Highlights
                                                                        </b>
                                                                    </td>
                                                                    <td>
                                                                        <b>
                                                                            Additional
                                                                            Benefits{' '}
                                                                        </b>
                                                                    </td>
                                                                </tr>
                                                                {obj.table.map(
                                                                    (
                                                                        table_content,
                                                                        index,
                                                                    ) => {
                                                                        return (
                                                                            <tr
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className={`${styles.table_content_item}`}
                                                                            >
                                                                                <td
                                                                                    className={`${styles.bordertable}`}
                                                                                >
                                                                                    {
                                                                                        table_content.qus
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        table_content.ans
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    },
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </AccordionBody>
                                        </AccordionItem>
                                    )
                                })}
                            </Accordion>
                        )}

                        {/*  CONTENT SECTION */}

                        <section className="scholarship-main-wrapper">
                            <div
                                style={{
                                    textAlign: 'right',
                                    color: '#818181',
                                    marginTop: '1%',
                                }}
                            >
                                *T&C Apply
                            </div>
                            {details &&
                                details.map((item, key) => (
                                    <div
                                        key={key}
                                        className={`card w-100 ${styles.repeater_card}`}
                                    >
                                        <div className="card-body">
                                            <h3
                                                className={`${styles.section_top_head}`}
                                            >
                                                <b>
                                                   {item.details_title}
                                                </b>
                                            </h3>
                                            <ul style={{ listStyleType: 'none' }} className='px-5'>
                                                {item.details_para.map((obj, k) => {
                                                    return (
                                                        <li
                                                            key={k}
                                                            className={`${styles.card_section_rep}`}
                                                        >
                                                            <img
                                                                src="https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/logo-i.png"
                                                                style={{
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    marginright: '2px',
                                                                    paddingtop: '0',
                                                                    position: 'absolute',
                                                                    left: '-25px',
                                                                    top: '4px',
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    marginLeft:
                                                                        '5px',
                                                                }}
                                                            >
                                                                {obj.cardbodyitem}
                                                            </span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                ))}

                            {coursehighlight && (
                                <>
                                    <Row className="mt-5">
                                        <h3 className="text-center fw-bold">
                                            {registration_page == 'iacst_repeater_neet_hindi' || registration_page == 'iacst_repeater_common_hindi' ? 'पाठ्यक्रम की मुख्य बातें':'Course Highlights'}
                                        </h3>
                                        <div className="d-flex align-content-start flex-wrap justify-content-center">
                                            {coursehighlight.map((obj, index) => {
                                                return (
                                                    <Col
                                                        className="p-2"
                                                        sm="3"
                                                        xs="6"
                                                        key={index}
                                                    >
                                                        <Card className='border-light'>
                                                            <div className={`m-auto d-block p-3 ${styles.box_highlight}`}>
                                                                <img
                                                                    src={
                                                                        obj.hightlighticon
                                                                    }
                                                                    className="m-auto d-block"
                                                                    width={'90px'}
                                                                    alt="expert-faculty"
                                                                />
                                                                <p className="mt-2 mb-0 font-size-sm text-center">
                                                                    {
                                                                        obj.listingcourse
                                                                    }
                                                                </p>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                )
                                            })}
                                        </div>
                                    </Row>
                                </>
                            )}
                        </section>
                        {successStories && (
                            <div>
                                {successStories.map((obj, key) => {
                                    return (
                                        <img
                                            style={{
                                                width: '100%',
                                                marginTop: '2%',
                                            }}
                                            src={obj.headingImage}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
                {scholarshipDetails && (
                    <div>
                        <section className="scholarship-main-wrapper">
                            <div className="container">
                                {scholarshipDetails.map((item, key) => (
                                    <div key={key}>
                                        {item.scholarshipTitle && (
                                            <h2 className={`${styles.head_top}`}>
                                                <b>{item.scholarshipTitle}</b>
                                            </h2>
                                        )}
                                        {item.scholarshipPara && (
                                            <p>{item.scholarshipPara}</p>
                                        )}
                                        {item.scholarshipData &&
                                            item.scholarshipData.map((i, k) => (
                                                <div key={k}>
                                                    <h4
                                                        className={`${styles.head_top} fw-bold`}
                                                    >
                                                        {i.scholarshipDataTitle}
                                                    </h4>
                                                    {i.scholarshipDataPara.map(
                                                        (list, listkey) => (
                                                            <p key={listkey}>
                                                                {
                                                                    <ConvertHTMLString
                                                                        content={
                                                                            list.scholarshipDataParagraph
                                                                        }
                                                                    />
                                                                }
                                                            </p>
                                                        ),
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                )}
            </section>
            <footer className={`${styles.foot_sec}`}>
                <p className='mb-0'
                    style={{
                        textAlign: 'center',
                        fontSize: '18px',
                        color: 'white',
                        paddingTop: '0.5%',
                        paddingBottom: '0.5%',
                    }}
                >
                    © 2024&nbsp;All right reserved by Aakash Educational Services
                    Limited | Email us:{' '}
                    <a
                        style={{ textDecoration: 'none', color: 'white' }}
                        href="mailto:acstsupport@aesl.in"
                    >
                        acstsupport@aesl.in
                    </a>
                </p>
            </footer>
        </div>
    )
}

export default IacstLandingPageTemplate
