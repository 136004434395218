import { useEffect, useState } from 'react'
import styles from './antheRsCard.module.css'

import Highcharts from 'highcharts'
const AnthePerformanceCard = (props) => {
    useEffect(() => {
        new Highcharts.Chart({
            chart: {
                renderTo: props.renderTo,
                type: 'column',
                width: '640',
                height: '350',
                shadow: false,
                borderWidth: 0.2,
                borderRadius: 6,
                borderColor: '#b2b3b3',
                scrollablePlotArea: {
                    minWidth: '600',
                    scrollPositionX: 0,
                },
                spacing: 15,
            },
            title: {
                text: `YOUR SUBJECT WISE PERFORMANCE WITH AVERAGE & MAXIMUM SCORE`,
                style: {
                    fontFamily: 'outfit',
                    fontWeight: 'bold',
                    color: '#0083C9',
                    fontSize: '14px',
                },
                align: 'center',
            },
            xAxis: {
                lineColor: '#5b5b5b',
                lineWidth: 1,
                categories: props.data.subject_labels,
                labels: {
                    style: {
                        fontFamily: 'outfit',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    },
                },
            },

            yAxis: {
                tickAmount: 9,
                lineColor: '#5b5b5b',
                lineWidth: 0,
                tickInterval: 5,
                // gridLineColor: "#b2b3b3",
                // gridLineWidth: "10px",
                gridLineDashStyle: 'dash',
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        fontFamily: 'outfit',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    },
                },
            },
            tooltip: {
                style: {
                    display: 'none',
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontFamily: 'outfit',
                            fontWeight: 'bold',
                            fontSize: '9px',
                        },
                        align: 'top',
                        color: '#5b5b5b',
                        x: 0,
                    },
                    pointPadding: 0,
                },
            },
            credits: {
                enabled: false,
            },
            series: props.data.score_data_sets.map((ele, ind) => {
                return {
                    name: ele.label,
                    style: {
                        fontFamily: 'outfit',
                    },
                    data: ele.data.map((i) => Number(Number(i).toFixed(1))),
                    color: `${
                        ind == 0
                            ? '#4091c6'
                            : ind == 1
                            ? '#EE857D'
                            : ind == 2
                            ? '#FCC025'
                            : '#81CAC6'
                    }`,
                }
            }),
        })
    }, [])

    return (
        <>
            <div id={props.renderTo} className={styles.card115}></div>
        </>
    )
}
export default AnthePerformanceCard
