import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import styles from './card.module.css'
import ButtonText from 'components/shared/ButtonText'
import Lock from '../../assets/Lock'
import { connect } from 'react-redux'

const mapStateToProps = (state) => ({
    profileInfo: state?.profileData,
})

function MockCard(props) {
    const { cardData, serialNumber } = props
    const profileData = props.profileInfo

    return (
        <>
            <Card className={`${styles.mockCard} border-0`}>
                <CardBody className="p-2">
                    <Row
                        className=" my-1"
                        style={{ userSelect: 'none', cursor: 'pointer' }}
                    >
                        <Col xs={4} md={3} lg={3} >
                            <Button
                                className={`w-100 fw-bold lh-xl rounded-2 ${
                                    styles.mockCardSerial
                                } ${
                                    cardData.isLocked
                                        ? styles.lockedSerialNumber
                                        : ` btn-green text-secondary`
                                }`}
                            >
                                {serialNumber}
                            </Button>
                        </Col>
                        <Col xs={8} md={7} lg={8} className="p-1">
                            {!cardData.isLocked ? (
                                <p className="font-size-xs font-size-md-md m-0 fw-bold">
                                    {cardData.card_title}
                                </p>
                            ) : (
                                <p className="font-size-xs font-size-md-md m-0 fw-bold d-flex align-items-middle">
                                    <Lock fill={'#757575'} /> &nbsp;
                                    {cardData.card_title}
                                </p>
                            )}
                            <p className="font-size-xs font-size-md-sm fw-normal">
                                {cardData.exam_end_date}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        {!cardData.view_report ? (
                            <Col className="py-0">
                                <ButtonText
                                    buttonText={cardData.exam_button_text}
                                    actionLink={cardData.exam_button_link}
                                    cardType={'mockCard'}
                                    mockNum={serialNumber}
                                    profileData={profileData}
                                />
                            </Col>
                        ) : (
                            <Col xs={12} className="py-0">
                                <Button
                                    color="primary"
                                    className="w-100 bg-transparent text-primary font-size-xs font-size-md-sm fw-bold"
                                    outline
                                    onClick={() => {
                                        window.open(
                                            cardData.report_link,
                                            '_blank',
                                            'noopener',
                                        )
                                        window.webengage.track(
                                            'ANTHE_db_mock_view_report',
                                            {
                                                'roll number':
                                                    profileData.roll_number,
                                                'exam type': profileData.exam_type,
                                                class: profileData.class && profileData.class.toString(),
                                                stream: profileData.stream,
                                                mockTestNumber: serialNumber,
                                            },
                                        )
                                    }}
                                >
                                    View report
                                </Button>
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default connect(mapStateToProps)(MockCard)
