import { useEffect, useState } from 'react'
import ArrowNext from '../../assets/ArrowNext'
import courseGift from '../../assets/coursegift.gif'
import { useMediaQuery } from 'react-responsive'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
    return {
        profileData: state?.profileData,
        dashboardData: state?.dashboardData,
        courseFlagsData: state?.ecommerce?.courseFlagsData,
    }
}

const GuidedJourney = ({
    disableRegister,
    courseFlagsData,
    profileData,
    dashboardData,
    isSticky,
}) => {
    const [isDisabled, setIsDisabled] = useState(true)
    const isSmallDevice = useMediaQuery({ query: '(max-width: 767px)' })
    const {
        mobile_number,
        roll_number,
        student_dob,
        student_psid,
        exam_type,
        class: division,
        classtream,
        stream,
        branch_code,
    } = profileData

    const { scholarship_msg, overall_percentage, scholarship_percentage } =
        dashboardData

    const handleCourses = () => {
        const targetElement = document.getElementById('courseCardSection')

        if (targetElement) {
            const offsetTop =
                targetElement.getBoundingClientRect().top + window.scrollY
            const offset = isSmallDevice ? 150 : 180
            window.scrollTo({
                top: offsetTop - offset,
                behavior: 'smooth',
            })
        }

        // Webengage Call for Green banner Register
        window.webengage.track('iACST_db_pr_green_banner_register', {
            'mobile number': mobile_number,
            'roll number': roll_number,
            psid: student_psid ? student_psid : 'NA',
            exam_type: exam_type,
            class_studying: division.toString(),
            class_opted: classtream.split('&')[0],
            stream: stream,
            center_code: branch_code,
            scholarship_waiver_message: scholarship_msg,
            score_percent: overall_percentage,
            scholarship_percentage: scholarship_percentage,
            dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
        })
    }
    const handleStartMainJourney = () => {
        // Webengage Call for Dashboard MyAakash journey start
        window.webengage.track('iACST_db_pr_start_myAakash_journey', {
            'mobile number': mobile_number,
            'roll number': roll_number,
            psid: student_psid ? student_psid : 'NA',
            exam_type: exam_type,
            class_studying: division.toString(),
            class_opted: classtream.split('&')[0],
            stream: stream,
            center_code: branch_code,
            scholarship_waiver_message: scholarship_msg,
            score_percent: overall_percentage,
            scholarship_percentage: scholarship_percentage,
            dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
        })
        const url = student_psid
            ? `${courseFlagsData?.preadmission_url}?psid=${student_psid}`
            : courseFlagsData?.preadmission_url
        window.open(url, '_blank')
    }
    useEffect(() => {
        if (courseFlagsData?.enable_myaakash_status) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [courseFlagsData?.enable_myaakash_status])

    return (
        <div
            className={`bg-dark-green px-lg-3 pe-3 p-2 ${
                !courseFlagsData?.course_purchased && 'custom-guided-journey-header'
            }`}
        >
            <div className="d-flex align-items-sm-start align-items-md-center justify-content-between mb-1">
                <div className="d-flex align-items-center">
                    <div className="">
                        <img
                            src={courseGift}
                            alt="gift"
                            width={isSmallDevice ? 40 : 50}
                            className="me-2"
                        />
                    </div>
                    <div className="d-flex flex-column flex-md-row mt-md-2">
                        <p className="font-size-sm-mds m-0 p-0 fw-bolder text-white font-size-xs">
                            {courseFlagsData?.guided_journey_message}
                        </p>
                        {(isSticky?.withOutJourney || isSticky?.withJourney) &&
                        !courseFlagsData?.course_purchased &&
                        isSmallDevice ? (
                            <> </>
                        ) : (
                            <p className="ms-lg-1 font-size-sm-mds m-0 p-0 fw-bolder text-white font-size-xxs">
                                {courseFlagsData?.guided_journey_sub_message}{' '}
                                <span
                                    className={`font-size-sm-mds text-yellow fw-bold custom-journey-sub-gategory ${
                                        courseFlagsData?.course_purchased
                                            ? ''
                                            : 'ms-md-3'
                                    } mt-md-0 font-size-xxs`}
                                >
                                    Ebooks | Videos | Test series | Audios
                                </span>
                            </p>
                        )}
                    </div>
                </div>
                {!disableRegister && (
                    <div>
                        {courseFlagsData?.course_purchased ? (
                            <button
                                className={`btn ${
                                    isDisabled
                                        ? 'text-grey bg-grey-40 border-grey-40 opacity-100'
                                        : 'text-dark-green bg-green-10'
                                } btn-sm me-lg-3 px-lg-4 p-2 mt-1 w-100 font-size-xs font-size-md-md fw-lighter custom-journey-button`}
                                onClick={handleStartMainJourney}
                                disabled={isDisabled}
                            >
                                Start<span className="ms-1"></span>Now
                            </button>
                        ) : (
                            <button
                                className="btn text-dark-green bg-green-10 btn-sm px-lg-4 p-2 px-3 w-100 font-size-xs fw-lighter font-size-md-md d-flex custom-journey-button"
                                onClick={handleCourses}
                            >
                                <span>Register</span>
                                <span className="ms-2 d-none d-md-block">
                                    <ArrowNext />
                                </span>
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(GuidedJourney)
