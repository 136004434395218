import { useState, useEffect } from 'react'
import axios, { AxiosResponse, AxiosError } from 'axios'

interface ApiResponse {
    // Define the structure of the API response here
    // Example: Assuming the response is an array of objects with 'id' and 'name' properties
    success: string
    data: Object
    msg: string
}

interface ApiError {
    // Define the structure of the API error response here
    // Example: Assuming the error response has 'message' property
    msg: string
}

interface UseGetApiResult {
    data: ApiResponse[] | null
    loading: boolean
    error: ApiError | null
    setEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

const useGetApi = (
    url: string,
    token: string,
    enabledCall = true,
    Authorization: string
): UseGetApiResult => {
    const [data, setData] = useState<ApiResponse[] | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<ApiError | null>(null)
    const [enabled, setEnabled] = useState<boolean>(enabledCall)

    useEffect(() => {
        if (enabled) {
            const fetchData = async () => {
                try {
                    const response: AxiosResponse<ApiResponse[]> = await axios.get(
                        url,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                token: token,
                                Authorization: Authorization,
                            },
                        },
                    )
                    setData(response.data)
                } catch (error: unknown) {
                    const apiError = (error as AxiosError<ApiError>).response
                        ?.data || {
                        msg: 'An error occurred while fetching the data.',
                    }
                    setError(apiError)
                } finally {
                    setLoading(false)
                }
            }

            fetchData()
            setEnabled(false)
        }
    }, [url, token, enabled])

    return { data, loading, error, setEnabled }
}

export default useGetApi
