import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap'
import styles from './ECommerceCourse.css'
import { connect } from 'react-redux'
import {
    SET_COURSE_DETAILS,
    SET_COURSE_FLAGS_DETAILS,
    SET_VISIBLE_COURSE_DESC,
    SET_VISIBLE_COURSE_REGISTER,
} from 'constants/actionTypes'
import ClockIcon from '../../assets/Clock'
import BookingSVG from 'assets/BookingIcon'
import ProfileIcon from 'assets/ProfileIcon'
import { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import LeftSlideIcon from 'assets/LeftSlideIcon'
import RightSlideIcon from 'assets/RightSlideIcon'

const mapStateToProps = (state) => {
    return {
        // inputFieldData: state?.ecommerce?.inputFieldData,
        dashboardData: state?.dashboardData,
        ecommerce: state?.ecommerce,
        tpResultData: state?.tpResultData
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCourseDetails: (payload) => {
        dispatch({ type: SET_COURSE_DETAILS, payload })
    },
    setCourseFlagsDetails: (payload) => {
        dispatch({ type: SET_COURSE_FLAGS_DETAILS, payload })
    },
    setCourseDescVisible: (payload) => {
        dispatch({ type: SET_VISIBLE_COURSE_DESC, payload })
    },
    setCourseRegisterVisible: (payload) => {
        dispatch({ type: SET_VISIBLE_COURSE_REGISTER, payload })
    },
})

const CourseCard = (props) => {
    const [scrollPosition, setScrollPosition] = useState(0)
    const courseDetailsRef = useRef(null)
    const isSmallDevice = useMediaQuery({ query: '(max-width: 992px)' })
    const [scrollLeftVisible, setScrollLeftVisible] = useState(false)
    const [scrollRightVisible, setScrollRightVisible] = useState(false)

    const courseDetail = [
        {
            svgimg: ClockIcon,
        },
        {
            svgimg: ProfileIcon,
            detailDesc: `class ${props?.profileData.classtream.split('&')[0]}th`,
        },
    ]

    const {
        mobile_number,
        roll_number,
        student_dob,
        student_psid,
        exam_type,
        class: division,
        classtream,
        stream,
        branch_code,
    } = props?.profileData

    const [isDigitalUser, setIsDigitalUser] = useState(branch_code && branch_code == "IC006" ? true : false)

    const { scholarship_msg, overall_percentage, scholarship_percentage } =
        props?.dashboardData

    useEffect(() => {
        props.setCourseFlagsDetails(props?.CourseData?.flags)
    }, [props?.CourseData?.flags])

    const handleCoursesScroll = () => {
        const { scrollLeft } = courseDetailsRef.current
        const container = courseDetailsRef.current
        if (container) {
            setScrollLeftVisible(container.scrollLeft > 0)
            setScrollRightVisible(
                container.scrollLeft < container.scrollWidth - container.clientWidth,
            )
        }
        const scrollDistance = 100

        if (scrollLeft >= scrollPosition + scrollDistance) {
            // Webengage Call For Courses Scroll
            window.webengage.track('iACST_db_pr_courses_horizontal_scroll', {
                'mobile number': mobile_number,
                'roll number': roll_number,
                psid: student_psid ? student_psid : 'NA',
                exam_type: exam_type,
                class_studying: division.toString(),
                class_opted: classtream.split('&')[0],
                stream: stream,
                center_code: branch_code,
                scholarship_waiver_message: scholarship_msg,
                score_percent: overall_percentage,
                scholarship_percentage: scholarship_percentage,
                dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
            })
            // Update the scroll position
            setScrollPosition(scrollPosition + scrollDistance)
        }
    }

    useEffect(() => {
        const container = courseDetailsRef.current
        if (container) {
            setScrollRightVisible(
                container.scrollLeft + container.clientWidth < container.scrollWidth,
            )
        }
    }, [])

    const getDiscountedPrice = (breakup) => {
        let total = 0;
        const chargeableFees = {
            'Admission Kit & Digital Access': 'admfee',
            'Tuition Fee': 'tuitionfee',
            'Tech + Exam Fee': 'Technology_Examination_Fee',
            'Classroom Fee Service': 'ClassroomService'
        }

        const result = props?.tpResultData?.GetStudentResult_WebJsonResult || {};
        for (const key in breakup) {
            if (Object.prototype.hasOwnProperty.call(breakup, key)) {
                const feeValue = parseFloat(breakup[key])
                const tpkey = chargeableFees[key];
                if (tpkey && result[tpkey] && result[tpkey] != 'Chargeable' && result[tpkey] != '0') {
                    const match = result[tpkey].match(/(\d+)%/)
                    let wavierValue = 0
                    if (match && match.length > 1) {
                        wavierValue = parseFloat(match[1] / 100)
                    } else {
                        wavierValue = parseFloat(result[tpkey]) / 100
                    }

                    total += Math.round(feeValue - feeValue * wavierValue)
                }else{
                    total += feeValue
                }
            }
        }

        return Math.round(total)
    }

    const scrollLeft = () => {
        const container = courseDetailsRef.current
        if (container) {
            container.scrollBy({ left: -200, behavior: 'smooth' })
        }
    }

    const scrollRight = () => {
        const container = courseDetailsRef.current
        if (container) {
            container.scrollBy({ left: 200, behavior: 'smooth' })
        }
    }

    const handleCourseDescVisible = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 10)
        props.setCourseDescVisible(!props.ecommerce.courseDescVisible)
    }

    const handleCourseRegistrationsVisible = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 10)
        props.setCourseRegisterVisible(!props.ecommerce.courseRegisterVisible)
    }

    const handleViewCourseDesc = (ele) => {
        props.setCourseDetails(ele)
        /* if(props.inputFieldData != null){
         props.setInputFieldData();
         }*/

        // Webengage Call for Courses Details View
        window.webengage.track('iACST_db_pr_courses_view_details', {
            'mobile number': mobile_number,
            'roll number': roll_number,
            psid: student_psid ? student_psid : 'NA',
            exam_type: exam_type,
            class_studying: division.toString(),
            class_opted: classtream.split('&')[0],
            stream: stream,
            center_code: branch_code,
            course_name: ele.product_title,
            course_id: ele.product_id,
            course_term: ele.product_course_term,
            scholarship_waiver_message: scholarship_msg,
            score_percent: overall_percentage,
            scholarship_percentage: scholarship_percentage,
            dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
        })

        handleCourseDescVisible()
    }

    const handleRegisterCourse = (ele) => {
        props.setCourseDetails(ele)

        // Webengage Call for Courses Register View
        window.webengage.track('iACST_db_pr_courses_register', {
            'mobile number': mobile_number,
            'roll number': roll_number,
            psid: student_psid ? student_psid : 'NA',
            exam_type: exam_type,
            class_studying: division.toString(),
            class_opted: classtream.split('&')[0],
            stream: stream,
            center_code: branch_code,
            course_name: ele.product_title,
            course_id: ele.product_id,
            course_term: ele.product_course_term,
            scholarship_waiver_message: scholarship_msg,
            score_percent: overall_percentage,
            scholarship_percentage: scholarship_percentage,
            dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
        })

        handleCourseRegistrationsVisible()
    }

    return (
        <div className="courseContainer">
            <div
                className="coursedetails"
                onScroll={handleCoursesScroll}
                ref={courseDetailsRef}
            >
                <div
                    className={`scrollButtonleft ${
                        scrollLeftVisible ? 'd-md-block' : 'd-none'
                    }`}
                    onClick={scrollLeft}
                >
                    <LeftSlideIcon />
                </div>
                {props?.CourseData?.data && props?.CourseData?.data.length > 0 ? (
                    props?.CourseData?.data.map((ele, ind) => {
                        return (
                            <Col
                                key={ind}
                                sm={10}
                                xs={10}
                                md={5}
                                lg={5}
                                xl={5}
                                className="coursedetailsbox"
                            >
                                <Card
                                    className={`p-2 p-md-3 pb-md-0 mb-1 mx-0 mx-lg-2 h-100 ${
                                        isSmallDevice
                                            ? 'border-0 shadow-box'
                                            : 'border-light-grey'
                                    }`}
                                >
                                    <CardHeader className="border-0 bg-white fw-bold p-0  font-size-sm font-size-md-md customProductTitle">
                                        {ele.product_title}
                                    </CardHeader>
                                    <CardBody className="p-0 my-2 text-grey fw-lighter font-size-xs font-size-md-sm font-size-lg-md">
                                        <div className="d-flex">
                                            {courseDetail.map((e, k) => (
                                                <div
                                                    key={k}
                                                    className="border border-light-grey rounded me-2 pe-2 ps-1  d-flex align-items-center justify-content-center"
                                                >
                                                    <e.svgimg />
                                                    {e.detailDesc ? (
                                                        <span className="text-grey-black font-size-xxs font-size-md-xs fw-500 coursedetailtext">
                                                            {e.detailDesc}
                                                        </span>
                                                    ) : (
                                                        <span className="text-grey-black font-size-xxs font-size-md-xs fw-500 coursedetailtext">
                                                            {ele.course_category}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                        <hr />
                                        {ele.purchased ? (
                                            <>
                                                {isDigitalUser ? <></> : (<>
                                                    <p className="font-size-xs ms-2 m-0 mb-2 fw-400 text-black">
                                                        Receipt will be provided at the
                                                        branch.
                                                    </p>
                                                    <p className="bg-beige p-2 font-size-xs font-size-md-sm text-Yellow-70 fw-400 rounded">
                                                        Visit the branch for academic
                                                        counselling.
                                                    </p>
                                                </>)}
                                                <div className="grid">
                                                    <button
                                                        type="button"
                                                        className="btn border-aqua text-aqua p-2 w-100 font-size-xs font-size-md-sm fw-lighter custom-view-button"
                                                        onClick={() =>
                                                            handleViewCourseDesc(ele)
                                                        }
                                                    >
                                                        View Course
                                                    </button>
                                                </div>
                                                <span className="font-size-xxs font-size-md-xs fw-500 float-end mt-1 mx-1 mb-0 pb-0 text-dark-green">
                                                    Purchased
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <div className="p-0 d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <span className="font-size-xs text-dark-grey fw-500">
                                                            {isDigitalUser ? "Course Fee" : "Registration Fee"}
                                                        </span>
                                                        <div className="d-flex align-items-center mt-1">
                                                            <p className="font-size-mds text-dark-green-1 text-align-center font-weight-700 me-2 mb-0">
                                                                ₹{' '}
                                                                {parseInt(
                                                                    isDigitalUser ?
                                                                        ele.price_breakup.reduce((total, obj) => total + getDiscountedPrice(obj), 0)
                                                                        : ele.registration_fees
                                                                ).toLocaleString('en-IN')}
                                                            </p>
                                                            {/* <p className="border border-error-red background-white font-size-xxs p-1 registration_filling text-error-red">
                                                      Filling Fast
                                                  </p> */}
                                                        </div>
                                                    </div>
                                                    <BookingSVG />
                                                </div>
                                                <p className="text-grey-50 font-size-md-xs font-size-xxs fw-400 ">
                                                    {isDigitalUser ? "Course Fee" : "Registration Fee"} is non-refundable as per policy
                                                </p>
                                                <Row>
                                                    <div className="col-6 pe-1">
                                                        <button
                                                            type="button"
                                                            className="btn border-aqua text-aqua w-100 p-2 font-size-xs font-size-md-md fw-lighter custom-view-button"
                                                            onClick={() =>
                                                                handleViewCourseDesc(
                                                                    ele,
                                                                )
                                                            }
                                                        >
                                                            View Course
                                                        </button>
                                                    </div>
                                                    <div className="col-6 ps-1">
                                                        <button
                                                            type="button"
                                                            className="btn border-aqua bg-aqua text-white w-100 p-2 font-size-xs fw-lighter font-size-md-md  me-1 custom-register-button"
                                                            onClick={() =>
                                                                handleRegisterCourse(
                                                                    ele,
                                                                )
                                                            }
                                                        >
                                                            Register Now
                                                        </button>
                                                    </div>
                                                </Row>

                                                <span className="font-size-xxs font-size-md-xs float-end mt-1 mx-1 mb-0 pb-0 text-error-red">
                                                    Filling Fast
                                                </span>
                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                ) : (
                    <div className="ms-2">No Courses Available for this user</div>
                )}
                {/* Scroll buttons */}
                <div
                    className={`scrollButtonright ${
                        scrollRightVisible ? 'd-md-block' : 'd-none'
                    }`}
                    onClick={scrollRight}
                >
                    <RightSlideIcon />
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCard)
