import styles from '../../IacstResults/iacstResults.module.css'

const Table2 = (props) => {
    const { data } = props

    return (
        <div className={styles.tableWrapper}>
            <div style={{ width: '100%' }}>
                <header className={styles.header}>RESULT</header>
                <div className={styles.thead}></div>

                <div className={styles.thead}>
                    <section className={styles.sectionT2}>
                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>Roll No. / PSID</span> :
                            <span className={styles.spanBT2}>
                                <b>{props.rollNo}</b>
                            </span>
                        </div>

                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>
                                Name of the Candidate
                            </span>{' '}
                            :<span className={styles.spanBT2}>{props.name}</span>
                        </div>

                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>Father's Name</span> :
                            <span className={styles.spanBT2}>{props.fname}</span>
                        </div>

                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>
                                Stream / Class Opted{' '}
                            </span>{' '}
                            :
                            <span
                                className={styles.spanBT2}
                                style={{ wordBreak: 'break-word' }}
                            >
                                {props.class}
                            </span>
                        </div>
                        {props?.data.result?.OverallScorePercentage ? (
                            <div className={styles.secDataT2}>
                                <span className={styles.spanA}>
                                    Percentage Marks{' '}
                                </span>{' '}
                                :
                                <span className={styles.spanBT2}>
                                    {props?.data.result?.OverallScorePercentage}%
                                </span>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>iACST Result </span> :
                            <span
                                className={styles.spanBT2}
                                style={{ wordBreak: 'break-word' }}
                            >
                                {props.result}
                                {props?.result == 'Qualified' ? (
                                    <span>
                                        * |{' '}
                                        {props?.data.result?.ScholarshipPercentage}%
                                        Waiver on Tuition & Classroom Service Fee
                                        based on your IACST 2024 Percentage Score.
                                    </span>
                                ) : (
                                    ''
                                )}
                            </span>
                        </div>
                    </section>
                </div>
            </div>
            {props?.result == 'Qualified' ? (
                <div className={styles.qualifiedText}>
                    *The secured scholarship is applicable on Classroom Courses
                </div>
            ) : (
                <div className={styles.qualifiedText}></div>
            )}
        </div>
    )
}

export default Table2
