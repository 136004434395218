import { useEffect, useState } from 'react'
import { FormGroup, Input, InputGroup } from 'reactstrap'
import isNumeric from 'utils/isNumeric'
import { VALIDATION_FNS, errorMessages } from 'utils/validations'

export const InputField = ({
    name = '',
    value,
    onChange = () => {},
    onBlur = () => {},
    placeholder,
    title = '',
    type = 'text',
    required = true,
    disabled = false,
    messages,
    errorMsg = '',
    showError = true,
    showErrorOn,
    validation,
    onErrorUpdate = () => {},
    readOnly = false,
    actionBtn,
    onInternalBtnClick,
    inputGroupStyles = '',
    inputBoxStyles = '',
    classNames = '',
    maxLength,
}) => {
    const [errors, setErrors] = useState({
        error: errorMsg,
        showError: showError,
    })

    const { error } = errors

    useEffect(() => {
        setErrors({
            error: errorMsg,
            showError: showError,
        })
    }, [showError, errorMsg])

    const handleOnChange = (event) => {
        const value = event.target.value
        if (event.target.type === 'tel' && !/^\d*$/.test(value)) {
            return true
        }

        setErrors({
            showError: false,
            error: '',
        })

        if (validation?.toLowerCase() === 'phone') if (!isNumeric(value)) return

        onChange(event)
    }

    const handleOnInternalBtnClick = () => {
        onInternalBtnClick?.()
    }

    const handleKeyDown = (e) => {
        // Only integer numbers
        type === 'number' &&
            (e.key === 'e' ||
                e.keyCode === 187 ||
                e.keyCode === 189 ||
                e.keyCode === 190) &&
            e.preventDefault()
    }

    const handleBlur = (event) => {
        const args = { event, value: event.target.value }

        if (showErrorOn?.toLowerCase() === 'blur') validate(args.value)

        onBlur && onBlur(args)
    }

    const validate = (value) => {
        let _showError = true,
            error = ''

        if (typeof validation === 'string') {
            error = VALIDATION_FNS[validation]({ value })
            if (error && messages[validation]) error = messages[validation]
        }

        if (required && !value.trim()) {
            error = messages?.required || errorMessages.REQUIRED
        }

        if (errorMsg) error = errorMsg

        if (showError) _showError = true

        setErrors({
            error,
            showError: _showError,
        })

        onErrorUpdate?.(name, error)
    }

    return (
        <FormGroup className={`mb-3 w-100 ${classNames}`} key={name}>
            {title && (
                <label
                    className={`form-control-label font-size-sm fw-lighter mb-1 ${
                        error ? 'text-error-red' : 'text-light-grey-200'
                    }`}
                    htmlFor={name}
                >
                    {title}
                </label>
            )}

            <InputGroup className={`w-100 ${inputGroupStyles}`}>
                <Input
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    type={type}
                    name={name}
                    value={value}
                    disabled={disabled}
                    className={`w-100 fw-lighter font-size-sm rounded ${
                        error.length
                            ? `border border-error-red`
                            : `border border-grey-100`
                    } ${
                        disabled ? 'bg-light-grey dark-grey' : 'text-grey-black'
                    } ${inputBoxStyles}`}
                    placeholder={placeholder}
                    // defaultValue={defaultValue}
                    readOnly={readOnly}
                    required={required}
                    autoComplete="off"
                    maxLength={maxLength}
                />
                {actionBtn && (
                    <div
                        // className={cx(styles.actionButton, {
                        //     [styles.in]: !showAnalysis,
                        // })}
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            height: '100%',
                            margin: 'auto',
                            pointerEvents: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={handleOnInternalBtnClick}
                    >
                        {actionBtn}
                    </div>
                )}
            </InputGroup>

            {error && <p className="font-size-xs text-error-red">{error}</p>}
        </FormGroup>
    )
}
