import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import { SunspotLoader } from 'react-awesome-loaders'

import ResultTable from './iacstResultTable/rsTable'
import ResultTable2 from './iacstResultTable/rsTable2'
import { isSafari, isMobile } from 'components/Result/helper'
import CmsData from './components/cms'
import Parser from 'html-react-parser'
import useResultData from './useResultData'
import PrintButtons from './components/print-buttons'
import Header from './components/header'
import ComingSoon from './components/coming-soon'
import IacstResultNotFound from './iacstResultNotFound/iacstResultNotFound'
import ScoreDetails from 'components/Result/components/score-details'
import AdmissionNotes from './components/admission-notes'
import Footer from '../components/footer'

import styles from './result.module.scss'

const IacstResults = (props) => {
    const [isPrinting, setIsPrinting] = useState(false)
    const [showAnalysis, setShowAnalysis] = useState(false)

    const componentRef = useRef()
    const isSafariBrowser = isSafari()
    const isMobileBrowser = isMobile()

    const { loading, data, dataFailureMsg, error } = useResultData()

    useEffect(() => {
        document.title = "Student Result | Aakash BYJU'S"
        return () => (document.title = 'Student Login | IACST')
    }, [])

    const resulthandle = () => {
        // Mobile Chrome browser calls onAfterPrint just after onBeforeGetContent is called, so to let the print take control before we set variables, we're using a 1sec delay.
        return new Promise((resolve) => {
            setTimeout(() => {
                setShowAnalysis(false)
                setIsPrinting(false)
                resolve()
            }, 1000)
        })
    }

    const scorehandle = () => {
        window.webengage.track('iACST_db_main_sample_detailed_result_download', {
        })
        setShowAnalysis(true)
    }

    const printhandle = () => {
        setIsPrinting(true)
        if (data?.analysis_tab_status) {
            setShowAnalysis(true)
        }

        // We're using a promise, setTimeout so that the state gets updated & UI for pdf is rendered.
        return new Promise((resolve) => {
            setTimeout(
                () => {
                    resolve()
                },
                // We have a highcharts that use animation to load the statistics, so we need to wait a second for it to render before we give it to print.
                data.analysis_tab_status ? 1000 : 0,
            )
        })
    }

    if (error) {
        return <IacstResultNotFound apiError={false} />
    }

    if (loading) {
        return (
            <div className={styles.loader}>
                <SunspotLoader
                    background={'#fff'}
                    gradientColors={['#6366F1', '#E0E7FF']}
                    shadowColor={'#3730A3'}
                    desktopSize={'128px'}
                    mobileSize={'100px'}
                />
            </div>
        )
    }

    if (!data || dataFailureMsg) {
        return <div>{Parser(`${dataFailureMsg}`)}</div>
    }

    if (!data.result_page_status) {
        return <ComingSoon />
    }

    const shouldRenderStudentsResultTable =
        (!isPrinting && !showAnalysis) || (isPrinting && !data.analysis_tab_status)

    return (
        <div
            className={cx(styles.container, { [styles.preview]: !isPrinting })}
            ref={componentRef}
        >
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                <Header isPrinting={isPrinting} />
                {!isPrinting ? (
                    <PrintButtons
                        showAnalysis={showAnalysis}
                        resulthandle={resulthandle}
                        scorehandle={scorehandle}
                        printhandle={printhandle}
                        componentRef={componentRef}
                        data={data}
                    />
                ) : null}
                {shouldRenderStudentsResultTable ? (
                    <>
                        <div className={styles.message}>
                            {Parser(`${data?.result_message}`)}
                        </div>
                        <ResultTable
                            classname={`FOR CLASS ${data?.result.ClassStudying} STUDYING STUDENT`}
                            data={data}
                        />
                    </>
                ) : data.analysis_tab_status ? (
                    <>
                        <div className={styles.message}>
                            {Parser(`${data?.result_message}`)}
                        </div>
                        <ResultTable2
                            classname={`FOR CLASS ${data?.result.ClassStudying} STUDYING STUDENT`}
                            rollNo={`${data?.result.rollno} / ${data?.result.PSID}`}
                            name={`${data?.result.Name}`}
                            fname={`${data?.result.Fname}`}
                            class={`${data?.result.stream} / ${data?.result.ClassOpted}`}
                            result={`${data?.result.Status}`}
                            rank={`${data?.rank_to_check_in_template}`}
                            data={data}
                        />
                    </>
                ) : (
                    <>
                        <div className={styles.disableMsg}>
                            <p>
                                *Please Visit Nearest Aakash Branch for Detailed
                                Result, Scorecard as well as for Special Admission
                                Offers. <strong>Enroll Today</strong>
                            </p>
                            <a href="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/anthe-sample-result-2022-new.pdf">
                                (Click here for Sample Scorecard Sheet for Detail
                                Reference)
                            </a>
                        </div>
                    </>
                )}
                {isPrinting ? (
                    <div className={styles.address}>
                        <Footer />
                    </div>
                ) : null}
            </div>
            {!showAnalysis ||
            (showAnalysis && data.analysis_tab_status) ||
            isPrinting ? (
                <>
                    <div className={styles.break} />
                    <div
                        className={cx(
                            styles.page,
                            { [styles.safari]: isSafariBrowser },
                            { [styles.mobile]: isMobileBrowser },
                            { [styles.print]: isPrinting },
                        )}
                    >
                        <AdmissionNotes
                            phoneNo={data.branch_contact_info.phone_no}
                            isPrinting={isPrinting}
                        />
                        {isPrinting ? (
                            <div className={styles.address}>
                                <Footer />
                            </div>
                        ) : null}
                    </div>
                </>
            ) : null}
            {showAnalysis && data?.analysis_tab_status ? (
                <ScoreDetails
                    data={data}
                    isSafariBrowser={isSafariBrowser}
                    isMobileBrowser={isMobileBrowser}
                    isPrinting={isPrinting}
                />
            ) : null}
            {!showAnalysis || isPrinting ? (
                <CmsData
                    isPrinting={isPrinting}
                    isSafariBrowser={isSafariBrowser}
                    isMobileBrowser={isMobileBrowser}
                    data={data}
                />
            ) : null}
        </div>
    )
}

export default IacstResults
