import { useLocation } from 'react-router-dom'

import WarningIcon from '../../assets/Warning'
import { ReactComponent as OopsImage } from '../../assets/oops.svg'
import Header from 'components/Header/header'

function RegistrationClosed() {
    const location = useLocation()
    const availableDates = location.state ? location.state.availableDates : ''

    return (
        <>
            <div className="mb-3">
                <Header />
            </div>
            <div className="vw-100 text-center d-flex flex-column justify-content-center align-items-center">
                <div className="w-6">
                    <OopsImage />

                    <p className="fw-lighter font-size-md mt-5 mb-4">
                        Sorry registration for ANTHE exam not available today
                    </p>
                </div>

                {availableDates && (
                    <div className="bg-beige text-brown rounded py-1 px-4 m-3">
                        <WarningIcon fill="transparent" stroke="#705010" />
                        <span className="font-size-sm ms-4">
                            ANTHE offline dates are {availableDates} Oct
                        </span>
                    </div>
                )}
            </div>
        </>
    )
}

export default RegistrationClosed
