import cx from 'classnames'
import Parser from 'html-react-parser'

import AnthePerformanceCard from '../graphCard/anthePerformanceCard'
import ScoreCardTable from '../AntheResults/resultTable/score-card-table'
import RelativeAnalysisTable from '../AntheResults/resultTable/relative-analysis-table'

import ANTHE_HEADER from 'assets/header-anthe-2022.png'

import styles from './score-details.module.scss'

const ScoreDetailsAnthe = (props) => {
    const { data, isPrinting, isSafariBrowser, isMobileBrowser } = props

    const isAnthe = !window.location.pathname.includes('iacst-result')

    function renderHeaderDetails() {
        if (!isPrinting) {
            return null
        }
        return (
            <div className={styles.details}>
                <p>
                    Name:
                    <span>{data?.result.Name}</span>
                </p>
                <p>
                    PSID:
                    <span>{data?.result.PSID}</span>
                </p>
            </div>
        )
    }

    return (
        <div
            className={cx(
                styles.wrapper,
                { [styles.safari]: isSafariBrowser },
                { [styles.mobile]: isMobileBrowser },
            )}
        >
            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    styles.percentile,
                    {
                        [styles.print]: isPrinting,
                    },
                )}
            >
                {isPrinting ? (
                    <img
                        className={styles.header}
                        src={ANTHE_HEADER}
                        alt="anthe-header"
                    />
                ) : null}
                {renderHeaderDetails()}
                <ScoreCardTable
                    isSafari={isSafariBrowser}
                    isMobile={isMobileBrowser}
                    data={data}
                />
                {isAnthe ? (
                    <div className={styles.text}>
                        <span>
                            <strong>
                                Method for Calculation of Percentile Score:
                            </strong>
                        </span>
                        <p>Percentile Score = (Your score) x 100 / Highest Score</p>{' '}
                        <p>
                            (For example: Your score is 80 out of 90 against a
                            highest marks of 88 of the class topper, then your
                            percentile marks will be 80*100/88 = 90.90)
                        </p>
                    </div>
                ) : null}
                {data.score_data_sets.length ? (
                    <div className={styles.card}>
                        <AnthePerformanceCard
                            isSafari={isSafariBrowser}
                            isMobile={isMobileBrowser}
                            renderTo="cardData115"
                            data={data}
                        />
                    </div>
                ) : null}
            </div>
            <div className={styles.break} />
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    {
                        [styles.print]: isPrinting,
                    },
                )}
            >
                {isPrinting ? (
                    <img
                        className={styles.header}
                        src={ANTHE_HEADER}
                        alt="anthe-header"
                    />
                ) : null}
                {renderHeaderDetails()}
                {data?.rpa?.length || data.score_data_sets.length ? (
                    <div
                        className={styles.difficut_analysis_section}
                        style={{
                            fontFamily: 'arial',
                            background: '#fff',
                            border: '2px solid #ebeff3',
                            marginBottom: '10px',
                            marginTop: '72px',
                            textAlign: 'center',
                            padding: '10px',
                        }}
                    >
                        <h6 style={{ color: '#2b7dca' }}>
                            YOUR LEVEL OF PREPARATION IN (
                            {`${isAnthe ? 'ANTHE' : 'IACST'}`}-2024)
                        </h6>

                        <h3>Based on your Percentage of Marks</h3>
                        <img
                            style={{ width: '100%' }}
                            alt="performance"
                            src="https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/peformance-img.png"
                        />
                        <div
                            style={{
                                borderTop: '1px solid #ebeff3',
                                padding: '5px 15px',
                            }}
                        >
                            {data?.result.PreparationLevelText
                                ? Parser(`${data?.result.PreparationLevelText}`)
                                : null}
                        </div>
                    </div>
                ) : null}
            </div>
            <div className={styles.break} />
            <div className={cx(styles.page, { [styles.print]: isPrinting })}>
                {isPrinting ? (
                    <img
                        className={styles.header}
                        src={ANTHE_HEADER}
                        alt="anthe-header"
                    />
                ) : null}
                {renderHeaderDetails()}
                {props.data.rpa.length ? (
                    <RelativeAnalysisTable data={data} />
                ) : null}
            </div>
        </div>
    )
}

export default ScoreDetailsAnthe
