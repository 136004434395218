import Icon from '../components/shared/IconBase'

const CloseIcon = (props) => (
    <Icon width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
        <circle cx="18" cy="18" r="18" fill="white"/>
        <path d="M24.405 22.7392L19.6657 17.9998L24.4046 13.2608C24.8647 12.8008 24.8647 12.0551 24.4046 11.595C23.9446 11.135 23.1989 11.135 22.7389 11.595L17.9999 16.3341L13.261 11.595C12.801 11.135 12.0553 11.135 11.5952 11.595C11.1352 12.0551 11.1352 12.8008 11.5952 13.2608L16.3342 17.9998L11.5949 22.7392C11.1352 23.1989 11.1349 23.9449 11.5949 24.405C12.0549 24.865 12.801 24.8647 13.2607 24.405L17.9999 19.6656L22.7392 24.405C23.1993 24.865 23.9449 24.865 24.405 24.405C24.865 23.9449 24.865 23.1992 24.405 22.7392Z" fill="#333333"/>

   </Icon>
)

export default CloseIcon
