import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ReactComponent as SuccessConfetti } from '../../assets/successConfetti.svg'
import WarningIcon from 'assets/Warning'
import Header from 'components/Header/header'

function RegistrationSuccessful() {
    const history = useHistory()
    const location = useLocation()
    const registrationDetails = location.state
        ? location.state.registrationDetails
        : ''
    React.useEffect(() => {
        if (registrationDetails === '') history.replace('/')
        else {
            const {
                roll_number,
                student_mobile_no,
                dob,
                student_name,
                student_email,
                father_name,
                class_studying,
            } = registrationDetails

            const { staticWebAnalyticsObj } = location.state

            window.webengage.track('ANTHE_EXI_7_Thank_You_Page', {
                roll_number,
                student_name,
                father_name,
                dob,
                email_id: student_email,
                mobile: student_mobile_no,
                class_studying_stream: class_studying,
                exam_mode: 'offline',
                state: staticWebAnalyticsObj.utm_state,
                center: staticWebAnalyticsObj.utm_center,
                ...staticWebAnalyticsObj,
            })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Header />
            <div className="w-9 maxW-370 m-auto pt-4 pb-3">
                <div className="text-center">
                    <SuccessConfetti />
                    <p className="fw-lighter font-size-lg text-secondary">
                        {location.state.is_Registered
                            ? 'You are already registered'
                            : 'Registration Successful'}
                    </p>
                </div>

                <div className="text-center" style={{ fontWeight: 500 }}>
                    <p className="text-light-grey-200 font-size-md mb-0">
                        Student Name:{' '}
                        <span className="text-light-grey-200">
                            {registrationDetails.student_name}
                        </span>
                    </p>

                    <p className="text-light-grey-200 font-size-md mb-0">
                        Roll number:{' '}
                        <span className="text-aqua">
                            {registrationDetails.roll_number}
                        </span>
                    </p>

                    <p className="font-size-sm">
                        {registrationDetails.class_studying}
                    </p>
                </div>

                <p className="w-9 m-auto text-center font-size-sm">
                    Wishing you success on your exam! Looking forward to having you
                    join our institute
                </p>

                <div className="d-flex align-items-center bg-beige text-brown rounded py-2 px-4 mt-4 mx-2">
                    <WarningIcon fill="transparent" stroke="#705010" />

                    <div className="font-size-xs ms-4">
                        <div>Capture your login details!</div>
                        <div>Screenshot now to secure your credentials</div>
                    </div>
                </div>

                <div className="rounded-10px my-4 py-3 px-4 bg-off-white">
                    <p className="font-size-md fw-lighter mb-0">View Results</p>
                    <p
                        className="font-size-xs pb-2"
                        style={{ borderBottom: '2px dotted #757575' }}
                    >
                        Post examination you can view your result on the ANTHE
                        dashboard
                    </p>

                    <p className="font-size-sm mb-0">
                        Roll number: {registrationDetails.roll_number}
                    </p>
                    <p className="font-size-sm">
                        Password: {registrationDetails.dob}
                    </p>

                    <a
                        target="_self"
                        href={registrationDetails.dashboard_link}
                        className="font-size-sm text-aqua"
                    >
                        Click here to go to dashboard
                    </a>
                </div>
            </div>
        </>
    )
}

export default RegistrationSuccessful
