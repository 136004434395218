import { startMainExam } from 'api/api'
import { Button } from 'reactstrap'

const ButtonText = ({
    buttonText,
    actionLink,
    cardType,
    mockNum = 0,
    profileData = {},
    customStyles = '',
    apiData = {},
}) => {
    const matchBtnText = buttonText.toLowerCase()

    let styles = ''

    const webEngageSampleCardPayload = {
        'mobile number': profileData.mobile_number,
        'exam type': profileData.exam_type,
        psid: profileData.student_psid || '',
        class: (profileData.class && profileData.class.toString()) || '',
        stream: profileData.stream,
        branchcode: profileData.exam_center_id,
    }
    const webEngageMockCardPayload = {
        'roll number': profileData.roll_number,
        'exam type': profileData.exam_type,
        class: profileData.class && profileData.class.toString(),
        stream: profileData.stream,
        mockTestNumber: mockNum,
        branchcode: profileData.exam_center_id,
    }
    let webEngageEvent
    let webEngagePayload
    switch (matchBtnText) {
        case 'upcoming':
            styles = 'btn-green text-secondary pe-none font-size-xs font-size-md-sm'
            break

        case 'missed':
            styles = 'btn-pink text-danger pe-none font-size-xs font-size-md-sm'
            break

        case 'result awaited':
            styles =
                'btn-light-yellow text-warning pe-none font-size-xs font-size-md-sm'
            break

        default:
            styles = 'btn-aqua text-white font-size-xs font-size-md-sm'
            switch (cardType) {
                case 'sampleCard':
                    styles = 'btn-aqua text-white font-size-xs font-size-md-sm'
                    if (matchBtnText === 'start now') {
                        webEngageEvent = 'iACST_db_sample_Start_Now'
                    } else if (matchBtnText === 'resume') {
                        webEngageEvent = 'iACST_db_sample_resume_test'
                    } else if (matchBtnText === 're-attempt') {
                        webEngageEvent = 'iACST_db_sample_reattempt'
                    } else webEngageEvent = ''
                    webEngagePayload = webEngageSampleCardPayload
                    break

                case 'mockCard':
                    styles = 'btn-aqua text-white font-size-xs font-size-md-sm'
                    if (matchBtnText === 'start now') {
                        webEngageEvent = 'iACST_db_mock_begin_test'
                    } else if (matchBtnText === 'resume') {
                        webEngageEvent = 'iACST_db_mock_resume_test'
                    } else webEngageEvent = ''
                    webEngageMockCardPayload.mockTestNumber = mockNum
                    webEngagePayload = webEngageMockCardPayload
                    break
                // case 'mainCard':
                //     styles = 'btn-aqua text-white font-size-xs font-size-md-sm'
                //     if (matchBtnText === 'begin test') {
                //         webEngageEvent = 'iACST_db_mock_begin_test'
                //     } else if (matchBtnText === 'resume test') {
                //         webEngageEvent = 'iACST_db_mock_resume_test'
                //     } else webEngageEvent = ''
                //     webEngageMockCardPayload.mockTestNumber = mockNum
                //     webEngagePayload = webEngageMockCardPayload
                //     break
                default:
                    break
            }
    }

    const startMainExamEvent = async () => {
        await startMainExam(
            apiData.token,
            apiData.test_short_code,
            apiData.source_type,
        )
        window.webengage.track('iACST_db_main_begin_test', {
            'roll number': profileData.roll_number,
            psid: profileData.student_psid ? profileData.student_psid : 'NA',
            'exam type': profileData.exam_type,
            class_studying: profileData.class.toString() || '',
            stream: profileData.stream,
            branchcode: profileData.exam_center_id,
        })
    }

    return (
        <Button
            className={`${styles} w-100 font-size-xs fw-bold ${customStyles}`}
            onClick={(e) => {
                // if(matchBtnText==="begin test")
                // {
                //     window.webengage.track('iACST_db_main_begin_test', {
                //     });
                // }
                if(matchBtnText==="resume test")
                {
                    window.webengage.track('iACST_db_main_resume_test', {
                    })
                }
                if (cardType === 'mainCard' && matchBtnText === 'begin test' )
                    startMainExamEvent()
                if (actionLink !== '#') window.open(actionLink, '_blank', 'noopener')
                webEngageEvent &&
                    webEngageEvent.length > 0 &&
                    window.webengage.track(webEngageEvent, webEngagePayload)
            }}
        >
            {buttonText}
        </Button>
    )
}

export default ButtonText
