import React from 'react';
import { PropTypes } from 'prop-types';

const MathRenderer = (props) => {
  const { mathml } = props;

  React.useEffect(() => {
    // Trigger MathJax rendering when component mounts
    if (window.MathJax) {
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
    }
  }, [mathml]);

  return (
    <div className="math-renderer">
      <div dangerouslySetInnerHTML={{ __html: mathml }} />
    </div>
  );
};

export default MathRenderer;

MathRenderer.propTypes = {
  mathml: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};