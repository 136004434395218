import React, { useState, useRef } from 'react'
import cx from 'classnames'
import Parser from 'html-react-parser'
import { SunspotLoader } from 'react-awesome-loaders'
import ANTHE_FOOTER from 'assets/anthefooter.png'

import { isSafari, isMobile } from 'components/Result/helper'

import Header from './components/header'
import useResultData from './useResultData'
import InvalidResult from './components/invalid-result'
import ComingSoon from './components/coming-soon'
import ResultNotFound from '../resultNotFound/resultNotFound'
import ScoreDetailsAnthe from '../components/score-details'
import ResultTable from './resultTable/result-table'
import AntheCmsData from './components/anthe-cms'
import PrintButtons from './components/print-buttons'
import Footer from './components/footer'
import AnalysisTable from './components/analysis-table'

import styles from './result.module.scss'

const Result = (props) => {
    const componentRef = useRef()
    const [isPrinting, setIsPrinting] = useState(false)
    const [showAnalysis, setShowAnalysis] = useState(false)

    const { loading, data, dataFailureMsg, error } = useResultData()

    const isSafariBrowser = isSafari()
    const isMobileBrowser = isMobile()

    const resulthandle = () => {
        // Mobile Chrome browser calls onAfterPrint just after onBeforeGetContent is called, so to let the print take control before we set variables, we're using a 1sec delay.
        return new Promise((resolve) => {
            setTimeout(() => {
                setShowAnalysis(false)
                setIsPrinting(false)
                resolve()
            }, 1000)
        })
    }

    const scorehandle = () => {
        window.webengage.track('iACST_db_main_sample_detailed_result_download', {
        })
        setShowAnalysis(true)
    }
    const printhandle = () => {
        setIsPrinting(true)

        // We're using a promise, setTimeout so that the state gets updated & UI for pdf is rendered.
        return new Promise((resolve) => {
            setTimeout(
                () => {
                    resolve()
                },
                // We have a highcharts that use animation to load the statistics, so we need to wait a second for it to render before we give it to print.
                data.analysis_tab_status ? 1000 : 0,
            )
        })
    }

    if (error) {
        return <ResultNotFound />
    }

    if (loading) {
        return (
            <div className={styles.loader}>
                <SunspotLoader
                    background={'#fff'}
                    gradientColors={['#6366F1', '#E0E7FF']}
                    shadowColor={'#3730A3'}
                    desktopSize={'128px'}
                    mobileSize={'100px'}
                />
            </div>
        )
    }

    if (!data || dataFailureMsg) {
        return <div>{Parser(`${dataFailureMsg}`)}</div>
    }

    if (!data?.show_result) return <InvalidResult />

    if (!data.result_page_status) return <ComingSoon />

    const shouldRenderStudentsResultTable =
        (!isPrinting && !showAnalysis) || (isPrinting && !data.analysis_tab_status)

    return (
        <div
            ref={componentRef}
            className={cx(styles.container, { [styles.preview]: !isPrinting })}
        >
            <div
                className={cx(
                    styles.page,
                    { [styles.safari]: isSafariBrowser },
                    { [styles.mobile]: isMobileBrowser },
                    { [styles.print]: isPrinting },
                )}
            >
                <Header />
                {!isPrinting ? (
                    <PrintButtons
                        showAnalysis={showAnalysis}
                        resulthandle={resulthandle}
                        scorehandle={scorehandle}
                        printhandle={printhandle}
                        componentRef={componentRef}
                        data={data}
                    />
                ) : null}
                {shouldRenderStudentsResultTable ? (
                    <>
                        <div className={styles.message}>
                            {Parser(`${data.result_message}`)}
                        </div>
                        <ResultTable
                            title={`FOR CLASS ${data?.result.ClassStudying} STUDYING STUDENT`}
                            data={data}
                        />
                    </>
                ) : data.analysis_tab_status ? (
                    <AnalysisTable data={data} />
                ) : (
                    <>
                        <div className={styles.message}>
                            {Parser(`${data.result_message}`)}
                        </div>
                        <div className={styles.disableMsg}>
                            <p>
                                *Please Visit Nearest Aakash Branch for Detailed
                                Result, Scorecard as well as for Special Admission
                                Offers. <strong>Enroll Today</strong>
                            </p>
                            <a href="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/anthe-sample-result-2022-new.pdf">
                                (Click here for Sample Scorecard Sheet for Detail
                                Reference)
                            </a>
                        </div>
                    </>
                )}
                {isPrinting ? <Footer /> : null}
            </div>

            {!shouldRenderStudentsResultTable && data.analysis_tab_status ? (
                <ScoreDetailsAnthe
                    isSafariBrowser={isSafariBrowser}
                    isMobileBrowser={isMobileBrowser}
                    data={data}
                    isPrinting={isPrinting}
                />
            ) : null}

            {isPrinting || !showAnalysis ? (
                <AntheCmsData
                    isSafariBrowser={isSafariBrowser}
                    isMobileBrowser={isMobileBrowser}
                    data={data}
                    isPrinting={isPrinting}
                />
            ) : null}

            {isPrinting ? (
                <img
                    className={styles.footerImg}
                    src={ANTHE_FOOTER}
                    alt="anthe-page-footer"
                />
            ) : null}
        </div>
    )
}

export default Result
