import React from 'react'
import logo from '../../assets/aakash-logo.png'
import styles from './LoginLhs.module.css'

const LoginLhs = () => {
    return (
        <div className={`${styles.lhsWrapper} col-md-6`}>
            <div className={styles.login1}>
                <div className={styles.welcome}>
                    <p>Welcome to</p>
                    <p>aakash</p>
                    <img src={logo} className={styles.logo} />
                    <p className={styles.lhsText}>
                        A Scholarship exam for Class VIIth to XIIth Studying/Passed
                        Students
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LoginLhs
