import { SET_TP_RESULT_DATA } from '../constants/actionTypes'

const setTpResultData = (state = {}, action) => {
    switch (action.type) {
        case SET_TP_RESULT_DATA:
            return action.payload
        default:
            return state
    }
}

export default setTpResultData
