import { Interweave } from 'interweave'

import MathRenderer from './MathRenderer'

const ConvertHTMLString = ({ content, tagName, className }) => {
    return (
        <>
            {content &&
            content !== undefined &&
            content !== null &&
            content.includes('<math') ? (
                <MathRenderer mathml={content} />
            ) : content !== undefined && content !== null ? (
                <Interweave
                    content={content}
                    tagName={tagName}
                    className={className}
                />
            ) : null}
        </>
    )
}

export default ConvertHTMLString
