export const SET_TOKEN = 'SET_TOKEN'
export const SET_DASHBOARD_API_DATA = 'SET_DASHBOARD_API_DATA'
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA'
export const SET_EXAM_INFO_API_DATA = 'SET_EXAM_INFO_API_DATA'
export const SUBMIT_TEST = 'SUBMIT_TEST'
export const SET_ROLLNO = 'SET_ROLLNO'
export const SET_DOB = 'SET_DOB'
export const SET_TOAST_DATA = 'SET_TOAST_DATA'
export const USER_LOGOUT = 'USER_LOGOUT'
export const SET_COURSE_DETAILS = 'SET_COURSE_DETAILS';
export const SET_COURSE_FLAGS_DETAILS = 'SET_COURSE_FLAGS_DETAILS';
export const SET_INPUT_FIELD_DATA = 'SET_INPUT_FIELD_DATA';
export const SET_VISIBLE_COURSE_DESC = 'SET_VISIBLE_COURSE_DESC';
export const SET_VISIBLE_COURSE_REGISTER =  'SET_VISIBLE_COURSE_REGISTER';
export const SET_ECOMMERCE_SUCCESS = 'SET_ECOMMERCE_SUCCESS';
export const SET_ECOMMERCE_FAILED =  'SET_ECOMMERCE_FAILED';
export const RESET_REDUCERS = 'RESET_REDUCERS';
export const SET_RETRY_PAYMENT = 'SET_RETRY_PAYMENT';
export const SET_TP_RESULT_DATA = 'SET_TP_RESULT_DATA';
