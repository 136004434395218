import { USER_LOGOUT } from '../constants/actionTypes'

const resetUser = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGOUT:
            return state
        default:
            return state
    }
}
export default resetUser
