import { useEffect, useState } from 'react'
import cx from 'classnames'
import { Button, Modal } from 'reactstrap'
import { SunspotLoader } from 'react-awesome-loaders'

import config from 'config'
import useTimer from 'hooks/useTimer'
import { usePutPostApi } from 'api/usePutPostApi'
import { InputField } from 'components/shared/InputField'
import {
    MAX_SIGNUP_ATTEMPTS,
    MOBILE_NUM_LENGTH,
    PRIVACY_POLICY,
    RESEND_OTP_TIME,
    TERMS_AND_CONDITIONS,
} from 'components/Result/constants'
import {
    filterParamsStartingWithUtm,
    getURLParameters,
} from 'utils/getAllURLParameters'
import EditIcon from 'assets/iconComp/EditIcon'

import styles from './signupStyles.module.css'

const StepOneOTP = ({
    goToNextStep,
    defaultUserInfo,
    setDefaultUserInfo,
    setIsNewUser,
    setUserMethod,
}) => {
    const queryString = window.location.href
    const url = new URL(queryString)
    const parameters = getURLParameters(url.search)
    const utmParams = filterParamsStartingWithUtm(parameters)

    const [formData, setFormData] = useState({
        phone: '',
        mobile_psid: '',
    })
    const [otp, setOtp] = useState(new Array(4).fill(''))
    const [receiveWhatsapp, setWhatsappComm] = useState(true)
    const [editBtnEnable, setEditBtnEnable] = useState(false)
    const [otpSent, setOtpSent] = useState(false)
    const [otpVerified, setOtpVerified] = useState(false)

    const [errors, setErrors] = useState({
        mobile_psid: '',
        otp: '',
    })

    const { timer, isTimerRunning, togglePlayPause, onResetTimer } = useTimer({
        duration: RESEND_OTP_TIME,
    })

    const {
        data: otpData,
        loading: isSendingOtp,
        error: sendOtpError,
        setPutEnabled: sendPhoneOtp,
    } = usePutPostApi(
        `${config.SEND_OTP_API}?mobile=${formData.mobile_psid}`,
        '',
        'post',
        '',
        false,
    )

    const {
        data: validateOtpData,
        loading: isValidatingOtp,
        error: verifyOtpError,
        setPutEnabled: validatePhoneOtp,
    } = usePutPostApi(
        `${config.VERIFY_OTP_API}?mobile_no=${formData.mobile_psid}&otp=${otp.join(
            '',
        )}`,
        '',
        'post',
        '',
        false,
    )

    useEffect(() => {
        sessionStorage.setItem('utmParams', JSON.stringify(utmParams))

        // web-analytics
        window.webengage.track('iACST_0_Session_Start', {
            'utm parameters': utmParams,
        })
    }, [])

    useEffect(() => {
        if (otpData?.success === true) onSendPhoneOtpSuccess()
        else if (otpData && !otpData.success)
            setErrors({ mobile_psid: otpData.msg, otp: '' })
    }, [otpData])

    useEffect(() => {
        if (sendOtpError) setErrors({ mobile_psid: sendOtpError.msg, otp: '' })

        if (verifyOtpError) {
            setErrors({ mobile_psid: '', otp: verifyOtpError.msg })

            // reset OTP field in case of invalid-otp
            if (verifyOtpError.error_code === 1) resetOtpBoxes()
        }
    }, [sendOtpError, verifyOtpError])

    useEffect(() => {
        if (validateOtpData?.success === true) {
            const isAttemptsExhausted =
                Number(validateOtpData.student_details[0].registration_count) ===
                MAX_SIGNUP_ATTEMPTS

            if (isAttemptsExhausted) onUserAttemptsExhausted()
            else onValidatePhoneOtpSuccess(validateOtpData.student_details[0])
        } else if (validateOtpData && !validateOtpData.success) {
            setErrors({ otp: validateOtpData.msg, mobile_psid: '' })

            // reset OTP field in case of invalid-otp
            if (validateOtpData.error_code === 1) resetOtpBoxes()
        }
    }, [validateOtpData])

    useEffect(() => {
        if (otpSent) autoFocusOtpBox()
    }, [otpSent])

    useEffect(() => {
        // restart timer countdown if OTP is sent but not verified
        if (timer !== -1 && isTimerRunning === false && otpVerified === false)
            togglePlayPause()
    }, [timer, isTimerRunning])

    const resetOtpBoxes = () => {
        setOtp(new Array(4).fill(''))

        setTimeout(() => {
            autoFocusOtpBox()
        }, 1000)
    }

    const autoFocusOtpBox = () => {
        //focus on otp field when sent and resend OTP
        const input = document.querySelector('#otp-0')
        input.focus()
    }

    const onSendPhoneOtpSuccess = () => {
        if (formData.mobile_psid.length > MOBILE_NUM_LENGTH) {
            // update mob-num when psid entered by user
            const phone = otpData.data.mobile_no
            setFormData((prevFormData) => ({
                ...prevFormData,
                phone,
            }))
        }

        onResetTimer()
        setOtpSent(true)
    }

    const onValidatePhoneOtpSuccess = ({
        student_name,
        father_name,
        student_email,
        student_dob,
        mobile_no,
        registration_count,
    }) => {
        setOtpVerified(true)
        onResetTimer()

        const isMobile = formData.mobile_psid.length === MOBILE_NUM_LENGTH

        if (student_name && student_dob) {
            !isMobile && setIsNewUser(false) // disabled studentInfo fields when entered PSID

            setDefaultUserInfo({
                studentName: student_name || '',
                fatherName: father_name || '',
                email: student_email || '',
                dob: new Date(student_dob) || '',
                mobileNumber: mobile_no,
                prevSignupAttempt: registration_count,
            })
        } else
            setDefaultUserInfo({
                ...defaultUserInfo,
                mobileNumber: formData.mobile_psid,
                prevSignupAttempt: registration_count,
            })

        goToNextStep()

        const userMobileNumber = !isMobile ? mobile_no : formData.mobile_psid

        if (!isMobile) setUserMethod({ isPsid: true, psid: formData.mobile_psid })

        // web-analytics
        window.webengage.track('iACST_1_Mobile_Verification_OTP_Verified', {
            method: isMobile ? 'mobile' : 'psid',
            'mobile number': userMobileNumber,
            psid: isMobile ? '' : formData.mobile_psid,
            'utm parameters': utmParams,
        })

        /**
         *  for new user: extract mobile number from input box
         *  for existing user: `mobile_no` key from API response
         */

        window.webengage.user.login(userMobileNumber)
        window.webengage.user.setAttribute('we_phone', userMobileNumber)
        window.webengage.user.setAttribute('we_whatsapp_opt_in', true)
    }

    const onUserAttemptsExhausted = () => {
        const isMobile = formData.mobile_psid.length === MOBILE_NUM_LENGTH

        setErrors({
            mobile_psid:( 
            <>
            Account already exists with this mobile number. Please <a href="/">login</a> or <a href="/iacstexam-v1">register</a> with a different mobile number. For support please write to us at acstsupport@aesl.in
            </>
            ),
            otp: '',
        })

        setOtpVerified(true)

        // web-analytics
        window.webengage.track('iACST_1_Mobile_Verification_Attempts_Exhausted', {
            method: isMobile ? 'mobile' : 'psid',
            'mobile number': !isMobile ? '' : formData.mobile_psid,
            psid: isMobile ? '' : formData.mobile_psid,
            'utm parameters': utmParams,
        })
    }

    const handleUpdateErrors = (name, error) => {
        setErrors((err) => ({
            ...err,
            [name]: error,
        }))
    }

    const handleEditMobileNum = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            phone: '',
        }))
        otpSent && setOtpSent(false)
        setEditBtnEnable(false)
        setOtp(new Array(4).fill(''))
        if (errors?.otp) {
            setErrors((preErrorStates) => {
                return {
                    ...preErrorStates,
                    otp: '',
                }
            })
        }
        setOtpVerified(false)
    }

    const editMobileNumBtn = (
        <span disabled={otpSent} className={styles.inputAddOnEditBtn}>
            <EditIcon width="20" height="20" />
        </span>
    )

    const handleFormDataChange = (event) => {
        const { name, value } = event.target

        setFormData((data) => {
            return {
                ...data,
                [name]: value,
            }
        })

        if (errors[name]) {
            setErrors((preErrorStates) => {
                return {
                    ...preErrorStates,
                    [name]: '',
                }
            })
        }
    }

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false

        if (errors?.otp) {
            setErrors((preErrorStates) => {
                return {
                    ...preErrorStates,
                    otp: '',
                }
            })
        }

        const updatedValues = otp.map((d, i) => (i === index ? element.value : d))
        setOtp([...updatedValues])

        if (element.nextSibling && element.value) {
            const input = element.nextSibling
            let end = input.value.length
            input.setSelectionRange(end, end)
            input.focus()
        } else if (!element.nextSibling && element.value && receiveWhatsapp) {
            const isOtpFilled = updatedValues.slice(0, 2).some((val) => val !== '')

            if (isOtpFilled) validatePhoneOtp(true)
        }
    }

    const autoFillOtp = (pasted, indexFocused) => {
        if (isNaN(pasted)) return false
        const otpDigits = pasted.split('')

        const digitsLeft = Math.min(otpDigits.length, otp.length - indexFocused)
        let inputNum = indexFocused,
            iterations = digitsLeft,
            i = 0

        const updatedValues = otp.map((val, index) => {
            if (index === inputNum && iterations > 0) {
                const value = otpDigits[i]
                inputNum++
                i++
                iterations--
                return value
            }
            return val
        })
        setOtp([...updatedValues])
        if (errors?.otp) {
            setErrors((preErrorStates) => {
                return {
                    ...preErrorStates,
                    otp: '',
                }
            })
        }

        const nextSibling = document.getElementById(
            `otp-${indexFocused + digitsLeft}`,
        )
        const currentValue = updatedValues[indexFocused + digitsLeft - 1]

        if (nextSibling && currentValue) {
            let end = nextSibling.value.length
            nextSibling.setSelectionRange(end, end)
            nextSibling.focus()
        } else if (!nextSibling && currentValue && receiveWhatsapp) {
            const isOtpFilled = updatedValues.slice(0, 2).some((val) => val !== '')

            if (isOtpFilled) validatePhoneOtp(true)
        }
    }

    const handleValidateOtp = (event) => {
        const isChecked = event.target.checked
        setWhatsappComm(isChecked)

        if (isChecked && !otpVerified && !otp.some((val) => val === ''))
            validatePhoneOtp(true)
    }

    const inputFocus = (event) => {
        if (event.key === 'Backspace') {
            if (event.target.previousSibling) {
                const input = event.target.previousSibling
                let end = input.value.length
                input.setSelectionRange(end, end)
                input.focus()
            }
        }
    }

    const handleRequestOtp = ({ isResend = false }) => {
        const isMobile = formData.mobile_psid.length === MOBILE_NUM_LENGTH

        if (formData.mobile_psid.length < MOBILE_NUM_LENGTH) return
        else if (isMobile && formData.mobile_psid[0] === '0') {
            handleUpdateErrors('mobile_psid', 'Invalid Mobile number')
            return
        }

        sendPhoneOtp(true)
        setEditBtnEnable(true)

        // web-analytics
        if (!isResend)
            window.webengage.track('iACST_1_Mobile_Verification_OTP_Sent', {
                method: isMobile ? 'mobile' : 'psid',
                'mobile number': !isMobile ? '' : formData.mobile_psid,
                psid: isMobile ? '' : formData.mobile_psid,
                'utm parameters': utmParams,
            })
    }

    const handleResendOTPClick = () => {
        if (isSendingOtp) return
        handleRequestOtp({ isResend: true })

        setOtp(new Array(4).fill(''))
        if (errors?.otp) {
            setErrors({
                ...errors,
                otp: '',
            })
        }
        setOtpVerified(false)
        autoFocusOtpBox()

        const isMobile = formData.mobile_psid.length === MOBILE_NUM_LENGTH
        // web-analytics
        window.webengage.track('iACST_1_Mobile_Verification_OTP_Resend', {
            method: isMobile ? 'mobile' : 'psid',
            'mobile number': !isMobile ? '' : formData.mobile_psid,
            psid: isMobile ? '' : formData.mobile_psid,
            'utm parameters': utmParams,
        })
    }

    const isSendOtpBtnDisabled =
        errors.mobile_psid !== '' ||
        formData.mobile_psid.length < MOBILE_NUM_LENGTH ||
        !receiveWhatsapp

    return (
        <div className="px-3">
            <InputField
                required
                placeholder="Mobile number/ PSID"
                type="tel"
                name="mobile_psid"
                showErrorOn="blur"
                errorMsg={errors.mobile_psid}
                messages={{
                    required: 'Please enter correct mobile number/ PSID',
                }}
                maxLength={11}
                value={formData.mobile_psid}
                onChange={handleFormDataChange}
                onErrorUpdate={handleUpdateErrors}
                disabled={isSendingOtp}
                readOnly={editBtnEnable}
                onInternalBtnClick={handleEditMobileNum}
                actionBtn={!editBtnEnable ? null : editMobileNumBtn}
            />

            {otpSent && formData.mobile_psid.length > MOBILE_NUM_LENGTH && (
                <div className="font-size-xs fw-500 text-dark-grey mb-3">
                    We have sent an OTP on the below number.
                    <br />
                    {formData.phone}
                </div>
            )}

            {/* 4 digit OTP inputs */}
            <div
                className={`d-flex flex-column w-100 ${
                    otpSent ? 'd-flex' : 'd-none'
                }`}
            >
                <div
                    className={cx('d-flex', {
                        'mb-1': errors.otp,
                    })}
                >
                    {otp.map((data, index) => {
                        return (
                            <input
                                key={index}
                                autoComplete="off"
                                name="otp"
                                id={`otp-${index}`}
                                pattern="[0-9]{1}"
                                inputMode="numeric"
                                className={cx(styles.otpInputBox, {
                                    'border-primary': data,
                                    // 'border-error-red': errors.otp,
                                    'border-dark-green': otpVerified,
                                })}
                                type="text"
                                tabIndex={index + 1}
                                // maxLength={1}
                                value={data}
                                onChange={(e) => {
                                    if (e.target.value.length > 1)
                                        autoFillOtp(e.target.value, index)
                                    else handleOtpChange(e.target, index)
                                }}
                                onFocus={(e) => e?.target?.select()}
                                onKeyUp={(e) => inputFocus(e)}
                                disabled={otpVerified || isValidatingOtp}
                            />
                        )
                    })}
                </div>
                {errors?.otp && (
                    <span className="mt-1 mb-2 font-size-xs text-error-red fw-500">
                        {errors.otp}
                    </span>
                )}

                <div className="mt-3 ml-auto">
                    {isTimerRunning && !otpVerified && (
                        <div className="fw-500 font-size-xs text-dark-grey">
                            Resend OTP in{' '}
                            <span className="text-primary">
                                0{Math.floor(timer / 60)}:
                                {timer.toString().padStart(2, '0')} seconds
                            </span>
                        </div>
                    )}
                    {!isTimerRunning && !otpVerified && (
                        <div
                            className={cx(
                                'text-primary cursor-pointer fw-500 font-size-xs',
                                {
                                    'text-grey-50': isSendingOtp,
                                },
                            )}
                            onClick={handleResendOTPClick}
                        >
                            Resend OTP
                        </div>
                    )}
                </div>
            </div>

            {/* whatsapp opt-in */}
            <div className="mt-4 mx-auto d-flex justify-content-center align-items-start">
                <input
                    className={styles.whatsAppOptinCheckBox}
                    type="checkbox"
                    checked={receiveWhatsapp}
                    onChange={(e) => {
                        if (otpSent) handleValidateOtp(e)
                        else setWhatsappComm(!receiveWhatsapp)
                    }}
                />{' '}
                <p className="mb-0 font-size-xs text-grey lh-16">
                    By submitting this form I agree to the{' '}
                    <a
                        href={TERMS_AND_CONDITIONS}
                        target="_blank"
                        rel="noreferrer"
                        className="text-aqua text-decoration-underline"
                    >
                        T&C
                    </a>
                    ,&nbsp;
                    <a
                        href={PRIVACY_POLICY}
                        target="_blank"
                        rel="noreferrer"
                        className="text-aqua text-decoration-underline"
                    >
                        Privacy Policy
                    </a>
                    &nbsp;and to receive SMS, whatsApp & call communication
                    <br />
                    {!receiveWhatsapp && (
                        <section
                            className={cx('mt-1 font-size-xs text-error-red', {
                                'fw-500': otpSent,
                            })}
                        >
                            Please accept T&C in order to continue
                        </section>
                    )}
                </p>
            </div>

            {/* Sent OTP Button */}
            <Button
                className={cx(
                    `btn btn-aqua text-white w-100 fw-bold font-size-sm font-size-lg-md mt-4 ${styles.sendOtpButton}`,
                    { 'bg-grey-20 text-grey-70 border-0': isSendOtpBtnDisabled },
                    { 'd-none': otpSent },
                )}
                onClick={handleRequestOtp}
                isLoading={isSendingOtp}
                disabled={isSendOtpBtnDisabled}
            >
                Verify mobile number
            </Button>

            <div className="w-full text-center mt-3">
                <p className="text-center font-size-sm mb-0">
                    Already registered&nbsp;
                    <a
                        href="/"
                        className="text-decoration-none text-aqua fw-lighter"
                    >
                        Log in
                    </a>
                </p>
            </div>

            {(isValidatingOtp || isSendingOtp) && (
                <Modal
                    isOpen={true}
                    backdrop={true}
                    centered
                    className={styles.customModalLoader}
                >
                    <SunspotLoader
                        gradientColors={['#4039d4', '#E0E7FF']}
                        size={'10px'}
                        desktopSize={'30px'}
                        mobileSize={'20px'}
                        className="m-auto"
                    />
                </Modal>
            )}
        </div>
    )
}

export default StepOneOTP
