import { SET_DASHBOARD_API_DATA } from '../constants/actionTypes'

const setDashboardData = (state = {}, action) => {
    switch (action.type) {
        case SET_DASHBOARD_API_DATA:
            return action.payload

        default:
            return state
    }
}
export default setDashboardData
