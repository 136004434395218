import Icon from '../components/shared/IconBase'

const DangerIcon = (props) => (
    <Icon
        width={props.width || '15'}
        height={props.height || '12'}
        viewBox={
            props.viewBox || `0 0 ${props.width || '15'} ${props.height || '12'}`
        }
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.1515 0.961394L13.9974 9.37148C14.104 9.62251 14.1507 9.82663 14.164 10.0387C14.1907 10.5341 14.0174 11.0157 13.6774 11.3863C13.3375 11.7556 12.8775 11.9736 12.3776 12H2.61927C2.41264 11.9874 2.20601 11.9405 2.01271 11.8679C1.0462 11.4781 0.579615 10.3815 0.972883 9.43093L5.85206 0.955449C6.0187 0.657519 6.27199 0.400546 6.58527 0.235396C7.49178 -0.26732 8.64492 0.0629796 9.1515 0.961394ZM8.07835 6.50382C8.07835 6.82091 7.8184 7.08581 7.49845 7.08581C7.1785 7.08581 6.91188 6.82091 6.91188 6.50382V4.63498C6.91188 4.31724 7.1785 4.06026 7.49845 4.06026C7.8184 4.06026 8.07835 4.31724 8.07835 4.63498V6.50382ZM7.49845 9.34505C7.1785 9.34505 6.91188 9.08015 6.91188 8.76373C6.91188 8.44598 7.1785 8.18174 7.49845 8.18174C7.8184 8.18174 8.07835 8.44003 8.07835 8.75646C8.07835 9.08015 7.8184 9.34505 7.49845 9.34505Z"
            fill={props.fill || '#0F0F0F'}
        />
    </Icon>
)

export default DangerIcon
