import { Container,Card, CardBody, CardHeader } from 'reactstrap'
import ExamIllustration from '../../assets/iacst-img.png'
import DiscountIcon from 'assets/DiscountIcon'
import { useMediaQuery } from 'react-responsive'
import { useState, useEffect } from 'react';
import DownloadIcon from 'assets/DownloadIcon';

const ResultWavier = ({ mainExamData, profileData, flags }) => {
  const [greeting, setGreeting] = useState('');
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' })

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setGreeting('morning');
    } else if (currentHour >= 12 && currentHour < 17) {
      setGreeting('afternoon');
    } else {
      setGreeting('evening');
    }
  }, []);

  const downloadProspectus = () => {

    fetch(flags?.iacst_download_prosp, {
      method: 'GET',
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'prospectus.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading the PDF:', error);
      });
  }
  return (
    <Container className="p-3 pb-lg-0 pb-xl-3 mt-lg-3 mw-100 mh-100">
      <Card>
        <CardHeader className="bg-aqua-60 rounded-top border-0 py-2 px-lg-4">
          {flags?.course_purchased ? <>
            <p className="text-white font-size-sm-lg fw-500 m-0 font-size-xs ps-lg-2 ps-xl-2">
              <span className='font-size-sm-24 font-size-sm'>Welcome!</span> Thank you for choosing Aakash institute
            </p>
          </> : <>
            {mainExamData.scholarship_percentage != '0' ? (
              <div className="d-flex align-items-center text-align-center">
                <p className="text-yellow font-size-3xl fw-700 me-3 m-0">
                  {mainExamData.scholarship_percentage}%
                </p>
                <p className="text-white font-size-sm-lg fw-lighter font-size-xs m-0">
                  {String(mainExamData.scholarship_msg)[0] == '%' ? mainExamData.scholarship_msg.replace('%', '') : mainExamData.scholarship_msg} of{' '}
                  {mainExamData.overall_percentage}%
                </p>
              </div>
            ) : (
              <p className="text-white font-size-sm-lg fw-500 m-0 font-size-sm ps-lg-2 ps-xl-2">
                {mainExamData.scholarship_msg}
              </p>
            )}
          </>
          }
        </CardHeader>
        <CardBody className="p-0">
          <div className="d-flex align-items-center">
            <div className="d-none d-md-block">
              <img
                src={ExamIllustration}
                alt="students-degree-illustration"
                height={mainExamData.scholarship_percentage == '0' && 205}
                width={
                  mainExamData.scholarship_percentage == '0'
                    ? 300
                    : 240
                }
              />
            </div>
            <div className="py-2 ms-lg-5 mx-3 mb-2 w-10">
                <p className='font-size-sm font-size-sm-lg fw-lighter mb-2 text-capitalize' >{greeting + " " + profileData.student_firstname},</p>
                {flags?.course_purchased && profileData?.student_psid  ? <>
                    <p className='font-size-xs font-size-sm-lg fw-bolder mb-2'>
                      Your Aakash Student Id (PSID) is <span className='text-aqua'>{profileData?.student_psid}</span>
                    </p>
                    </> 
                    : <></>}
              {
                !flags.course_purchased ?
                  <p
                    className={`font-size-sm font-size-sm-mds fw-lighter mb-2 ${flags?.course_purchased ? 'text-dark-grey' : ''}`}
                  >
                    {flags?.scholarship_course_purchased_msg}
                  </p>
                  : <></>
              }
              {mainExamData.scholarship_percentage != '0' ? (
                <div className="d-flex align-items-center justify-content-between">
                  {
                    flags.course_purchased ?
                      <p
                        className='font-size-sm font-size-sm-mds fw-lighter mb-2 text-dark-grey'
                      >
                        {flags?.scholarship_course_purchased_msg}
                      </p>
                      :
                      <span className="font-size-sm font-size-sm-md  text-dark-grey fw-500">
                        For detailed Result Visit branch
                      </span>
                  }

                  <a
                    href={flags.term_condition_link}
                    target="_blank"
                    rel="noreferrer"
                    className="d-none d-md-flex font-size-xs text-grey text-decoration-none me-5"
                  >
                    *Terms and conditions applied
                  </a>
                </div>
              ) : (
                <>
                  {
                    flags.course_purchased ?
                      <p
                        className='font-size-sm font-size-sm-mds fw-lighter mb-2 text-dark-grey'
                      >
                        {flags?.scholarship_course_purchased_msg}
                      </p>
                      :
                      <span className="font-size-sm font-size-sm-md  text-dark-grey fw-500">
                        For detailed Result Visit branch
                      </span>
                  }
                  <div className="d-flex align-items-center justify-content-between">
                    <div className={`d-flex align-items-center justify-content-between bg-beige p-2 rounded ${isSmallScreen ? 'mt-1' : 'w-8 mt-3'} `}>
                      <div className="d-flex align-items-center">
                        <div className="me-3 d-none d-md-flex">
                          <DiscountIcon fill="#A17217" />
                        </div>
                        <p
                          className={`mb-0 font-size-xs font-size-md-md ${isSmallScreen
                            ? 'fw-normal'
                            : 'fw-lighter'
                            } text-Yellow-70`}
                        >
                          <span
                            className={`${isSmallScreen
                              ? 'fw-bolder'
                              : 'fw-lighter'
                              }`}
                          >
                            Visit Branch for additional{' '}
                          </span>
                          scholarships & discounts
                        </p>
                      </div>
                      <button
                        type="button"
                        className={`btn btn-sm text-Yellow-70 bg-white fw-500 border-Yellow-70 font-size-md-sm font-size-xxs custom-download-prospectus ${isSmallScreen ? 'w-7 px-1' :''}`}
                        onClick={downloadProspectus}
                      >
                        Download Details <DownloadIcon fill={'#a17217'} className='mb-1'/>
                      </button>
                    </div>
                    <a
                     href={flags.term_condition_link}
                      target="_blank"
                      rel="noreferrer"
                      className="d-none d-md-flex font-size-xs text-grey text-decoration-none mt-5"
                    >
                      *Terms and conditions applied
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <a
        href={flags.term_condition_link}
        target="_blank"
        rel="noreferrer"
        className="font-size-xs text-grey d-lg-none text-decoration-none"
      >
        *Terms and conditions applied
      </a>
    </Container>
  )
}

export default ResultWavier
