import { SET_TOKEN } from '../constants/actionTypes'

const setToken = (state = null, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return action.payload
        default:
            return state
    }
}
export default setToken
