import { connect } from 'react-redux'
import styles from './registrationsteps.module.css'
import { Container } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'

const mapStateToProps = (state) => ({
    profileData: state?.profileData,
    courseFlagsData: state?.ecommerce?.courseFlagsData,
})
const RegistartionSteps = (props) => {
    const isSMDevice = useMediaQuery({ query: '(max-width: 340px)' })
    return (
        <Container id="RegistartionSteps">
            <div className={styles.antheScholarshipStep}>
                <ul className={`${styles.progressbar} p-0 m-0`}>
                    <li className={styles.active}>
                        <span
                            className={`${
                                isSMDevice ? 'font-size-xxs' : 'font-size-xs'
                            } text-grey`}
                        >
                            {props.profileData.exam_taken
                                ? 'Scholarship Test'
                                : 'Registration'}
                        </span>
                    </li>
                    <li
                        className={
                            props.profileData.exam_taken &&
                            props?.courseFlagsData?.course_purchased
                                ? styles.active
                                : styles.done_two
                        }
                    >
                        <span
                            className={`${
                                isSMDevice ? 'font-size-xxs' : 'font-size-xs'
                            } text-grey`}
                        >
                            {props.profileData.exam_taken
                                ? 'Course Registration'
                                : 'Scholarship Test'}
                        </span>
                    </li>
                    <li className={`${styles.three_active} text-grey`}>
                        <div className="d-flex flex-column">
                            {props.profileData.exam_taken ? (
                                <>
                                    <span
                                        className={`${
                                            isSMDevice
                                                ? 'font-size-xxs'
                                                : 'font-size-xs'
                                        }`}
                                    >
                                        Visit Branch
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span
                                        className={`${
                                            isSMDevice
                                                ? 'font-size-xxs'
                                                : 'font-size-xs'
                                        }`}
                                    >
                                        Admission
                                    </span>
                                    <span className="font-size-xxs lh-xs">
                                        (Visit Branch)
                                    </span>
                                </>
                            )}
                        </div>
                    </li>
                </ul>
            </div>
        </Container>
    )
}

export default connect(mapStateToProps)(RegistartionSteps)
