import React from 'react'
import cx from 'classnames'

import styles from './admission-notes.module.scss'

function AdmissionNotes({ phoneNo, isPrinting }) {
    return (
        <div className={cx({ [styles.notPrinting]: !isPrinting })}>
            <div className={styles.banner}>
                <p className={styles.header}>
                    BOOK YOUR ACADEMIC COUNSELLING SESSION FOR
                </p>

                <div className={styles.right}>
                    <div className={styles.desc}>
                        <img
                            src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/themes/anthe/images/result-images/scholarship-detail-icon.png"
                            alt="scholarship"
                        />
                        <p>
                            SCHOLARSHIP
                            <br />
                            DETAILS
                        </p>
                    </div>

                    <div className={styles.desc} style={{ borderRight: 'none' }}>
                        <img
                            src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/themes/anthe/images/result-images/counselling-icon.png"
                            alt="conselling"
                            style={{ width: '33%' }}
                        />
                        <p>
                            ACADEMIC
                            <br />
                            COUNSELLING
                        </p>
                    </div>
                </div>

                <div style={{ clear: 'both' }}>&nbsp;</div>
            </div>

            <div className={styles.details}>
                <p className={styles.highlight}>
                    <strong>
                        *The secured scholarship is applicable on both Classroom
                        &amp; Hybrid Courses.{' '}
                    </strong>
                </p>

                <div className={styles.availableBox}>
                    <div className={styles.counselling}>
                        <div className={styles.heading}>
                            <p>COUNSELLING AVAILABLE THROUGH</p>
                        </div>

                        <div className={styles.avail}>
                            <p className={styles.avail}>VIDEO CALL</p>

                            <p className={styles.avail}>HOUSE VISIT</p>

                            <p
                                className={styles.avail}
                                style={{ borderRight: 'none' }}
                            >
                                BRANCH VISIT
                            </p>
                        </div>
                    </div>
                    {phoneNo ? (
                        <div className={styles.branchWrapper}>
                            <div className={styles.branch} id="branch-contact">
                                <div className={styles.text}>
                                    <img
                                        alt="anthe"
                                        src="https://d2flmtdfyoymgx.cloudfront.net/Aakash-Anthe/themes/anthe/images/result-images/call-icon-anthe.png"
                                    />{' '}
                                    <span style={{ margin: 0 }}>{phoneNo}</span>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className={styles.desc}>
                    <p>
                        <strong>IACST</strong> Scholarship is valid
                        <br />
                        for one month from the date of exam.
                    </p>

                    <p style={{ borderRight: 'none' }}>
                        The Scholarship offered is applicable
                        <br />
                        for any Aakash Centre throughout India.
                    </p>

                    <div style={{ clear: 'both' }}>&nbsp;</div>
                </div>
            </div>

            <div className={styles.checkList}>
                <h5>
                    CHECK LIST FOR COMPLETING ADMISSION FORMALITIES (OFFLINE VISIT)
                </h5>

                <div className={styles.notes}>
                    <p className={styles.header}>
                        Please note that the following documents should be carried
                        along for completing the admission formalities at any centre
                        of Aakash in India:
                    </p>

                    <div className={styles.checks}>
                        <div>
                            <div className={styles.boxWrapper}>
                                <div className={styles.checkBox}>&nbsp;</div>

                                <p className={styles.text}>Latest Mark sheet</p>
                            </div>

                            <div className={styles.boxWrapper}>
                                <div className={styles.checkBox}>&nbsp;</div>

                                <p className={styles.text}>
                                    Course Fee: As applicable (Please refer to the
                                    Classroom Course Prospectus or web link:{' '}
                                    <span>
                                        https://www.aakash.ac.in/classroom-courses?
                                    </span>
                                    )
                                </p>
                            </div>
                        </div>

                        <div>
                            <div className={styles.boxWrapper}>
                                <div className={styles.checkBox}>&nbsp;</div>

                                <p className={styles.text}>
                                    Two recent passport size colour photographs
                                </p>
                            </div>

                            <div className={styles.boxWrapper}>
                                <div className={styles.checkBox}>&nbsp;</div>

                                <p className={styles.text}>
                                    A duly filled-in Admission Form (which will be
                                    provided at the Centre at the time of Admission)
                                </p>
                            </div>
                        </div>
                    </div>

                    <p className={styles.footer}>
                        Kindly carry a printout of this result sheet along with the
                        copy of iACST Admit Card and a valid Photo-ID (Passport,
                        School I-Card etc.) for completing the admission formalities.
                    </p>
                </div>
            </div>

            <div className={styles.assistance}>
                <p>
                    SPECIAL ASSISTANCE AVAILABLE FOR COMPLETING ADMISSION FORMALITIES
                    ONLINE.
                </p>
            </div>

            <div style={{ clear: 'both' }}>&nbsp;</div>

            <div className={styles.footerNotes}>
                <h4>Please Note:</h4>

                <ul>
                    <li>
                        If at any point of time, the Govt. Charges GST/Service Tax on
                        Scholarship granted, it will be borne by the Students only.
                    </li>
                    <li>
                        The highest amount of scholarship will not exceed 100% of the
                        overall fee in any case whatsoever.
                    </li>
                    <li>
                        <strong>
                            Course Fee: Cash will be accepted only or Registration
                            Fee &amp; Admission Fee (components of Course Fees.)
                        </strong>
                    </li>
                    <li>
                        A student is allowed a maximum of two attempts in a year at
                        the iACST (Scholarship Test)
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default AdmissionNotes
