import { SunspotLoader } from 'react-awesome-loaders'
import { useState, useEffect } from 'react'
import { Button, FormGroup, Label, Input, Toast, ToastHeader, ToastBody } from 'reactstrap'
import cx from 'classnames'

import config from 'config'
import { connect } from 'react-redux'
import useGetApi from 'api/useGetApi'
import { changeToIst, webEngageDob } from 'utils/dateTime'
import { usePutPostApi } from 'api/usePutPostApi'
import SelectCreatable from 'utils/SelectDropdown'
import { SET_TOAST_DATA } from 'constants/actionTypes'

import styles from './signupStyles.module.css'

import infoicon from '../../assets/fi_info.svg'

const mapDispatchToProps = (dispatch) => ({
    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
})

const StepThreeExamInfo = ({
    userInfo,
    userMethod,
    isUserInfoValid,
    setToastMsgsData,
    setSignupSuccessDetails,
    examInfo,
    setExamInfo,
    dropdownOptions,
    setDropdownOptions,
    errors,
    getSelectDefaultValue,
    handleOnChange,
    handleUpdateErrors,
    registration_page,
    handleErrorsOnChangeCoursType
}) => {
    const utmParams = JSON.parse(sessionStorage.getItem('utmParams'))

    const [isFormValid, setIsFormValid] = useState(false)

    const [courseType, setCourseType] = useState(true);
    const [showToast, setShowToast] = useState(0);

    const { data: examStatesData } = useGetApi(config.FETCH_EXAM_STATE, '', true)

    const {
        data: examCentresData,
        error: examCentreError,
        setEnabled: fetchExamCentres,
    } = useGetApi(
        `${config.FETCH_EXAM_CENTRE}/Online/${examInfo.state.value}/${examInfo.classStream.value}`,
        '',
        false,
    )
    const {
        data: examDatesData,
        error: examDateError,
        setEnabled: fetchExamDates,
    } = useGetApi(
        `${config.FETCH_EXAM_DATE}/${examInfo.centre.centreCode}/${examInfo.classStream.stream}`,
        '',
        false,
    )

    // POST API for user registration

    const signupPayload = {
        student_name: userInfo.studentName,
        father_name: userInfo.fatherName,
        student_email: userInfo.email.toLowerCase(),
        student_dob: changeToIst(userInfo.dob), // yyyy-mm-dd
        student_mobile_no: userInfo.mobileNumber,

        school_board: examInfo.schoolBoard.value,
        class_stream: examInfo.classStream.value,
        exam_state: examInfo.state.value,
        branch_code: examInfo.centre.centreCode,
        exam_date: examInfo.dateOfExam.value,

        whatsapp_status: true, //! whatsapp_optin always `true`
        registration_page,
        ...utmParams,
    }

    const {
        data: signupData,
        loading: isSignupLoading,
        error: signupError,
        setPutEnabled: callSignupApi,
    } = usePutPostApi(config.SIGNUP_API, '', 'post', signupPayload, false)

    useEffect(() => {
        if (examStatesData?.status && examStatesData?.data.length) {
            const examStatesOptions = examStatesData.data.map(
                ({ tid, shortCode, stateName }) => ({
                    label: stateName,
                    value: tid,
                    shortCode,
                }),
            )

            setDropdownOptions({
                ...dropdownOptions,
                state: examStatesOptions,
            })
        }
    }, [examStatesData])

    useEffect(() => {
        if (examCentresData?.success && examCentresData?.data.length) {
            const examCentreOptions = examCentresData.data.map(
                ({ label, value }) => {
                    const centreCode = value.split('-')[1]
                    return {
                        label,
                        value,
                        centreCode,
                    }
                },
            )

            setDropdownOptions({
                ...dropdownOptions,
                centre: examCentreOptions,
            })
        } else if (!examCentresData?.data.length)
            setDropdownOptions({
                ...dropdownOptions,
                centre: [],
                dateOfExam: [],
            })
    }, [examCentresData])

    useEffect(() => {
        if (examCentreError)
            setDropdownOptions({
                ...dropdownOptions,
                centre: [],
                dateOfExam: [],
            })

        if (examDateError)
            setDropdownOptions({
                ...dropdownOptions,
                dateOfExam: [],
            })
    }, [examCentreError, examDateError])

    useEffect(() => {
        if (examInfo.state && examInfo.classStream && courseType) {
            fetchExamCentres(true)
            setExamInfo({
                ...examInfo,
                centre: '',
                dateOfExam: '',
            })
        }
    }, [examInfo.state, examInfo.classStream,courseType])

    useEffect(() => {
        if (examDatesData?.success && examDatesData?.data.length) {
            setDropdownOptions({
                ...dropdownOptions,
                dateOfExam: examDatesData.data,
            })
        }
    }, [examDatesData])

    useEffect(() => {
        if (examInfo.centre && examInfo.classStream) fetchExamDates(true)
    }, [examInfo.centre, examInfo.classStream])

   

    useEffect(() => {
        // will call onTouch of select dropdown fields
        const examInfoValues = Object.values(examInfo).some((val) => val === '')

        const errorValues = Object.values(errors).some((val) => val)

        // set to `true` when examInfo form is completed with no errors
        setIsFormValid(!examInfoValues && !errorValues);

    }, [examInfo, errors])

    useEffect(() => {
        if (signupData?.success && signupData?.data)
            onSuccessfulRegistration(signupData.data)
        else if ((signupData && !signupData?.status) || signupError)
            setToastMsgsData({
                isToastOpen: true,
                toastType: 'error',
                toastMessage:
                    signupData?.msg ||
                    signupError.msg ||
                    'We are facing technical issues. Please try after some time.',
            })
    }, [signupData, signupError])

    const onSuccessfulRegistration = (response) => {
        setSignupSuccessDetails({
            isSignupSuccessful: true,
            ...response,
            exam_date: signupPayload.exam_date,
        })

        const {
            dob, //dd-mm-yyyy
            roll_number,
            dashboard_link: dashboard_autologin_url,
            branch_phone_numbers,
            branch_gps,
            branch_address,
            gps_link,
        } = response

        const {
            studentName: student_name,
            fatherName: father_name,
            email: email_ID,
            mobileNumber,
            prevSignupAttempt,
        } = userInfo

        const { classStream, state, centre, dateOfExam,schoolBoard } = examInfo
        const [class_opted, stream] = classStream.stream.split('&')

        //web-analytics
        window.webengage.track('iACST_4_Thank_You_Page', {
            'utm parameters': utmParams,
            method: userMethod.isPsid ? 'psid' : 'mobile',
            'mobile number': mobileNumber,
            board: schoolBoard.value,
            course_type: courseType ? 'Classroom' : 'Aakash Digital',
            student_name,
            father_name,
            dob,
            email_ID,
            class_opted,
            stream,
            exam_mode: 'Online',
            exam_state: state.label,
            exam_centre: centre.label,
            exam_centre_code: centre.centreCode,
            exam_date: new Date(dateOfExam.value + 'T03:30:00.000Z'), // 9AM time
            num_attempt: prevSignupAttempt + 1, //value can be 1-2 including current
            roll_number,
            dashboard_password: dob,
            dashboard_autologin_url,
            branch_phone_numbers,
            branch_gps,
            branch_address,
            gps_link,
        })
    }

    const handleSubmit = () => {
        callSignupApi(true)

        const {
            studentName: student_name,
            fatherName: father_name,
            email: email_ID,
            dob,
            mobileNumber,
            prevSignupAttempt,
        } = userInfo

        const { classStream, state, centre, dateOfExam,schoolBoard } = examInfo
        const [class_opted, stream] = classStream.stream.split('&')

        // web-analytics
        window.webengage.track('iACST_3_Exam_Details', {
            'utm parameters': utmParams,
            method: userMethod.isPsid ? 'psid' : 'mobile',
            'mobile number': mobileNumber,
            board: schoolBoard.value,
            course_type: courseType ? 'Classroom' : 'Aakash Digital',
            student_name,
            father_name,
            dob: webEngageDob(dob), // dd-mm-yyyy
            email_ID,
            class_opted,
            stream,
            exam_mode: 'Online',
            exam_state: state.label,
            exam_centre: centre.label,
            exam_centre_code: centre.centreCode,
            exam_date: new Date(dateOfExam.value + 'T03:30:00.000Z'), // 9AM time
            num_attempt: prevSignupAttempt + 1, //value can be 1-2 including current
        })
    }

    const Loader = (
        <SunspotLoader
            gradientColors={['#4039d4', '#E0E7FF']}
            size={'10px'}
            desktopSize={'10px'}
            className="m-auto"
        />
    )

    const isSubmitBtnDisabled = !isFormValid || !isUserInfoValid

    const handleRadioClick = (courseType, toastIndex) => {
        setCourseType(courseType);
        setShowToast(0); 
    };

    const toggleToast = (toastIndex) => {
        setShowToast(showToast === toastIndex ? 0 : toastIndex);
    };

    useEffect(() => {
        handleErrorsOnChangeCoursType();
        if (!courseType){
            setExamInfo({
            ...examInfo,
            state:{
                label: "Delhi",
                shortCode: "DL",
                value: 18
            },
            centre: {
                centreCode: "IC006",
                label: "Delhi-Janak Puri Branch test",
                value: "DelhiJanak Puri Branch test-JP001-14130"
            },
            dateOfExam: '',
            });
        }
        else
        {
            fetchExamDates(false)
            setDropdownOptions({
                ...dropdownOptions,
                centre: [],
                dateOfExam: [],
            })
            setExamInfo({
            ...examInfo,
            state:'',
            centre: '',
            dateOfExam: '',
        })
        }
    }, [courseType])

    return (
        <>
            <div
                className={`bg-light pt-2 px-3 my-3 ${errors.centre ? 'pb-1' : 'pb-3'
                    }`}
            >
                <p className="mb-2 text-dark-grey font-size-xs fw-500">
                    Preferred Aakash Centre for Coaching
                </p>
                <div className={`d-flex justify-content-between ${styles.courseSection}`}>
                    <FormGroup check className='px-0 d-flex align-items-baseline'>
                        <Label check className='font-size-sm d-flex'>
                            <Input type="radio" name="course" value="1" className={styles.customRadio}
                                onClick={() => {handleRadioClick(true, 0)}} checked={courseType}
                            /> Classroom
                        </Label>
                        <div onClick={() => toggleToast(1)}>  <img src={infoicon} className={styles.imgInfo}
                        /></div>
                        <Toast isOpen={showToast == 1} className={`border-0 ${styles.toastMessage}`}>
                            <ToastBody className='font-size-xs'>
                                Regular classroom courses(RCC) serve as offline hubs for preparing for exams like NEET, JEE, and Foundation courses.
                            </ToastBody>
                        </Toast>
                    </FormGroup>
                    <FormGroup check className='d-flex align-items-baseline'>
                        <Label check className='font-size-sm d-flex'>
                            <Input type="radio" name="course" value="0" className={styles.customRadio}
                                onClick={() =>{handleRadioClick(false, 0)}}
                            />Aakash Digital
                        </Label>
                        <div onClick={() => toggleToast(2)}>
                            <img src={infoicon} className={styles.imgInfo} /></div>
                        <Toast isOpen={showToast == 2} className={`border-0 ${styles.toastMessagedigi}`}>
                            <ToastBody className='font-size-xs'>
                                Aakash Digital functions as an online platform for preparing for exams such as NEET, JEE, and Foundation courses.
                            </ToastBody>
                        </Toast>
                    </FormGroup>
                </div>
                {courseType &&
                    <div>
                        <SelectCreatable
                            required
                            placeholder="State"
                            name="state"
                            options={dropdownOptions.state}
                            value={getSelectDefaultValue(
                                examInfo.state,
                                dropdownOptions.state,
                            )}
                            onChange={handleOnChange}
                            onErrorUpdate={handleUpdateErrors}
                            messages={{
                                required: 'Please select state',
                            }}
                            isSearchable={false}
                            className="mb-3 mt-2"
                            outerDivStyles={`mb-0 w-100 font-size-sm rounded box-shadow-none ${errors.state ? `border-0` : `border border-grey-100`
                                }`}
                            placeholderStyles={{ color: '#545454' }}
                            borderStyles={`2px solid #00b0f5 !important`}
                        />
                        <SelectCreatable
                            required
                            placeholder="Centre"
                            name="centre"
                            options={dropdownOptions.centre}
                            value={getSelectDefaultValue(
                                examInfo.centre,
                                dropdownOptions.centre,
                            )}
                            onChange={handleOnChange}
                            onErrorUpdate={handleUpdateErrors}
                            messages={{
                                required: !examInfo.state
                                    ? 'Please select state first'
                                    : !examInfo.classStream
                                        ? 'Please select class & stream'
                                        : 'Please select centre',
                            }}
                            isSearchable={false}
                            outerDivStyles={`w-100 font-size-sm rounded box-shadow-none ${errors.centre ? `border-0` : `border border-grey-100`
                                }`}
                            placeholderStyles={{ color: '#545454' }}
                            borderStyles={`2px solid #00b0f5 !important`}
                        />
                    </div>
                }
            </div>

            {/* Due to error exist on the coursetype toggle for DateofExam on Single Component that's why use seperate Select Box for date of Exam for coursetype toogle */}

            {/* Date of Exam */}
            {courseType && (
            <SelectCreatable
                required
                placeholder="Date of exam (DD/MM/YYYY)"
                name="dateOfExam"
                options={dropdownOptions.dateOfExam}
                value={getSelectDefaultValue(
                    examInfo.dateOfExam,
                    dropdownOptions.dateOfExam,
                )}
                onChange={handleOnChange}
                onErrorUpdate={handleUpdateErrors}
                messages={{
                    required: !examInfo.centre
                        ? 'Please select centre first'
                        : !examInfo.classStream
                            ? 'Please select class & stream'
                            : 'Please select date of exam',
                }}
                isSearchable={false}
                className="mb-3 px-3"
                outerDivStyles={`w-100 font-size-sm rounded box-shadow-none ${errors.dateOfExam ? `border-0` : `border border-grey-100`
                    }`}
                placeholderStyles={{ color: '#545454' }}
                borderStyles={`2px solid #00b0f5 !important`}
            />
            )}
            {!courseType && (
                <SelectCreatable
                required
                placeholder="Date of exam (DD/MM/YYYY)"
                name="dateOfExam"
                options={dropdownOptions.dateOfExam}
                value={getSelectDefaultValue(
                    examInfo.dateOfExam,
                    dropdownOptions.dateOfExam,
                )}
                onChange={handleOnChange}
                onErrorUpdate={handleUpdateErrors}
                messages={{
                    required: !examInfo.centre
                        ? 'Please select centre first'
                        : !examInfo.classStream
                            ? 'Please select class & stream'
                            : 'Please select date of exam',
                }}
                isSearchable={false}
                className="mb-3 px-3"
                outerDivStyles={`w-100 font-size-sm rounded box-shadow-none ${errors.dateOfExam ? `border-0` : `border border-grey-100`
                    }`}
                placeholderStyles={{ color: '#545454' }}
                borderStyles={`2px solid #00b0f5 !important`}
            />
            )}
            

            {/* Submit Form Button */}
            <Button
                className={cx(
                    `btn btn-aqua text-white mx-3 w-9 fw-bold font-size-sm font-size-lg-md ${styles.sendOtpButton}`,
                    { 'bg-grey-20 text-grey-70 border-0': isSubmitBtnDisabled },
                )}
                onClick={handleSubmit}
                disabled={isSubmitBtnDisabled || isSignupLoading}
            >
                {isSignupLoading ? Loader : 'Submit'}
            </Button>
        </>
    )
}

export default connect(null, mapDispatchToProps)(StepThreeExamInfo)
