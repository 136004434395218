import { useHistory } from 'react-router-dom'

import AakashLogo from '../assets/aakash-byjus-logo.svg'

function PageNotFound() {
    const history = useHistory()

    return (
        <div className="vh-100 vh-md-80 w-10 w-md-7 w-lg-6 m-auto text-center d-flex flex-column justify-content-center align-items-center">
            <div
                className="rounded shadow-md w-9 w-md-7"
                style={{ padding: '44px 25px', margin: '1.5rem' }}
            >
                <img src={AakashLogo} alt="aakash-logo" width={120} height={120} />

                <p className="fw-lighter font-size-lg mt-4">Oops! Page not found.</p>

                <p className="font-size-sm w-7 text-grey text-center m-auto">
                    Go to{' '}
                    <span
                        onClick={() => history.push('/')}
                        className={`cursor-pointer font-size-sm text-aqua text-decoration-none`}
                    >
                        Login page
                    </span>{' '}
                    or visit our{' '}
                    <a
                        href="https://www.aakash.ac.in/"
                        className={`font-size-sm text-aqua text-decoration-none`}
                    >
                        website
                    </a>
                </p>
            </div>
        </div>
    )
}

export default PageNotFound
