import axios from 'axios'
import CONFIG from '../config'

export const login = (roll_no, dob) => {
    let data = { roll_number: roll_no, date_of_birth: dob }
    let config = {
        method: 'post',
        url: CONFIG.LOGIN_API,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
    }
    return axios(config)
}
export const dashboard = (token) => {
    let config = {
        method: 'get',
        url: CONFIG.DASHBOARD_API,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
    }
    return axios(config)
}

export const mockPayment = (token, url) => {
    let config = {
        method: 'get',
        url,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
    }
    return axios(config)
}

export const examInfo = (token) => {
    let config = {
        method: 'get',
        url: CONFIG.EXAM_INFO_API,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
    }
    return axios(config)
}
export const editDetails = (token) => {
    let config = {
        method: 'get',
        url: CONFIG.MASTER_API,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
    }
    return axios(config)
}
export const examDates = (token, data) => {
    let config = {
        method: 'post',
        url: CONFIG.EXAM_DATES_API,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
        data: data,
    }
    return axios(config)
}
export const generateOTP = (token) => {
    let config = {
        method: 'post',
        url: CONFIG.GENERATE_OTP_API,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
    }
    return axios(config)
}
export const validateOTP = (token, otp) => {
    let config = {
        method: 'post',
        url: CONFIG.VALIDATE_OTP_API,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
        data: { otp: otp },
    }
    return axios(config)
}
export const updateExamInfo = (token, data) => {
    let config = {
        method: 'post',
        url: CONFIG.UPDATE_EXAM_INFO_API,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
        data: data,
    }
    return axios(config)
}
export const startMainExam = (token, shortCode, source_type) => {
    let config = {
        method: 'post',
        url: CONFIG.START_MAIN_EXAM_API,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
        data: { test_short_code: shortCode, source_type },
    }
    return axios(config)
}
export const resetSampleExam = (token, shortCode) => {
    let config = {
        method: 'post',
        url: CONFIG.RESET_SAMPLE_EXAM_API,
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
        data: { test_short_code: shortCode },
    }
    return axios(config)
}

export const result = (body) => {
    let config = {
        method: 'post',
        url: CONFIG.RESULT_API,
        headers: {
            'Content-Type': 'application/json',
        },
        data: body,
    }
    return axios(config)
}

export const iacstResult = (body) => {
    let config = {
        method: 'post',
        url: CONFIG.IACST_RESULT_API,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic YXBpX2FkbWluX2Fha2FzaDpBYWthc2hAMTIzIyE=',
        },
        data: body,
    }
    return axios(config)
}
export const forgotPassword = (roll_number) => {
    let config = {
        method: 'post',
        url: CONFIG.FORGOT_PASSWORD,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            roll_number: roll_number,
        },
    }
    return axios(config)
}
export const coursePurchase = (body) => {
    let config = {
        method: 'post',
        url: CONFIG.COURSE_PAYMENT_API,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic a3VuYWxfYXBpOkFha2FzaEAxMjMhIw==',
        },
        data: body,
    }
    return axios(config)
}

export const courseListing = (params) => {
    let config = {
        method: 'get',
        url: CONFIG.FETCH_COURSE_DATA+`/${params}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic a3VuYWxfYXBpOkFha2FzaEAxMjMhIw==',
        },
    }
    return axios(config)
}

export const getPincode = (params) => {
    let config = {
        method: 'get',
        url: CONFIG.PINCODE_API+`/${params}`,
        headers: {
            'Content-Type': 'application/json',
        },
    }
    return axios(config)
}

export const getState = () => {
    let config = {
        method: 'get',
        url: CONFIG.STATE_API,
        headers: {
            'Content-Type': 'application/json',
        },
    }
    return axios(config)
}
