import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import LoginLhs from './LoginLhs'
import styles from './forgotPassword.module.css'
import {
    getURLParameters,
    filterParamsStartingWithUtm,
} from 'utils/getAllURLParameters'
import Header from 'components/Header/header'
import { InputField } from 'components/shared/InputField'
import { SunspotLoader } from 'react-awesome-loaders'
import useTimer from 'hooks/useTimer'
import EditIcon from 'assets/iconComp/EditIcon'
import { connect } from 'react-redux'
import { usePutPostApi } from 'api/usePutPostApi'
import { SET_TOAST_DATA, SET_TOKEN } from 'constants/actionTypes'
import CONFIG from 'config'
import { MAX_OTP_DIGITS, RESEND_OTP_TIME } from 'components/Result/constants'

const mapStateToProps = (token) => {
    return token
}

const mapDispatchToProps = (dispatch) => ({
    setToken: (payload) => dispatch({ type: SET_TOKEN, payload }),

    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
})
const ForgotPassword = (props) => {
    const { setToastMsgsData } = props
    let history = useHistory()

    const [otp, setOtp] = useState('')
    const [userId, setUserId] = useState('')
    const [showOtp, setShowOtp] = useState(false)
    const [passwordRecovered, setPassRecovered] = useState('')
    const [errorMsg, setErrorMsg] = useState({ userId: '', otp: '' })

    const { timer, isTimerRunning, togglePlayPause, onResetTimer } = useTimer({
        duration: RESEND_OTP_TIME,
    })

    const [utmParams, setUtmParameters] = useState({})
    const [recoveredCreds, setRecoveredCreds] = useState({
        maskedEmail: '',
        maskedMobileNum: '',
    })

    const { maskedEmail, maskedMobileNum } = recoveredCreds
    const queryString = window.location.href

    useEffect(() => {
        if (props.token !== null) history.replace('/dashboard')
        else props.setToken(null)

        const url = new URL(queryString)

        const parameters = getURLParameters(url.search)
        const utmParameters = filterParamsStartingWithUtm(parameters)
        setUtmParameters(utmParameters)

        // eslint-disable-next-line
    }, [])

    const handleUserIdChange = (e) => {
        const { value } = e.target
        if (value.length) {
            setErrorMsg({ ...errorMsg, userId: '' })
        }

        if (value.length <= 12) setUserId(e.target.value)
    }

    const handleOtpChange = (event) => {
        const { value } = event.target
        if (value.length <= MAX_OTP_DIGITS) setOtp(value)
        setErrorMsg({ ...errorMsg, otp: '' })
    }

    const generateOtpPayload = { login_id: userId }
    const verifyOtpPayload = {
        otp_id: Number.parseInt(localStorage.getItem('otp_id')),
        otp: otp,
        login_id: userId,
        resendUserDetails: passwordRecovered === '' ? false : true,
    }

    const {
        data: generateOtpResponse,
        loading: isOtpGenerating,
        setPutEnabled: callGenerateOtp,
    } = usePutPostApi(CONFIG.GENERATE_OTP_API, '', 'post', generateOtpPayload, false)

    const {
        data: verifyOtpResponse,
        loading: isOtpVerifying,
        setPutEnabled: callVerifyOtp,
    } = usePutPostApi(CONFIG.VALIDATE_OTP_API, '', 'post', verifyOtpPayload, false)

    const handleEditMobileNum = () => {
        showOtp && setShowOtp(false)
        setPassRecovered('')
    }

    useEffect(() => {
        if (
            generateOtpResponse &&
            generateOtpResponse.success === 'true' &&
            passwordRecovered === ''
        ) {
            const { data } = generateOtpResponse
            if (data && data.is_mobile) {
                setShowOtp(true)
                setToastMsgsData({
                    isToastOpen: true,
                    toastType: 'Success',
                    toastMessage: generateOtpResponse.msg || 'Success',
                })
                localStorage.setItem('otp_id', data.otp_id)
            } else {
                setShowOtp(true)
                setPassRecovered('rollno')
                setRecoveredCreds({
                    maskedEmail: generateOtpResponse.data.email.replaceAll('x', '*'),
                    maskedMobileNum:
                        generateOtpResponse.data.mobile_number.replaceAll('x', '*'),
                })
            }
        } else if (
            generateOtpResponse &&
            generateOtpResponse.success === 'false' &&
            passwordRecovered === ''
        ) {
            setErrorMsg({
                ...errorMsg,
                userId: generateOtpResponse.msg || 'Invalid',
            })
        } else if (
            generateOtpResponse &&
            generateOtpResponse.success === 'true' &&
            passwordRecovered !== ''
        ) {
            setToastMsgsData({
                isToastOpen: true,
                toastType: 'Success',
                toastMessage: generateOtpResponse.msg || 'Success',
            })
        }
        // eslint-disable-next-line
    }, [generateOtpResponse])

    useEffect(() => {
        if (verifyOtpResponse) {
            if (
                verifyOtpResponse.success === 'true' &&
                passwordRecovered === '' &&
                verifyOtpResponse.data
            ) {
                setPassRecovered('mobileno')
                onResetTimer()
            } else if (
                verifyOtpResponse.success === 'false' &&
                passwordRecovered === ''
            ) {
                setErrorMsg({ ...errorMsg, otp: verifyOtpResponse.msg })
            } else if (
                verifyOtpResponse.success === 'true' &&
                passwordRecovered !== ''
            ) {
                setToastMsgsData({
                    isToastOpen: true,
                    toastType: 'Success',
                    toastMessage: verifyOtpResponse.msg || 'Success',
                })
            }
        }

        // eslint-disable-next-line
    }, [verifyOtpResponse])

    const handleSubmit = () => {
        onResetTimer()
        togglePlayPause()
        setErrorMsg({ ...errorMsg, otp: '' })
        setOtp('')

        if (userId.length === 10) {
            callGenerateOtp(true)

            window.webengage.track('iACST_login_recover_roll_pw_with_mobile', {
                'Page URL': queryString,
                'mobile number': userId,
                'UTM PArameters': utmParams,
            })
        } else if (userId.length === 12) {
            callGenerateOtp(true)

            window.webengage.track('iACST_login_recover_pw_with_rollno', {
                'Page URL': queryString,
                'roll number': userId,
                'UTM PArameters': utmParams,
            })
        }
    }
    const handleVerifyOtp = () => {
        callVerifyOtp(true)
       
        window.webengage.track('iACST_login_forgot_pw_mobile_verify_otp', {
            'Page URL': queryString,
            'mobile number': userId,
            'UTM PArameters': utmParams,
        })
    }

    const handleResendOtp = () => {
        callGenerateOtp(true)
        onResetTimer()
        togglePlayPause()

        window.webengage.track('iACST_login_forgot_pw_mobile_resend_otp', {
            'Page URL': queryString,
            'mobile number': userId,
            'UTM PArameters': utmParams,
        })
    }

    const handleResendDetails = () => {
        if (passwordRecovered === 'mobileno') {
            callVerifyOtp(true)
            window.webengage.track('iACST_login_forgot_pw_resend_details', {
                'Page URL': queryString,
                'mobile number': userId,
                'roll number': verifyOtpResponse.data.roll_number,
                'UTM PArameters': utmParams,
            })
        } else {
            callGenerateOtp(true)
            window.webengage.track('iACST_login_forgot_pw_resend_details', {
                'Page URL': queryString,
                'roll number': userId,
                'UTM PArameters': utmParams,
            })
        }

        onResetTimer()
        togglePlayPause()
    }

    const handleLoginToDashboard = () => {
        if (passwordRecovered === 'mobileno') {
            const { roll_number, dob } = verifyOtpResponse.data
            history.replace(`/?roll_no=${roll_number}&dob=${dob}`)

            window.webengage.track('iACST_login_forgot_pw_login_to_db', {
                'Page URL': queryString,
                'mobile number': userId,
                'roll number': roll_number,
                'UTM PArameters': utmParams,
            })
        } else {
            history.push(`/?roll_no=${userId}`)

            window.webengage.track('iACST_login_forgot_pw_login_to_db', {
                'Page URL': queryString,
                'roll number': userId,
                'UTM PArameters': utmParams,
            })
        }
    }

    const editMobileNumBtn = (
        <span className={styles.inputAddOnEditBtn}>
            <EditIcon />
        </span>
    )

    return (
        <>
            <Header />
            <div className={`${styles.mainContainer} d-flex`}>
                <LoginLhs />
                <div
                    className={`${styles.rightsideCard} w-100 w-md-50 bg-off-white py-0 px-3 px-lg-2 d-flex justify-content-center align-items-center`}
                >
                    {passwordRecovered ? (
                        <div className={styles.wrapper}>
                            <p className="font-size-lg text-dark-grey text-center lh-sm">
                                Roll number and password have been successfully sent
                                to your registered mobile number
                                <span className="font-size-md fw-lighter">
                                    {' '}
                                    {passwordRecovered === 'mobileno'
                                        ? ''
                                        : `${maskedMobileNum} `}
                                </span>
                                and email
                                <span className="font-size-md fw-lighter">
                                    {' '}
                                    {passwordRecovered === 'mobileno'
                                        ? verifyOtpResponse.data.email
                                        : maskedEmail}
                                </span>
                            </p>

                            {passwordRecovered === 'mobileno' && (
                                <>
                                    <InputField
                                        value={verifyOtpResponse.data.mobile_number}
                                        disabled
                                        title="Your mobile number"
                                    />
                                    <InputField
                                        value={verifyOtpResponse.data.roll_number}
                                        disabled
                                        title="Your roll number"
                                    />
                                </>
                            )}

                            <p className="font-size-xs text-dark-grey text-center mt-auto">
                                Didn’t received the details?{' '}
                                <span
                                    className={`${
                                        isTimerRunning
                                            ? 'text-grey-50 pe-none'
                                            : 'text-aqua cursor-pointer'
                                    }`}
                                    onClick={
                                        !isTimerRunning
                                            ? handleResendDetails
                                            : undefined
                                    }
                                >
                                    Resend details
                                </span>{' '}
                                {isTimerRunning && (
                                    <span>
                                        {' '}
                                        in 0{Math.floor(timer / 60)}:
                                        {timer >= 60 ? '00' : timer}
                                    </span>
                                )}
                            </p>

                            <button
                                className={`btn btn-aqua text-white w-100 font-size-sm rounded-10px mb-2 ${styles.submitButton}`}
                                onClick={handleLoginToDashboard}
                            >
                                {/* {passwordRecovered === 'mobileno'
                                    ? 'Go to dashboard'
                                    : 'Login'} */}
                                Login to dashboard
                            </button>
                        </div>
                    ) : (
                        <div className={styles.wrapper} style={{ minHeight: '40%' }}>
                            <p className="font-size-lg fw-lighter">
                                Forgot roll number or password
                            </p>

                            <InputField
                                name="roll/mob-no"
                                placeholder="Enter your roll number/mobile number"
                                title="Roll number/mobile number"
                                value={userId}
                                type="number"
                                onChange={handleUserIdChange}
                                required
                                errorMsg={errorMsg.userId}
                                disabled={showOtp}
                                actionBtn={showOtp ? editMobileNumBtn : null}
                                onInternalBtnClick={handleEditMobileNum}
                            />

                            {showOtp && (
                                <>
                                    <InputField
                                        name="otp"
                                        placeholder="Enter OTP sent to your mobile number"
                                        title="Enter OTP"
                                        value={otp}
                                        onChange={handleOtpChange}
                                        required
                                        errorMsg={errorMsg.otp}
                                    />

                                    {isTimerRunning ? (
                                        <p
                                            className={`${
                                                errorMsg.otp
                                                    ? styles.relativePos
                                                    : styles.resendOtpTimer
                                            } text-grey font-size-xs text-end position-relative`}
                                        >
                                            0{Math.floor(timer / 60)}:
                                            {timer >= 60 ? '00' : timer}
                                        </p>
                                    ) : (
                                        <p
                                            className={`${
                                                errorMsg.otp
                                                    ? styles.relativePos
                                                    : styles.resendOtpTimer
                                            } text-aqua font-size-xs cursor-pointer text-end position-relative`}
                                            onClick={handleResendOtp}
                                        >
                                            Resend OTP
                                        </p>
                                    )}
                                </>
                            )}

                            {showOtp ? (
                                <button
                                    className={`btn btn-aqua text-white w-100 font-size-sm rounded-10px mt-5 mb-2 ${styles.submitButton}`}
                                    onClick={handleVerifyOtp}
                                    disabled={
                                        errorMsg.otp || otp.length !== MAX_OTP_DIGITS
                                    }
                                >
                                    {isOtpVerifying ? (
                                        <SunspotLoader
                                            gradientColors={['#fafafa', '#fff']}
                                            size={'10px'}
                                            desktopSize={'10px'}
                                            className="m-auto"
                                        />
                                    ) : (
                                        'Verify'
                                    )}
                                </button>
                            ) : (
                                <button
                                    className={`btn btn-aqua text-white w-100 font-size-sm rounded-10px mt-5 mb-2 ${styles.submitButton}`}
                                    onClick={handleSubmit}
                                    disabled={
                                        errorMsg.userId ||
                                        (userId.length !== 10 &&
                                            userId.length !== 12)
                                    }
                                >
                                    {isOtpGenerating ? (
                                        <SunspotLoader
                                            gradientColors={['#fafafa', '#fff']}
                                            size={'10px'}
                                            desktopSize={'10px'}
                                            className="m-auto"
                                        />
                                    ) : (
                                        'Next'
                                    )}
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
