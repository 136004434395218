import { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// import { login } from '../../api/api'
import Footer from '../../components/Footer/footer'
import Header from '../../components/Header/header'
import {
    SET_TOKEN,
    SET_DOB,
    SET_ROLLNO,
    SET_TOAST_DATA,
} from '../../constants/actionTypes'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { getMonth, getYear } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'
import LoginLhs from './LoginLhs'
import styles from './login.module.css'
import { SunspotLoader } from 'react-awesome-loaders'
import {
    filterParamsStartingWithUtm,
    extractQueryParams,
} from 'utils/getAllURLParameters'
import MaskedTextInput from 'react-text-mask'
import { InputField } from 'components/shared/InputField'
import { usePutPostApi } from 'api/usePutPostApi'
import config from 'config'
import { months, years } from 'components/Result/constants'

const mapStateToProps = (token) => {
    return token
}

const mapDispatchToProps = (dispatch) => ({
    setToken: (payload) => dispatch({ type: SET_TOKEN, payload }),
    setRollNo: (payload) => dispatch({ type: SET_ROLLNO, payload }),
    setDOB: (payload) => dispatch({ type: SET_DOB, payload }),
    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
})

const Login = (props) => {
    const MAX_ROLLNO_LENGTH = 12
    // const datepickerRef = useRef()
    const [errorMsg, setErrorMsg] = useState({ rollno: '', dob: '' })
    const [rollno, setRollno] = useState('')
    const [dob, setDob] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    let history = useHistory()

    const { data, loading, setPutEnabled } = usePutPostApi(
        config.LOGIN_API,
        '',
        'post',
        { roll_number: rollno, date_of_birth: dob },
        false,
    )

    const queryString = window.location.search

    {
        /* useEffect(() => {
        // auto-login
        let parameters = {}

        const queryParamsArray = queryString.replace('?', '').split(/&|=/)
        parameters = extractQueryParams(queryParamsArray)

        if (
            !queryParamsArray.includes('roll_no') &&
            !queryParamsArray.includes('dob') &&
            props.token !== null
        )
            history.replace('/dashboard')
        else if (
            !queryParamsArray.includes('roll_no') &&
            !queryParamsArray.includes('dob') &&
            props.token === null
        )
            props.setToken(null)

        if (queryParamsArray.includes('roll_no')) {
            setRollno(parameters['roll_no'])
        }
        if (queryParamsArray.includes('dob')) {
            setRollno(parameters['roll_no'])
            setDob(parameters['dob'])
            setPutEnabled(true)
        }
        // loginUser(parameters['roll_no'], parameters['dob'])
        else setIsLoading(loading)

        const utmParameters = filterParamsStartingWithUtm(parameters)
        localStorage.setItem('utmParams', JSON.stringify(utmParameters))

        // eslint-disable-next-line
    }, []) */
    }

    useEffect(() => {
        if (data && data.success === 'true') {
            props.setToken(data.token)
            props.setRollNo(rollno)
            props.setDOB(dob)

            history.push({
                pathname: '/dashboard',
            })
        } else if (data && data.success === 'false') {
            props.setToastMsgsData({
                isToastOpen: true,
                toastType: 'danger',
                toastMessage: data?.msg || 'Something went wrong.',
            })
        }

        // eslint-disable-next-line
    }, [data])

    const handleRollNo = (event) => {
        const { value } = event.target

        setRollno(value.slice(0, MAX_ROLLNO_LENGTH))
        setErrorMsg({ ...errorMsg, rollno: '' })
    }

    const handleClick = () => {
        if ((rollno === '' || rollno.length !== MAX_ROLLNO_LENGTH) && dob === '')
            setErrorMsg({ rollno: 'Invalid roll number', dob: 'Invalid password' })
        else if (rollno === '' || rollno.length !== MAX_ROLLNO_LENGTH) {
            setErrorMsg({ ...errorMsg, rollno: 'Invalid roll number' })
        } else if (dob === '' || dob === null) {
            setErrorMsg({ ...errorMsg, dob: 'Invalid password' })
        }

        if (dob !== '' && dob !== null && rollno.length === MAX_ROLLNO_LENGTH)
            setPutEnabled(true)
    }

    return (
        <Fragment>
            {isLoading ? (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                    }}
                >
                    <SunspotLoader
                        gradientColors={['#4039d4', '#E0E7FF']}
                        size={'10px'}
                        desktopSize={'30px'}
                        mobileSize={'20px'}
                        className="m-auto"
                    />
                </div>
            ) : (
                <>
                    <Header />

                    <div className={styles.login}>
                        <LoginLhs />
                        <div className={styles.login2}>
                            <div
                                className={`${styles.card} bg-white d-flex flex-column align-items-center justify-content-center w-9 w-md-7 py-3`}
                            >
                                <div className="w-9 w-md-8">
                                    <h1 className="fw-lighter font-size-lg mb-4">
                                        Login to your dashboard
                                    </h1>

                                    <InputField
                                        value={rollno}
                                        onChange={handleRollNo}
                                        name="rollno"
                                        placeholder="Enter your roll number"
                                        title="Roll number"
                                        type="number"
                                        required
                                        errorMsg={errorMsg.rollno}
                                    />

                                    <label
                                        className={`form-control-label font-size-sm fw-lighter ${
                                            errorMsg.dob
                                                ? 'text-error-red'
                                                : 'text-light-grey-200'
                                        }`}
                                    >
                                        Password
                                    </label>
                                    <span>
                                        <DatePicker
                                            customInput={
                                                <MaskedTextInput
                                                    type="text"
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        '/',
                                                        /\d/,
                                                        /\d/,
                                                        '/',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                />
                                            }
                                            className={`${
                                                styles.inputBox_login
                                            } w-100  px-2 ${
                                                errorMsg.dob
                                                    ? 'border border-error-red'
                                                    : 'border border-grey-100'
                                            }`}
                                            selected={dob}
                                            onChange={(date) => {
                                                setDob(date)
                                                setErrorMsg({
                                                    ...errorMsg,
                                                    dob: '',
                                                })
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Enter Date of birth (DD/MM/YYYY)"
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                            }) => (
                                                <div
                                                    className={styles.dateSelectDiv}
                                                >
                                                    <select
                                                        value={
                                                            months[getMonth(date)]
                                                        }
                                                        onChange={({
                                                            target: { value },
                                                        }) =>
                                                            changeMonth(
                                                                months.indexOf(
                                                                    value,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        {months.map((option) => (
                                                            <option
                                                                key={option}
                                                                value={option}
                                                            >
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select
                                                        value={getYear(date)}
                                                        onChange={({
                                                            target: { value },
                                                        }) => changeYear(value)}
                                                    >
                                                        {years.map((option) => (
                                                            <option
                                                                key={option}
                                                                value={option}
                                                            >
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                        />
                                    </span>
                                    <div className="w-100 d-flex align-items-center justify-content-between">
                                        {errorMsg.dob && (
                                            <p className="font-size-xs text-error-red mt-1">
                                                {errorMsg.dob}
                                            </p>
                                        )}
                                        <p
                                            className="text-aqua font-size-xs cursor-pointer text-end mt-1 ms-auto"
                                            onClick={() => {
                                                window.webengage.track(
                                                    'iACST_login_forgot_pw',
                                                    {
                                                        'page url':
                                                            window.location.href,
                                                        'utm parameters':
                                                            localStorage.getItem(
                                                                'utmParams',
                                                            ),
                                                    },
                                                )
                                                history.push('/recover-credentials')
                                            }}
                                        >
                                            Forgot roll number/password?
                                        </p>
                                    </div>

                                    <button
                                        className={`btn btn-aqua text-white w-100 fw-lighter font-size-sm rounded-10px mt-5 mb-2 ${styles.submitButton}`}
                                        onClick={handleClick}
                                        isLoading={isLoading}
                                    >
                                        Login
                                    </button>

                                    <p className="font-size-sm text-center">
                                        Don’t have an account?
                                        <span
                                            className="text-aqua cursor-pointer"
                                            onClick={() => {
                                                window.webengage.track(
                                                    'iACST_login_register',
                                                    {
                                                        'utm parameters':
                                                            localStorage.getItem(
                                                                'utmParams',
                                                            ),
                                                    },
                                                )
                                                window.location.href =
                                                    '/iacstexam'
                                            }}
                                        >
                                            &nbsp;Register here
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            )}
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
