// reducers.js
import { SET_COURSE_DETAILS,SET_COURSE_FLAGS_DETAILS,SET_INPUT_FIELD_DATA,SET_VISIBLE_COURSE_DESC,SET_VISIBLE_COURSE_REGISTER,SET_ECOMMERCE_SUCCESS,SET_ECOMMERCE_FAILED,RESET_REDUCERS,SET_RETRY_PAYMENT } from '../constants/actionTypes';

const initialState = {
  finalCourseDetailsPayload: {},
  visibleCourseDesc: false,
  visibleCourseRegister : false,
  ecommerceSuccess : false,
  ecommerceFailed : false,
  retryPayment: false,
  inputFieldData: {},
};

const setEcommerce = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_DETAILS:
      return {
        ...state,
        finalCourseDetailsPayload: action.payload,
      };
    case SET_INPUT_FIELD_DATA:
      return {
        ...state,
        inputFieldData: action.payload,
      };
      case SET_COURSE_FLAGS_DETAILS:
      return {
        ...state,
        courseFlagsData: action.payload,
      };
      case SET_VISIBLE_COURSE_DESC:
      return {
        ...state,
        courseDescVisible: action.payload,
      };
    case SET_VISIBLE_COURSE_REGISTER:
      return {
        ...state,
        courseRegisterVisible: action.payload,
      };
    case SET_ECOMMERCE_SUCCESS:
      return {
        ...state,
        ecommerceSuccess: action.payload,
      };
    case SET_ECOMMERCE_FAILED:
      return {
        ...state,
        ecommerceFailure: action.payload,
      };
    case SET_RETRY_PAYMENT:
      return {
        ...state,
        retryPayment: action.payload,
      }
    case RESET_REDUCERS:
      return {
        ...initialState,
        inputFieldData: state.inputFieldData,
      }; 

    default:
      return state;
  }
};

export default setEcommerce;
