import { Switch, Route, useRouteMatch } from 'react-router-dom'
import PageNotFound from 'views/404'

import Register from 'views/exigency'
import ExamAlreadyGiven from 'views/exigency/ExamAlreadyGiven'
import NotWantToConduct from 'views/exigency/NotWantToConduct'
import RegistrationClosed from 'views/exigency/RegistrationClosed'
import RegistrationSuccessful from 'views/exigency/RegistrationSuccessful'

function Exigency() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path} render={() => <Register />} />

            <Route
                exact
                path={`${path}/unavailable`}
                render={() => <RegistrationClosed />}
            />

            <Route
                exact
                path={`${path}/exam-given`}
                render={() => <ExamAlreadyGiven />}
            />

            <Route
                exact
                path={`${path}/not-conducted`}
                render={() => <NotWantToConduct />}
            />

            <Route
                exact
                path={`${path}/registration-successful`}
                render={() => <RegistrationSuccessful />}
            />

            <Route path="*" render={() => <PageNotFound />} />
        </Switch>
    )
}

export default Exigency
