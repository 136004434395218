import styles from './antheRsTable.module.css'

const ResultTable = (props) => {
    const { data } = props

    return (
        <>
            <div className={styles.resultTableWrapper}>
                <div style={{ minWidth: '315px' }}>
                    <header className={styles.header}>RESULT</header>
                    <main className={styles.header2}>{props.title}</main>
                    <section className={styles.section}>
                        <div className={styles.secData}>
                            <span className={styles.spanA}>Roll No. / PSID</span> :
                            <span className={styles.spanB}>
                                <b>{`${data?.result.rollno} / ${data?.result.PSID}`}</b>
                            </span>
                        </div>

                        <div className={styles.secData}>
                            <span className={styles.spanA}>
                                Name of the Candidate
                            </span>{' '}
                            :
                            <span className={styles.spanB}>{data?.result.Name}</span>
                        </div>

                        <div className={styles.secData}>
                            <span className={styles.spanA}>Father's Name</span> :
                            <span className={styles.spanB}>
                                {data?.result.Fname}
                            </span>
                        </div>

                        <div className={styles.secData}>
                            <span className={styles.spanA}>
                                Stream / Class Opted{' '}
                            </span>{' '}
                            :
                            <span
                                className={styles.spanB}
                            >{`${data?.result.stream} / ${data?.result.ClassOpted}`}</span>
                        </div>

                        <div className={styles.secData}>
                            <span className={styles.spanA}>ANTHE Result </span> :
                            <span
                                className={styles.spanB}
                                style={{ fontWeight: 900 }}
                            >
                                {data?.result.Status === 'Not Qualified'
                                    ? 'Not eligible for ANTHE Scholarship. However, eligible for iACST'
                                    : data?.result.Status}
                            </span>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default ResultTable
