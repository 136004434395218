import config from "config"
    export const aboutCard = [
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/scholarship-test-details.png',
        cardbodyitem:
            '<p>Take the test at a date and time of your choice </br><b>Timings </b>: 9AM to 10PM Daily | <b>Duration</b> : 60&nbsp;mins</br><b>Mode </b>: Online (from home)</p>',
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/avail-scholarship.png',
        cardbodyitem:
            "<p className='integrated'>1-Year Integrated Classroom & Online Courses<br/> for NEET and JEE</p>",
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/how_can_appear.png',
        cardbodyitem:
            " <p> Class 12th passed students</p>",
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/how-to-take.png',
        cardbodyitem:
            '<ul><li>Verify your mobile number with OTP and complete the registration </li><li> Get your login details, roll number and select an exam date &amp; time slot </li> <li>Take the Aakash iACST online from your home</li></ul>',
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/test-highlight.png',
        cardbodyitem:
            ' <ul><li>Completely online ensuring safety of all candidates </li> <li>Flexible timings - students can choose and select any time slot between 9AM to 10PM</li> <li>Conducted everyday so that students can schedule the test as per their convenience</li></ul>',
    },
    ]
    export const coursehighlight = [
        {
            hightlighticon:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Expert%20Faculty.jpg',
            listingcourse:'Expert faculty',
        },
        {
            hightlighticon:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/iTutor%20Lab%20Facility.jpg',
            listingcourse:'Access to Digital Product',
        },
        {
            hightlighticon:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Tests%20on%20NEET_JEE%20pattern.jpg',
            listingcourse:'Tests on NEET/JEE pattern',
        },
        {
            hightlighticon:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Test%20Series%E2%80%9A%20%20AIATS%2C%20SMTS%2C%20etc.jpg',
            listingcourse:'Test Series‚ AIATS, SMTS, etc.',
        },
        {
            hightlighticon:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Audiprep_Icon_White.jpg',
            listingcourse:'Free Access NEET Audiobooks',
        },
        {
            hightlighticon:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/Exhaustive%20Study%20material.jpg',
            listingcourse:'Exhaustive Study material',
        },
        {
            hightlighticon:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/ic-questions-bank.webp',
            listingcourse:'Question Banks',
        }
    ]

    export const successStories=[
        {
            headingImage:`https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/combined-success-stroies-from-repeater-2023-lg.webp?v=${config.VERSION_BANNER}`,
        },
        {
            headingImage: `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-advanced-2024-results.webp?v=${config.VERSION_BANNER}`,
        },
        {
            headingImage:`https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-main-2024-results.webp?v=${config.VERSION_BANNER}`,
        },
        {
            headingImage:`https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/neet-2023-topper-banner-1260x300.webp?v=${config.VERSION_BANNER}`,
        },
    ]

    export const scholarshipDetails = [
        {
            scholarshipTitle: 'NEET and JEE Repeater Course for Droppers',
            scholarshipPara:
                " NEET and JEE are the most popular competitive exams in India that determine admission to top engineering and medical colleges. Students who are unable to clear these exams in their first attempt often choose to take a gap year and prepare again. These students are commonly known as droppers. While it can be challenging to prepare for these exams again, there are several coaching institutes that offer specialized courses for NEET and JEE droppers. One such coaching institute is Aakash Institute, which has been a popular choice among droppers for years.",
            scholarshipData: [
                {
                    scholarshipDataTitle:
                        'Here are Some Benefits of Choosing Aakash Repeater Course for NEET/JEE Droppers',
                    scholarshipDataPara: [
                        {
                            scholarshipDataParagraph:
                                ' <strong> Experienced Faculty:</strong> Aakash Institute has a team of highly experienced faculty members who have been teaching students for many years. They have a deep understanding of the exam pattern and are experts in their respective fields. This ensures that NEET/JEE droppers get the best guidance and coaching from the best in the business.',
                        },
                        {
                            scholarshipDataParagraph:'<strong>Comprehensive Course Material:</strong> Aakash Institute provides its students with comprehensive course material that covers all the topics in the NEET/JEE syllabus. The course material is designed in such a way that it is easy to understand and remember, making it easier for students to revise and practice.',
                        },
                        {
                            scholarshipDataParagraph:"<strong>Personalized Learning:</strong> The Aakash Repeater Course is designed in such a way that it caters to the individual needs of each student. The course is customized based on the student's strengths and weaknesses, which helps them focus on the areas they need to improve on.",
                        },
                        {
                            scholarshipDataParagraph:'<strong>Regular Assessment:</strong> Aakash conducts regular assessments and mock tests to help students evaluate their progress. This helps students identify their weaknesses and work on them to improve their overall score.',
                        },
                        {
                            scholarshipDataParagraph:'<strong>Doubt Clearing Sessions:</strong> Aakash repeater course provides doubt-clearing sessions to students to help them understand the concepts better. This helps students clear their doubts and gain confidence in their abilities.',
                        },
                        {
                            scholarshipDataParagraph:'<strong>Time Management:</strong> Aakash Institute provides NEET/JEE droppers with tips and tricks to manage their time effectively during the exam. This helps students improve their speed and accuracy, which is essential to score well in NEET/JEE.',
                        },

                    ],
                },
                {
                    scholarshipDataTitle: 'About Aakash Institute',
                    scholarshipDataPara: [
                        {
                            scholarshipDataParagraph:'Aakash Educational Services Limited (AESL), also known as Aakash Institute, has been leading the way in competitive exam preparation for over 35 years. Our aim is to help students achieve their goals of cracking medical and engineering entrance exams, and we have a legacy of producing top rankers in NEET, IIT-JEE, Olympiad NTSE, KVPY, 10th and 12th exams every year.',
                        },
                        {
                            scholarshipDataParagraph:"Our success can be attributed to our expert faculty and unmatched study material, as well as our meticulous approach to crafting learning modules that cater to every student's individual needs. We offer foundation to advanced courses, with a range of scholarship tests available to recognize and reward passionate and talented students across the nation. These tests provide the opportunity to secure a scholarship of up to 100% and get admission in a course of their choice, kick-starting their journey towards success.",
                        },
                        {
                            scholarshipDataParagraph:'Our scholarship tests are conducted via multiple mediums, including app or website based online exams from home and offline at Aakash Institute regional branches. We also offer a blend of classroom and online learning courses, such as the Aakash Hybrid Program, which is designed to provide a seamless learning experience for all students.',
                        },
                        {
                            scholarshipDataParagraph:'To learn more about our courses and how we can assist you in achieving your academic goals, simply fill out a form on our website or visit/call your nearest Aakash Institute branch. Our team of experts will be more than happy to guide and assist you.',
                        },
                    ],
                },
                {
                    scholarshipDataTitle: 'NEET Coaching @ Aakash',
                    scholarshipDataPara: [
                        {
                            scholarshipDataParagraph:
                                'Aakash has been the leading NEET coaching center for over 35 years. It has consistently held the title of the best NEET coaching center among all other centers in India. With multiple branches spread across the country and online courses, Aakash has made its NEET coaching accessible to aspiring students from all corners of India. Aakash offers a range of courses, specifically designed to cater to the individual requirements of each student, ensuring optimal test preparation. Aakash provides scholarships for NEET coaching fees, and their experienced faculty aims to support students at every step of their NEET journey.',
                        },
                    ],
                },
                {
                    scholarshipDataTitle: 'JEE Coaching @ Aakash',
                    scholarshipDataPara: [
                        {
                            scholarshipDataParagraph:
                                'Every year, Aakash Institute produces exceptional top rankers, solidifying its position as the premier coaching center for the Joint Entrance Examination (JEE). We provide students with the necessary resources and guidance to gain an edge in their JEE preparation, making Aakash the ultimate destination for IIT JEE coaching. Our institute has established itself as the best in the business, and we take pride in maintaining our status as a trusted name in the IIT JEE coaching domain.',
                        },
                        {
                            scholarshipDataParagraph:'For students and parents searching for the top IIT coaching center, Aakash Institute is the answer to all their queries. Our institute offers various scholarships to ensure that students can access our JEE coaching courses, which are renowned for their high-quality education and expertise. At Aakash Institute, we are committed to providing the best possible learning experience for our students, making us the go-to institute for JEE coaching.',
                        }
                    ],
                },
            ],
        },
    ]

    export const banner_title=[
        {
            head_title: 'NEET/JEE 2025 Repeater <br/>Courses for 12th Passed Batches',
            sub_head_title: 'Up to 90%* Total Scholarship',
            type: 'Online Instant Admission Cum Scholarship Test',
            registration: 'REGISTER For Free',
        }
    ]