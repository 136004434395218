const MaskString = ({ inputString, n = 2, maskCharacter = '*' }) => {
  // Check if the input string exists and has at least 2*n characters
  if (!inputString || inputString.length < 2 * n) {
    return null;
  }

  const firstNCharacters = inputString.substring(0, n);
  const lastNCharacters = inputString.substring(inputString.length - n);
  const numberOfMaskCharacters = inputString.length - 2 * n;
  const maskedString = firstNCharacters + maskCharacter.repeat(numberOfMaskCharacters) + lastNCharacters;

  return maskedString
};

export default MaskString;
