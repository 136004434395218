import { useState } from 'react'
import {
    Card,
    CardBody,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap'
import './accordion.css'
import Pdf_image from '../../assets/PDF.png'
import Pdfdownload_icon from '../../assets/pdf-down-icon.svg'
import { beforeTestFAQs, afterTestFAQs } from 'constants/faqsData'
import { useMediaQuery } from 'react-responsive'
const Faq = ({ profileData }) => {
    const [open, setOpen] = useState('0 + 1')
    const toggle = (id) => {
        setOpen((prevOpen) => (prevOpen === id ? '' : id));
    };  
    const isSmallDevice = useMediaQuery({ query: '(max-width: 767px)' })
    const data = profileData.exam_taken ? afterTestFAQs : beforeTestFAQs

    return (
        <Card
            className="border-0 p-0 ms-1"
            style={{
                width: '100%',
                filter: isSmallDevice ? 'drop-shadow(0px 4px 4px rgba(151, 151, 151, 0.2))' : '',
            }}
        >
            <CardBody className="p-4">
                <div className="advantage_heading">
                    <h3 className="font-size-lg text-grey-black p-1 pb-0">FAQs</h3>
                </div>
                <Accordion flush open={open} toggle={toggle} className="faq-height">
                    {data.map((obj, key) => (
                        <AccordionItem key={key} className="item">
                            <AccordionHeader
                                targetId={`${key} + 1`}
                                onClick={() => {
                                    window.webengage.track('iACST_db_faqs', {
                                        'mobile number': profileData.mobile_number,
                                        class:
                                            profileData.class &&
                                            profileData.class.toString(),
                                        stream: profileData.stream,
                                        branchcode: profileData.exam_center_id,
                                    })
                                }}
                            >
                                <p className="font-size-sm m-0 fw-400 text-grey-black p-0 ">
                                    {obj?.question}
                                </p>
                            </AccordionHeader>
                            <AccordionBody accordionId={`${key} + 1`}>
                                {obj.type === 'video' ? (
                                    <iframe
                                        title="ANTHE banner video"
                                        width="100%"
                                        height="120px"
                                        src={obj?.faq_video}
                                        className="rounded"
                                    ></iframe>
                                ) : obj.type === 'pdf' ? (
                                    <div className="bg-light mb-3 p-3 d-flex justify-content-between align-items-center rounded">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <img src={Pdf_image} alt="pdf-icon" />
                                            <p className="mb-0 font-size-xs fw-lighter ps-3">
                                                Sample papers.pdf
                                            </p>
                                        </div>
                                        <a
                                            href={obj?.faq_pdf}
                                            download
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={Pdfdownload_icon}
                                                alt="pdf-download-icon"
                                            />
                                        </a>
                                    </div>
                                ) : (
                                    ''
                                )}

                                <p
                                    className="font-size-xs fw-normal mb-0 faq-section"
                                    dangerouslySetInnerHTML={{ __html: obj?.answer }}
                                ></p>
                            </AccordionBody>
                        </AccordionItem>
                    ))}
                </Accordion>
            </CardBody>
        </Card>
    )
}
export default Faq
