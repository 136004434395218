import { useState, useEffect } from 'react'

import StepThreeExamInfo from './StepThreeExamInfo'
import { InputField } from 'components/shared/InputField'
import DatePickerField from 'components/shared/DatePickerField'
import { changeToIst } from 'utils/dateTime'

import useGetApi from 'api/useGetApi'
import config from 'config'
import SelectCreatable from 'utils/SelectDropdown'

const StepTwoUserInfo = ({
    registration_page,
    defaultUserInfo,
    isNewUser,
    userMethod,
    setSignupSuccessDetails,
}) => {
    const { mobileNumber } = defaultUserInfo
    const utmParams = JSON.parse(sessionStorage.getItem('utmParams'))

    const [userInfo, setUserInfo] = useState(defaultUserInfo)

    const [errorMsg, setErrorMsg] = useState({
        studentName: '',
        fatherName: '',
        email: '',
        dob: '',
    })

    const [examInfo, setExamInfo] = useState({
        schoolBoard: '',
        classStream: '',
        state: '',
        centre: '',
        dateOfExam: '',
    })

    const [dropdownOptions, setDropdownOptions] = useState({
        schoolBoard: [],
        classStream: [],
        state: [],
        centre: [],
        dateOfExam: [],
    })

    const [errors, setErrors] = useState({
        schoolBoard: '',
        classStream: '',
        state: '',
        centre: '',
        dateOfExam: '',
    })

    // API hooks to fetch dropdown options
    const { data: schoolBoardData } = useGetApi(config.FETCH_SCHOOL_BOARD, '', true)
    const { data: classStreamData } = useGetApi(config.FETCH_CLASS_STREAM+'/?registration_page='+registration_page, '', true)

    const [isFormValid, setIsFormValid] = useState(false)
    const [isExamDetailsFormVisible, setExamDetailsFormVisibile] = useState(false)

    useEffect(() => {
        const userInfoValues = Object.values(userInfo).some((val) => val === '')
        const errorValues = Object.values(errorMsg).some((val) => val)

        setIsFormValid(!userInfoValues && !errorValues);
        
    }, [userInfo, errorMsg])

    useEffect(() => {
        if (
            isFormValid &&
            examInfo.schoolBoard !== '' &&
            examInfo.classStream !== ''
        ) {
            /**
             * show Exam details form when userInfo &
             * Board, class&stream (2 fields of examInfo) dropdowns are filled
             * with no errors
             */
            setExamDetailsFormVisibile(true)

        }

        // todo version 2: call API to save userInfo
    }, [isFormValid, examInfo, errors])

    useEffect(() => {
        if (isExamDetailsFormVisible) {
            const {
                studentName: student_name,
                fatherName: father_name,
                email: email_ID,
                dob,
                board,
            } = userInfo

            // web-analytics
            window.webengage.track('iACST_2_Student_Info', {
                'utm parameters': utmParams,
                method: userMethod.isPsid ? 'psid' : 'mobile',
                psid: userMethod.psid,
                'mobile number': mobileNumber,
                board: examInfo.schoolBoard.value,
                student_name,
                father_name,
                email_ID,
                dob: changeToIst(dob),
            })

            window.webengage.user.setAttribute('we_first_name', student_name)
            window.webengage.user.setAttribute('we_email', email_ID)

            window.dataLayer.push({
                event: 'iacst_registration_mobile_number',
                mobile_no: userInfo.mobileNumber,
            })

            window.dataLayer.push({
                event: 'iacst_registration_email',
                email: userInfo.email,
            })
            
            window.dataLayer.push({
                event: 'iacst_registration_board',
                board: examInfo.schoolBoard.value,
            })
        }
    }, [isExamDetailsFormVisible])

    useEffect(() => {
        if (schoolBoardData?.success && schoolBoardData?.data.length) {
            setDropdownOptions({
                ...dropdownOptions,
                schoolBoard: schoolBoardData?.data,
            })
        }
    }, [schoolBoardData])

    useEffect(() => {
        if (classStreamData?.success && Object.keys(classStreamData?.data).length) {
            const classStreamOptions = Object.entries(classStreamData?.data).map(
                (innerArray) => ({
                    label: innerArray[1].display_name,
                    value: innerArray[0],
                    stream: innerArray[1].stream,
                }),
            )

            setDropdownOptions({
                ...dropdownOptions,
                classStream: classStreamOptions,
            })
        }
    }, [classStreamData])

    const handleFormDataChange = (event) => {
        const { name, value } = event.target
        if (name === 'studentName') {
            // Restrict dots
            if (value.includes('.')) {
                return;
            }
        }
        const sanitizedValue = value.split('').map((char, index) => {
             if (index < 2 && char === ' ') { 
                return ''; 
             }
              return char;
             }).join('');

       setUserInfo({ ...userInfo, [name]: sanitizedValue })
         
        if (errorMsg[name]) {
            setErrorMsg((preErrorStates) => {
                return {
                    ...preErrorStates,
                    [name]: '',
                }
            })
        }
    }

    const handleDobChange = (date) => {
        setUserInfo({ ...userInfo, dob: date })

        setErrorMsg({
            ...errorMsg,
            dob: '',
        })
    }

    const handleOnChange = ({ name, value }) => {
        setExamInfo({ ...examInfo, [name]: value });
    }

    const handleErrorsOnChangeCoursType = () => {
        setErrors((err) => ({
            ...err,
            'state':'',
            'centre':'',
            'dateOfExam':'',
        }))
    }

    const handleUpdateErrors = (name, error,) => {
        const MIN_NAME_LENGTH = 2;
        if (name.length < MIN_NAME_LENGTH) {
            return 'Minimum length should be 2';
        }
        setErrorMsg((err) => ({
            ...err,
            [name]: error,
        }))

        // only trigger WebEngage event when `error` value is not empty
        if (error) {
            const fieldErrors = { ...errorMsg, [name]: error }

            // web-analytics
            const {
                studentName: student_name,
                fatherName: father_name,
                email: email_ID,
                dob,
            } = fieldErrors

            window.webengage.track('iACST_2_Student_Info_Error', {
                'utm parameters': utmParams,
                method: userMethod.isPsid ? 'psid' : 'mobile',
                psid: userMethod.psid,
                'mobile number': mobileNumber,
                error_fields: {
                    student_name,
                    father_name,
                    email_ID,
                    dob: changeToIst(dob),
                },
            })
        }
    }

    const handleExamErrors = (name, error) => {
        setErrors((err) => ({
            ...err,
            [name]: error,
        }))

        // only trigger WebEngage event when `error` value is not empty
        if (error) {
            const fieldErrors = { ...errors, [name]: error }
            const {
                schoolBoard: Board,
                classStream: Class_n_Stream,
                state: State,
                centre: Center,
                dateOfExam: Exam_Date,
            } = fieldErrors

            // web-analytics
            window.webengage.track('iACST_3_Exam_Details_Error', {
                'utm parameters': utmParams,
                method: userMethod.isPsid ? 'psid' : 'mobile',
                'mobile number': userInfo.mobileNumber,
                error_fields: {
                    Board,
                    Class_n_Stream,
                    State,
                    Center,
                    Exam_Date,
                },
            })
        }
    }

    const handleDobOnBlur = (userSelectedDob, event = 'select') => {
        if (userSelectedDob === '' || userSelectedDob === null)
            setErrorMsg({ ...errorMsg, dob: 'Please select date of birth' })
    }

    const getSelectDefaultValue = (value, array) => {
        if (value === '') return false
        const isPresent = array.find(
            (a) =>
                a.value.toString().toLowerCase() ===
                value?.value.toString().toLowerCase(),
        )

        if (isPresent) return isPresent
        return false
    }

    return (
        <div>
            <InputField
                required
                placeholder="Student name"
                name="studentName"
                validation="name"
                classNames="px-3"
                messages={{
                    required: 'Please enter student name',
                    studentName: errorMsg.studentName,
                }}
                type="text"
                value={userInfo.studentName}
                onChange={handleFormDataChange}
                showErrorOn="blur"
                onErrorUpdate={handleUpdateErrors}
                disabled={!isNewUser}
            />

            <InputField
                required
                placeholder="Father name"
                name="fatherName"
                validation="name"
                classNames="px-3"
                messages={{
                    required: 'Please enter father name',
                    studentName: errorMsg.fatherName,
                }}
                type="text"
                value={userInfo.fatherName}
                onChange={handleFormDataChange}
                showErrorOn="blur"
                onErrorUpdate={handleUpdateErrors}
                disabled={!isNewUser}
            />

            <InputField
                required
                placeholder="Email ID"
                name="email"
                validation="email"
                classNames="px-3"
                messages={{
                    required: 'Please enter email',
                    studentName: errorMsg.email,
                }}
                type="text"
                value={userInfo.email}
                onChange={handleFormDataChange}
                showErrorOn="blur"
                onErrorUpdate={handleUpdateErrors}
                disabled={!isNewUser}
            />

            <DatePickerField
                value={userInfo.dob}
                onChange={handleDobChange}
                onBlur={handleDobOnBlur}
                placeholder="Enter Date of birth (DD/MM/YYYY)"
                errorMsg={errorMsg.dob}
                disabled={!isNewUser}
            />

            <SelectCreatable
                required
                placeholder="School Board"
                name="schoolBoard"
                options={dropdownOptions.schoolBoard}
                value={getSelectDefaultValue(
                    examInfo.schoolBoard,
                    dropdownOptions.schoolBoard,
                )}
                onChange={handleOnChange}
                onErrorUpdate={handleExamErrors}
                messages={{
                    required: 'Please select your Board',
                }}
                isSearchable={false}
                className="mb-3 px-3"
                outerDivStyles={`w-100 font-size-sm rounded box-shadow-none ${
                    errors.schoolBoard ? `border-0` : `border border-grey-100`
                }`}
                placeholderStyles={{ color: '#545454' }}
                borderStyles={`2px solid #00b0f5 !important`}
            />

            <SelectCreatable
                required
                placeholder="Class & stream"
                name="classStream"
                options={dropdownOptions.classStream}
                value={getSelectDefaultValue(
                    examInfo.classStream,
                    dropdownOptions.classStream,
                )}
                onChange={handleOnChange}
                onErrorUpdate={handleExamErrors}
                messages={{
                    required: 'Please select class & stream',
                }}
                isSearchable={false}
                className="mb-3 px-3"
                outerDivStyles={`w-100 font-size-sm rounded box-shadow-none ${
                    errors.classStream ? `border-0` : `border border-grey-100`
                }`}
                placeholderStyles={{ color: '#545454' }}
                borderStyles={`2px solid #00b0f5 !important`}
            />

            {isExamDetailsFormVisible && (
                <StepThreeExamInfo
                    userInfo={userInfo}
                    userMethod={userMethod}
                    isUserInfoValid={isFormValid}
                    setSignupSuccessDetails={setSignupSuccessDetails}
                    examInfo={examInfo}
                    setExamInfo={setExamInfo}
                    dropdownOptions={dropdownOptions}
                    setDropdownOptions={setDropdownOptions}
                    errors={errors}
                    handleOnChange={handleOnChange}
                    handleUpdateErrors={handleExamErrors}
                    getSelectDefaultValue={getSelectDefaultValue}
                    registration_page={registration_page}
                    handleErrorsOnChangeCoursType={handleErrorsOnChangeCoursType}
                />
            )}
        </div>
    )
}

export default StepTwoUserInfo
