import { useState, useEffect } from 'react'
import { iacstResult } from 'api/api'
import { getUrlSearchParams } from 'components/Result/helper'

function useResultData() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [error, setError] = useState()
    const [dataFailureMsg, setDataFailureMsg] = useState()

    useEffect(() => {
        setLoading(true)
        const params = getUrlSearchParams()
        iacstResult(params)
            .then((res) => res.data)
            .then((res) => {
                setLoading(false)
                setData(res.data)
                setDataFailureMsg(res.msg)
            })
            .catch((err) => {
                if (err) {
                    setError(
                        err.message ||
                            err.response.message ||
                            'Something went wrong!',
                    )
                }
            })
    }, [])

    return { loading, data, error, dataFailureMsg }
}

export default useResultData
