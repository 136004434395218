import { Card, CardBody } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'

const Byjus_Advantage = ({ profileData }) => {
    const isSmallDevice = useMediaQuery({ query: '(max-width: 767px)' })
    const videoObject = {
        heading: {
            banner_heading: "Aakash Advantage",
        },
        SOE: {
            banner_video_url: 'https://www.youtube.com/embed/s1yOlixm34Y',
        },
        SOF: {
            banner_video_url: 'https://www.youtube.com/embed/e_ByZJ_5TEI',
        },
        SOM: {
            banner_video_url: 'https://www.youtube.com/embed/pA74TdYdc9w',
        },
    }

    window.setInterval(trackClick, 100)
    function trackClick() {
        if (document.activeElement === document.querySelector('#anthe-video')) {
            window.webengage.track('iACST_db_video', {
                'mobile number': profileData.mobile_number,
                class: profileData.class && profileData.class.toString(),
                stream: profileData.stream,
                branchcode: profileData.exam_center_id,
            })
            window.focus()
        }
    }

    return (
        <>
            <div className='d-md-none'>
                <h3 className="font-size-lg text-grey-black mx-1 mb-3">
                    {videoObject?.heading?.banner_heading}
                </h3>
            </div>
            <Card
                className="border-0"
                style={{
                    width: '100%',
                    height: '100%',
                    filter: isSmallDevice ? 'drop-shadow(0px 4px 4px rgba(151, 151, 151, 0.2))' : '',
                }}
            >
                <CardBody className="p-3">
                    <div className='d-none d-md-block'>
                        <h3 className="font-size-lg text-grey-black">
                            {videoObject?.heading?.banner_heading}
                        </h3>
                    </div>

                    {videoObject[profileData.stream] &&
                        videoObject[profileData.stream]?.banner_video_url ? (
                        <iframe
                            title="ANTHE banner video"
                            width="100%"
                            height="242px"
                            src={(videoObject[
                                profileData.stream
                            ]?.banner_video_url).replace('watch?v=', 'embed/')}
                            id="anthe-video"
                        ></iframe>
                    ) : (
                        <iframe
                            title="ANTHE banner video"
                            width="100%"
                            height="232px"
                            src={(videoObject['SOE']?.banner_video_url).replace(
                                'watch?v=',
                                'embed/',
                            )}
                            id="anthe-video"
                        ></iframe>
                    )}
                </CardBody>
            </Card>
        </>
    )
}
export default Byjus_Advantage
