import fomoGroup from '../../assets/fomo-group.png'
const Fomo = ({iacstFomoDesc}) => {
    return (
        <div className="d-flex align-items-center bg-secondary p-2">
            <img src={fomoGroup} alt="fomogroup" width={80}/>
            <span className='font-size-sm-md ms-2 fw-lighter text-white font-size-xs'>
                {iacstFomoDesc}
            </span>
        </div>
    )
}

export default Fomo
