import { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import backArrow from '../../assets/backArrow.png'
import CheckOutForm from './CheckOutForm'
import FixedBottomPrice from './FixedBottomPrice'
import GuidedJourney from './GuidedJourney'
import RegistartionSteps from 'components/RegistrationSteps/RegistartionSteps'
import { connect } from 'react-redux'
import ConvertHTMLString from 'components/shared/ConvertHTMLString'
import { useMediaQuery } from 'react-responsive'
import fomoGroup from '../../assets/fomo-group.png'
import DownloadIcon from 'assets/DownloadIcon'
import {
    SET_ECOMMERCE_FAILED,
    SET_VISIBLE_COURSE_DESC,
    SET_VISIBLE_COURSE_REGISTER,
} from 'constants/actionTypes'

const mapStateToProps = (state) => ({
    profileData: state?.profileData,
    dashboardData: state?.dashboardData,
    ecommerce: state?.ecommerce,
})

const mapDispatchToProps = (dispatch) => ({
    setecomerceFailed: (payload) => {
        dispatch({ type: SET_ECOMMERCE_FAILED, payload })
    },
    setCourseRegisterVisible: (payload) => {
        dispatch({ type: SET_VISIBLE_COURSE_REGISTER, payload })
    },
    setCourseDescVisible: (payload) => {
        dispatch({ type: SET_VISIBLE_COURSE_DESC, payload })
    },
})

const CourseDescription = (props) => {
    const [isSticky, setIsSticky] = useState({
        withJourney: false,
        withOutJourney: false,
    })

    const isSmallDevice = useMediaQuery({ query: '(max-width: 992px)' })

    useEffect(() => {
        const handleScroll = () => {
            if (
                !props?.ecommerce?.courseFlagsData?.course_purchased &&
                ((!isSmallDevice && window.scrollY > 100) ||
                    (isSmallDevice && window.scrollY > 90))
            ) {
                setIsSticky({
                    withOutJourney: false,
                    withJourney: true,
                })
            } else if (
                props?.ecommerce?.courseFlagsData?.course_purchased &&
                ((!isSmallDevice && window.scrollY > 160) ||
                    (isSmallDevice && window.scrollY > 165))
            ) {
                setIsSticky({
                    withJourney: false,
                    withOutJourney: true,
                })
            } else {
                setIsSticky({
                    withJourney: false,
                    withOutJourney: false,
                })
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const {
        mobile_number,
        roll_number,
        student_dob,
        student_psid,
        exam_type,
        class: division,
        classtream,
        stream,
        branch_code,
    } = props?.profileData

    const {
        product_title,
        product_id,
        product_course_term,
        course_highlights,
        course_description,
        course_feature,
        course_content_cta,
    } = props?.ecommerce.finalCourseDetailsPayload

    const { scholarship_msg, overall_percentage, scholarship_percentage } =
        props?.dashboardData

    const handleCheckoutForm = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 10)

        props.setCourseRegisterVisible(!props.ecommerce.courseRegisterVisible)

        window.webengage.track('iACST_db_pr_provisional_fee_proceed_to_payment', {
            'mobile number': mobile_number,
            'roll number': roll_number,
            psid: student_psid ? student_psid : 'NA',
            exam_type: exam_type,
            class_studying: division.toString(),
            class_opted: classtream.split('&')[0],
            stream: stream,
            center_code: branch_code,
            course_name: product_title,
            course_id: product_id,
            course_term: product_course_term,
            scholarship_waiver_message: scholarship_msg,
            score_percent: overall_percentage,
            scholarship_percentage: scholarship_percentage,
            dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
        })
    }

    const handleBack = () => {
        props.setCourseDescVisible(!props.ecommerce.courseDescVisible)
    }

    const downloadCourseContent = () => {
        fetch(course_content_cta, {
            method: 'GET',
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download = 'course_detail.pdf'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                // window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error downloading the PDF:', error)
            })
    }

    return (
        <div>
            {!props?.ecommerce.ecommerceFailed &&
            props?.ecommerce.courseDescVisible &&
            !props?.ecommerce.courseRegisterVisible ? (
                <div className="bg-white">
                    <RegistartionSteps />
                    <GuidedJourney disableRegister={true} isSticky={isSticky} />
                    <div className={`${isSmallDevice ? 'bg-white' : 'bg-light'}`}>
                        <div className="mx-lg-4">
                            <Row className="g-0">
                                <div
                                    className={`d-flex align-items-start p-lg-3 bg-white my-3 px-4 ${
                                        isSticky.withJourney &&
                                        (isSmallDevice
                                            ? 'bg-white shadow custom-course-title-header'
                                            : 'bg-light custom-course-title-header')
                                    } ${
                                        isSticky.withOutJourney &&
                                        (isSmallDevice
                                            ? 'bg-white shadow custom-course-title-without-journey-header'
                                            : 'bg-light custom-course-title-without-journey-header')
                                    }`}
                                >
                                    <div onClick={handleBack} className="cu-pointer">
                                        <img src={backArrow} alt="back" />
                                    </div>
                                    <span
                                        className={`font-size-md-lg text-align-center ms-4 fw-700 ${
                                            (isSticky.withJourney ||
                                                isSticky.withOutJourney) &&
                                            'custom-course-title'
                                        }`}
                                    >
                                        {product_title}
                                    </span>
                                </div>
                                {/* <div className="d-sm-none d-flex  bg-beige align-items-center mb-3">
                                    <div>
                                        <img src={fomoGroup} alt="fomo" width={80} />
                                    </div>
                                    <p className="mt-3 fw-500 font-sixe-xs">
                                        {
                                            props.courseFlagsData
                                                .iacst_total_course_purchased_count
                                        }
                                    </p>
                                </div> */}
                                <Col lg={8}>
                                    <div className="content-container">
                                        <div
                                            className={`${isSmallDevice && 'mx-3'}`}
                                        >
                                            {/* Course Highlight */}

                                            <div className="custom-course-highlights">
                                                <ConvertHTMLString
                                                    content={course_highlights}
                                                />
                                            </div>
                                        </div>
                                        <div className="px-lg-3 py-lg-1 p-md-3 bg-white shadow-md">
                                            <div className="custom-course-description mb-2">
                                                <ConvertHTMLString
                                                    content={course_description}
                                                />
                                            </div>

                                            {/* Course Feature */}
                                            <div className="p-lg-2 p-3 ">
                                                <h3 className="font-size-lg fw-700 mb-3">
                                                    COURSE FEATURES
                                                </h3>
                                                <div className="custom-course-feature-container ">
                                                    {course_feature.map(
                                                        (item, key) => (
                                                            <div className="custom-course-feature">
                                                                {/* <CheckSignIcon /> */}
                                                                <ConvertHTMLString
                                                                    content={item}
                                                                    key={key}
                                                                    className="ms-2 course-element"
                                                                />
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                            {course_content_cta ? (
                                                <div className="p-3 mb-lg-3 rounded-lg bg-aqua-40">
                                                    <p className="mb-0 text-aqua-60 font-size-md font-size-md-lg fw-700">
                                                        Course Content
                                                    </p>
                                                    <div
                                                        className={`${
                                                            !isSmallDevice
                                                                ? 'd-flex align-items-center'
                                                                : ''
                                                        }`}
                                                    >
                                                        <p
                                                            className={`mt-2 text-aqua-60 font-size-xs font-size-md-md lh-sm fw-normal ${
                                                                !isSmallDevice
                                                                    ? 'w-75'
                                                                    : ''
                                                            } `}
                                                        >
                                                            Download the course
                                                            content to access the
                                                            comprehensive test
                                                            schedule, syllabus, and
                                                            detailed information.
                                                        </p>
                                                        <div
                                                            className={`${
                                                                !isSmallDevice
                                                                    ? 'flex-grow-1'
                                                                    : 'd-grid'
                                                            }`}
                                                        >
                                                            <button
                                                                type="button"
                                                                className="btn border-aqua bg-aqua font-size-sm font-size-md-mds fw-lighter text-white px-4 custom-course-content-cta"
                                                                onClick={
                                                                    downloadCourseContent
                                                                }
                                                            >
                                                                Course details
                                                                <span className="ms-2">
                                                                    <DownloadIcon
                                                                        fill={
                                                                            'white'
                                                                        }
                                                                    />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="ms-md-4">
                                        <FixedBottomPrice
                                            handleCheckout={handleCheckoutForm}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            ) : (
                <CheckOutForm
                // courseViewAndRegister={props.courseViewAndRegister}
                // isVisibleCheckoutForm={isVisibleCheckoutForm}
                // handleBack={handleBack}
                // isSmallDevice={isSmallDevice}
                />
            )}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDescription)
