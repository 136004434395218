import Icon from '../components/shared/IconBase'

const AlertIcon = (props) => (
    <Icon width="125" height="125" viewBox="0 0 125 125"  fill="none" {...props}>
            <g
                opacity="0.9"
                clipPath="url(#clip0_1908_24539)"
            >
                <mask
                    id="mask0_1908_24539"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="125"
                    height="125"
                >
                    <path
                        d="M124.5 0.487305H0.5V124.487H124.5V0.487305Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_1908_24539)">
                    <g opacity="0.6">
                        <path
                            d="M62.4991 15.3584C88.5276 15.3584 109.628 36.4585 109.628 62.4869C109.628 88.5154 88.5276 109.615 62.4991 109.615C36.4707 109.615 15.3706 88.5154 15.3706 62.4869C15.3706 36.4585 36.4707 15.3584 62.4991 15.3584Z"
                            fill="#FFA0A0"
                        />
                    </g>
                    <path
                        d="M62.5001 23.9277C83.7961 23.9277 101.06 41.1915 101.06 62.4874C101.06 83.7834 83.7961 101.047 62.5001 101.047C41.2042 101.047 23.9404 83.7834 23.9404 62.4874C23.9404 41.1915 41.2042 23.9277 62.5001 23.9277Z"
                        fill="#F23434"
                    />
                    <path
                        d="M58.5215 81.3059C58.5215 83.503 60.3024 85.284 62.4995 85.284C64.6966 85.284 66.4776 83.503 66.4776 81.3059H58.5215ZM66.4776 43.6675C66.4776 41.4704 64.6966 39.6895 62.4995 39.6895C60.3024 39.6895 58.5215 41.4704 58.5215 43.6675H66.4776ZM58.5215 67.2298C58.5215 69.4269 60.3024 71.2078 62.4995 71.2078C64.6966 71.2078 66.4776 69.4269 66.4776 67.2298H58.5215ZM66.4776 80.0819C66.4776 77.8848 64.6966 76.1039 62.4995 76.1039C60.3024 76.1039 58.5215 77.8848 58.5215 80.0819H66.4776ZM66.4776 67.2298V43.6675H58.5215V67.2298H66.4776ZM66.4776 81.3059V80.0819H58.5215V81.3059H66.4776Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1908_24539">
                    <rect
                        width="124"
                        height="124"
                        fill="white"
                        transform="translate(0.5 0.487305)"
                    />
                </clipPath>
            </defs>
    </Icon>
)

export default AlertIcon