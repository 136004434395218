import { useHistory } from 'react-router-dom'

import AakashLogo from '../../assets/aakash-byjus-logo.svg'
import { CUSTOMER_SUPPORT } from 'components/Result/constants'

function NotWantToConduct() {
    const history = useHistory()

    return (
        <div className="vh-100 vh-md-80 m-auto text-center d-flex flex-column justify-content-center align-items-center">
            <div
                className="rounded shadow-md"
                style={{ padding: '44px 25px', margin: '1.5rem' }}
            >
                <img src={AakashLogo} alt="aakash-logo" width={120} height={120} />

                <p className="fw-lighter font-size-md mt-4">
                    Aakash wishes you best!
                </p>

                <p className="font-size-sm w-7 text-grey text-center m-auto">
                    For any queries call{' '}
                    <a
                        href={`tel:${CUSTOMER_SUPPORT}`}
                        className={`font-size-xs text-aqua text-decoration-none`}
                    >
                        {CUSTOMER_SUPPORT}
                    </a>{' '}
                    or visit our website
                </p>
            </div>

            <button
                className={`btn btn-aqua text-white w-9 w-md-5 w-lg-3 font-size-sm fw-lighter rounded-10px mt-5`}
                style={{ padding: '11px' }}
                onClick={() => {
                    history.replace('/')
                }}
            >
                Go to Dashboard
            </button>
        </div>
    )
}

export default NotWantToConduct
