export const banner_title = [
    {
        top_head: '(Aakash Scholarship Test)',
        head_title: 'Up to 90% Total Scholarship',
        sub_head_title: 'NEET, JEE & Foundation Courses',
        class_applicable: 'For 8<sup>th</sup> to 12<sup>th</sup> studying & 12<sup>th</sup> Passed students',
        type: '',
        registration: 'REGISTRATION OPEN',
    },
]

export const aboutCard = [
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/scholarship-test-details.png',
        cardbodyitem:
            '<p>Take the test at a date and time of your choice </br><b>Timings </b>: 9AM to 10PM Daily | <b>Duration</b> : 60&nbsp;mins</br><b>Mode </b>: Online</p><p >A student is allowed a maximum of two attempts in a year at the iACST</p><p>&nbsp;</p>',
    },
    {
        headingImage:
            'https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/State%20Boards%20Regional%20CETs.png',
        cardbodyitem:
            ' <div> Also applicable on separate batches for State Boards &amp; State-wise Common Entrance Tests (CET)<br /><b>State Board</b> : UP Board | Rajasthan Board | Gujarat Board | MP Board | Karnataka Board |&nbsp; Bihar Board |&nbsp; Jharkhand Board | Tamil Nadu Board <br /> <b>CETs</b> : Karnataka CET | MHT CET | KEAM (Kerala) | EAMCET (Andhra Pradesh and Telangana) </div>',
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/how_can_appear.png',
        cardbodyitem:
            " <p> <b>Class 8th to 12th</b> studying students <br /> <b>Class 12th passed</b> students <br /> <span style={{ fontSize: '14px' }}> (Students moving from <b>Class 7th to 8th</b> are also eligible) </span> </p>",
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/avail-scholarship.png',
        cardbodyitem:
            "<p className='integrated'>1, 2, 3 & 4-Year Integrated Classroom & Online Courses<br/> for NEET, JEE and Foundations <br /></p>",
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/how-to-take.png',
        cardbodyitem:
            '<ul><li>Verify your mobile number with OTP and complete the registration </li><li> Get your login details, roll number and select an exam date &amp; time slot </li> <li>Take the Aakash iACST online</li></ul>',
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/test-highlight.png',
        cardbodyitem:
            ' <ul><li>Completely online ensuring the safety and convenience of all candidates </li> <li>Flexible timings - students can choose and select any time slot between 9AM to 10PM </li> <li>Conducted everyday so that students can schedule the test as per their convenience</li></ul>',
    },
]

export const scholarships = [
    {
        targetId: '1',
        heading: 'Aakash Medical',
        subheading: ' For Class 9th to 12th Studying & Class 12th Passed Students',
        bulletpoints: [
            {
                point: 'Two-Year Integrated Classroom Course for NEET (For Class 10th Studying Students moving to Class 11th)',
            },
            {
                point: 'One-Year Integrated Classroom Course for NEET (For Class 11th Studying Students moving to Class 12th)',
            },
            {
                point: 'Regular Classroom Course for NEET (For Class 12th Passed Students)',
            },
            {
                point: 'Integrated Classroom Course for Class X to XII, NEET, Olympiads & NTSE',
            },
            {
                point: 'Integrated Classroom Course for Class IX to XII, NEET, Olympiads & NTSE',
            },
            {
                point: '1, 2, 3 & 4-Year Aakash Digital Live Online Course for NEET',
            },
        ],
        table: [
            {
                qus: 'Coverage and revision of Class 11th and Class 12th syllabus',
                ans: 'Test Series Course for NEET preparation',
            },
            {
                qus: 'Thorough preparation for school/board exams',
                ans: 'Question Bank for NEET',
            },
            {
                qus: 'Regular tests on school/board as well as NEET pattern',
                ans: 'NEET Challenger App',
            },
            {
                qus: 'Extensive and in-depth study material',
                ans: 'iTutor Lab Facility',
            },
            {
                qus: 'NCERT Maps for quick and thorough revision of NCERT',
                ans: 'Books for Crash Course & Repeater Course',
            },
        ],
    },
    {
        targetId: '2',
        heading: 'Aakash Engineering',
        subheading: 'For Class 9th to 12th Studying & Class 12th Passed Students',
        bulletpoints: [
            {
                point: 'Two-Year Integrated Classroom Course for JEE - Main & Advanced (For Class 10th Studying Students moving to Class 11th)',
            },
            {
                point: 'One-Year Integrated Classroom Course for JEE Main & Advanced (For Class 11th Studying Students moving to Class 12th)',
            },
            {
                point: 'Power Step Integrated Classroom Course for JEE Main & Advanced (For Class 12th Passed Students)',
            },
            {
                point: 'Integrated Classroom Course for Class X to XII, JEE(Main & Advanced), Olympiads & NTSE',
            },
            {
                point: 'Integrated Classroom course for Class IX to XII, JEE(Main & Advanced), Olympiads & NTSE',
            },
            {
                point: '1, 2, 3 & 4-Year Aakash Digital Live Online Course for JEE',
            },
        ],
        table: [
            {
                qus: 'Coverage and revision of Class 11th and Class 12th syllabus',
                ans: 'Final Test Series',
            },
            {
                qus: 'Thorough preparation for school/board exams',
                ans: 'Success Achiever Question Bank for JEE Main',
            },
            {
                qus: 'Regular tests on school/board as well as JEE pattern',
                ans: 'Success Magnet Question Bank for JEE',
            },
            {
                qus: 'Extensive and in-depth study material',
                ans: 'Archive for JEE Main and Advanced',
            },
            {
                qus: 'NCERT Maps for quick and thorough revision of NCERT',
                ans: 'iTutor Lab Facility',
            },
        ],
    },
    {
        targetId: '3',
        heading: 'Aakash Foundations courses ',
        subheading: 'For Class 8th, Class 9th & Class 10th',
        bulletpoints: [
            {
                point: 'One-Year Integrated Classroom Course for Olympiads & Class VIII (For Class 7th Studying Students moving to Class 8th)',
            },
            {
                point: 'One-Year Integrated Classroom Course for Olympiads and Class IX (For Class 8th Studying Students moving to Class 9th)',
            },
            {
                point: 'One-Year Integrated Classroom Course for Olympiads, NTSE & Class X (For Class 9th Studying Students moving to Class 10th)',
            },
            {
                point: 'Two-Year Integrated Classroom Course for Olympiads, NTSE & Class IX & X (For Class 8th Studying Students moving to Class 9th)',
            },
            {
                point: 'Three-Year Integrated Classroom Course for Olympiads, NTSE and Class VIII, IX & X (For Class 7th Studying Students moving to Class 8th)',
            },
            {
                point: '1, 2 & 3-Year Aakash Digital Live Online Course for Olympiads, NTSE and Class VIII, IX & X',
            },
        ],
        table: [
            {
                qus: 'Thorough preparation for school/board exams',
                ans: 'Micro Assignment - a special booklet of NCERT (questions for better practice)',
            },
            {
                qus: 'Class VIII, IX, X Syllabus Coverage with Revision & Doubt Clearance',
                ans: 'Fast Track & Trickopedia - unique tricks & tips to increase speed',
            },
            {
                qus: 'Special Classes for NTSE preparation for Class X',
                ans: 'Special classes & test series for Olympiad/scholarship exams',
            },
            {
                qus: 'Extensive and in-depth study material',
                ans: 'Mental ability preparation',
            },
            {
                qus: 'Regular tests on school /board, Olympiad, NTSE pattern',
                ans: 'iTutor Lab Facility',
            },
        ],
    },
]

export const scholarshipDetails = [
    {
        scholarshipData: [
            {
                scholarshipDataTitle: 'About Aakash',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            "With a legacy of over 35 years, Aakash Educational Services Limited (AESL) has pioneered the competitive exam preparation. Popularly known as Aakash, we have been at the forefront of preparing students to achieve their goals of cracking medical and engineering entrance exams. With our expert faculty and unmatched study material Aakash produces top rankers in NEET, IIT-JEE, Olympiad NTSE, KVPY, 10th and 12th exams every year. From foundation to advanced courses, at Aakash there are learning modules meticulously crafted for every student's learning needs. To recognize and reward the passionate and talented students across the nation, Aakash offers various scholarship tests. Through these scholarship tests students can secure a scholarship up to 100% and get admission in a course of their choice to kick-start their journey. These scholarship tests are conducted via multiple mediums: app or website based online exam from home and offline at Aakash regional branches. In addition to this, a blend of classroom and online learning courses such as Aakash Hybrid mode is designed to ensure seamless experience for all our students. To know more about the courses, you can fill a simple form and submit your details on our website and our team will get in touch with you. You can also visit or call your nearest Aakash branch and we would be happy to guide and assist you.",
                    },
                ],
            },
            {
                scholarshipDataTitle: 'NEET Coaching @ Aakash',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            "For more than three decades, Aakash has been the top choice when it comes to a NEET coaching centre. Of all the neet coaching centres in India, Aakash has consistently held the title of the best NEET coaching centre. With branches all across the country and online courses, Aakash NEET coaching centres are now within the reach of NEET aspirants in every part of India. With multiple course options, Aakash provides test preparation tailored to every student's needs. From scholarships for NEET coaching fees to expert faculty, Aakash aims to help students at every step of their NEET journey.",
                    },
                ],
            },
            {
                scholarshipDataTitle: 'JEE Coaching @ Aakash',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'Every year, Aakash produces top rankers elevating its status as the best coaching centre for JEE. We provide the students much-needed push to gain an edge in their jee coaching. No wonder, Aakash has emerged as the best IIT JEE coaching centre and continues to stay a trusted name in IIT JEE coaching domain. For students and parents looking for the best IIT coaching centre, getting in touch with Aakash will answer all their questions. We offer various scholarships to enable students to get admission in JEE coaching courses at Aakash - the best institute for jee coaching.',
                    },
                ],
            },
            {
                scholarshipDataTitle: 'Foundation Coaching @ Aakash',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'Aakash Foundation courses offer best quality education by highly qualified, experienced and well-trained faculty that not only help you pass school/board exams with flying colours but also crack competitive scholarship exams and Olympiads held at national as well as international level.',
                    },
                ],
            },
            {
                scholarshipDataTitle:
                    'What makes Foundation @Aakash the smartest choice?',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                        `<ul style="margin-bottom:20px;
                        padding-left: 0px;
                        ">
                        <li style="line-height: 30px!important;text-align: left;list-style: disc;font-size: 14px;font-family: 'Poppins', sans-serif;margin-left: 15px;">Nurtures you with personalized attention in a competitive atmosphere</li>
                        <li style="
                                line-height: 30px!important;
                                text-align: left;
                                list-style: disc;
                                font-size: 14px;
                                font-family: 'Poppins', sans-serif;
                                margin-left: 15px;
                            ">Improves your performance in school &amp; board examinations</li>
                        <li style="
                                line-height: 30px!important;
                                text-align: left;
                                list-style: disc;
                                font-size: 14px;
                                font-family: 'Poppins', sans-serif;
                                margin-left: 15px;
                            ">Builds your analytical &amp; reasoning skills to succeed in Olympiads &amp; Scholarship examinations such as NTSE</li>
                        <li style="
                                line-height: 30px!important;
                                text-align: left;
                                list-style: disc;
                                font-size: 14px;
                                font-family: 'Poppins', sans-serif;
                                margin-left: 15px;
                            ">Lays the strong groundwork for entrance examinations such as NEET, JEE, UPSC, IAS, IFS, CAT, MAT, etc.</li>
                        <li style="
                                line-height: 30px!important;
                                text-align: left;
                                list-style: disc;
                                font-size: 14px;
                                font-family: 'Poppins', sans-serif;
                                margin-left: 15px;
                            ">Introduces you to innovative learning methods to make learning fun, engaging and easy</li>
                        <li style="
                                line-height: 30px!important;
                                text-align: left;
                                list-style: disc;
                                font-size: 14px;
                                font-family: 'Poppins', sans-serif;
                                margin-left: 15px;
                            ">Focuses on conceptual and application-based learning, thereby debunking old rote-learning methods</li>
                        <li style="
                                line-height: 30px!important;
                                text-align: left;
                                list-style: disc;
                                font-size: 14px;
                                font-family: 'Poppins', sans-serif;
                                margin-left: 15px;
                            ">Indulges in more than just academic syllabi</li>
                        <li style="
                                line-height: 30px!important;
                                text-align: left;
                                list-style: disc;
                                font-size: 14px;
                                font-family: 'Poppins', sans-serif;
                                margin-left: 15px;
                            ">Offers you competitive exposure through national level test series that enables you to know your potential at all India level</li>
                    </ul>`
                    },
                ],
            },
        ],
    },
]
