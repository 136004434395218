import { useEffect } from 'react'
import { ReactComponent as SuccessConfetti } from '../../assets/successConfetti.svg'
import Branchmapsection from 'components/branchDetails/branch_map_section'

const ECommerceSuccessful = (props) => {
    useEffect(() => {
        document.body.style.setProperty('background', 'white', 'important');
        return () => {
            document.body.style.backgroundColor = 'light'; 
        };
    }, []);
    return (
        <div className='bg-light p-lg-4'>
            <div className="m-auto col-lg-4 col-sm-6 pt-4 pb-3 bg-white p-4" >
                <div className="text-center">
                    <SuccessConfetti />
                    <p className="fw-lighter font-size-24 font-size-md-36 m-0 text-dark-green">
                        Payment Successful!
                    </p>
                    <p className="font-size-sm font-size-md-md fw-500">
                        Order ID: {localStorage.getItem('iacst_order_id')}
                    </p>
                </div>

                <p className="m-auto text-center font-size-sm font-size-md-md fw-500 ">
                    A confirmation message will be sent to your registered mobile
                    number and email ID.
                </p>
                {props?.profileData?.branch_code != "IC006" &&
                    <>
                        <div>
                            <p className="mx-5 mx-lg-0  text-Yellow-70 font-size-sm font-size-md-mds fw-lighter text-center m-auto py-3 ">
                                Visit the branch for academic counselling and batch
                                information
                            </p>
                        </div>
                        <div className='mx-auto' >
                            <Branchmapsection
                                token={props.token}
                                profileData={props.profileData}
                                NearestBranch={true}
                            />
                        </div>
                    </>
                }

                <p className="text-center mt-2">
                    <a target="_self" href="/dashboard" className="font-size-md font-size-md-lg fw-lighter underline text-aqua">
                        Continue to dashboard
                    </a>
                </p>
            </div>
            </div>
    )
}

export default ECommerceSuccessful
