import { isSafari } from 'components/Result/helper'

const getURLParameters = (url) => {
    let params = {}

    if (isSafari()) {
        // 3.2-10 Safari versions doesn't support URLSearchParams()
        const queryParamsArray = url.split(/&|=/)
        params = extractQueryParams(queryParamsArray)
    } else {
        const urlSearchParams = new URLSearchParams(url)

        for (const [key, value] of urlSearchParams.entries()) {
            params[key] = value
        }
    }

    return params
}

const filterParamsStartingWithUtm = (params) => {
    const utmParams = {}

    for (const key in params) {
        const keyInLowerCase = key.toLowerCase()
        if (keyInLowerCase.startsWith('utm')) {
            utmParams[key] = params[key]
        }
    }

    return utmParams
}

const extractQueryParams = (queryParamsArray) => {
    const params = {}

    for (let i = 0; i < queryParamsArray.length; i += 2) {
        params[queryParamsArray[i]] = queryParamsArray[i + 1]
    }

    return params
}

export { getURLParameters, filterParamsStartingWithUtm, extractQueryParams }
