import {
    scholarships,
    banner_title,
    aboutCard,
    scholarshipDetails,
} from '../../constants/mainScholarshipContentJson'
import { Helmet } from 'react-helmet'
import IacstLandingPageTemplate from 'utils/iACSTLandingTemplate/IacstLandingPageTemplate'

const Signup = () => {
    const registration_page = 'default'

    return (
        <>
            <Helmet>
                <title>
                   Aakash iACST: Online Instant Admission Cum Scholarship Test
                </title>
                <meta
                    name="description"
                    content="Repeater Courses for JEE Droppers: Aakash iACST - Aakash Instant Scholarship Test is a national level online scholarship test for Class XII Passed students where they instantly get scholarships upto 90% on tuition fee and get admission into Aakash."
                />
            </Helmet>
            <IacstLandingPageTemplate
                banner_title={banner_title}
                registration_page={registration_page}
                aboutCard={aboutCard}
                scholarships={scholarships}
                scholarshipDetails={scholarshipDetails}
            />
        </>
    )
}

export default Signup
