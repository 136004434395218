import { SET_EXAM_INFO_API_DATA } from '../constants/actionTypes'

const setExamInfo = (state = {}, action) => {
    switch (action.type) {
        case SET_EXAM_INFO_API_DATA:
            return action.payload
        default:
            return state
    }
}
export default setExamInfo
