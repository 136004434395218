import Icon from '../components/shared/IconBase'

const WarningIcon = (props) => (
    <Icon width="17" height="16" viewBox="0 0 17 16" {...props}>
        <path
            d="M8.50001 14.6668C12.1819 14.6668 15.1667 11.6821 15.1667 8.00016C15.1667 4.31826 12.1819 1.3335 8.50001 1.3335C4.81811 1.3335 1.83334 4.31826 1.83334 8.00016C1.83334 11.6821 4.81811 14.6668 8.50001 14.6668Z"
            stroke="#705010"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.5 10.6667V8"
            stroke="#705010"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.5 5.3335H8.50667"
            stroke="#705010"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

export default WarningIcon
