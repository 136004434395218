import { MAX_NAME_LENGTH, MIN_NAME_LENGTH } from 'components/Result/constants'
import isPhoneNumber, { COUNTRY_ISO_CODES } from './isPhoneNumber'

export const VALIDATION_FNS = {
    phone({ value }) {
        if (
            !isPhoneNumber({
                phone: value,
                countryIsoCode: COUNTRY_ISO_CODES.INDIA,
            })
        )
            return errorMessages.INVALID_PHONE
        return false
    },

    email({ value }) {
        /** Email validations:
         * 1) Must contains an "@" symbol.
         * 2) No consecutive dots, spaces and "@"
         * 3) No Consecutive domain extension.
         * 4) Do not allow invalid special Characters like Space (" "),
         * Double quotes (""), Square brackets ([]), Parentheses (()), Angle brackets (<>),
         * Comma (,), Colon (:), Semi Colon (;), Hash (#), Dollar ($),
         * Percentage(%), Caret (^), Exclamation Mark (!), AND (&), Asterisk (*)
         * 5) Allow consecutive underscores & single dot
         */

        if (!isStrongEmail(value)) return errorMessages.INVALID_EMAIL
        if (!isDomainUnique(value)) return errorMessages.INVALID_EMAIL
        return false
    },

    name({ value }) {
        /** First/Last Name Validation:
         * 1) No Special Characters
         * 2) Will only Accept Alphabets
         */
        if (value.length < MIN_NAME_LENGTH)
            return `${errorMessages.INVALID_LENGTH} ${MIN_NAME_LENGTH}`
        if (value.length > MAX_NAME_LENGTH)
            return `Maximum ${MAX_NAME_LENGTH} characters are allowed`
        if (!isAlphabeticalName(value)) return errorMessages.INVALID_STUDENT_NAME
        return false
    },

    lastName({ value }) {
        if (value && !isAlphabeticalName(value, true))
            return errorMessages.INVALID_STUDENT_NAME
        return false
    },
}

export const isStrongEmail = (email) => {
    if (!email) return false

    const regex = new RegExp(
        /^[0-9a-zA-Z_]+(?:\.[0-9a-zA-Z_]+)*@[a-zA-Z0-9]{2,}(?:\.[a-zA-Z]{2,})+$/,
        'g',
    )
    return email.match(regex)
}

export const isDomainUnique = (email) => {
    const address = email.split('@')[1]
    const domains = address.split('.')
    const uniqueDomains = new Set(domains)
    return uniqueDomains.size === domains.length
}

export function isAlphabeticalName(str, isAllowDot = false) {
    if (!str) return false

    if (isAllowDot) {
        const regex = /^[A-Za-z .]+$/
        return regex.test(str)
    }

    const regex = /^[A-Za-z ]+$/
    return regex.test(str)
}

export const errorMessages = {
    REQUIRED: 'This field is required',
    INVALID_EMAIL: 'Enter a valid email address',
    INVALID_PHONE: 'Enter a valid mobile number',
    INVALID_NUMBER: 'Enter a valid numbers',
    API_ERROR: 'Something went wrong',
    INVALID_OTP: 'Please enter a valid OTP',
    INVALID_STUDENT_NAME: 'Only alphabets are allowed',
    INVALID_LENGTH: 'Minimum length should be',
}
