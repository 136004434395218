import React from 'react'
import ANTHE_LOGO from 'assets/pdf/anthe-logo.png'
import AAKASH_LOGO from 'assets/pdf/aakash-anthe-logo.png'

import styles from './header.module.scss'

function Header() {
    return (
        <div className={styles.wrapper}>
            <img className={styles.antheLogo} src={ANTHE_LOGO} alt="anthe logo" />
            <div className={styles.title}>
                <p>aakash</p>
                <p>national</p>
                <p>talent</p>
                <p>hunt exam</p>
            </div>
            <img className={styles.aakashLogo} src={AAKASH_LOGO} alt="aakash logo" />
        </div>
    )
}

export default Header
