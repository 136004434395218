import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import ExamIllustration from '../../assets/exam-illustration.png'
import styles from './card.module.css'

function ResultAwaitedCase({ cardData, staticData, profileData }) {
    const handleCallNow = () => {
        window.webengage.track('iACST_db_call_now', {
            'support number': cardData.exam_help_line,
            'exam type': profileData.exam_type,
            center: profileData.center_name,
            class: profileData.class && profileData.class.toString(),
            stream: profileData.stream,
        })
    }

    return (
        <>
            <Card className={`${styles.mainExamCard} d-md-none`}>
                <CardHeader
                    className={`bg-primary fw-lighter font-size-sm text-white text-center ${styles.mainExamCardHeader}`}
                >
                    {cardData.card_title}
                </CardHeader>

                <CardBody className="pb-0">
                    <Row className="justify-content-between">
                        <Col sm={12} md={4}>
                            <p className="text-grey-black fw-bold font-size-lg mb-1">
                                Hey {profileData.student_firstname}!
                            </p>
                            <p className="font-size-sm">
                                Your result is being calculated.
                            </p>
                        </Col>
                        <Col className="d-flex justify-content-between">
                            <p className="font-size-sm m-0 fw-light">
                                Estimated Date
                            </p>
                            <p className="font-size-sm fw-bold">
                                {cardData.result_expected_date}
                            </p>
                        </Col>
                        <Col sm={12} md={4}>
                            <Button className="w-100 fw-bold btn-light-yellow text-warning pe-none font-size-sm">
                                {cardData.report_text}
                            </Button>
                        </Col>
                    </Row>

                    <Row className="align-bottom my-2">
                        <Col className="font-size-xs font-size-md-sm text-end align-self-center">
                            Need help?&nbsp;
                            <a
                                href={'tel:' + cardData.exam_help_line}
                                className={`text-aqua`}
                                onClick={handleCallNow}
                            >
                                <span>Call now</span>
                            </a>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <a
                href={staticData.termsLink}
                target="_blank"
                rel="noreferrer"
                className="font-size-xs text-grey d-md-none text-decoration-none"
            >
                *Terms and conditions applied
            </a>

            <Card className={`${styles.awaitingCardDesktop} d-none d-md-block`}>
                <CardHeader
                    className={`bg-primary fw-bold text-white text-center ${styles.mainExamCardHeader}`}
                >
                    {cardData.card_title}
                </CardHeader>

                <CardBody className="pb-0 h-100">
                    <Row className="align-items-center h-75">
                        <Col className={`d-none d-xl-block`}>
                            <img
                                src={ExamIllustration}
                                alt="students-degree-illustration"
                                width={328}
                            />
                        </Col>

                        <Col className="text-grey-black">
                            <p className="fw-bold font-size-lg font-size-xl-xxl mb-1">
                                Hey {profileData.student_firstname}!
                            </p>
                            <p className="font-size-sm font-size-xl-md">
                                Your result is being calculated.
                            </p>
                        </Col>

                        <Col className="ms-md-2 ms-xl-0">
                            <p className="font-size-sm font-size-xl-md m-0 fw-light">
                                Estimated Result Date
                            </p>
                            <p className="font-size-md font-size-xl-lg fw-bold">
                                {cardData.result_expected_date}
                            </p>
                        </Col>

                        <Col className="h-100 d-flex flex-column justify-content-between">
                            <div className="font-size-sm text-end">
                                Need help?&nbsp;
                                <a
                                    onClick={handleCallNow}
                                    href={'tel:' + cardData.exam_help_line}
                                    className={`font-size-sm text-aqua`}
                                >
                                    Call now
                                </a>
                            </div>

                            <Button className="w-100 fw-bold btn-light-yellow text-warning pe-none font-size-sm">
                                {cardData.report_text}
                            </Button>

                            {/* <Col className="mt-1"> */}
                            <p className="text-end m-0">
                                <a
                                    href={staticData.termsLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="font-size-xs text-grey text-decoration-none"
                                >
                                    *Terms and conditions applied
                                </a>
                            </p>
                            {/* </Col> */}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default ResultAwaitedCase
