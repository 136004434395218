import Faq from './faq'
import ByjusAdvantage from './byjus_advantage'
import { Col, Container, Row } from 'reactstrap'
import Branchmapsection from 'components/branchDetails/branch_map_section'
import CourseCard from 'components/ECommerce/CourseCard'
import { useMediaQuery } from 'react-responsive'
// import Lottie from 'lottie-react';
// import newAnimation from '../../assets/newAnimation.json'
import newAnimation from '../../assets/newAnimation.gif'


const RightsideBar = (props) => {
    const { branch_code } = props?.profileData;
    const isDigitalUser = branch_code && branch_code == "IC006" ? true : false
    const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' })
    return (
        <>
            <Container className="mt-lg-0 mw-100 ">
                <Row>
                    {props.postExam && (!isDigitalUser || (isDigitalUser && !props?.CourseData?.flags.course_purchased)) && (
                        <Col
                            sm={12}
                            lg={8}
                            className={`my-xl-3 mb-xl-2 px-lg-3 px-0 ps-3 map mb-3 rounded ${!isSmallScreen && 'bg-white'} `}
                        >
                            {
                                props?.CourseData?.flags.course_purchased ?
                                    <>
                                        <div className='mt-lg-3 mt-md-3'>
                                            <h5 className="text-start mx-2">Visit your Branch</h5>
                                            <Branchmapsection
                                                token={props.token}
                                                profileData={props.profileData}
                                                soloBranch={true}
                                            />
                                        </div>
                                    </> : <>
                                        <div id='courseCardSection'>
                                            <div className='mt-lg-3'>
                                                <div className='d-flex align-items-center' style={{ height: '30px' }}>
                                                    <h5 className='text-start mb-0 mx-2'>
                                                        Courses for you
                                                    </h5>
                                                    <div>
                                                        {/* <Lottie animationData={newAnimation} style={{ height: '100px' }} /> */}
                                                        <img src={newAnimation} alt='new Animation' width={80} />
                                                    </div>
                                                </div>

                                                <span className='mx-2 font-size-xs text-dark-grey font-size-md-sm fw-lighter text-end'>
                                                    At Aakash Institute - {props?.profileData.exam_center}
                                                </span>
                                            </div>
                                            <CourseCard
                                                profileData={props.profileData}
                                                CourseData={props?.CourseData}
                                            />
                                        </div>
                                    </>
                            }
                        </Col>
                    )}

                    <Col
                        sm={12}
                        md={7}
                        lg={4}
                        className="mb-3 my-xl-3 mb-xl-2 px-0 px-md-2 px-3 pe-md-1 iframe-height"
                    >
                        <ByjusAdvantage
                            token={props.token}
                            profileData={props.profileData}
                        />
                    </Col>
                    {(!isDigitalUser || (isDigitalUser && props?.CourseData?.flags.course_purchased > 0)) && <Col
                        sm={12}
                        lg={8}
                        className={`my-xl-3 mb-xl-2 px-lg-3 px-0 ps-3 map mb-3 rounded ${!isSmallScreen && 'bg-white'} `}
                    >
                        {
                            props?.CourseData?.flags.course_purchased ?
                                <>
                                    <div id='courseCardSection' className='pt-4 mt-2 pt-lg-0 pt-md-0'>
                                        <div>
                                            <h5 className='text-start mt-3 mb-0  mx-2'>
                                                Courses for you
                                            </h5>
                                            <span className='mx-2 font-size-xs text-dark-grey font-size-md-sm fw-lighter text-end'>
                                                At Aakash Institute - {props?.profileData.exam_center}
                                            </span>
                                        </div>
                                        <CourseCard
                                            profileData={props.profileData}
                                            CourseData={props?.CourseData}
                                        />
                                    </div>

                                </> : <>
                                    <div className='pt-4 pt-lg-0 pt-md-0'>
                                        <h5 className="text-start m-3 mt-4 mt-lg-3 mt-md-3 mx-2">Branches Near you</h5>
                                        <Branchmapsection
                                            token={props.token}
                                            profileData={props.profileData}

                                        />
                                    </div>

                                </>
                        }
                    </Col>}
                    <Col sm={12} md={7} lg={4} className="mb-3 my-xl-3 px-lg-1 px-3 ">
                        <Faq token={props.token} profileData={props.profileData} />
                    </Col>
                </Row>
                {/* {props.profileData.exam_taken == 1 && (
                <Row>  
                    <Col
                        sm={12}
                        lg={8}
                        className=" my-xl-3 mb-xl-2 px-3 map bg-white"
                    >
                        <h5 className="text-start m-3 mx-2">Branches Near you</h5>
                        <Branchmapsection
                            token={props.token}
                            profileData={props.profileData}
                        />
                    </Col>
                    <Col
                        sm={12}
                        md={7}
                        lg={4}
                        className="mb-3 my-xl-3 mb-xl-2 px-0 px-md-2 pe-md-1 iframe-height"
                    >
                        <ByjusAdvantage
                            token={props.token}
                            profileData={props.profileData}
                        />
                    </Col>
                    <Col sm={12} md={5} lg={4} className="mb-3 my-xl-3 px-0 px-lg-1">
                        <Faq token={props.token} profileData={props.profileData} />
                    </Col>
                   
                </Row>
                )} */}
            </Container>
        </>
    )
}

export default RightsideBar
