import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import styles from './card.module.css'
import Document from '../../assets/Document.svg'
import ButtonText from 'components/shared/ButtonText'
function SampleTestCard({ style, cardData, profileData, heading }) {
    return (
        <>
            <Card
                className={`${styles.mockCard} border-0 mt-xs-2 mt-md-0 w-10 h-100`}
                style={style}
            >
                <CardBody className="px-3 px-md-2 px-xl-4">
                    <p className="text-start font-size-lg fw-lighter mb-4">
                        {heading}
                    </p>
                    <Row className="justify-content-between my-2">
                        <Col xs={4} md={4} xl={3}>
                            <Button
                                className="w-100 btn-green text-secondary font-size-xxl py-3 fw-bold rounded-2"
                                style={{ padding: 'initial' }}
                            >
                                <img width={45} src={Document} alt="document icon" />
                            </Button>
                        </Col>
                        <Col xs={8} md={8} xl={9} className="p-1">
                            <p className="font-size-md m-0 fw-bold ">
                                {cardData.card_title}
                            </p>
                            <p className="font-size-xs font-size-xl-sm fw-normal mb-1">
                                {cardData.exam_end_date}
                            </p>
                            <p className="font-size-xs font-size-xl-sm fw-normal">
                                {cardData.card_description}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={cardData.view_report ? 6 : 12} className="py-1">
                            <ButtonText
                                buttonText={cardData.exam_button_text}
                                actionLink={cardData.exam_button_link}
                                cardType={'sampleCard'}
                                profileData={profileData}
                            />
                        </Col>

                        {!!cardData.view_report && (
                            <Col xs={6} className="py-0">
                                <Button
                                    color="primary"
                                    className="w-100 bg-transparent text-primary font-size-sm fw-bold"
                                    outline
                                    onClick={() => {
                                        window.open(
                                            cardData.report_link,
                                            '_blank',
                                            'noopener',
                                        )
                                        window.webengage.track(
                                            'iACST_db_sample_view_report',
                                            {
                                                'roll number':
                                                    profileData.roll_number,
                                                'exam type': profileData.exam_type,
                                                class:
                                                    profileData.class &&
                                                    profileData.class.toString(),
                                                stream: profileData.stream,
                                            },
                                        )
                                    }}
                                >
                                    View report
                                </Button>
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default SampleTestCard
