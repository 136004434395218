import { SET_ROLLNO } from '../constants/actionTypes'

const setRollNo = (state = {}, action) => {
    switch (action.type) {
        case SET_ROLLNO:
            return action.payload
        default:
            return state
    }
}
export default setRollNo
