import useGetApi from 'api/useGetApi'
import { usePutPostApi } from 'api/usePutPostApi'
import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import SelectCreatable from 'utils/SelectDropdown'
import DangerIcon from 'assets/Danger'
import CONFIG from '../../config'
import { SunspotLoader } from 'react-awesome-loaders'
import { SET_TOAST_DATA } from 'constants/actionTypes'
import { connect } from 'react-redux'
import MaskString from 'utils/MaskString'

const mapDispatchToProps = (dispatch) => ({
    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
})
function EditProfile({
    isModalOpen = false,
    toggle,
    profileData,
    token,
    setToastMsgsData,
    fetchProfileData,
}) {
    const getSelectDefaultValue = (value, array) => {
        if (value === '') return false
        return (
            array &&
            array.find(
                (a) =>
                    a.value.toString().toLowerCase() ===
                    value?.value.toString().toLowerCase(),
            )
        )
    }
    const isDisabled = false
    const isUpdateDetailsBtnEnabled = true

    const [masterData, setMasterData] = React.useState({
        boards: [],
        city: [],
        school: [],
    })
    const [isDisabledSave, setIsDisableSave] = useState(true)
    const [userSelected, setUserSelected] = React.useState({
        school_board: profileData.exam_board
            ? {
                  label: profileData.exam_board,
                  value: profileData.exam_board,
              }
            : '',
        school_city: profileData.student_city
            ? {
                  label: profileData.student_city,
                  value: profileData.student_city_value,
              }
            : '',
        school_name: profileData.school_name
            ? {
                  label: profileData.school_name,
                  value: profileData.school_id,
              }
            : '',
    })

    const displayProfileData = [
        {
            key: 'Student Name',
            value:
                profileData.student_firstname +
                ' ' +
                (profileData.student_lastname != null
                    ? profileData.student_lastname
                    : ''),
        },
        {
            key: 'Fathers name',
            value: profileData?.father_name || '',
        },
        {
            key: 'Roll number',
            value: profileData?.roll_number || '',
        },
        {
            key: 'PSID',
            value: profileData?.student_psid || '',
        },
        {
            key: 'Class & stream',
            value: (() => {
                const [classPart, streamPart] = (profileData?.classtream || '').split('&');
                switch (streamPart) {
                    case 'SOE':
                        return classPart+'th'+' • '+'Engineering';
                    case 'SOF':
                        return classPart+'th'+' • '+'Foundation';
                    case 'SOM':
                        return classPart+'th'+' • '+'Medical';
                    default:
                        return profileData?.classtream || '';
                }
            })(),
        },
        {
            key: 'Mobile number',
            value: MaskString({
                inputString: profileData?.mobile_number || '',
                n: 2,
                maskCharacter: '*',
            }),
        },
        {
            key: 'Email ID',
            value: MaskString({
                inputString: profileData?.email || '',
                n: 2,
                maskCharacter: '*',
            }),
        },
    ]
    const handleOnChange = ({ name, value }) => {
        setIsDisableSave(false)
        setUserSelected({ ...userSelected, [name]: value })
    }

    const { data: masterResponse, setEnabled } = useGetApi(
        CONFIG.MASTER_API + `?masterKey=exam_board,school_city`,
        token,
        false,
    )
    const { data: schoolResponse, setEnabled: setFetchSchholEnabled } = useGetApi(
        CONFIG.SCHOOL_API + userSelected.school_city.label,
        token,
        false,
    )
    useEffect(() => {
        isModalOpen && setEnabled(true)
        if (
            isModalOpen &&
            profileData &&
            profileData.student_city &&
            profileData.student_city.length > 3
        ) {
            setFetchSchholEnabled(true)
        }
    }, [isModalOpen])

    useEffect(() => {
        const boardArr = []
        const cityArr = []
        if (masterResponse && masterResponse.success === 'true') {
            masterResponse.data.school_city.forEach((el) =>
                cityArr.push({ label: el.label, value: el.value }),
            )
            masterResponse.data.exam_board.forEach((el) =>
                boardArr.push({ label: el.key, value: el.value }),
            )
            setMasterData({ ...masterData, city: cityArr, boards: boardArr })
        }
    }, [masterResponse])

    useEffect(() => {
        const schoolArr = []
        let isSchoolPresent = false
        if (schoolResponse && schoolResponse.success === 'true') {
            schoolResponse.data.forEach((el) => {
                if (
                    el.short_school_name === profileData.school_name ||
                    el.full_school_name === profileData.school_name
                )
                    isSchoolPresent = true
                schoolArr.push({
                    label: el.short_school_name,
                    value: el.school_code,
                })
            })
            if (
                !isSchoolPresent &&
                profileData.student_city === userSelected.school_city.value
            )
                schoolArr.push({
                    label: profileData.school_name,
                    value: profileData.school_name,
                })
            setMasterData({ ...masterData, school: schoolArr })
        }
    }, [schoolResponse])

    const handleSubmitProfileData = () => {
        setPutEnabled(true)
        window.webengage.track('iACST_db_save_profile_edit', {
            'mobile number': profileData.mobile_number,
            'roll number': profileData.roll_number,
            psid: profileData.student_psid ? profileData.student_psid : 'NA',
            'exam type': profileData.exam_type,
            class_studying: profileData.class.toString(),
            stream: profileData.stream,
            branchcode: profileData.exam_center_id,
            'original school city': profileData.student_city,
            'original school name': profileData.school_name,
            'original school board': profileData.exam_board,
            'updated school city:': profileUpdateObj.student_city,
            'updated school name': userSelected.school_name.label,
            'updated school board': profileUpdateObj.school_board,
        })
    }
    //
    let profileUpdateObj = {
        student_city: userSelected.school_city.value,
        school_board: userSelected.school_board.value,
        school_id: userSelected.school_name.value,
    }
    const {
        data: profileUpdateData,
        loading: isLoadingPayment,
        setPutEnabled,
    } = usePutPostApi(
        CONFIG.PROFILE_UPDATE_API,
        token,
        'post',
        profileUpdateObj,
        false,
    )
    useEffect(() => {
        if (
            profileUpdateData &&
            profileUpdateData.success &&
            profileUpdateData.success === 'true'
        ) {
            setToastMsgsData({
                isToastOpen: true,
                toastType: 'Success',
                toastMessage: profileUpdateData.msg,
            })
            setIsDisableSave(true)
            fetchProfileData(true)
        } else if (
            profileUpdateData &&
            profileUpdateData.success &&
            profileUpdateData.success !== 'true'
        ) {
            setToastMsgsData({
                isToastOpen: true,
                toastType: 'danger',
                toastMessage: 'Please try again later',
            })
            fetchProfileData(true)
        }
    }, [profileUpdateData])

    const isSaveChangesBtnEnabled =
        userSelected.school_board.value &&
        userSelected.school_name.value &&
        userSelected.school_city.value &&
        !isDisabledSave

    const handletextOnlyInput = (e) => {
        const inputElement = e.target
        const inputValue = inputElement.value + e.key
        const regex = /^[a-zA-Z. ]*$/
        if (e.key !== 'Backspace' && !regex.test(inputValue)) {
            e.preventDefault()
        }
    }
    return (
        <Modal
            isOpen={isModalOpen}
            toggle={toggle}
            className="w-9 mx-auto"
            contentClassName="bg-light-blue"
            centered
        >
            <ModalHeader toggle={toggle} className="border-0 pb-0">
                <span className="font-size-md font-size-lg-lg fw-lighter">
                    View profile
                </span>
            </ModalHeader>
            <ModalBody className="p-0">
                <div className="p-3 pb-0">
                    {/* <div className="p-2 font-size-xs d-flex justify-content-center align-items-center rounded-10px mb-3 bg-warning bg-mustard">
                        <DangerIcon />
                        &nbsp;
                        <span className="ms-1">
                            School details can only be edited till{' '}
                            {profileData?.profile_edit_end_date}
                        </span>
                    </div> */}
                    <div className="bg-white rounded-10px px-2 py-3 mb-4">
                        <div className="mx-3">
                            {displayProfileData.map((el) => {
                                return (
                                    el.value && (
                                        <>
                                            <span className="text-grey font-size-xs font-size-lg-sm fw-500">
                                                {el.key}
                                            </span>
                                            <p className="light-grey-200 font-size-sm font-size-lg-md fw-500">
                                                {el.value}
                                            </p>
                                        </>
                                    )
                                )
                            })}
                        </div>
                        {/* <SelectCreatable
                            onChange={({ name, value }) => {
                                setFetchSchholEnabled(true)
                                setUserSelected({
                                    ...userSelected,
                                    [name]: value,
                                    school_name: '',
                                })
                                setIsDisableSave(true)
                            }}
                            value={getSelectDefaultValue(
                                userSelected.school_city,
                                masterData.city,
                            )}
                            isSearchable={false}
                            disabled={isDisabled}
                            options={masterData.city}
                            name={'school_city'}
                            placeholder={'Select city'}
                        />
                        <SelectCreatable
                            classNamePrefix={'reactSelect'}
                            onChange={({ name, value }) => {
                                handleOnChange({ name, value })
                            }}
                            value={getSelectDefaultValue(
                                userSelected.school_board,
                                masterData.boards,
                            )}
                            disabled={isDisabled}
                            isSearchable={false}
                            options={masterData.boards}
                            name={'school_board'}
                            placeholder={'Select board'}
                        />
                        <SelectCreatable
                            onChange={({ name, value }) => {
                                handleOnChange({ name, value })
                            }}
                            onKeyDown={(e) => handletextOnlyInput(e)}
                            value={getSelectDefaultValue(
                                userSelected.school_name,
                                masterData.school,
                            )}
                            disabled={isDisabled}
                            options={masterData.school}
                            name={'school_name'}
                            isAllowCreation
                            placeholder={'Select school name'}
                        /> */}
                    </div>

                    {/* Submit Form Button */}
                    {/* <Button
                        disabled={
                            profileData?.is_profile_edit_allowed &&
                            !isSaveChangesBtnEnabled
                        }
                        className={`${
                            isUpdateDetailsBtnEnabled ? 'bg-aqua' : 'bg-grey-50'
                        } border-0 fw-lighter `}
                        style={{
                            padding: '15px',
                            width: '100%',
                            margin: '24px 0',
                            borderRadius: '10px ',
                            letterSpacing: '0.5px',
                            maxWidth: '-webkit-fill-available',
                        }}
                        onClick={() => {
                            handleSubmitProfileData()
                        }}
                    >
                        {isLoadingPayment ? (
                            <SunspotLoader
                                gradientColors={['#4039d4', '#E0E7FF']}
                                size={'10px'}
                                desktopSize={'10px'}
                                className="m-auto"
                            />
                        ) : (
                            'Save changes'
                        )}
                    </Button> */}
                </div>
            </ModalBody>
        </Modal>
    )
}

export default connect('', mapDispatchToProps)(EditProfile)
