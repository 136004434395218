import Icon from '../components/shared/IconBase'

const LeftSlideIcon = (props) => (
    <Icon width="69" height="82" viewBox="0 0 69 82" fill="none" {...props}>
        <g filter="url(#filter0_d_4017_4165)">
            <circle cx="21" cy="21" r="21" transform="matrix(-1 0 0 1 49 19)" fill="white" fillOpacity="0.9" />
            <path d="M31.3428 49.5244C31.6705 49.5252 31.991 49.4285 32.2636 49.2468C32.5363 49.065 32.7488 48.8064 32.8742 48.5037C32.9995 48.2009 33.0321 47.8678 32.9679 47.5465C32.9036 47.2252 32.7453 46.9302 32.513 46.6991L26.0761 40.2621L32.513 33.8251C32.6667 33.6715 32.7886 33.489 32.8718 33.2882C32.9549 33.0875 32.9978 32.8723 32.9978 32.6549C32.9978 32.4376 32.9549 32.2224 32.8718 32.0216C32.7886 31.8208 32.6667 31.6384 32.513 31.4847C32.3594 31.331 32.1769 31.2091 31.9761 31.126C31.7754 31.0428 31.5602 31 31.3428 31C31.1255 31 30.9103 31.0428 30.7095 31.126C30.5087 31.2091 30.3263 31.331 30.1726 31.4847L22.5655 39.0919C22.4116 39.2455 22.2896 39.4279 22.2063 39.6287C22.123 39.8295 22.0802 40.0447 22.0802 40.2621C22.0802 40.4795 22.123 40.6947 22.2063 40.8955C22.2896 41.0963 22.4116 41.2787 22.5655 41.4323L30.1726 49.0395C30.3261 49.1934 30.5085 49.3155 30.7093 49.3987C30.9102 49.4819 31.1254 49.5247 31.3428 49.5244Z" fill="#545454" />
        </g>
        <defs>
            <filter id="filter0_d_4017_4165" x="-13" y="0" width="82" height="82" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4017_4165" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4017_4165" result="shape" />
            </filter>
        </defs>
    </Icon>
)

export default LeftSlideIcon
