import { Toast, ToastBody } from 'reactstrap'
import cx from 'classnames'

import styles from './toastMessage.module.css'

function ToastMessage({ isToastOpen = false, toastType = '', toastMessage = '' }) {
    const type = toastType.toLowerCase()
    return (
        <div className="d-flex justify-content-center text-white fw-lighter border-0 text-center">
            <Toast
                fade
                isOpen={isToastOpen}
                className={cx(
                    styles.toast,
                    { 'bg-dark-green': type === 'success' },
                    {
                        'bg-error-red': (type === 'error') || (type === 'danger'),
                    },
                    {
                        'bg-warning': type === 'warn',
                    },
                )}
            >
                <ToastBody>{toastMessage}</ToastBody>
            </Toast>
        </div>
    )
}

export default ToastMessage
