import React from 'react'
import cx from 'classnames'

import IACST_PDF_LOGO from 'assets/pdf/iacst-pdf-logo.png'
import HEADER_DESIGN from 'assets/pdfHeader.png'

import styles from './header.module.scss'

function Header({ isPrinting }) {
    return (
        <div className={cx(styles.wrapper, { [styles.print]: isPrinting })}>
            <img src={IACST_PDF_LOGO} alt="pdf-logo" className={styles.logo} />
            {isPrinting ? (
                <div className={styles.bg}>
                    <img src={HEADER_DESIGN} alt="header-design" />
                </div>
            ) : null}
        </div>
    )
}

export default Header
