import React from 'react'
import cx from 'classnames'
import ReactToPrint from 'react-to-print'

import { isMobile } from 'components/Result/helper'
import { pageStyle } from 'components/Result/constants'

import styles from './print-buttons.module.scss'

function PrintButtons({
    showAnalysis,
    resulthandle,
    scorehandle,
    printhandle,
    componentRef,
    data,
}) {
    return (
        <div className={styles.wrapper}>
            <div>
                <button
                    className={cx(styles.button, {
                        [styles.active]: !showAnalysis,
                    })}
                    onClick={resulthandle}
                >
                    RESULT
                </button>
                <button
                    className={cx(styles.button, {
                        [styles.active]: showAnalysis,
                    })}
                    onClick={scorehandle}
                    
                >
                    {isMobile() ? 'ANALYSIS' : 'SCORE CARD & ANALYSIS'}
                </button>
            </div>
            <ReactToPrint
                trigger={() => (
                    <button className={cx(styles.button, styles.active)}>
                        Print
                    </button>
                )}
                onBeforeGetContent={async () => await printhandle()}
                onAfterPrint={async () => await resulthandle()}
                content={() => componentRef.current}
                documentTitle={`Name: ${data?.result.Name} | PSID: ${data?.result.PSID}`}
                pageStyle={pageStyle}
            />
        </div>
    )
}

export default PrintButtons
