import React from 'react'

function PaymentForm({ filteredFormValues }) {
    React.useEffect(() => {
        document.forms[filteredFormValues.form_name].submit()
    }, [])

    return (
        <div className="d-none">
            <form
                action={filteredFormValues.action_url}
                method="post"
                name={filteredFormValues.form_name}
            >
                {Object.entries(filteredFormValues).length > 0 &&
                    Object.entries(filteredFormValues).map((element, index) => {
                        if (
                            element[0] !== 'action_url' &&
                            element[0] !== 'form_name'
                        ) {
                            return (
                                <input
                                    key={index}
                                    type="hidden"
                                    name={element[0]}
                                    value={element[1]}
                                />
                            )
                        }
                    })}
            </form>
        </div>
    )
}

export default PaymentForm
