import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ExamCard from 'components/Card/ExamCard'
import MockCarousel from 'components/carousel/MockCarousel'
import SampleTestCard from 'components/Card/SampleTestCard'
import { Col, Container, Row } from 'reactstrap'
import useGetApi from '../../api/useGetApi'
import CONFIG from '../../config'
import MockExamLocked from '../Card/MockExamLocked'
import { SunspotLoader } from 'react-awesome-loaders'
import MoreInfoSection from 'components/MoreInfoSection/MoreInfoSection'
import ResultAwaitedCase from 'components/Card/ResultAwaitedCase'
import TestServerDownMsg from 'components/Card/TestServerDownMsg'
import ResultWavier from 'components/ECommerce/ResultWavier'
import GuidedJourney from 'components/ECommerce/GuidedJourney'
import Fomo from 'components/ECommerce/Fomo'
import { courseListing } from 'api/api'
import { SET_DASHBOARD_API_DATA, SET_TP_RESULT_DATA } from 'constants/actionTypes'

const mapStateToProps = (state) => ({
    profileInfo: state?.profileData,
})

const mapDispatchToProps = (dispatch) => ({
    setDashBoardData: (payload) => {
        dispatch({ type: SET_DASHBOARD_API_DATA, payload })
    },
    setTpResultData: (payload) => {
        dispatch({ type: SET_TP_RESULT_DATA, payload: payload })
    }
});


function ScholarshipTest(props) {
    const [CourseData, setCourseData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [tpToken, setTpToken] = useState("")

    const {
        data: responseData,
        loading,
        error,
        setEnabled: fetchDashboardData,
    } = useGetApi(CONFIG.DASHBOARD_API, props?.token, true)

    useEffect(() => {
        if (
            props.updateDetailsResponse &&
            props.updateDetailsResponse?.success &&
            props.updateDetailsResponse?.success === 'true'
        ) {
            fetchDashboardData(true)
        }

        // eslint-disable-next-line
    }, [props])

    const { data: tpTokenDataResponse, setEnabled: setTPTokenEnabled } = useGetApi(
        CONFIG.TP_TOKEN_API, '', false, CONFIG.TP_TOKEN_API_TOKEN)

    const { data: tpResultDataResponse, setEnabled: setTPDataEnabled } = useGetApi(
        CONFIG.TP_RESULT_DATA + `?rollNo=${props.profileInfo.roll_number}`, '', false, tpToken)

    const fetchCourseData = async () => {
        setIsLoading(true);
        try {
            const res = await courseListing(`${props.profileInfo.classtream.split('&')[0]}/${props.profileInfo.stream}/${props.profileInfo.branch_code}/${props.profileInfo.roll_number}/${props.profileInfo.mobile_number}`);
            if (res.data) {
                setCourseData(res.data);
                setTPTokenEnabled(true)
            }else{
                setIsLoading(false);
            }
            props.setDashBoardData(responseData?.data.main_exam[0]);
        } catch (error) {
            console.error('Error fetching course data:', error);
            setIsLoading(false);
        }
    };

    useEffect(async () => {
        if (props.profileInfo && responseData?.data.main_exam[0]?.view_report) {
            fetchCourseData();
        }
    }, [responseData]);

    useEffect(() => {
        if (tpTokenDataResponse && tpTokenDataResponse.data !== '') {
            setIsLoading(true);
            setTpToken(tpTokenDataResponse.data)
            setTPDataEnabled(true)
        } else if (tpTokenDataResponse) {
            setIsLoading(false)
        }
    }, [tpTokenDataResponse])

    useEffect(() => {
        if (tpResultDataResponse && tpResultDataResponse.data) {
            props.setTpResultData(tpResultDataResponse.data)
            setIsLoading(false)
        } else if (tpResultDataResponse) {
            setIsLoading(false)
        }
    }, [tpResultDataResponse])

    if (loading || isLoading) {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                }}
            >
                <SunspotLoader
                    gradientColors={['#6366F1', '#E0E7FF']}
                    shadowColor={'#3730A3'}
                    desktopSize={'30px'}
                    mobileSize={'20px'}
                />
            </div>
        )
    }

    if (error || responseData.success === 'false')
        return <div>Error: {error ? error.message : responseData.msg}</div>

    const { main_exam, sample_exam, mock_exam, static_data } = responseData?.data
    const postExam = main_exam[0].view_report === 1

    return (
        <>
            {postExam ? (
                <>
                    <GuidedJourney flags={CourseData?.flags} />
                    { !CourseData?.flags?.course_purchased ?
                        <Fomo iacstFomoDesc={main_exam[0]?.iacst_fomo_desc} /> : <></>
                    }
                    <ResultWavier mainExamData={main_exam[0]} flags={CourseData?.flags} profileData={props.profileInfo} />
                </>
            ) : (
                <Container className="p-3 pb-lg-0 pb-xl-3 mw-100 mh-100">
                    {/* ANTHE MAIN EXAM CARD */}

                    {static_data.testplayer_down ? (
                        <TestServerDownMsg msg={static_data.dashboard_msg} />
                    ) : main_exam[0].result_expected_date ? (
                        <ResultAwaitedCase
                            cardData={main_exam[0]}
                            staticData={static_data}
                            profileData={props.profileInfo}
                        />
                    ) : (
                        <ExamCard
                            fetchDashboardData={fetchDashboardData}
                            fetchProfileData={props.fetchProfileData}
                            cardData={main_exam[0]}
                            staticData={static_data}
                            profileData={props.profileInfo}
                        />
                    )}

                    {/* Mock Card Locked div */}
                    {!static_data.mock_purchased && (
                        <Row>
                            <Col sm={11} md={4} className="my-3">
                                {sample_exam.map((cardDetails, index) => (
                                    <SampleTestCard
                                        key={index}
                                        cardData={cardDetails}
                                        profileData={props.profileInfo}
                                        heading={static_data.sample_heading}
                                        style={{
                                            width: '100%',
                                            filter: 'drop-shadow(0px 4px 4px rgba(151, 151, 151, 0.2))',
                                        }}
                                    />
                                ))}
                            </Col>
                            <Col sm={12} md={8} className="my-0 my-md-3">
                                <MockExamLocked
                                    profileData={props.profileInfo}
                                />
                            </Col>
                        </Row>
                    )}

                    {/* Mock carousel when mock purchased */}
                    {responseData?.data?.static_data.mock_purchased &&
                        mock_exam.length > 0 ? (
                        <Row className="mt-3">
                            <Col
                                sm={12}
                                md={5}
                                lg={4}
                                className="mb-3 mt-lg-0 mb-lg-3 my-xl-0 px-3 pe-lg-0"
                            >
                                {sample_exam.map((cardDetails, index) => (
                                    <SampleTestCard
                                        key={index}
                                        cardData={cardDetails}
                                        profileData={props.profileInfo}
                                        heading={static_data.sample_heading}
                                        style={{
                                            width: '100%',
                                            filter: 'drop-shadow(0px 4px 4px rgba(151, 151, 151, 0.2))',
                                        }}
                                    />
                                ))}
                            </Col>
                            <Col sm={12} md={12} lg={8}>
                                <MockCarousel
                                    response={mock_exam}
                                    staticData={static_data}
                                />
                            </Col>
                        </Row>
                    ) : null}
                </Container>
            )}
            <div className='mx-lg-3'>
                <MoreInfoSection
                    profileData={props.profileInfo}
                    token={props?.token}
                    CourseData={CourseData}
                    postExam={postExam}
                />
            </div>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipTest)
