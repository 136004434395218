import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import ExamIllustration from '../../assets/iacst-img.png'
import AdmitCardicon from '../../assets/admit-card-icon.png'
import ButtonText from 'components/shared/ButtonText'
import styles from './card.module.css'
import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import EditExamDetails from 'components/ExamDetailsEdit/EditExamDetails'
import { extractQueryParams } from 'utils/getAllURLParameters'

const mapStateToProps = (state) => ({
    token: state?.token,
})

function ExamCard(props) {
    const {
        cardData,
        profileData,
        staticData,
        token,
        refetchDashboard,
        fetchDashboardData,
        fetchProfileData,
    } = props

    const [isEditExamOpen, setIsEditExamOpen] = React.useState(false)
    const [greeting, setGreeting] = useState('');
    const handleEditExam = () => {
        setIsEditExamOpen(!isEditExamOpen)
        window.webengage.track('iACST_db_edit_exam_details', {
            'mobile number': profileData.mobile_number,
            'roll number': profileData.roll_number,
            psid: profileData.student_psid ? profileData.student_psid : 'NA',
            'exam type': profileData.exam_type,
            class_studying: profileData.class.toString(),
            stream: profileData.stream,
            branchcode: profileData.exam_center_id,
            edit_attempts_left:
                (profileData.exam_attempts_left &&
                    profileData.exam_attempts_left.toString()) ||
                '0',
        })
    }

    useEffect(() => {
        const currentHour = new Date().getHours();
    
        if (currentHour >= 5 && currentHour < 12) {
          setGreeting('morning');
        } else if (currentHour >= 12 && currentHour < 17) {
          setGreeting('afternoon');
        } else {
          setGreeting('evening');
        }
      }, []);
    

    const apiData = {
        token,
        test_short_code: cardData.test_short_code,
        source_type: 'aakash',
    }
    let parameters = {}
    const queryString = window.location.search
    const queryParamsArray = queryString.replace('?', '').split(/&|=/)
    parameters = extractQueryParams(queryParamsArray)
    let isPaymentParam =
        parameters.hasOwnProperty('status') && parameters.hasOwnProperty('mode')

    useEffect(() => {
        if (
            isPaymentParam &&
            parameters.editExam === 'true' &&
            parameters.status === 'failure'
        ) {
            setIsEditExamOpen(true)
        }

        // eslint-disable-next-line
    }, [isPaymentParam])

    const handleViewResultCta = () => {
        const {
            roll_number,
            class: class_studying,
            exam_type,
            stream,
            exam_center_id,
            exam_state,
        } = profileData
        window.webengage.track('iACST_db_main_result_student_view', {
            'roll number': roll_number,
            exam_type,
            class_studying,
            stream,
            state: exam_state,
            branchCode: exam_center_id,
        })
        window.open(cardData.report_link, '_blank', 'noopener')
    }

    return (
        <>
            <Card className={`${styles.mainExamCard} d-xl-none`}>
                <CardHeader
                    className={`bg-primary fw-bold text-white text-center ${styles.mainExamCardHeader}`}
                >
                    {cardData.card_title}
                </CardHeader>

                <CardBody className="pb-0">
                    <Row>
                        <Col xs={8}>
                            <CardTitle tag="h5" className="text-grey-black fw-bold text-capitalize">
                                {greeting+ " "+profileData.student_firstname},
                            </CardTitle>
                        </Col>
                        <Col xs={4} className="text-end">
                            {cardData.is_admit_card_available && (
                                <div>
                                    <a
                                        target="_blank"
                                        without
                                        rel="noreferrer"
                                        href={cardData.admit_card_url}
                                        onClick={() => {
                                            window.webengage.track(
                                                'iACST_db_download_admit_card',
                                                {
                                                    'mobile number':
                                                        profileData.mobile_number,
                                                    'roll number':
                                                        profileData.roll_number,
                                                    psid: profileData.student_psid
                                                        ? profileData.student_psid
                                                        : 'NA',
                                                    'exam type':
                                                        profileData.exam_type,
                                                    class_studying:
                                                        profileData.class.toString(),
                                                    stream: profileData.stream,
                                                    state: profileData.exam_state,
                                                    center: profileData.exam_center,
                                                    location:
                                                        profileData.exam_location,
                                                    'exam time':
                                                        profileData.exam_mode_and_slot,
                                                    'exam date':
                                                        profileData.exam_date,
                                                },
                                            )
                                        }}
                                        className="text-aqua font-size-xs font-size-md-sm font-size-xs fw-normal text-decoration-none"
                                    >
                                        Admit card{' '}
                                        <img
                                            src={AdmitCardicon}
                                            alt="AdmitCard-download-icon"
                                            className="ms-1"
                                        />
                                    </a>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row className="justify-content-between mt-3">
                        <Col
                            sm={12}
                            md={7}
                            className="d-flex justify-content-center"
                        >
                            <Col>
                                <p className="font-size-xs m-0 fw-light">Date</p>
                                <p className="font-size-xs font-size-md-md fw-bold">
                                    {cardData.exam_date}
                                </p>
                            </Col>
                            <Col xs={5} style={{ whiteSpace: 'pre' }}>
                                <p className="font-size-xs m-0 fw-light">Timings</p>
                                <p className="font-size-xs font-size-md-md fw-bold">
                                    {cardData.exam_time}
                                </p>
                            </Col>
                            <Col>
                                <div className={styles.mobileExamCardDuration}>
                                    <p className="font-size-xs m-0 fw-light">
                                        Duration
                                    </p>
                                    <p className="font-size-xs font-size-md-md fw-bold">
                                        {cardData.exam_duration}
                                    </p>
                                </div>
                            </Col>
                        </Col>
                        <Col sm={12} md={4}>
                            {cardData.view_report ? (
                                <>
                                    <Button
                                        onClick={handleViewResultCta}
                                        className={`btn-aqua text-white w-100 font-size-sm fw-bold mb-1`}
                                    >
                                        {cardData.report_text}
                                    </Button>
                                    <p className="text-gray font-size-xxs lh-1 d-none">
                                        Post clicking "View Result", wait for 5
                                        seconds for result pdf generation
                                    </p>
                                </>
                            ) : (
                                <ButtonText
                                    buttonText={cardData.exam_button_text}
                                    actionLink={cardData.exam_button_link}
                                    cardType="mainCard"
                                    apiData={apiData}
                                    profileData={profileData}
                                />
                            )}
                        </Col>
                    </Row>
                    {cardData.center_address &&
                        cardData.center_address.length > 0 && (
                            <Col className="font-size-xs bg-light col p-2 m-2 mt-3 text-center">
                                Offline exam centre - {cardData.center_address}
                            </Col>
                        )}
                    <Row className="align-bottom my-2">
                        {/* {!profileData.remove_edit_exam_edit_profile && (
                            <Col>
                                <span
                                    className="font-size-xs font-size-md-sm text-aqua text-decoration-underline cursor-pointer"
                                    onClick={handleEditExam}
                                >
                                    View/ Edit Details
                                </span>
                            </Col>
                        )} */}
                        <Col className="font-size-xs font-size-md-sm text-end align-self-center">
                            Need help?&nbsp;
                            <a
                                 onClick={() => {
                                    window.webengage.track('iACST_db_call_now', {
                                        'support number': '1800-102-272',
                                        'exam type': profileData.exam_type,
                                        center: profileData.center_name,
                                        class: profileData.class,
                                        stream: profileData.stream,
                                    })
                                }}

                                href={'tel:' + cardData.exam_help_line}
                                className={`text-aqua`}
                            >
                                <span>Call now</span>
                            </a>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <a
                href={staticData.termsLink}
                target="_blank"
                rel="noreferrer"
                className="font-size-xs text-grey d-lg-none text-decoration-none"
            >
                *Terms and conditions applied
            </a>

            <Card className={`${styles.mainExamCardDesktop} d-none d-xl-block`}>
                <CardHeader
                    className={`bg-primary fw-bold text-white text-center ${styles.mainExamCardHeader}`}
                >
                    {cardData.card_title}
                </CardHeader>

                <CardBody className="pb-0 pt-0 ps-0">
                    <Row className="h-75 justify-content-between">
                        <Col className={`me-lg-0 ${styles.desktopTitle} me-xl-3`}>
                            <img
                                src={ExamIllustration}
                                alt="students-degree-illustration"
                                className="d-none d-md-block"
                                width={cardData.center_address &&
                                    cardData.center_address.length > 0 ? 260 : 280 }
                            />
                        </Col>

                        {cardData.is_admit_card_available && (
                            <Col
                                lg={3}
                                style={{
                                    position: 'absolute',
                                    right: '50px',
                                    marginTop: '0.4rem',
                                }}
                            >
                                <a
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                    onClick={() => {
                                        window.webengage.track(
                                            'iACST_db_download_admit_card',
                                            {
                                                'mobile number':
                                                    profileData.mobile_number,
                                                'roll number':
                                                    profileData.roll_number,
                                                psid: profileData.student_psid
                                                    ? profileData.student_psid
                                                    : 'NA',
                                                'exam type': profileData.exam_type,
                                                class_studying:
                                                    profileData.class.toString(),
                                                stream: profileData.stream,
                                                state: profileData.exam_state,
                                                center: profileData.exam_center,
                                                location: profileData.exam_location,
                                                'exam time':
                                                    profileData.exam_mode_and_slot,
                                                'exam date': profileData.exam_date,
                                            },
                                        )
                                    }}
                                    href={cardData.admit_card_url}
                                    className="text-blue  font-size-md text-decoration-none font-size-xs fw-normal mt-3 mb-2"
                                >
                                    Admit card{' '}
                                    <img
                                        src={AdmitCardicon}
                                        alt="AdmitCard-download-icon"
                                        className="ms-1"
                                    />
                                </a>
                            </Col>
                        )}

                        <Col className="me-xl-3 mt-2">
                            <CardTitle tag="h3" className="text-grey-black fw-bold font-size-24 text-capitalize">
                                {greeting+" "+profileData.student_firstname},
                            </CardTitle>
                            <Row className="d-flex justify-content-between align-items-center mt-3">
                                <Col xs={4}>
                                    <p
                                        className='font-size-lg m-0 fw-500 text-dark-grey lh-1'
                                    >
                                        Date
                                    </p>
                                    <p
                                        className='font-size-md fw-bold mb-0'
                                        style={{ whiteSpace: 'pre' }}
                                    >
                                        {cardData.exam_date}
                                    </p>
        
                                    {/* <p
                                        className={`font-size-lg m-0 fw-500 text-dark-grey ${
                                            profileData.remove_edit_exam_edit_profile
                                                ? 'lh-1'
                                                : ''
                                        }`}
                                    >
                                        Date
                                    </p>
                                    <p
                                        className={`font-size-md fw-bold mb-0 ${
                                            !profileData.remove_edit_exam_edit_profile
                                                ? 'mb-0 lh-1'
                                                : ''
                                        }`}
                                        style={{ whiteSpace: 'pre' }}
                                    >
                                        {cardData.exam_date}
                                    </p> 
                                    {!profileData.remove_edit_exam_edit_profile && (
                                        <span
                                            className={`font-size-xs text-aqua text-decoration-underline cursor-pointer`}
                                            onClick={handleEditExam}
                                        >
                                            View/ Edit Details
                                        </span>
                                    )} */}
                                </Col>
                                <Col xs={6} xl={4} className="ms-md-2 ms-xl-0">
                                    <p className="font-size-lg m-0 fw-500 lh-1 text-dark-grey">
                                        Timings
                                    </p>
                                    <p
                                        className="font-size-md fw-bold mb-0"
                                        style={{ whiteSpace: 'pre' }}
                                    >
                                        {cardData.exam_time}
                                    </p>
                                </Col>
                                <Col xl={2} className="ms-4 text-end">
                                    <p className="font-size-md m-0 fw-500 lh-1 text-dark-grey">
                                        Duration
                                    </p>
                                    <p className="font-size-md fw-bold mb-0">
                                        {cardData.exam_duration}
                                    </p>
                                </Col>
                            </Row>
                            {cardData.center_address &&
                                cardData.center_address.length > 0 && (
                                    <Row
                                        style={{
                                            position: 'relative',
                                            bottom: '0.2rem',
                                        }}
                                    >
                                        <Col className="font-size-xs bg-light col p-2 m-2 mt-3 text-center">
                                            Offline exam centre -{' '}
                                            {cardData.center_address}
                                        </Col>
                                    </Row>
                                )}
                        </Col>
                        <Col
                            md={3}
                            className="d-flex flex-column"
                        >
                            <div className="font-size-sm text-end d-flex justify-content-end pt-2 mt-1">
                                Need help?&nbsp;
                                <a
                                    onClick={() => {
                                        window.webengage.track('iACST_db_call_now', {
                                            'support number':
                                                cardData.exam_help_line,
                                            'exam type': profileData.exam_type,
                                            center: profileData.center_name,
                                            class:
                                                profileData.class &&
                                                profileData.class.toString(),
                                            stream: profileData.stream,
                                        })
                                    }}
                                    href={'tel:' + cardData.exam_help_line}
                                    className={`font-size-xs text-aqua`}
                                >
                                    <span className="d-xl-none p-auto">
                                        Call now
                                    </span>
                                </a>
                                <span className="d-none d-xl-block font-size-sm text-black">
                                    Call us at&nbsp;
                                </span>
                                <span className="d-none d-xl-block font-size-sm fw-lighter text-black">
                                    {cardData.exam_help_line}
                                </span>
                            </div>
                            <div className="mt-4 pt-1">
                                {cardData.view_report ? (
                                    <>
                                        <Button
                                            onClick={handleViewResultCta}
                                            className={`btn-aqua text-white w-100 font-size-sm fw-bold`}
                                        >
                                            {cardData.report_text}
                                        </Button>
                                        <p className=" font-size-xxs m-0 d-none">
                                            Post clicking "View Result", wait for 5
                                            seconds for result pdf generation
                                        </p>
                                    </>
                                ) : (
                                    <ButtonText
                                        buttonText={cardData.exam_button_text}
                                        actionLink={cardData.exam_button_link}
                                        cardType="mainCard"
                                        apiData={apiData}
                                        profileData={profileData}
                                    />
                                )}
                            </div>
                            <p className="text-end mb-2 mt-3">
                                <a
                                    href={staticData.termsLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="font-size-xs text-grey mt-2 text-decoration-none"
                                >
                                    *Terms and conditions applied
                                </a>
                            </p>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {isEditExamOpen && (
                <EditExamDetails
                    fetchDashboardData={fetchDashboardData}
                    fetchProfileData={fetchProfileData}
                    modal={isEditExamOpen}
                    toggle={handleEditExam}
                    refetchDashboard={refetchDashboard}
                />
            )}
        </>
    )
}

export default connect(mapStateToProps)(ExamCard)
