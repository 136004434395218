import { useHistory } from 'react-router-dom'

import WarningIcon from '../../assets/Warning'
import { ReactComponent as AlertImage } from '../../assets/alert.svg'
import Header from 'components/Header/header'

function ExamAlreadyGiven() {
    const history = useHistory()

    return (
        <>
            <div className="mb-3">
                <Header />
            </div>
            <div className="vw-100 text-center d-flex flex-column justify-content-center align-items-center">
                <div className="w-6">
                    <AlertImage />

                    <p className="fw-lighter font-size-md mt-5 mb-4">
                        You have already given ANTHE exam, wait for result
                    </p>
                </div>

                <div className="bg-beige text-brown rounded py-1 px-5 m-4">
                    <WarningIcon fill="transparent" stroke="#705010" />
                    <span className="font-size-xs ms-4">
                        You will find your result on ANTHE dashboard
                    </span>
                </div>

                <button
                    className={`btn btn-aqua text-white w-9 w-md-5 w-lg-3 font-size-sm fw-lighter rounded-10px`}
                    style={{ padding: '11px' }}
                    onClick={() => {
                        history.replace('/')
                    }}
                >
                    Go to Dashboard
                </button>
            </div>
        </>
    )
}

export default ExamAlreadyGiven
