import { SET_TOAST_DATA } from '../constants/actionTypes'

const initialState = {
    isToastOpen: false,
    toastType: '',
    toastMessage: '',
}

const setToastMsgsData = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOAST_DATA:
            return action.payload

        default:
            return state
    }
}

export default setToastMsgsData
