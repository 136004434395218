const IconBase = ({ children, width, height, style = {}, ...props }) => (
    <svg
        children={children}
        fill="currentColor"
        preserveAspectRatio="xMidYMid meet"
        height={height}
        width={width}
        {...props}
        style={{
            ...style,
        }}
    />
)

export default IconBase
