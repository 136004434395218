import { Fragment, useEffect, useState } from 'react'
import styles from './antheRsTable.module.css'
const ScoreCardTable = (props) => {
    const [scoreCard, setScoreCard] = useState([])

    useEffect(() => {
        let score = []
        props.data.score.map((item) => {
            let tableData = []
            props.data.score_th.map((ele) => {
                tableData.push(item[ele])
            })
            score.push(tableData)
        })
        setScoreCard(score)
    }, [])

    return (
        <Fragment>
            {props.data.score.length ? (
                <div className={styles.tableWrapper}>
                    <div style={{ minWidth: '500px' }}>
                        <div className={styles.score_card_section}>
                            <p>YOUR SCORECARD (IACST 2024)</p>
                        </div>
                        <table className={styles.antheScoreTable}>
                            <thead className={styles.antheScoreThead}>
                                <tr className={styles.antheScoreTr}>
                                    {props.data.score_th.map((ele) => {
                                        return (
                                            <th
                                                key={ele}
                                                style={{ textAlign: 'center' }}
                                                className={styles.antheScoreTh}
                                            >
                                                {ele}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody className={styles.antheScoreTbody}>
                                {scoreCard.map((ele) => {
                                    return (
                                        <tr
                                            className={styles.antheScoreTr}
                                            key={ele}
                                        >
                                            {ele.map((item, ind) => {
                                                return (
                                                    <td
                                                        key={item}
                                                        style={
                                                            ind === 0
                                                                ? {
                                                                      textAlign:
                                                                          'start',
                                                                  }
                                                                : null
                                                        }
                                                        className={styles.antheTd}
                                                    >
                                                        {item}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : null}
        </Fragment>
    )
}

export default ScoreCardTable
