import config from 'config'
export const aboutCard = [
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/scholarship-test-details.png',
        cardbodyitem:
            '<p>Take the test at a date and time of your choice </br><b>Timings </b>: 9AM to 10PM Daily | <b>Duration</b> : 60&nbsp;mins</br><b>Mode </b>: Online(from home)</p></p>',
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/avail-scholarship.png',
        cardbodyitem:
            "<p className='integrated'>1 Year Integrated Classroom Courses <br /> for NEET<br /></p>",
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/how_can_appear.png',
        cardbodyitem: ' <p>Class 12th passed students</p>',
    },

    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/how-to-take.png',
        cardbodyitem:
            '<ul><li>Verify your mobile number with OTP and complete the registration </li><li> Get your login details, roll number and select an exam date &amp; time slot </li> <li>Take the Aakash iACST online from your home</li></ul>',
    },
    {
        headingImage:
            'https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/test-highlight.png',
        cardbodyitem:
            ' <ul><li>Completely online ensuring the safety and convenience of all candidates </li> <li>Flexible timings - students can choose and select any time slot between 9AM to 10PM </li> <li>Conducted everyday so that students can schedule the test as per their convenience</li></ul>',
    },
]

export const details = [
    {
        details_title: 'Take the NEET Repeater Course if you',
        details_para: [
            {
                cardbodyitem:
                    'Have passed Class 12th and have already appeared in NEET but did not qualify',
            },
            {
                cardbodyitem:
                    'Qualified NEET but did not get admission in the college of their choice due to low score',
            },
            {
                cardbodyitem: 'Did not get your expected rank',
            },
            {
                cardbodyitem:
                    'Did not even once appear in NEET due to Class 12th Boards and wish to drop a year for exclusive preparation for NEET',
            },
        ],
    },
    {
        details_title: 'Why NEET Repeater Course?',
        details_para: [
            {
                cardbodyitem:
                    'Better performance at NEET improves chances of admission in top medical colleges',
            },
            {
                cardbodyitem:
                    'Better awareness of exam pattern and the ability to handle the pressure of the exam day provides an edge over the freshers',
            },
            {
                cardbodyitem:
                    'One can focus on NEET alone, without any compulsion of maintaining school attendance and stress of class 12th',
            },
            {
                cardbodyitem:
                    'Repeater students have performed extraordinarily well in NEET exam in previous years',
            },
        ],
    },
]

export const successStories = [
    {
        headingImage: `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/neet-success-stroies-from-repeater-2023-lg.webp?v=${config.VERSION_BANNER}`,
    },
    {
        headingImage: `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/neet-2023-topper-banner-1260x300.webp?v=${config.VERSION_BANNER}`,
    },
    {
        headingImage: `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-main-result-2024-session1-lpbanner.webp?v=${config.VERSION_BANNER}`,
    },
    {
        headingImage: `https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/pdf_management_files/target_solutions/jee-advanced-2023-topper-banner-1260x300.webp?v=${config.VERSION_BANNER}`,
    },
]

export const scholarshipDetails = [
    {
        scholarshipTitle: 'NEET Repeater Course for NEET Droppers',
        scholarshipPara:
            " If you are a NEET dropper, don't lose heart! You have another chance to fulfill your dreams of becoming a doctor by enrolling in the Aakash NEET Repeater Course. This course is specially designed for NEET droppers who have appeared for NEET before but were unable to qualify.",
        scholarshipData: [
            {
                scholarshipDataTitle:
                    'Benefits of Aakash NEET Repeater Course for NEET Droppers',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'Here are some benefits of enrolling in the Aakash NEET Repeater Course:',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Comprehensive Course Material:</strong> Aakash NEET Repeater Course offers an updated and well-researched study material for NEET droppers that covers all the topics of NEET. The course material is designed by experienced faculty members who have an in-depth understanding of the NEET exam pattern and syllabus.',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Experienced Faculty Members:</strong>The Aakash NEET Repeater Course has a team of experienced and knowledgeable faculty members who provide personalized attention to each student. They offer doubt-clearing sessions, weekly tests, and regular feedback to help students improve their performance.',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Practice Tests and Mock Tests:</strong>Aakash NEET Repeater Course offers a range of practice tests and mock tests to help students get familiar with the NEET exam pattern and build their confidence. These tests are designed to simulate the real exam environment and help students identify their strengths and weaknesses.',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Individual Attention:</strong> Aakash NEET Repeater Course has a limited number of students in each batch, ensuring that each student receives personalized attention from the faculty members. This helps students to get their doubts cleared, understand complex concepts, and perform better in exams.',
                    },
                    {
                        scholarshipDataParagraph:
                            '<strong>Doubt-clearing Sessions:</strong>Aakash NEET Repeater Course offers doubt-clearing sessions, where NEET droppers can ask their queries and get their doubts cleared by experienced faculty members. These sessions help students to understand complex concepts and build their confidence.',
                    },
                ],
            },
            {
                scholarshipDataTitle: 'About Aakash Institute',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'Over the years, Aakash Institute has grown to become one of the largest coaching institutes in India, with a network of over 200 centers across the country. The institute is renowned for its high-quality coaching programs, experienced faculty, and comprehensive study materials.',
                    },
                    {
                        scholarshipDataParagraph:
                            "Aakash Institute offers a range of courses for medical and engineering aspirants, including classroom programs, online courses, and distance learning programs. The institute's flagship program is the Aakash Classroom Program, which is designed to help students prepare for various entrance examinations such as NEET, JEE Main, JEE Advanced, and more. Apart from the classroom programs, Aakash Institute also offers a range of online courses and distance learning programs for students who cannot attend regular classroom sessions. These courses are designed to provide students with the flexibility to learn at their own pace and convenience.",
                    },
                    {
                        scholarshipDataParagraph:
                            'In addition to its coaching programs, Aakash Institute also conducts scholarship tests for students to help them achieve their dreams of becoming doctors and engineers. The institute also offers various other resources such as mock tests, study materials, and doubt-clearing sessions to help students prepare for their exams.',
                    },
                ],
            },
            {
                scholarshipDataTitle: 'NEET Coaching @ Aakash',
                scholarshipDataPara: [
                    {
                        scholarshipDataParagraph:
                            'The NEET coaching program at Aakash is led by experienced faculty members who are experts in their respective fields. The faculty members use innovative teaching methods to ensure that students understand the concepts thoroughly. The coaching program also includes doubt-clearing sessions where students can clarify their doubts with the faculty members.',
                    },
                    {
                        scholarshipDataParagraph:
                            'Aakash Institute has state-of-the-art infrastructure that provides students with a comfortable learning environment. The institute also offers study material that is updated regularly to keep up with the changes in the exam pattern and syllabus. The study material is designed in a way that helps students understand the concepts easily and retain them for a longer period of time.',
                    },
                    {
                        scholarshipDataParagraph:
                            'Apart from the regular coaching program, Aakash Institute also offers distance learning programs and repeater courses for NEET droppers. The distance learning program includes study material, mock tests, and doubt-clearing sessions that students can access from the comfort of their homes. The online coaching program includes live classes, recorded lectures, and doubt-clearing sessions that students can attend from anywhere.',
                    },
                ],
            },
        ],
    },
]
export const banner_title = [
    {
        head_title: 'NEET 2025 Repeater Courses<br> for 12th Passed Batches',
        sub_head_title: 'Up to 90%* Scholarship',
        type: 'Online Instant Admission Cum Scholarship Test',
        registration: 'Register For FREE',
    },
]
