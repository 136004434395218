import DatePicker from 'react-datepicker'
import { getMonth, getYear } from 'date-fns'
import range from 'lodash/range'
import 'react-datepicker/dist/react-datepicker.css'
import MaskedTextInput from 'react-text-mask'

import styles from './datepickerStyles.module.css'
import { MAX_DOB, months } from 'components/Result/constants'

const DatePickerField = ({
    value,
    onChange,
    onBlur,
    placeholder,
    errorMsg,
    disabled,
    ...props
}) => {
    const MIN_DOB = new Date('1993-01-01')
    const years = range(1993, 2016, 1)

    return (
        <div className="mb-3 px-3">
            <DatePicker
                customInput={
                    <MaskedTextInput
                        type="text"
                        mask={[
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                        ]}
                    />
                }
                className={`${styles.inputBox_login} w-100 px-2 ${
                    errorMsg ? 'border border-error-red' : 'border border-grey-100'
                } ${disabled ? 'bg-light-grey dark-grey' : 'text-grey-black'}`}
                minDate={MIN_DOB}
                maxDate={MAX_DOB}
                selected={value}
                onChange={onChange}
                onBlur={(event) => onBlur(event.target.value, 'blur')}
                onSelect={onBlur}
                disabled={disabled}
                dateFormat="dd/MM/yyyy"
                placeholderText={placeholder}
                {...props}
                renderCustomHeader={({ date, changeYear, changeMonth }) => (
                    <div className={styles.dateSelectDiv}>
                        <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            />
            {errorMsg && (
                <p className="font-size-xs text-error-red mt-1">{errorMsg}</p>
            )}
        </div>
    )
}

export default DatePickerField
