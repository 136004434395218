import style from "./resultNotFound.module.css";
import logo from "../../../assets/aakash-byjus-logo.svg";
const ResultNotFound = () => {
  return (
    <div className={style.result_coming_soon}>
      <div className={style.coming_logo}>
        <img src={logo} />
      </div>

      <div className={style.coming_section}>
        <div className={style.comimg_text}>
          <h3>Your result would be available soon.</h3>

          <p>
            Please connect at{" "}
            <a href="mailto:anthesupport@aesl.in">anthesupport@aesl.in</a> for
            any query regarding your result.
          </p>
        </div>

        <div className={style.anthe_coming_img}>
          <img
            alt="img"
            className={style.img_responsive_coming}
            src="https://d16swpibua0gnc.cloudfront.net/Aakash-Anthe/s3fs-public/inline-images/coming-soon-image-result-2021.png"
          />
        </div>
      </div>
    </div>
  );
};

export default ResultNotFound;
