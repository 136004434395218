import Icon from '../components/shared/IconBase'

const BookingSVG = (props) => (
    <Icon width="155" height="45" viewBox="0 0 129 33" fill="none" {...props}>
        <path
            d="M8.12677 2.10265C8.84241 0.869183 10.1606 0.109985 11.5866 0.109985H125C127.209 0.109985 129 1.90085 129 4.10999V28.11C129 30.3191 127.209 32.11 125 32.11H11.5866C10.1606 32.11 8.84241 31.3508 8.12678 30.1173L1.16462 18.1173C0.444424 16.876 0.444423 15.344 1.16462 14.1026L8.12677 2.10265Z"
            fill="#E6F4ED"
        />
        <foreignObject x="7" y="4" width="130" height="25">
            <div xmlns="http://www.w3.org/1999/xhtml">
                <div className="d-flex align-items-start">
                    <div className='me-1 mt-1'>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="20"
                        viewBox="0 0 15 20"
                        fill="none"
                        className='align-top'
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.7994 5.86099L14.3396 6.40099C14.7672 6.82099 14.9998 7.39099 14.9998 7.99099C15.0073 8.59099 14.7747 9.16174 14.3546 9.58849C14.3496 9.59399 14.3446 9.59882 14.3396 9.60366C14.3371 9.60607 14.3346 9.60849 14.3321 9.61099L13.7994 10.1435C13.5893 10.3535 13.4693 10.6385 13.4693 10.9392V11.711C13.4693 12.956 12.4564 13.9692 11.211 13.9692H10.4382C10.1381 13.9692 9.85296 14.0885 9.64288 14.2985L9.10269 14.8385C8.66003 15.2817 8.08232 15.4985 7.50461 15.4985C6.9269 15.4985 6.34919 15.2817 5.90653 14.8467L5.35883 14.2985C5.14875 14.0885 4.86365 13.9692 4.56354 13.9692H3.79076C2.54531 13.9692 1.53244 12.956 1.53244 11.711V10.9392C1.53244 10.6385 1.4124 10.3535 1.20232 10.136L0.662127 9.60349C-0.215691 8.72674 -0.223194 7.29349 0.654624 6.40924L1.20232 5.86099C1.4124 5.65099 1.53244 5.36599 1.53244 5.05849V4.29349C1.53244 3.04849 2.54531 2.03674 3.79076 2.03674H4.56354C4.86365 2.03674 5.14875 1.91599 5.35883 1.70599L5.89902 1.16599C6.77684 0.281737 8.20986 0.281737 9.09518 1.15924L9.64288 1.70599C9.85296 1.91599 10.1381 2.03674 10.4382 2.03674H11.211C12.4564 2.03674 13.4693 3.04849 13.4693 4.29349V5.06674C13.4693 5.36599 13.5893 5.65099 13.7994 5.86099ZM5.56881 10.5861C5.74887 10.5861 5.91393 10.5186 6.03398 10.3911L9.89037 6.53681C10.1455 6.28181 10.1455 5.86106 9.89037 5.60606C9.63528 5.35181 9.22263 5.35181 8.96754 5.60606L5.11114 9.46106C4.85605 9.71606 4.85605 10.1361 5.11114 10.3911C5.23119 10.5186 5.39625 10.5861 5.56881 10.5861ZM8.77256 9.92551C8.77256 10.293 9.06517 10.5855 9.4328 10.5855C9.79293 10.5855 10.0855 10.293 10.0855 9.92551C10.0855 9.56626 9.79293 9.27301 9.4328 9.27301C9.06517 9.27301 8.77256 9.56626 8.77256 9.92551ZM5.5764 5.41903C5.93653 5.41903 6.22914 5.71153 6.22914 6.07153C6.22914 6.43978 5.93653 6.73153 5.5764 6.73153C5.21627 6.73153 4.91616 6.43978 4.91616 6.07153C4.91616 5.71153 5.21627 5.41903 5.5764 5.41903Z"
                            fill="#07794C"
                        />
                    </svg>
                    </div>
                    <span className="text-secondary fw-500 lh-sm font-size-xxs">
                Videos • Audios • Test series • E-books!
                </span>
                </div>
            </div>
        </foreignObject>
    </Icon>
)

export default BookingSVG
