import React from 'react'
import Header from './header'

import styles from './invalid-result.module.scss'

function InvalidResult() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Header />
            </div>
            <p className={styles.text}>
                The result of ANTHE 2021 is invalid. Apply for{' '}
                <a
                    href="https://anthe.aakash.ac.in/anthe"
                    target="_blank"
                    rel="noreferrer"
                >
                    Anthe 2023{' '}
                </a>{' '}
                to earn a 100% scholarship and cash awards for pursuing your career
                goals.
            </p>
        </div>
    )
}

export default InvalidResult
