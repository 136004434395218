import styles from './antheRsTable.module.css'
import { useEffect, useState } from 'react'
const RelativeTable = (props) => {
    const [rlaTable, setRlaTable] = useState([])
    const [head1, setHead] = useState([])

    useEffect(() => {
        let head = []
        if (props.data.rpa.length) {
            Object.keys(props.data.rpa[0]).map((item) => {
                head.push(item)
                setHead(head)
            })
        }

        let score = []
        props.data.rpa.map((item) => {
            let tableData = []
            head.map((ele) => {
                tableData.push(item[ele])
            })
            score.push(tableData)
        })
        setRlaTable(score)
    }, [])

    return (
        <div className={styles.tableWrapper}>
            <div style={{ minWidth: '400px' }}>
                <div className={styles.difficut_analysis_section}>
                    <h4 style={{ color: '#2B7DCA' }}>
                        RELATIVE PERFORMANCE ANALYSIS - SUBJECT WISE
                    </h4>
                </div>
                <table style={{ minWidth: '400px' }} className={styles.antheRTable}>
                    <thead className={styles.antheRThead}>
                        <tr className={styles.antheRTr}>
                            {head1.map((item) => {
                                return <th className={styles.antheRth}>{item}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody className={styles.antheRTbody}>
                        {rlaTable.map((ele, index) => {
                            return (
                                <tr key={'rtable' + index} className={styles.rtr}>
                                    {ele.map((item, index) => {
                                        return (
                                            <td
                                                key={'answerkey' + index}
                                                className={styles.antheRTd}
                                            >
                                                <div className={styles.answerValues}>
                                                    {item.includes('=')
                                                        ? item.slice(
                                                              0,
                                                              item.indexOf('='),
                                                          )
                                                        : item}
                                                </div>
                                                {item.includes('=') ? (
                                                    <div
                                                        className={styles.bottomTd}
                                                        style={
                                                            index === 1
                                                                ? {
                                                                      backgroundColor:
                                                                          '#81C9C6',
                                                                  }
                                                                : index === 2
                                                                ? {
                                                                      backgroundColor:
                                                                          '#0F4C8C',
                                                                  }
                                                                : {
                                                                      backgroundColor:
                                                                          '#ee837a',
                                                                  }
                                                        }
                                                    >
                                                        {item.includes('=')
                                                            ? item.slice(
                                                                  item.indexOf('=') +
                                                                      1,
                                                              )
                                                            : null}
                                                    </div>
                                                ) : null}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default RelativeTable
