import { Fragment, useEffect, useState } from 'react'

import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import Header from '../../components/Header/header'
import Result from '../../components/Result/AntheResults/result'
import IacstResults from '../../components/Result/IacstResults/result'
import { connect } from 'react-redux'
import ScholarshipTest from 'components/scholarship-test/ScholarshipTest'
import useGetApi from 'api/useGetApi'
import CONFIG from '../../config'
import RegistartionSteps from 'components/RegistrationSteps/RegistartionSteps'
import {
    RESET_REDUCERS,
    SET_PROFILE_DATA,
    SET_TOAST_DATA,
    SET_VISIBLE_COURSE_DESC,
    SET_VISIBLE_COURSE_REGISTER,
    SET_ECOMMERCE_SUCCESS,
    SET_ECOMMERCE_FAILED,
} from 'constants/actionTypes'
import { extractQueryParams } from 'utils/getAllURLParameters'
import { usePutPostApi } from 'api/usePutPostApi'
import { SunspotLoader } from 'react-awesome-loaders'
import CourseDescription from 'components/ECommerce/CourseDescription'
import ECommerceSuccessful from 'components/ECommerce/ECommerceSuccessful'

const mapStateToProps = (state) => {
    return {
        token: state?.token,
        profileInfo: state?.profileData,
        dashboardData: state?.dashboardData,
        ecommerce: state?.ecommerce,
    }
}

const mapDispatchToProps = (dispatch) => ({
    setProfileData: (payload) => {
        dispatch({ type: SET_PROFILE_DATA, payload: payload })
    },
    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
    setCourseDescVisible: (payload) => {
        dispatch({ type: SET_VISIBLE_COURSE_DESC, payload })
    },
    setCourseRegisterVisible: (payload) => {
        dispatch({ type: SET_VISIBLE_COURSE_REGISTER, payload })
    },
    setecommerceSuccess: (payload) => {
        dispatch({ type: SET_ECOMMERCE_SUCCESS, payload })
    },
    setecommerceFailed: (payload) => {
        dispatch({ type: SET_ECOMMERCE_FAILED, payload })
    },
    setResetReducer: () => {
        dispatch({ type: RESET_REDUCERS })
    },
})

const Dashboard = (props) => {
    const location = useLocation()
    const history = useHistory()

    // useEffect(() => {
    //     console.log(parameters)
    //     if (
    //         props?.ecommerce?.retryPayment &&
    //         (parameters.status === 'failure' || parameters.status === 'aborted') &&
    //         parameters.hasOwnProperty('mode') &&
    //         parameters.type === 'iacstecomm'
    //     ) {
    //         window.history.replaceState({}, '', '/dashboard')
    //     }
    // }, [])

    useEffect(() => {
        document.body.style.zoom = '100%'
        if (!props?.token && location.pathname !== '/result')
            return history.push('/')

        // eslint-disable-next-line
    }, [])

    const [finalEditDetailsPayload, setFinalPayload] = useState({})

    let parameters = {}
    const queryString = window.location.search
    const queryParamsArray = queryString.replace('?', '').split(/&|=/)
    parameters = extractQueryParams(queryParamsArray)
    let isPaymentParam =
        parameters.hasOwnProperty('status') && parameters.hasOwnProperty('mode')

    const {
        data: profileData,
        loading,
        error,
        setEnabled: fetchProfileData,
    } = useGetApi(CONFIG.PROFILE_API, props?.token, true)

    const {
        data: updateDetailsResponse,
        isLoading,
        errors,
        setPutEnabled: handleEditDetailsSubmit,
    } = usePutPostApi(
        CONFIG.UPDATE_EXAM_INFO_API,
        props?.token,
        'post',
        finalEditDetailsPayload,
        false,
    )
    const [isLoader, setIsLoading] = useState(isLoading || loading || true)

    useEffect(() => {
        if (isPaymentParam && parameters.hasOwnProperty('editExam')) {
            const updateDetails = localStorage.getItem('originalProfileData')
            const restoredArr = JSON.parse(updateDetails)
            let updateDetailsPayload = {
                ...restoredArr,
                'payment status': parameters.status || '',
            }

            if (parameters.editExam === 'true') {
                window.webengage.track(
                    'iACST_db_confirm_exam_detail_update',
                    updateDetailsPayload,
                )
            }
        }
    }, [isPaymentParam])

    useEffect(() => {
        if (
            updateDetailsResponse &&
            updateDetailsResponse.success &&
            updateDetailsResponse.success === 'true'
        ) {
            setIsLoading(loading || isLoading)
            history.push('/dashboard')
            fetchProfileData(true)

            setTimeout(() => {
                props.setToastMsgsData({
                    isToastOpen: true,
                    toastType: 'Success',
                    toastMessage: updateDetailsResponse.msg,
                })
            }, 700)
        } else if (
            updateDetailsResponse &&
            updateDetailsResponse.success &&
            updateDetailsResponse.success === 'false'
        ) {
            setIsLoading(loading || isLoading)
            setTimeout(() => {
                props.setToastMsgsData({
                    isToastOpen: true,
                    toastType: 'danger',
                    toastMessage: 'Something went wrong. Please try again later.',
                })
            }, 700)
        }
        // eslint-disable-next-line
    }, [updateDetailsResponse])

    useEffect(() => {
        if (parameters.status === 'success' && parameters.editExam === 'true') {
            const getEditExamDetails = JSON.parse(
                localStorage.getItem('editExamDetails'),
            )

            setFinalPayload({
                ...getEditExamDetails,
            })

            handleEditDetailsSubmit(true)
        } else setIsLoading(false)

        // eslint-disable-next-line
    }, [parameters.editExam])

    useEffect(() => {
        const {
            exam_type,
            roll_number,
            student_psid,
            student_dob,
            branch_code,
            class: division,
            classtream,
            stream,
            mobile_number,
        } = props?.profileInfo

        const { product_title, product_id, product_course_term } =
            props?.ecommerce.finalCourseDetailsPayload

        const { scholarship_msg, overall_percentage, scholarship_percentage } =
            props?.dashboardData

        if (
            parameters.status === 'success' &&
            parameters.hasOwnProperty('mode') &&
            parameters.type === 'iacstecomm'
        ) {
            props.setecommerceSuccess(true)

            // Webengage for Successfull course Purchase
            window.webengage.track('iACST_db_pr_course_payment_successful', {
                'mobile number': mobile_number,
                'roll number': roll_number,
                psid: student_psid ? student_psid : 'NA',
                exam_type: exam_type,
                class_studying: division.toString(),
                class_opted: classtream.split('&')[0],
                stream: stream,
                center_code: branch_code,
                course_name: product_title,
                course_id: product_id,
                course_term: product_course_term,
                scholarship_waiver_message: scholarship_msg,
                score_percent: overall_percentage,
                scholarship_percentage: scholarship_percentage,
                dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
            })
        } else if (
            (parameters.status === 'failure' || parameters.status === 'aborted') &&
            parameters.hasOwnProperty('mode') &&
            parameters.type === 'iacstecomm' &&
            !props?.ecommerce?.retryPayment
        ) {
            props.setecommerceFailed(true)

            // Webengage for Failure course Purchase
            window.webengage.track('iACST_db_pr_course_payment_failure  ', {
                'mobile number': mobile_number,
                'roll number': roll_number,
                psid: student_psid ? student_psid : 'NA',
                exam_type: exam_type,
                class_studying: division.toString(),
                class_opted: classtream.split('&')[0],
                stream: stream,
                center_code: branch_code,
                course_name: product_title,
                course_id: product_id,
                course_term: product_course_term,
                scholarship_waiver_message: scholarship_msg,
                score_percent: overall_percentage,
                scholarship_percentage: scholarship_percentage,
                dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
            })
        } else {
            // Reset all the Redux Values.
            props.setResetReducer()
        }
    }, [parameters.status])

    useEffect(() => {
        if (isPaymentParam) {
            let payStatus = localStorage.getItem('payStatus')

            if (
                profileData &&
                Object.keys(profileData['data']).length > 0 &&
                (payStatus !== parameters.status ||
                    parameters.status === 'failure') &&
                parameters.editExam === 'false'
            ) {
                console.log('payment success---')
                window.webengage.track('ANTHE_db_mock_payment_status', {
                    price: localStorage.getItem('mock_amount') || '50',
                    'exam type': 'anthe',
                    'roll number': profileData.data.roll_number,
                    class: profileData.data.class.toString(),
                    stream: profileData.data.stream,
                    'payment method': parameters.mode,
                    'payment status': parameters.status,
                })
                localStorage.setItem('payStatus', parameters.status)
                history.push('/dashboard')
            }
        }
    }, [parameters.mode, profileData])

    useEffect(() => {
        if (profileData) {
            props.setProfileData(profileData.data)
            const {
                email,
                exam_type,
                roll_number,
                student_dob,
                class: division,
                stream,
                mobile_number,
                student_firstname,
                exam_center_id,
            } = profileData.data
            //mobile num is the unique user identifier being used here
            let loginObj = {
                'exam type': 'anthe',
                'roll number': roll_number,
                dob: student_dob,
                'utm parameters': localStorage.getItem('utmParams'),
            }
            let sessionObj = {
                'exam type': exam_type,
                'roll number': roll_number,
                dob: student_dob,
                class: division.toString(),
                stream: stream,
                branchcode: exam_center_id,
                exam_mode: profileData.data.exam_mode_and_slot,
                exam_state: profileData.data.exam_state
                    ? profileData.data.exam_state
                    : '',
                exam_centre: profileData.data.exam_center,
                exam_centre_code: profileData.data.exam_center_id
                    ? profileData.data.exam_center_id
                    : '',
                exam_date: new Date(
                    profileData.data.exam_date_id + 'T03:30:00.000Z',
                ),
                num_attempt: profileData.data.max_exam_edit_allowed == 2 ? 1 : 2,
                dashboard_password: student_dob,

                dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
            }
            let loginStatus = localStorage.getItem('loginStatus')
            if (loginStatus != 'true') {
                window.webengage.user.login(mobile_number)
                window.webengage.user.setAttribute(
                    'we_first_name',
                    student_firstname,
                )
                window.webengage.user.setAttribute('we_phone', mobile_number)
                window.webengage.user.setAttribute('we_email', email)
                window.webengage.track('iACST_login_sign_in', loginObj)
                // window.webengage.track('iACST_db_session_start',sessionObj)
                localStorage.setItem('loginStatus', 'true')
            }
            console.log(sessionObj)
            window.webengage.track('iACST_db_session_start', sessionObj)
            if (profileData.data.exam_taken) {
                window.webengage.track('iACST_db_main_attendance', {
                    attendance: profileData.data.exam_taken,
                })
            }
        }

        // eslint-disable-next-line
    }, [profileData])

    return (
        <Fragment>
            {isLoader ? (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                    }}
                >
                    <SunspotLoader
                        gradientColors={['#6366F1', '#E0E7FF']}
                        shadowColor={'#3730A3'}
                        desktopSize={'30px'}
                        mobileSize={'20px'}
                    />
                </div>
            ) : (
                <Switch>
                    <Route exact path="/result" render={() => <Result />} />
                    <Route
                        exact
                        path="/iacst-result"
                        render={() => <IacstResults />}
                    />

                    <Route
                        exact
                        path="/dashboard"
                        render={() => (
                            <>
                                <Header
                                    fetchProfileData={fetchProfileData}
                                    data={props?.token}
                                    profileData={profileData?.data}
                                />
                                {props?.ecommerce?.ecommerceSuccess ? (
                                    <ECommerceSuccessful
                                        profileData={props.profileInfo}
                                        token={props?.token}
                                    />
                                ) : (
                                    <>
                                        {props?.ecommerce?.courseDescVisible ||
                                        props?.ecommerce?.courseRegisterVisible ||
                                        props?.ecommerce?.ecommerceFailure ? (
                                            <CourseDescription />
                                        ) : (
                                            <>
                                                <div className="bg-white">
                                                    <RegistartionSteps />
                                                </div>
                                                <ScholarshipTest
                                                    fetchProfileData={
                                                        fetchProfileData
                                                    }
                                                    updateDetailsResponse={
                                                        updateDetailsResponse
                                                    }
                                                    token={props?.token}
                                                />
                                            </>
                                        )}
                                        {/* <ToastMessage /> */}
                                    </>
                                )}
                            </>
                        )}
                    />
                </Switch>
            )}
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
