import styles from './header.module.css'
import AakashLogo from '../../assets/AakashSignupLogo.png'
import profileicon from '../../assets/profile_icon_anthe.png'
import Callingicon from '../../assets/Calling.png'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import {
    Container,
    Row,
    Col,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Dropdown,
} from 'reactstrap'
import { connect } from 'react-redux'
import EditProfile from 'components/EditProfileDetails/EditProfile'
import { USER_LOGOUT } from 'constants/actionTypes'
import { CUSTOMER_SUPPORT } from 'components/Result/constants'
import { TRUE } from 'sass'

const mapStateToProps = (state) => ({
    profileInfo: state?.profileData,
    token: state?.token,
})

const mapDispatchToProps = (dispatch) => ({
    resetUser: () => dispatch({ type: USER_LOGOUT }),
})

const Header = (props) => {
    const { profileData } = props
    const history = useHistory()
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggle = () => setDropdownOpen((prevState) => !prevState)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const handleClick = () => {
        props.resetUser()
        localStorage.clear()
        window.webengage.user.logout(profileData.mobile_number)
        return history.push('/')
    }

    const handleEitProfile = () => {
        setIsEditModalOpen(!isEditModalOpen)
        window.webengage.track('iACST_db_edit_profile', {
            'mobile number': profileData.mobile_number,
            'roll number': profileData.roll_number,
            psid: profileData.student_psid ? profileData.student_psid : 'NA',
            'exam type': profileData.exam_type,
            class_studying: profileData.class.toString(),
            stream: profileData.stream,
            branchcode: profileData.exam_center_id,
        })
    }
    const customer_support_number = CUSTOMER_SUPPORT
    return (
        <div className={styles.header}>
            <Container className="p-3 mw-100">
                <Row>
                    <Col>
                        <img
                            className={styles.image}
                            src={AakashLogo}
                            alt="Aakash logo"
                        />
                    </Col>
                    <Col
                        xs
                        lg="3"
                        className="d-flex align-items-center justify-content-end"
                    >
                        {props.showmobile!="false" ? < div
                            className={styles.callingTollfree}
                            onClick={() => {
                                window.webengage.track('iACST_db_call_now', {
                                    'support number': '1800-102-272',
                                    'exam type': profileData.exam_type,
                                    center: profileData.center_name,
                                    class: profileData.class,
                                    stream: profileData.stream,
                                })
                            }}
                        >
                            <a
                                href={`tel:${customer_support_number}`}
                                className="font-size-md text-white text-decoration-none d-flex"
                            >
                                <img
                                    src={Callingicon}
                                    className={styles.callingIcon}
                                    alt="calling-icon"
                                />
                                <span className="d-none d-md-block">
                                    {customer_support_number}
                                </span>
                            </a>
                        </div>:""}
                        <div className="ms-4">
                            {profileData ? (
                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle className="bg-blue border-0">
                                        <img
                                            src={profileicon}
                                            alt="user-profile-icon"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu className={styles.logout_dropdown}>
                                        {!profileData.remove_edit_exam_edit_profile && (
                                            <DropdownItem>
                                                <div
                                                    className={styles.logout}
                                                    onClick={handleEitProfile}
                                                >
                                                    View Profile
                                                </div>
                                            </DropdownItem>
                                        )}
                                        <DropdownItem>
                                            <div
                                                className={styles.logout}
                                                onClick={handleClick}
                                            >
                                                Logout
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                {isEditModalOpen && (
                    <EditProfile
                        fetchProfileData={props.fetchProfileData}
                        profileData={profileData}
                        token={props.token}
                        isModalOpen={isEditModalOpen}
                        toggle={handleEitProfile}
                    />
                )}
            </Container>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
