import token from '../reducers/token'
import dashboardData from '../reducers/dashboardData'
import profileData from '../reducers/profileData'
import rollNo from '../reducers/rollNo'
import dob from '../reducers/dob'
import examInfo from '../reducers/examInfo'
import toastMsgs from '../reducers/toastMsgs'
import userLogout from '../reducers/userLogout'
import ecommerce from '../reducers/ecommerce'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { SUBMIT_TEST, USER_LOGOUT } from '../constants/actionTypes'
import tpResultData from '../reducers/resultData'

const persistConfig = {
    key: 'root',
    storage,
}
const appReducer = combineReducers({
    token,
    dashboardData,
    profileData,
    tpResultData,
    examInfo,
    rollNo,
    dob,
    toastMsgs,
    userLogout,
    ecommerce,
})

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        storage.removeItem('persist:root')
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

// export default rootReducer;
export default persistReducer(persistConfig, (state, action) =>
    rootReducer(action.type === SUBMIT_TEST ? undefined : state, action),
)
