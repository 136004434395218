import React from 'react'
import { Col, Row } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { SunspotLoader } from 'react-awesome-loaders'

import AakashLogo from '../../assets/aakash-logo.png'
import AntheMobileBanner from '../../assets/anthe-mobile-banner.webp'
import AntheDesktopBanner from '../../assets/anthe-desktop-banner.webp'
import Calender from '../../assets/ImgCalender.png'
import EditIcon from 'assets/iconComp/EditIcon'
import Clock from '../../assets/ImgClock.png'

import config from 'config'
import {
    MAX_OTP_DIGITS,
    RESEND_OTP_TIME,
    MAX_USER_ID_DIGITS,
    CAMPAIGN_NAME,
    CAMPAIGN_ID,
    MOBILE_NUM_LENGTH,
} from 'components/Result/constants'
import useTimer from 'hooks/useTimer'
import useGetApi from 'api/useGetApi'
import ConfirmationModal from './ConfirmationModal'
import { InputField } from 'components/shared/InputField'
import styles from '../Login/forgotPassword.module.css'
import { usePutPostApi } from 'api/usePutPostApi'
import { SET_TOAST_DATA } from 'constants/actionTypes'
import { connect } from 'react-redux'
import CompleteEnrollment from './CompleteEnrollmentForm'
import { getURLParameters } from 'utils/getAllURLParameters'

const mapDispatchToProps = (dispatch) => ({
    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
})

function Exigency(props) {
    const queryString = window.location.href
    const url = new URL(queryString)
    const parameters = getURLParameters(url.search)

    const history = useHistory()
    const { setToastMsgsData } = props

    const [isModalOpen, setModalOpen] = React.useState(false)
    const [isOtpVerified, setOtpVerifyStatus] = React.useState(false)

    const [otp, setOtp] = React.useState('')
    const [userId, setUserId] = React.useState('')
    const [showOtp, setShowOtp] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState({ userId: '', otp: '' })

    // custom hooks
    const { data: exigencyAvailabilityResponse, error } = useGetApi(
        config.EXIGENCY_DATE_TIME_API,
        '',
    )
    const { timer, isTimerRunning, togglePlayPause, onResetTimer } = useTimer({
        duration: RESEND_OTP_TIME,
    })

    const staticWebAnalyticsObj = {
        'utm parameters': parameters,
        utm_state: parameters.utm_state,
        utm_center: parameters.utm_branch,
        utm_medium: parameters.utm_medium,
        utm_source: parameters.utm_source,
        utm_campaign: parameters.utm_campaign,
        page_url: queryString,
        campaign_id: CAMPAIGN_ID,
        campaign_name: CAMPAIGN_NAME,
        campaign_source: CAMPAIGN_NAME,
    }

    React.useEffect(() => {
        if (
            !parameters.utm_source ||
            parameters.utm_source.toLowerCase() !== 'exigency' ||
            !parameters.utm_branch
        )
            history.replace('/404')

        if (exigencyAvailabilityResponse) {
            /*  run date/time API on initial render to check Exigency Availability */
            const { success, msg } = exigencyAvailabilityResponse

            if (success) {
                setModalOpen(true)
                window.webengage.track(
                    'ANTHE_EXI_1_Session_Start',
                    staticWebAnalyticsObj,
                )
            } else if (!success) {
                window.webengage.track(
                    'ANTHE_EXI_9_Out_Of_Window',
                    staticWebAnalyticsObj,
                )
                history.replace('/anthe-exigency/unavailable', {
                    availableDates: msg,
                })
            }
        }

        // eslint-disable-next-line
    }, [exigencyAvailabilityResponse])

    React.useEffect(() => {
        if (error !== null) {
            setToastMsgsData({
                isToastOpen: true,
                toastType: 'error',
                toastMessage: error.msg || 'An error occurred!',
            })
            history.push('/anthe-exigency/not-conducted')
        }

        // eslint-disable-next-line
    }, [error])

    const retrieveCurrentYear = () => {
        return new Date().getFullYear()
    }

    const generateOtpPayload = { mobile_number: userId }

    // API calls via Custom-hooks
    const {
        data: generateOtpResponse,
        loading: isOtpGenerating,
        setPutEnabled: callGenerateOtp,
    } = usePutPostApi(
        config.EXIGENCY_GENERATE_OTP_API,
        '',
        'post',
        generateOtpPayload,
        false,
    )

    const verifyOtpPayload = {
        otp: otp,
        mobile_number:
            generateOtpResponse !== null && generateOtpResponse.data
                ? generateOtpResponse.data.mobile_no
                : '',
        branch_code: parameters.utm_branch,
        utm_source: parameters.utm_source,
        utm_medium: parameters.utm_medium,
        utm_campaign: parameters.utm_campaign,
    }

    const {
        data: validateOtpResponse,
        loading: isOtpVerifying,
        setPutEnabled: callVerifyOtp,
    } = usePutPostApi(
        config.EXIGENCY_VERIFY_OTP_API,
        '',
        'post',
        verifyOtpPayload,
        false,
    )

    React.useEffect(() => {
        if (generateOtpResponse && generateOtpResponse.success === 'true') {
            setShowOtp(true)
            setToastMsgsData({
                isToastOpen: true,
                toastType: 'Success',
                toastMessage: generateOtpResponse.msg || 'Success',
            })
        } else if (generateOtpResponse && generateOtpResponse.success === 'false')
            setErrorMsg({
                ...errorMsg,
                userId: generateOtpResponse.msg || 'Invalid',
            })

        // eslint-disable-next-line
    }, [generateOtpResponse])

    React.useEffect(() => {
        if (validateOtpResponse && validateOtpResponse.data) {
            const {
                is_Appeared_for_Exam,
                whatsapp_optin,
                student_data,
                Exam_updated,
                is_Registered,
                student_register,
            } = validateOtpResponse.data
            const { mobile_no, is_mobile } = generateOtpResponse.data
            const isWhatsappActive = whatsapp_optin === 'true'

            window.webengage.user.login(mobile_no)
            window.webengage.user.setAttribute(
                'we_whatsapp_opt_in',
                isWhatsappActive,
            )
            window.webengage.track('ANTHE_EXI_5_Submit_OTP', {
                ...staticWebAnalyticsObj,
                ...(is_mobile
                    ? { 'mobile number': mobile_no }
                    : { psid: userId, 'mobile number': mobile_no }),
                whatsappoptin: isWhatsappActive,
            })
            if (Exam_updated) {
                history.replace('/anthe-exigency/registration-successful', {
                    registrationDetails: student_register,
                    staticWebAnalyticsObj,
                    is_Registered,
                })
            } else if (is_Appeared_for_Exam) {
                const {
                    student_roll_no,
                    student_dob,
                    student_name,
                    student_email,
                    father_name,
                    student_branch_code,
                    class_stream_name,
                } = student_data

                window.webengage.track('ANTHE_EXI_8_Exam_Already_Given', {
                    ...staticWebAnalyticsObj,
                    roll_number: student_roll_no,
                    mobile: mobile_no,
                    student_name,
                    father_name,
                    email_id: student_email,
                    dob: student_dob,
                    class_studying_stream: class_stream_name,
                    state: parameters.utm_state,
                    center: student_branch_code,
                })

                history.replace('/anthe-exigency/exam-given')
            } else setOtpVerifyStatus(true)
        } else if (otp && validateOtpResponse && !validateOtpResponse.success)
            setErrorMsg({ ...errorMsg, otp: validateOtpResponse.msg })

        // eslint-disable-next-line
    }, [validateOtpResponse])

    const setWebEngageForConfirmationModal = (take_exam_today = 'Yes') => {
        window.webengage.track('ANTHE_EXI_2_Give_Exam', {
            ...staticWebAnalyticsObj,
            take_exam_today,
        })
    }

    const handleNotWantToConduct = () => {
        setWebEngageForConfirmationModal('No')
        history.push('/anthe-exigency/not-conducted')
    }

    const closeConfirmationModal = () => {
        setWebEngageForConfirmationModal('Yes')
        setModalOpen(false)
    }

    const handleUserIdChange = (e) => {
        const { value } = e.target
        if (value.length) {
            setErrorMsg({ ...errorMsg, userId: '' })
        }

        if (value.length <= MAX_USER_ID_DIGITS) setUserId(e.target.value)
    }

    const editMobileNumBtn = (
        <span className={styles.inputAddOnEditBtn}>
            <EditIcon />
        </span>
    )

    const maskedMobNum = (userMobileNum) => {
        const last4Digits = userMobileNum.substring(userMobileNum.length - 4)
        return `${'*'.repeat(6)}${last4Digits}`
    }

    const handleEditMobileNum = () => {
        showOtp && setShowOtp(false)
    }

    const handleOtpChange = (event) => {
        const { value } = event.target
        if (value.length <= MAX_OTP_DIGITS) setOtp(value)
        setErrorMsg({ ...errorMsg, otp: '' })
    }

    const handleResendOtp = () => {
        window.webengage.track('ANTHE_EXI_4_Resend_OTP', {
            ...staticWebAnalyticsObj,
            ...(userId.length !== MAX_USER_ID_DIGITS
                ? { 'mobile number': userId }
                : { psid: userId }),
        })

        callGenerateOtp(true)

        onResetTimer()
        togglePlayPause()
    }

    const handleSubmit = () => {
        if (userId.length === MOBILE_NUM_LENGTH && userId[0] === '0') {
            setErrorMsg({
                ...errorMsg,
                userId: 'Mobile number can not start with 0',
            })
            return
        } else {
            onResetTimer()
            togglePlayPause()

            window.webengage.track('ANTHE_EXI_3_Verify_Mobile', {
                ...staticWebAnalyticsObj,
                ...(userId.length !== MAX_USER_ID_DIGITS
                    ? { 'mobile number': userId }
                    : { psid: userId }),
            })

            // clear OTP field & its error
            setErrorMsg({ ...errorMsg, otp: '' })
            setOtp('')
            // API call
            callGenerateOtp(true)
        }
    }

    const handleVerifyOtp = () => {
        callVerifyOtp(true)
    }

    if (isOtpVerified)
        return (
            <CompleteEnrollment
                isStudentAlreadyRegistered={validateOtpResponse.data.is_Registered}
                studentDetails={validateOtpResponse.data.student_data}
                studentMobNum={generateOtpResponse.data.mobile_no}
                staticWebAnalyticsObj={staticWebAnalyticsObj}
            />
        )
    return (
        <div>
            {isModalOpen && (
                <ConfirmationModal
                    closeTheModal={closeConfirmationModal}
                    notWantToConductExigency={handleNotWantToConduct}
                />
            )}
            <div className="w-full m-2 d-flex justify-content-between align-items-center maxW-lg-85 mx-lg-auto">
                <img src={AakashLogo} alt="Aakash-colored-logo" />
                <div className="font-size-lg font-size-md-xl fw-bold lh-sm text-end maxW-60 maxW-md-100">
                    Aakash National Talent Hunt Exam
                </div>
            </div>

            <div>
                <img
                    src={AntheMobileBanner}
                    alt="Anthe banner"
                    width="100%"
                    className="d-md-none"
                />
                <img
                    src={AntheDesktopBanner}
                    width="100%"
                    alt="Anthe banner"
                    className="d-none d-md-block w-full"
                />
            </div>

            <div className="mx-3 my-4">
                <div className="m-auto maxW-fit font-size-3xl fw-bold text-dark-blue">
                    ANTHE {retrieveCurrentYear()}
                </div>

                {/* Enter mob/psid form */}
                <section className="bg-light-blue rounded-10px my-4 p-4 maxW-md-85 maxW-lg-75 mx-auto">
                    <div className="maxW-md-370 m-auto">
                        <p className="font-size-lg fw-bold text-cobalt text-center m-0 mb-4">
                            REGISTER NOW FOR FREE
                        </p>
                        <InputField
                            name="psid/mob-no"
                            placeholder="Mobile No. or PSID"
                            value={userId}
                            type="number"
                            onChange={handleUserIdChange}
                            required
                            inputGroupStyles="shadow-sm"
                            inputBoxStyles={`${
                                errorMsg.userId.length
                                    ? 'border border-error-red'
                                    : 'border-0'
                            }`}
                            errorMsg={errorMsg.userId}
                            readOnly={showOtp}
                            actionBtn={showOtp ? editMobileNumBtn : null}
                            onInternalBtnClick={handleEditMobileNum}
                        />
                        {showOtp && (
                            <div className="text-center">
                                <p
                                    className={`font-size-xxs fw-bold mb-0 ${
                                        isTimerRunning ? 'visible' : 'invisible'
                                    }`}
                                >
                                    Time Left{' '}
                                    <span>
                                        {Math.floor(timer / 60)}:
                                        {timer >= 60 ? '00' : timer}
                                    </span>
                                </p>

                                <p
                                    className={`font-size-xxs fw-bolder text-secondary mb-0 ${
                                        generateOtpResponse &&
                                        !generateOtpResponse.data.is_mobile
                                            ? 'visible'
                                            : 'invisible'
                                    }`}
                                >
                                    OTP has been sent on mobile{' '}
                                    {maskedMobNum(
                                        generateOtpResponse.data.mobile_no,
                                    )}
                                </p>
                            </div>
                        )}

                        {/* OTP field */}
                        {showOtp && (
                            <InputField
                                name="otp"
                                placeholder="OTP"
                                type="number"
                                inputGroupStyles="shadow-sm mt-2"
                                inputBoxStyles={`${
                                    errorMsg.otp.length
                                        ? 'border border-error-red'
                                        : 'border-0'
                                }`}
                                required
                                value={otp}
                                onChange={handleOtpChange}
                                errorMsg={errorMsg.otp}
                                actionBtn={
                                    isTimerRunning ? null : (
                                        <p className="text-aqua text-decoration-underline cursor-pointer font-size-xs fw-bolder p-3 m-auto">
                                            Resend
                                        </p>
                                    )
                                }
                                onInternalBtnClick={handleResendOtp}
                            />
                        )}

                        {/* Form buttons */}
                        {showOtp ? (
                            <button
                                className={`btn btn-aqua text-white w-10 font-size-sm fw-lighter rounded-10px mt-3 mb-2`}
                                style={{ padding: '11px' }}
                                onClick={handleVerifyOtp}
                                disabled={
                                    errorMsg.otp || otp.length !== MAX_OTP_DIGITS
                                }
                            >
                                {isOtpVerifying ? (
                                    <SunspotLoader
                                        gradientColors={['#fafafa', '#fff']}
                                        size={'10px'}
                                        desktopSize={'10px'}
                                        className="m-auto"
                                    />
                                ) : (
                                    ' Submit'
                                )}
                            </button>
                        ) : (
                            <button
                                style={{ padding: '11px' }}
                                className={`btn btn-aqua text-white w-10 font-size-sm fw-lighter rounded-10px mt-3 mb-2 mx-auto`}
                                onClick={handleSubmit}
                                disabled={
                                    errorMsg.userId ||
                                    (userId.length !== MOBILE_NUM_LENGTH &&
                                        userId.length !== MAX_USER_ID_DIGITS)
                                }
                            >
                                {isOtpGenerating ? (
                                    <SunspotLoader
                                        gradientColors={['#fafafa', '#fff']}
                                        size={'10px'}
                                        desktopSize={'10px'}
                                        className="m-auto"
                                    />
                                ) : (
                                    'Verify mobile number'
                                )}
                            </button>
                        )}
                        <p className="text-center font-size-xs mt-3">
                            By submitting this form I agree to the{' '}
                            <a
                                href="https://anthe.aakash.ac.in/anthe/terms-conditions"
                                target="_blank"
                                rel="noreferrer"
                                className="text-aqua text-decoration-underline fw-lighter"
                            >
                                T&C
                            </a>
                            ,&nbsp;
                            <a
                                href="https://www.aakash.ac.in/privacypolicy"
                                target="_blank"
                                rel="noreferrer"
                                className="text-aqua text-decoration-underline fw-lighter"
                            >
                                Privacy Policy
                            </a>
                            &nbsp;and receiving SMS & WhatsApp communication{' '}
                            <span className="text-error-red">*</span>
                        </p>
                        <p className="text-center font-size-sm mb-0">
                            Already Registered?{' '}
                            <a
                                href="/"
                                className="text-decoration-none text-aqua fw-lighter"
                            >
                                Login now
                            </a>
                        </p>
                    </div>
                </section>

                <Row className="text-dark-blue maxW-lg-75 maxW-xl-60 mx-auto mt-5">
                    <Col className="d-flex align-items-center mb-4 mb-md-0" md={6}>
                        <img
                            src={Calender}
                            alt="Calender-icon"
                            className="w-80px w-md-100px"
                        />
                        <div className="ms-2">
                            <h3 className="fw-bold">Exam Dates</h3>
                            <p className="mb-0">Online: 7th Oct to 15th Oct</p>
                            <p className="mb-0">Offline: 8th Oct & 15th Oct</p>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center" md={6}>
                        <img
                            src={Clock}
                            alt="Clock-icon"
                            className="w-80px w-md-100px"
                        />
                        <div className="ms-2">
                            <h3 className="fw-bold">Offline Exam Timing</h3>
                            <p className="mb-0">Morning: 10:30 AM - 11:30 AM</p>
                            <p className="mb-0">Evening: 04:00 PM - 05:00 PM</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default connect(null, mapDispatchToProps)(Exigency)
