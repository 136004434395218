import React, { useState, useEffect, useContext } from 'react'
import { result } from 'api/api'
import { getUrlSearchParams } from 'components/Result/helper'
import eventContext from 'eventContext'

function useResultData() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [error, setError] = useState()
    const [dataFailureMsg, setDataFailureMsg] = useState()
    const handleEventLogs = useContext(eventContext)

    useEffect(() => {
        setLoading(true)
        const params = getUrlSearchParams()
        result(params)
            .then((res) => res.data)
            .then((res) => {
                const isSuccess = JSON.parse(res.success)
                setLoading(false)
                setData(res.data)
                setDataFailureMsg(res.msg)

                if (isSuccess) {
                    handleEventLogs(
                        'pageview_result_success_react',
                        'Result view success',
                        {
                            admin_type: JSON.parse(params.admin)
                                ? 'admin'
                                : 'student',
                            roll_no: params.roll_number,
                            dob: params.date_of_birth,
                            class_studying: res.data?.result?.ClassStudying || null,
                            class_opted: res.data?.result?.ClassOpted || null,
                        },
                    )
                } else {
                    handleEventLogs(
                        'pageview_result_failure_react',
                        'Result view failure',
                        {
                            admin_type: JSON.parse(params.admin)
                                ? 'admin'
                                : 'student',
                            roll_no: params.roll_number,
                            dob: params.date_of_birth,
                            class_studying: res.data?.result?.ClassStudying || null,
                            class_opted: res.data?.result?.ClassOpted || null,
                        },
                    )
                }
            })
            .catch((err) => {
                if (err) {
                    setError(
                        err.message ||
                            err.response.message ||
                            'Something went wrong!',
                    )
                }
                handleEventLogs(
                    'pageview_result_failure_react',
                    'Result view failure',
                    {
                        admin_type: JSON.parse(params.admin) ? 'admin' : 'student',
                        roll_no: params.roll_number,
                        dob: params.date_of_birth,
                        class_studying: err.data?.result?.ClassStudying || null,
                        class_opted: err.data?.result?.ClassOpted || null,
                    },
                )
            })
    }, [])

    return { loading, data, error, dataFailureMsg }
}

export default useResultData
