import React from 'react'
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap'
import styles from './card.module.css'
import Lock from '../../assets/Lock'
import { SunspotLoader } from 'react-awesome-loaders'
import { mockPayment } from 'api/api'
import { connect } from 'react-redux'
import CONFIG from '../../config'
import PaymentForm from './paymentForm'
import MobilePaymentForm from 'components/shared/MobilePaymentForm'

const mapStateToProps = (state) => ({
    token: state?.token,
})

function MockExamLocked(props) {
    const [isLoading, setIsLoading] = React.useState(false)
    const [paymentMethod, setPaymentMethod] = React.useState('paytm')
    const [filteredFormValues, setfilteredFormValues] = React.useState({})
    const { profileData, token } = props

    const handlePaymentMethod = (e) => {
        let name = e.target.name.toLowerCase()
        setPaymentMethod(name)
    }

    const handleSubmit = async () => {
        setIsLoading(true)

        // API call
        const mockPrice = await mockPayment(
            token,
            `${CONFIG.MOCK_PAYMENT_API}?payment_mode=${paymentMethod}`,
        ).then((res) => {
            if (res.data.success === 'true') {
                setfilteredFormValues(res.data.data)
                localStorage.setItem(
                    'mock_amount',
                    res.data.data.amount || res.data.data.TXN_AMOUNT,
                )
                return res.data.data.amount || res.data.data.TXN_AMOUNT
            } else {
                console.log('Error occurred: ', res.data.msg)
            }

            setIsLoading(false)
            return 50 // default mock price value
        })
        // web-engage event
        window.webengage.track('ANTHE_db_mock_proceed_to_pay', {
            'roll number': profileData.roll_number,
            'exam type': profileData.exam_type,
            class: profileData.class && profileData.class.toString(),
            stream: profileData.stream,
            price: mockPrice && mockPrice.toString(),
            'payment method': paymentMethod,
        })
    }

    return (
        <>
            <Card className={`${styles.lockModal} border-0 w-100 h-100`}>
                <CardBody className="p-3 d-lg-flex">
                    <Row className="justify-content-xs-between my-1 col-xs-12 col-lg-7 col-xl-8 my-auto">
                        <Col xs={3} xl={2} className="align-self-baseline d-flex">
                            <Button
                                className={`${styles.modalLockBtn} w-11 font-size-xxl py-3 py-lg-4 fw-bold border-white rounded-1`}
                                style={{ padding: 'initial' }}
                            >
                                <Lock fill={'white'} width="40%" height="40" />
                            </Button>
                        </Col>
                        <Col xs={9} md={7} className="p-1">
                            <p className="font-size-md text-white fw-bold mb-2">
                                ANTHE MOCK TEST PAPER
                            </p>
                            <p className="font-size-sm font-size-md-md font-size-xl-lg font-size-xl-xxl m-0 fw-lighter text-white">
                                Set of 4 mock test paper
                            </p>
                            <p className="font-size-xs font-size-md-xs font-size-xl-md fw-normal text-white">
                                High chances of getting scholarship
                            </p>
                        </Col>
                    </Row>
                    <Row
                        className={`${styles.paymentFormContainer} d-flex align-items-center col-xs-12 col-lg-5 col-xl-4 h-xl-75 mx-1 py-4 my-auto rounded-8px`}
                    >
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            <Row className="d-flex justify-content-around">
                                <MobilePaymentForm
                                    paymentMethod={paymentMethod}
                                    handlePaymentMethod={handlePaymentMethod}
                                />
                            </Row>

                            <Button
                                type="submit"
                                className={`w-100 bg-aqua border-0 font-size-md fw-lighter mt-4`}
                            >
                                {isLoading ? (
                                    <SunspotLoader
                                        gradientColors={['#4039d4', '#E0E7FF']}
                                        size={'10px'}
                                        desktopSize={'10px'}
                                        className="m-auto"
                                    />
                                ) : (
                                    'Buy now at just ₹50'
                                )}
                            </Button>
                        </Form>

                        {Object.keys(filteredFormValues).length > 0 && (
                            <PaymentForm filteredFormValues={filteredFormValues} />
                        )}
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default connect(mapStateToProps)(MockExamLocked)
