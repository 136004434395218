export const changeToIst = (date) => {
    const localTime = new Date(date)
    const year = localTime.getFullYear()
    const month = (localTime.getMonth() + 1).toString().padStart(2, '0')
    const selectedDate = localTime.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${selectedDate}`
}

export const parseToIst = (dateString) => {
    //expected: dd/MM/yyyy

    const dateParts = dateString.split('/')

    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
}

export const webEngageDob = (date) => {
    const localTime = new Date(date)
    const year = localTime.getFullYear()
    const month = (localTime.getMonth() + 1).toString().padStart(2, '0')
    const selectedDate = localTime.getDate().toString().padStart(2, '0')

    return `${selectedDate}-${month}-${year}`
}
