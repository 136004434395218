import React from 'react'
import AAKASH_LOGO from 'assets/pdf/aakash-anthe-logo.png'
import COMING_SOON from 'assets/pdf/coming-soon-image.png'
import styles from './coming-soon.module.scss'

function ComingSoon() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <img src={AAKASH_LOGO} alt="logo" />
                <div className={styles.content}>
                    <p className={styles.header}>
                        Your result would be available soon.
                    </p>
                    <p className={styles.description}>
                        Please connect at{' '}
                        <a href="mailto:iacstsupport@aesl.in">
                            {' '}
                            iacstsupport@aesl.in
                        </a>{' '}
                        for any query regarding your result.
                    </p>
                    <p className={styles.subtitle}>
                        Your results would be available in the Exam/Result sections
                        after 4 hours of <br /> test submission once you log into
                        your IACST account
                    </p>
                </div>
            </div>
            <img className={styles.img} src={COMING_SOON} alt="coming-so" />
        </div>
    )
}

export default ComingSoon
