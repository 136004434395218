export const beforeTestFAQs = [
    {
        question: 'Is the iACST open for all Classes and Streams?',
        answer: 'iACST is open for Class studying 7th, 8th, 9th 10th (SOE & SOM), 11th & 12th SOM & SOE.',
        type: 'text',
    },
    {
        question: 'What are the exam timings?',
        answer: 'Online exam from 9 AM to 10 PM. Students can enroll at any time in the exam window.',
        type: 'text',
    },
    {
        question: 'What are the guidelines for appearing in the exam on the same day as registration?',
        type: 'text',
        answer: 'For online exam, students have to complete registration before 9 PM.',
    },
    {
        question:
            'How to update my personal details (Like dob, e-mail Id, and Address details) in IACST?',
        type: 'text',
        answer: 'You also call on IACST Toll-Free No. 8800198748 or write to <a href="mailto:acstsupport@aesl.in">acstsupport@aesl.in</a> or submit their request by filling out the Google form: <a href="https://forms.gle/EA5uzfGmKEzaeg4P8" target="_blank">https://forms.gle/EA5uzfGmKEzaeg4P8</a> to get their details changed ',
    },
    {
        question: 'How should we get the Class/Date/Stream changed for the student?',
        type: 'text',
        answer: 'You call on IACST Toll-Free No. 8800198748 or write on <a href="mailto:acstsupport@aesl.in">acstsupport@aesl.in</a>  or submit their request by filling out the Google form:<a href="https://forms.gle/EA5uzfGmKEzaeg4P8" target="_blank"> https://forms.gle/EA5uzfGmKEzaeg4P8</a> to get their details changed',
    },
    {
        question: "Will I be able to give the exam if the student's PS ID is not created?",
        type: 'text',
        answer: 'Yes, you will be able to give the exam without the PS ID.',
    },
    {
        question: 'What are the supported browsers & operating systems to write IACST Online?',
        type: 'text',
        answer: '<ul><li>Device Support: Mobile, Desktop, Tablet</li><li>Browser Support: Chrome, Samsung Internet, Safari, Edge, Opera, Firefox.</li><li>OS Support: Android (Android 7 and above), Windows (Windows 7,8,8.1,10, 11), Linux, iOS (iOS 12 and above), MacOS (Mac OS 10 and above)</li><ul><li> Windows 7, 8, 8.1, 10, 11: Preferred Browser - Chrome, Edge, Firefox</li><li>Android OS version (7 and above):Preferred Browser - Chrome, Firefox, Samsung Internet browser for Samsung devices</li><li>MAC OS version 10 & above. Preferred browser - Chrome, Safari</li><li>IOS OS version 12 & above. Preferred browser - Chrome, Safari</li><li>Linux. Preferred browser: Chrome</li></ul></ul>',
    },
    {
        question: 'Student says the IACST Test is not showing on the dashboard.',
        type: 'text',
        answer: 'Please check the your details for which tests are not visible. Main IACST test card will always be visible on the dashboard. The status of the Main IACST card can be<ul><li><strong>Missed</strong> - If you did not give the exam on the intended date</li><li><strong>Upcoming</strong> - If your exam is in the future date</li><li><strong>Start Now</strong> - Status on the day of the test</li><li><strong>Result Awaited</strong> - If you have submitted your test.</li><li><strong>View Result</strong> - If you have submitted your test.</li></ul>',
    },
    {
        question: 'I want to take IACST Test in Gujarati Language. How can I change the Language?',
        type: 'text',
        answer: 'The iACST Test in Gujarati language is exclusively available in offline mode. To take the test, please visit your nearest Aakash center. In case you need any further assistance, please contact us at the iACST Toll-Free Number: 8800198748, or email us at <a href="mailto:acstsupport@aesl.in">acstsupport@aesl.in</a>.',
    },
    {
        question: 'What should we do if a student is not able to view questions/images of the test?',
        type: 'text',
        answer: 'Clear the cache, close all the browser tabs and open the test in a new incognito window in chrome browser. The test attempts will remain intact<br/>Steps to clear cache on Chrome<ul><li>On your computer, open Chrome.</li><li>At the top right, click More.</li><li>Click More tools and then Clear browsing data.</li><li>At the top, choose a time range. To delete everything, select All time.</li><li>Next to "Cookies and other site data" and "Cached images and files," check the boxes.</li><li>Click Clear data</li></ul>',
    },
    {
        question: 'What should we do if a student submits the test by mistake?',
        type: 'text',
        answer: 'You call on IACST Toll-Free No. 8800198748 or write on <a href="mailto:acstsupport@aesl.in">acstsupport@aesl.in</a> or submit their request by filling out the Google form: <a href="https://forms.gle/EA5uzfGmKEzaeg4P8" target="_blank">https://forms.gle/EA5uzfGmKEzaeg4P8</a> to get their details changed',
    },
    {
        question: 'Question not appearing by clicking on the next button',
        type: 'text',
        answer: 'Clear the cache, close all the browser tabs and open the test in a new incognito window in chrome browser. The test attempts will remain intact',
    },
    {
        question: 'What should we do if I student is not able to view questions/images of the test',
        type: 'text',
        answer: 'Clear the cache, close all the browser tabs and open the test in a new incognito window in the chrome browser. The test attempts will remain intact<ul><li>On your computer, open Chrome.</li><li>At the top right, click the three vertical dots.</li><li>Click More tools and then Clear browsing data.</li><li>At the top, choose a time range. To delete everything, select All time.</li><li>Next to "Cookies and other site data" and "Cached images and files," check the boxes.</li><li>Click Clear data.</li><li>Login again to the </li><li>Check the internet connection. The student can write “internet speed test” on google and check the speed. This works for both mobile and desktop. The result should say “Your internet speed is fast or very fast”. PFB the screenshot for your reference. <img src="https://dcx0p3on5z8dw.cloudfront.net/Aakash/s3fs-public/inline-images/faq-img.png" alt="pdf-icon" style="width: 100%;margin-top: 10px"/></li></ul>',
    },
]

export const afterTestFAQs = [
    {
        question: 'What courses are available at Aakash BYJU’S?',
        answer: 'Aakash BYJU’S offers courses for medical, and engineering entrance exams and scholarship exams such as NEET, JEE, Olympiads, NTSE and NSEs. You can take admission in one-year, two-year, three-year or four-year courses. You can download the latest prospectus here.<a href="https://aakash.ac.in/prospectus" target=_blank>(https://aakash.ac.in/prospectus).</a>',
        type: 'text',
    },
    {
        question: 'Can I get a scholarship on Aakash BYJU’S courses?',
        answer: 'We conduct scholarship exams throughout the year through which students can avail of scholarships on the course fee. These scholarship exams are completely online and can be taken online from the comfort of your home. You can appear for these scholarship exams in online or offline mode at any of the Aakash BYJU’S branches/centres as well.',
        type: 'text',
    },
    {
        question: 'How do I find an Aakash BYJU’S center?',
        answer: 'Students can refer to the center address and Google map link mentioned above to locate your nearest Aakash BYJU’S center. Students can  also call on the Branch/center contact numbers for more details.',
        type: 'text',
    },
    {
        question: 'I have some questions regarding courses and the admission process. Can I talk to someone at Aakash BYJU’S?',
        answer: 'You can call us at <a href="tel:1800-102-2727">1800-102-2727</a>. Our admission assistance team will answer all your queries.',
        type: 'text',
    },
]
