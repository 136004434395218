import React, { useState, useEffect,useRef } from 'react'
import styles from './branch_map.css'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import Gmap from '../../assets/google_map.png'
import Phone from '../../assets/centre_phone.png'
import { FETCH_BRANCHES } from 'constants/actionTypes'
import useGetApi from 'api/useGetApi'
import CONFIG from 'config'
import { useMediaQuery } from 'react-responsive'
import LeftSlideIcon from 'assets/LeftSlideIcon'
import RightSlideIcon from 'assets/RightSlideIcon'

const Branchmapsection = (props) => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' })
    const mapsRef = useRef(null)
    const [scrollLeftVisible, setScrollLeftVisible] = useState(false);
    const [scrollRightVisible, setScrollRightVisible] = useState(false);

    const apiData = {
        success: true,
        msg: 'success',
        data: [
            {
                center_name: 'Aakash Institute-Chandigarh Branch',
                address: 'SCO 121, 122 & 123, Sector 34A, Sector 34, Chandigarh',
                contact_numbers: [
                    '8800013079',
                    '8284072108',
                    '8989898980',
                    '5676776777',
                ],
                location_url: 'https://maps.app.goo.gl/QgqwtChtQ2h2Xvj37',
            },
            {
                center_name: 'Aakash Institute-Chandigarh Branch',
                address: 'SCO 121, 122 & 123, Sector 34A, Sector 34, Chandigarh',
                contact_numbers: [
                    '8800013079',
                    '8284072108',
                    '8989898980',
                    '5676776777',
                ],
                location_url: 'https://maps.app.goo.gl/QgqwtChtQ2h2Xvj37',
            },
            {
                center_name: 'Aakash Institute-Chandigarh Branch',
                address: 'SCO 121, 122 & 123, Sector 34A, Sector 34, Chandigarh',
                contact_numbers: [
                    '8800013079',
                    '8284072108',
                    '8989898980',
                    '5676776777',
                ],
                location_url: 'https://maps.app.goo.gl/QgqwtChtQ2h2Xvj37',
            },
        ],
    }
    const [branchDetails, setBranchDetails] = useState(apiData.data)

    const {
        data: brancData,
        loading,
        error,
        setEnabled: fetchBranchData,
    } = useGetApi(
        CONFIG.FETCH_BRANCHES + props.profileData.branch_code,
        props?.token,
        true,
    )
    useEffect(() => {
        fetchBranchData(true)
    }, [])
    useEffect(() => {
        if (brancData && brancData.data) {
            let details = brancData.data
            if (props.NearestBranch || props.soloBranch) {
                setBranchDetails([details[0]])
            } else {
                setBranchDetails(details)
            }

        }
    }, [brancData])

    const handleMapsScroll = () => {
        const container = mapsRef.current;
        if (container) {
            setScrollLeftVisible(container.scrollLeft > 0);
            setScrollRightVisible(container.scrollLeft < container.scrollWidth - container.clientWidth);
        }
    }

    useEffect(() => {
        const container = mapsRef.current;
        if (container) {
            setScrollRightVisible(container.scrollLeft + container.clientWidth < container.scrollWidth);
        }
    }, [branchDetails]);

    const scrollLeft = () => {
        const container = mapsRef.current;
        if (container) {
            container.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        const container = mapsRef.current;
        if (container) {
            container.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    return (
        <div className='mapContainer' >
            <div className="branchbox"  onScroll={handleMapsScroll} ref={mapsRef}>
                <div className={`scrollButtonleft ${scrollLeftVisible ? 'd-md-block' : 'd-none' }`} onClick={scrollLeft}>
                    <LeftSlideIcon />
                </div>
                {branchDetails && branchDetails.length > 0
                    ? branchDetails.map((ele, key) => {
                        return (
                            <Col
                                xs={props.NearestBranch ? 12 : 10}
                                sm={props.NearestBranch ? 12 : 10}
                                md={props.NearestBranch ? 12 : 6}
                                lg={props.NearestBranch ? 12 : 8}
                                xl={props.NearestBranch ? 12 : 6}
                                className="branchdetailsbox"
                                key={key}
                            >
                                <Card
                                    className={`pb-3 p-3 mb-3 mx-0 mx-lg-2 ${props.NearestBranch ? 'bg-beige' : ''} ${!isSmallScreen ? 'border-light-grey' : 'border-0'}`}
                                    style={{
                                        boxShadow: isSmallScreen ? '0px 2px 8px rgba(0, 0, 0, 0.15)' : '',
                                        minHeight: '75%',
                                        height: '100%',
                                    }}
                                >
                                    <CardHeader className={`border-0 ${props.NearestBranch ? 'bg-beige' : 'bg-white'} fw-bold p-0 font-size-sm font-size-md-md`}>
                                        {ele.center_name}
                                    </CardHeader>
                                    <CardBody className="p-0 text-grey fw-lighter font-size-xs font-size-md-sm font-size-lg-md">
                                        <p
                                            style={{
                                                height: '40%',
                                                maxHeight: '40%',
                                            }}
                                        >
                                            {ele.address}
                                        </p>
                                        <Row className="ps-2 p-0 my-2">
                                            <Col xs={7} md={8} className="p-0 ps-2">
                                                <p className="text-warning mb-1 mb-lg-2">
                                                    Open until 07:00 PM
                                                </p>
                                                <Row className="m-0 p-0 justifyc-content-center">
                                                    <Col xs={2} className="p-0">
                                                        <img src={Phone} />
                                                    </Col>
                                                    <Col className={`font-size-xxs font-size-md-xs ${props.NearestBranch ? 'font-size-lg-sm' : 'font-size-lg-md'} fw-lighter p-0`}>
                                                        <p className="mb-1">
                                                            <a
                                                                href={
                                                                    'tel:' +
                                                                    ele
                                                                        .contact_numbers[0]
                                                                }
                                                                className={`text-aqua me-1`}
                                                            //   onClick={handleCallNow}
                                                            >
                                                                {
                                                                    ele
                                                                        .contact_numbers[0]
                                                                }
                                                            </a>
                                                            &nbsp;
                                                            <a
                                                                href={
                                                                    'tel:' +
                                                                    ele
                                                                        .contact_numbers[1]
                                                                }
                                                                className={`text-aqua`}
                                                            //   onClick={handleCallNow}
                                                            >
                                                                {
                                                                    ele
                                                                        .contact_numbers[1]
                                                                }
                                                            </a>
                                                            &nbsp;
                                                        </p>
                                                        <p className="mb-1">
                                                            <a
                                                                href={
                                                                    'tel:' +
                                                                    ele
                                                                        .contact_numbers[2]
                                                                }
                                                                className={`text-aqua me-1`}
                                                            //   onClick={handleCallNow}
                                                            >
                                                                {
                                                                    ele
                                                                        .contact_numbers[2]
                                                                }
                                                            </a>
                                                            &nbsp;
                                                            <a
                                                                href={
                                                                    'tel:' +
                                                                    ele
                                                                        .contact_numbers[3]
                                                                }
                                                                className={`text-aqua`}
                                                            //   onClick={handleCallNow}
                                                            >
                                                                {
                                                                    ele
                                                                        .contact_numbers[3]
                                                                }
                                                            </a>
                                                            &nbsp;
                                                        </p>
                                                        {/* {groupContacts(ele.contact_numbers)} */}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={4} className="p-0 ">
                                                <a
                                                    href={ele.location_url}
                                                    target="blank"
                                                >
                                                    <img
                                                        alt={'google_map_link'}
                                                        src={Gmap}
                                                    />
                                                </a>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                    : ''}
                <div className={`scrollButtonright ${scrollRightVisible ? 'd-md-block' : 'd-none'}`} onClick={scrollRight}>
                    <RightSlideIcon />
                </div>
            </div>
        </div>
    )
}
export default Branchmapsection
