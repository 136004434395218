import Icon from '../components/shared/IconBase'

const ProfileIcon = (props) => (
    <Icon width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0105 11.7588C7.84743 11.7588 6.00024 12.0858 6.00024 13.3956C6.00024 14.7053 7.83572 15.0441 10.0105 15.0441C12.1735 15.0441 14.0202 14.7165 14.0202 13.4073C14.0202 12.0981 12.1852 11.7588 10.0105 11.7588Z"
            stroke="#262626"
            strokeWidth="0.533963"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0105 9.89049C11.43 9.89049 12.5805 8.73946 12.5805 7.31998C12.5805 5.9005 11.43 4.75 10.0105 4.75C8.59099 4.75 7.43996 5.9005 7.43996 7.31998C7.43516 8.73467 8.57821 9.8857 9.99236 9.89049H10.0105Z"
            stroke="#262626"
            strokeWidth="0.533963"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

export default ProfileIcon
