import range from 'lodash/range'

const pageStyle = `
    @media print {
        html,
        body {
            margin: 0 !important;
            padding: 0 !important;
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
        }
    }

    @page {
        size: A4 portrait;
        margin: 0 !important;
        padding: 0 !important;
    }
`

const classesByStreams = {
    Foundations: ['VII', 'VIII', 'IX'],
    Medical: ['X', 'XI', 'XII'],
    Engineering: ['X', 'XI', 'XII'],
}

const regex = {
    NUMBER: new RegExp(/^[0-9]+$/, 'g'),
    EMAIL: new RegExp(
        /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/,
        'g',
    ),
    ALPHABETS: new RegExp(/^[A-Za-z ]+$/),
}

const MIN_DOB = new Date('1992-01-01')
const MAX_DOB = new Date('2015-12-31')
const years = range(1992, 2016, 1)
const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]

const MAX_OTP_DIGITS = 4
const RESEND_OTP_TIME = 45 // 45 seconds
const MAX_USER_ID_DIGITS = 11
const MOBILE_NUM_LENGTH = 10
const MIN_NAME_LENGTH = 2
const MAX_NAME_LENGTH = 60
const MAX_SIGNUP_ATTEMPTS = 2 // user can signup with same mobile number in single term

const CAMPAIGN_ID = '701No000002sefSIAQ'
const CAMPAIGN_NAME = 'ANTHE2023_ExigencyLP'
const SUPPORT_PHONE_NUMBER = '7303529494'
const CUSTOMER_SUPPORT = SUPPORT_PHONE_NUMBER

const TERMS_AND_CONDITIONS =
    'https://iacst.aakash.ac.in/iacst-scholarship/terms-conditions'
const PRIVACY_POLICY = 'https://www.aakash.ac.in/privacypolicy'

export {
    pageStyle,
    classesByStreams,
    regex,
    years,
    months,
    MIN_DOB,
    MAX_DOB,
    MAX_OTP_DIGITS,
    MOBILE_NUM_LENGTH,
    RESEND_OTP_TIME,
    MAX_USER_ID_DIGITS,
    MIN_NAME_LENGTH,
    MAX_NAME_LENGTH,
    CAMPAIGN_ID,
    CAMPAIGN_NAME,
    CUSTOMER_SUPPORT,
    SUPPORT_PHONE_NUMBER,
    TERMS_AND_CONDITIONS,
    PRIVACY_POLICY,
    MAX_SIGNUP_ATTEMPTS,
}
