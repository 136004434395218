import IacstLandingPageTemplate from 'utils/iACSTLandingTemplate/IacstLandingPageTemplate'
import {
    aboutCard,
    successStories,
    scholarshipDetails,
    banner_title,
    coursehighlight
} from '../../constants/iacstMedicalEngineeringRepeaterCoursesHindiJson'
import { Helmet } from 'react-helmet'
const IacstMedicalEngineeringRepeaterCoursesHindi = () => {
    const registration_page = 'iacst_repeater_common_hindi'
    return (
        <>
            <Helmet>
                <title>Repeater Courses in Hindi for NEET &amp; JEE Droppers: Aakash iACST -Get Up to 90% Scholarship | Scholarships Exam</title>
                <meta name="description" content="Repeater Courses in Hindi for NEET &amp; JEE Droppers: Aakash iACST - Aakash Instant Scholarship Test is a national level online scholarship test for Class XII Passed students where they instantly get scholarships upto 90% on tuition fee and get admission into Aakash." />
            </Helmet>
            <IacstLandingPageTemplate
                registration_page={registration_page}
                aboutCard={aboutCard}
                coursehighlight={coursehighlight}
                successStories={successStories}
                scholarshipDetails={scholarshipDetails}
                banner_title={banner_title}
            />
        </>
    )
}

export default IacstMedicalEngineeringRepeaterCoursesHindi
