import { SET_PROFILE_DATA } from '../constants/actionTypes'

const setProfileData = (state = {}, action) => {
    switch (action.type) {
        case SET_PROFILE_DATA:
            return action.payload

        default:
            return state
    }
}

export default setProfileData
