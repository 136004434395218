import CheckIcon from 'assets/CheckIcon'
import DiscountIcon from 'assets/DiscountIcon.jsx'
import { useState, useEffect } from 'react'
import useGetApi from 'api/useGetApi'
import CONFIG from '../../config'
import { SunspotLoader } from 'react-awesome-loaders'
import { Modal, ModalHeader } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { connect } from 'react-redux'
import CloseIcon from 'assets/CloseIcon'
import DownloadIcon from 'assets/DownloadIcon'

const mapStateToProps = (state) => {
    return {
        dashboardData: state?.dashboardData,
        profileData: state?.profileData,
        ecommerce: state?.ecommerce,
        tpResultData: state?.tpResultData
    }
}

const FixedBottomPrice = (props) => {
    const [feeBreakUp, setFeeBreakUp] = useState(false)
    const [feeData, setFeeData] = useState([
        { key: '', value: '', strike_value: '' },
    ])
    const [totalFeePriceBeforeDiscount, setTotalFeePriceBeforeDiscount] = useState(0)
    const [totalFeePriceAfterDiscount, setTotalFeePriceAfterDiscount] = useState(0)
    const [totalSavingsPrice, setTotalSavingsPrice] = useState(0)
    const [isDigitalUser, setIsDigitalUser] = useState(props?.profileData?.branch_code == "IC006" ? true : false)
    const {
        mobile_number,
        roll_number,
        student_dob,
        student_psid,
        exam_type,
        class: division,
        classtream,
        stream,
        branch_code,
    } = props?.profileData

    const { product_title, product_id, product_course_term } =
        props?.ecommerce?.finalCourseDetailsPayload

    const { scholarship_msg, overall_percentage, scholarship_percentage } =
        props?.dashboardData

    const showBreakOut = () => {
        setFeeBreakUp(!feeBreakUp)
        if (!feeBreakUp) {
            // Webengage Call for provisional course fee

            window.webengage.track('iACST_db_pr_courses_provisional_course_fee', {
                'mobile number': mobile_number,
                'roll number': roll_number,
                psid: student_psid ? student_psid : 'NA',
                exam_type: exam_type,
                class_studying: division.toString(),
                class_opted: classtream.split('&')[0],
                stream: stream,
                center_code: branch_code,
                course_name: product_title,
                course_id: product_id,
                course_term: product_course_term,
                scholarship_waiver_message: scholarship_msg,
                score_percent: overall_percentage,
                scholarship_percentage: scholarship_percentage,
                dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
            })
        } else {
            // Webengage Call for provisional fee close
            window.webengage.track('iACST_db_pr_provisional_fee_close', {
                'mobile number': mobile_number,
                'roll number': roll_number,
                psid: student_psid ? student_psid : 'NA',
                exam_type: exam_type,
                class_studying: division.toString(),
                class_opted: classtream.split('&')[0],
                stream: stream,
                center_code: branch_code,
                course_name: product_title,
                course_id: product_id,
                course_term: product_course_term,
                scholarship_waiver_message: scholarship_msg,
                score_percent: overall_percentage,
                scholarship_percentage: scholarship_percentage,
                dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
            })
        }
    }

    const downloadProspectus = () => {
        fetch(props?.ecommerce?.courseFlagsData?.iacst_download_prosp, {
            method: 'GET',
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download = 'prospectus.pdf'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                // window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error downloading the PDF:', error)
            })

        window.webengage.track('iACST_db_pr_provisional_fee_download_scholarship', {
            'mobile number': mobile_number,
            'roll number': roll_number,
            psid: student_psid ? student_psid : 'NA',
            exam_type: exam_type,
            class_studying: division.toString(),
            class_opted: classtream.split('&')[0],
            stream: stream,
            center_code: branch_code,
            course_name: product_title,
            course_id: product_id,
            course_term: product_course_term,
            scholarship_waiver_message: scholarship_msg,
            score_percent: overall_percentage,
            scholarship_percentage: scholarship_percentage,
            dashboard_autologin_url: `https://${window.location.hostname}/?roll_no=${roll_number}&dob=${student_dob}`,
        })
    }
    const isSmallDevice = useMediaQuery({ query: '(max-width: 992px)' })
    const isMobileDevice = useMediaQuery({ query: '(max-width: 575px)' })

    useEffect(() => {
        const result = props?.tpResultData?.GetStudentResult_WebJsonResult || {};
        const chargeableFees = ['admfee', 'tuitionfee', 'Technology_Examination_Fee', 'ClassroomService']

        let updatedFeeData = []
        let totalFeePriceBeforeDiscount = 0
        let totalFeePriceAfterDiscount = 0
        let totalSavingsPrice = 0
        let tpCall = true

        chargeableFees.forEach((key) => {
            if (result[key] !== 'Chargeable' && result[key] !== '0') {
                tpCall = false
                props?.ecommerce?.finalCourseDetailsPayload.price_breakup.forEach((feeObject) => {
                    Object.entries(feeObject).forEach(
                        ([feeName, feeValue]) => {
                            if (
                                (feeName === 'Registration Fee' && key === 'regfee') ||
                                (feeName === 'Admission Kit & Digital Access' && key === 'admfee') ||
                                (feeName === 'Tuition Fee' && key === 'tuitionfee') ||
                                (feeName === 'Tech + Exam Fee' && key === 'Technology_Examination_Fee') ||
                                (feeName === 'Classroom Fee Service' && key === 'ClassroomService')
                            ) {
                                const percentagewaver = result[key] || "0"
                                const pattern = /(\d+)%/
                                const match = percentagewaver.match(pattern)
                                let wavierValue = 0
                                if (match && match.length > 1) {
                                    // Extract the numeric part from the matched array
                                    wavierValue = match[1]
                                    wavierValue = parseFloat(
                                        wavierValue / 100,
                                    )
                                } else {
                                    // Handle the case where percentagewaver is not in the format "85%"
                                    wavierValue =
                                        parseFloat(percentagewaver) / 100
                                }

                                let discountPrice = feeValue - feeValue * wavierValue
                                discountPrice = Math.round(discountPrice)

                                const existingIndex =
                                    updatedFeeData.findIndex(
                                        (item) => item.key === feeName,
                                    )

                                if (existingIndex !== -1) {
                                    // If the feeName exists, update its value and strike_value
                                    updatedFeeData[existingIndex] = {
                                        ...updatedFeeData[existingIndex],
                                        value: discountPrice,
                                        strike_value: feeValue,
                                    }
                                } else {
                                    // If the feeName does not exist, add it to updatedFeeData
                                    updatedFeeData.push({
                                        key: feeName,
                                        value: discountPrice,
                                        strike_value: feeValue,
                                    })
                                }
                            } else {
                                // Handle the else condition for non-chargeable fees

                                const existingIndex =
                                    updatedFeeData.findIndex(
                                        (item) => item.key === feeName,
                                    )

                                if (existingIndex === -1) {
                                    updatedFeeData.push({
                                        key: feeName,
                                        value: feeValue,
                                        strike_value: '',
                                    })
                                }
                            }
                        },
                    )
                },
                )
            }
        })

        props?.ecommerce?.finalCourseDetailsPayload?.price_breakup.forEach(
            (feeObject) => {
                Object.entries(feeObject).forEach(([feeName, feeValue]) => {
                    if (tpCall) {
                        updatedFeeData.push({
                            key: feeName,
                            value: feeValue,
                            strike_value: '',
                        })
                    }
                    totalFeePriceBeforeDiscount += feeValue
                })
            },
        )

        updatedFeeData.forEach((item) => {
            totalFeePriceAfterDiscount += item.value
        })
        totalSavingsPrice =
            totalFeePriceBeforeDiscount - totalFeePriceAfterDiscount

        setTotalFeePriceBeforeDiscount(totalFeePriceBeforeDiscount)
        setTotalFeePriceAfterDiscount(totalFeePriceAfterDiscount)
        setTotalSavingsPrice(totalSavingsPrice)
        setFeeData(updatedFeeData)
    }, [])

    return (
        <div
            className={`shadow-md mt-2 p-3 bg-white rounded ${
                isSmallDevice && 'fixed-bottom'
            }`}
        >
            {/* Provisional Fee breakup */}
            {feeBreakUp && (
                <>
                    {isSmallDevice ? (
                        <>
                            <Modal
                                isOpen={feeBreakUp}
                                className={`${
                                    isMobileDevice
                                        ? 'fixed-bottom m-0'
                                        : 'modal-dialog-centered'
                                }`}
                            >
                                <div className="modal-content">
                                    <button
                                        type="button"
                                        className=" btn p-1 rounded-5 bg-white modal-price-breakup-close"
                                        aria-label="Close"
                                        onClick={showBreakOut}
                                    >
                                        {<CloseIcon />}
                                    </button>
                                    <ModalHeader className="p-0 border-0 pt-4 ps-3 ">
                                        <h5 className="text-size-md-xs">
                                            {isDigitalUser?"Course Fee by Component":"Provisional Fee breakup"}
                                        </h5>
                                    </ModalHeader>
                                    <div
                                        className="modal-body p-3"
                                        style={{
                                            maxHeight: `${
                                                props?.ecommerce
                                                    .finalCourseDetailsPayload
                                                    ?.purchased
                                                    ? window.innerHeight > 700
                                                        ? '57vh'
                                                        : '47vh'
                                                    : window.innerHeight > 700
                                                    ? '52vh'
                                                    : '42vh'
                                            }`,
                                            overflowY: 'auto',
                                        }}
                                    >
                                        <FeeBreakupContent />
                                    </div>
                                    <div className="p-3 mt-2 bg-white shadow">
                                        <FeeContent
                                            data={props}
                                            showProvisonalCourseFee={false}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        </>
                    ) : (
                        <div className="d-none d-lg-block">
                            <h5 className="text-size-md-xs mt-2 mb-3 mt-lg-0 mb-lg-3">
                            {isDigitalUser?"Course Fee by Component":"Provisional Fee breakup"}
                            </h5>
                            <FeeBreakupContent />
                        </div>
                    )}
                </>
            )}
            <FeeContent showProvisonalCourseFee={true} />
        </div>
    )

    function FeeContent({ showProvisonalCourseFee }) {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <span className="fw-700 font-size-sm font-size-md-md text-grey-black ">
                        {isDigitalUser ? "Course Fee:" : "Registration amount:"}
                    </span>
                    <span className="text-secondary font-size-md font-size-md-lg fw-700">
                        ₹{' '}
                        {parseInt(
                            isDigitalUser ? (!feeBreakUp ? totalFeePriceBeforeDiscount : totalFeePriceAfterDiscount) : props?.ecommerce.finalCourseDetailsPayload?.registration_fees
                            ).toLocaleString('en-IN')}
                    </span>
                </div>

                {isDigitalUser && !feeBreakUp && (
                    <>
                        <div className="d-flex justify-content-between align-items-center ">
                            <span className="fw-700 font-size-sm font-size-md-md text-grey-black ">
                                Scholarship Discount :
                            </span>
                            <span className="text-secondary font-size-md font-size-md-lg text-error-red fw-700">
                              - ₹{' '} <s> {parseInt(totalSavingsPrice).toLocaleString('en-IN')}</s>
                            </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center ">
                            <span className="fw-700 font-size-sm font-size-md-md text-grey-black ">
                                Total Fee:
                            </span>
                            <span className="text-secondary font-size-md font-size-md-lg fw-700">
                                ₹{' '}
                                {parseInt(totalFeePriceAfterDiscount).toLocaleString('en-IN')}
                            </span>
                        </div>
                    </>
                )}
                <span className="font-size-xs fw-400 text-grey">
                {(isDigitalUser ? "Course fee" : "Registration fee") + " is non-refundable as per policy"}
                </span>

                <div className="d-grid mt-3">
                    {showProvisonalCourseFee && !feeBreakUp && (
                        <button
                            type="button"
                            className="btn bg-light text-aqua-60 mb-2 font-size-sm fw-500"
                            onClick={showBreakOut}
                        >
                            {isDigitalUser ? "View fee by components" : "View provisional course fee"}
                        </button>
                    )}
                    {!props?.ecommerce.finalCourseDetailsPayload?.purchased ? (
                        <button
                            type="button"
                            className="btn bg-aqua fw-bold font-size-md-md p-2 text-white custom-register-button"
                            onClick={props?.handleCheckout}
                        >
                            {props?.isLoadingPayment ? (
                                <SunspotLoader
                                    gradientColors={['#4039d4', '#E0E7FF']}
                                    size={'10px'}
                                    desktopSize={'10px'}
                                    className="m-auto"
                                />
                            ) : (
                                ' Proceed to Payment'
                            )}
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        )
    }

    function FeeBreakupContent() {
        return (
            <div>
                <div className="d-flex align-items-center bg-green-10 p-2 mb-3 rounded">
                    <div className="me-2">
                        <CheckIcon />
                    </div>
                    <p className="mb-0 font-size-xxs font-size-md-sm fw-lighter text-secondary">
                        {props?.dashboardData?.scholarship_percentage != '0' ? `Applied ${props?.dashboardData?.scholarship_percentage}${String(props?.dashboardData.scholarship_msg[0]) == '%' ? `` : `% `}${props?.dashboardData.scholarship_msg}` : props?.dashboardData.scholarship_msg}
                    </p>
                </div>
                <div>
                    <div className="d-flex align-items-center bg-beige p-2 mb-3 rounded">
                        <div className="me-2">
                            <DiscountIcon fill="#a17217" />
                        </div>
                        <p className="mb-0 font-size-xs font-size-md-sm fw-500 text-Yellow-70 me-1">
                            <strong>{isDigitalUser ? "For more scholarships and discounts" : "Visit Branch for additional"} </strong>
                            {isDigitalUser ? "download scholarship prospectus" : "scholarships & discounts"}
                        </p>
                        <button
                            type="button"
                            className="btn bg-white text-Yellow-70 btn-sm font-size-xs font-size-md-sm border-Yellow-70 custom-download-prospectus w-5 px-1"
                            onClick={downloadProspectus}
                        >
                            Download{' '}
                            {/* <DownloadIcon fill={'#a17217'} className="mb-1" /> */}
                        </button>
                    </div>
                </div>
                {feeData.map((item, index) => (
                    <div key={index}>
                        <div className="d-flex justify-content-between item-align-center">
                            <p className="font-size-xs font-size-md-sm fw-500 text-grey">
                                {item.key}:
                            </p>
                            <div className="d-flex item-align-center ">
                                {item.strike_value > 0 && (
                                    <p className="font-size-xs font-size-md-sm fw-500 text-error-red me-4">
                                        <s>
                                            {' '}
                                            ₹{' '}
                                            {item.strike_value.toLocaleString(
                                                'en-IN',
                                            )}
                                        </s>
                                    </p>
                                )}
                                <p
                                    className={`font-size-xs font-size-md-sm fw-500 ${
                                        item.strike_value
                                            ? 'text-dark-green'
                                            : 'text-grey'
                                    }`}
                                >
                                    ₹ {item.value.toLocaleString('en-IN')}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
                <hr className="my-0" />
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <p className="font-size-sm fw-700">Total Fee</p>
                    <p className="font-size-sm  fw-700">
                        ₹ {totalFeePriceAfterDiscount.toLocaleString('en-IN')}
                    </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <p className="font-size-sm fw-700">Total savings </p>
                    <p className="font-size-sm fw-700 text-secondary">
                        ₹ {totalSavingsPrice.toLocaleString('en-IN')}
                    </p>
                </div>
                <div className="bg-green-10 rounded p-2 mt-2">
                    <p className="mb-1 text-secondary font-size-xs font-size-md-sm fw-700">
                        Pay {isDigitalUser ? "Course" : "registration"} fee and get access to Aakash exclusive
                        course content
                    </p>
                    <p
                        className={`mb-0 text-warning font-size-xs font-size-md-sm fw-700`}
                    >
                        Ebooks | Videos | Test series | Audios
                    </p>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(FixedBottomPrice)
