import styles from '../resultTable/antheRsTable.module.css'
const Table2 = (props) => {
    const { data } = props

    const getFeeValue = (fee) => {
        const ifInteger = parseInt(fee)
        if (ifInteger || ifInteger === 0) {
            if (fee.includes('waiver')) {
                return fee
            } else {
                return fee + '%' + ' ' + 'waiver'
            }
        } else {
            return fee
        }
    }

    return (
        <div className={styles.resultTableWrapper}>
            <div style={{ minWidth: '400px' }}>
                <header className={styles.header}>RESULT</header>
                <div className={styles.thead}>
                    <main className={styles.headerT2}>{props.title}</main>
                    <main className={styles.headerT2}>
                        <p>DETAILS OF ANTHE-2023 SCHOLARSHIP</p>
                    </main>
                </div>

                <div className={styles.thead}>
                    <section className={styles.sectionT2}>
                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>Roll No. / PSID</span> :
                            <span className={styles.spanBT2}>
                                <b>{props.rollNo}</b>
                            </span>
                        </div>

                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>
                                Name of the Candidate
                            </span>{' '}
                            :<span className={styles.spanBT2}>{props.name}</span>
                        </div>

                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>Father's Name</span> :
                            <span className={styles.spanBT2}>{props.fname}</span>
                        </div>

                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>
                                Stream / Class Opted{' '}
                            </span>{' '}
                            :<span className={styles.spanBT2}>{props.class}</span>
                        </div>

                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>ANTHE Rank </span> :
                            <span className={styles.spanBT2}>{props.rank}</span>
                        </div>

                        <div className={styles.secDataT2}>
                            <span className={styles.spanA}>ANTHE Result </span> :
                            <span
                                className={styles.spanBT2}
                                style={{ fontWeight: 700 }}
                            >
                                {data?.result.Status === 'Not Qualified'
                                    ? 'Not eligible for ANTHE Scholarship. However, eligible for iACST'
                                    : props.result}
                            </span>
                        </div>
                    </section>

                    <section className={styles.sectionT2}>
                        <main className={styles.secMain}>
                            <div className={styles.mainDivData}>
                                <span className={styles.mainSpanA}>
                                    Registration Fee
                                </span>
                                <span className={styles.mainSpan}>
                                    {getFeeValue(data.result.regfee)}
                                </span>
                            </div>

                            <div className={styles.mainDivData}>
                                <span className={styles.mainSpanA}>
                                    Admission Kit & Digital Access Fee
                                </span>
                                <span className={styles.mainSpan}>
                                    {getFeeValue(data.result.admfee)}
                                </span>
                            </div>

                            <div className={styles.mainDivData}>
                                <span className={styles.mainSpanA}>
                                    Technology & Examination Fee
                                </span>
                                <span className={styles.mainSpan}>
                                    {getFeeValue(
                                        data.result.Technology_Examination_Fee,
                                    )}
                                </span>
                            </div>

                            <div className={styles.mainDivData}>
                                <span className={styles.mainSpanA}>Tuition Fee</span>
                                <span className={styles.mainSpan}>
                                    {getFeeValue(data.result.tuitionfee)}
                                </span>
                            </div>

                            <div className={styles.mainDivData}>
                                <span className={styles.mainSpanA}>
                                    Classroom Service Fee
                                </span>
                                <span className={styles.mainSpan}>
                                    {getFeeValue(data.result.ClassroomService)}
                                </span>
                            </div>

                            <div className={styles.mainDivData}>
                                <span className={styles.mainSpanA}>
                                    Eligible Cash Award **
                                    <br />
                                    <span className={styles.mainSpanA2}>
                                        2 Year / 3 Year / 4 Year Classroom Course
                                    </span>
                                </span>
                                <div className={styles.mainSpan}>
                                    {parseInt(
                                        data.result
                                            .Eligible_CashAward_TwoTheeFourYear_ClassRoomCourse,
                                    ) >= 0 ? (
                                        <div>
                                            <span>₹ </span>
                                            {
                                                data.result
                                                    .Eligible_CashAward_TwoTheeFourYear_ClassRoomCourse
                                            }
                                            /-{' '}
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                data.result
                                                    .Eligible_CashAward_TwoTheeFourYear_ClassRoomCourse
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={styles.mainDivData}>
                                <span className={styles.mainSpanA}>
                                    Eligible Cash Award **
                                    <br />
                                    <span className={styles.mainSpanA2}>
                                        1 Yr Classroom & Long Term Digital Course
                                    </span>
                                </span>
                                <div className={styles.mainSpan}>
                                    {parseInt(
                                        data.result
                                            .Eligible_CashAward_OneYear_LongTerm_DigitalCourse,
                                    ) >= 0 ? (
                                        <div>
                                            <span>₹ </span>
                                            {
                                                data.result
                                                    .Eligible_CashAward_OneYear_LongTerm_DigitalCourse
                                            }
                                            /-{' '}
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                data.result
                                                    .Eligible_CashAward_OneYear_LongTerm_DigitalCourse
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </main>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Table2
