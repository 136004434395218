import { Card } from 'reactstrap'

function TestServerDownMsg({ msg }) {
    const errorMsg =
        'We are facing some unexpected technical issues. Kindly try after some time.'

    return (
        <Card className={`p-3 text-center`} style={{ height: '100px' }}>
            <p className="text-error-red m-auto">{msg || errorMsg}</p>
        </Card>
    )
}

export default TestServerDownMsg
