import { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { errorMessages } from './validations'

const SelectCreatable = ({
    name,
    value,
    label = '',
    options = [],
    required = false,
    onChange = () => {},
    onBlur = () => {},
    showError,
    error: errorMessage,
    messages,
    className,
    outerDivStyles = 'm-2 p-1',
    borderStyles = 'inherit',
    placeholderStyles = {},
    selectClassName,
    labelClassName,
    errorClassName,
    size,
    width,
    isClearable,
    onInputChange = () => {},
    onKeyDown = () => {},
    disabled,
    noOptions = 'No Options Found',
    placeholder,
    onErrorUpdate = () => {},
    isMulti = false,
    isSearchable = true,
    menuPlacement = 'auto',
    createNewOption = () => {},
    // formatCreateLabel = () => {},
    isAllowCreation = false,
    ...props
}) => {
    const [errors, setErrors] = useState({
        error: errorMessage,
        showError: showError,
    })

    let inputValue = value

    const customStyles = {
        select: (baseStyles) => ({
            ...baseStyles,
            borderColor: disabled ? '#ededed' : 'white',
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: disabled ? '#eeeeee !important' : 'white',
            borderColor: disabled ? '#eeeeee' : 'white',
            border: state.isFocused
                ? borderStyles
                : errors.error
                ? '1px solid #dc2804'
                : messages
                ? ''
                : 'inherit',
            boxShadow: messages ? '' : '0px 4px 4px rgba(0, 0, 0, 0.05)',
            padding: '5px 0',
            fontSize: placeholderStyles.fontSize || '13px',
            '&:hover': {
                borderColor: errors.error ? '#dc2804' : messages ? '' : 'inherit',
            },
        }),
        placeholder: (providedStyles) => ({
            ...providedStyles,
            fontSize: placeholderStyles.fontSize || '14px',
            color: disabled ? '#999' : placeholderStyles.color || '#333',
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        option: (providedStyles) => ({
            ...providedStyles,
            padding: '10px !important',
            fontSize: '12px',
        }),
        menu: (providedStyles) => ({
            ...providedStyles,
            width: '98%',
        }),
        singleValue: (providedStyles) => ({
            ...providedStyles,
            fontWeight: placeholderStyles.fontWeight || 600,
        }),
    }

    const handleOnChange = (value) => {
        setErrors({
            showError: false,
            error: '',
        })

        inputValue = value.value

        onChange({ name, value })
    }

    const handleBlur = (event) => {
        onBlur && onBlur(event, inputValue)
        // onBlur event gives undefined target value
        validate(inputValue)
    }

    const validate = (value) => {
        let _showError = true,
            error = ''

        if (required && !value) {
            error = messages?.required || errorMessages.REQUIRED
        }

        if (errorMessage) error = errorMessage

        if (showError) _showError = true

        setErrors({
            error,
            showError: _showError,
        })

        onErrorUpdate?.(name, error)
    }

    const formatCreateLabel = (inputValue) => `${inputValue}`

    return (
        <div className={className}>
            {!!label && <label className={labelClassName}>{label}</label>}
            <CreatableSelect
                name={name}
                required={required}
                classNamePrefix={'reactSelect'}
                onChange={handleOnChange}
                isSearchable={isSearchable}
                isDisabled={disabled}
                options={options}
                value={value}
                placeholder={placeholder || 'Select'}
                styles={customStyles}
                className={outerDivStyles}
                formatCreateLabel={(val) => {
                    if (isAllowCreation) return formatCreateLabel(val)
                }}
                onInputChange={onInputChange}
                onKeyDown={onKeyDown}
                onBlur={handleBlur}
                noOptionsMessage={() => noOptions}
                isMulti={isMulti}
                onError={(error) => {
                    onErrorUpdate(name, error)
                }}
                allowCreateWhileLoading
                menuPlacement={menuPlacement}
                menuPosition="fixed"
                // defaultMenuIsOpen
                {...props}
            />
            {errors.showError && errors.error ? (
                <p className="font-size-xs text-error-red">{errors.error}</p>
            ) : null}
        </div>
    )
}

export default SelectCreatable
