import { useState, useEffect, useRef } from 'react'

const useTimer = ({ duration = 30 }) => {
    const [timer, setTimer] = useState(duration)
    const [pause, setPause] = useState(true)
    const timerRef = useRef(null)

    useEffect(() => {
        if (timer <= 0) {
            setPause(true)
            clearInterval(timerRef.current)
        }
    }, [timer])

    const togglePlayPause = () => {
        if (pause) {
            timerRef.current = setInterval(() => {
                setTimer((t) => t - 1)
            }, 1000)
            setPause(false)
        } else {
            clearInterval(timerRef.current)
            setPause(true)
        }
    }

    const onResetTimer = () => {
        clearInterval(timerRef.current)
        setTimer(duration)
        setPause(true)
    }

    return {
        timer,
        isTimerRunning: !pause,
        togglePlayPause,
        onResetTimer,
    }
}

export default useTimer
