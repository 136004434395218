import React from 'react'
import DatePicker from 'react-datepicker'
import { useHistory } from 'react-router-dom'
import { getMonth, getYear } from 'date-fns'
import MaskedTextInput from 'react-text-mask'
import { SunspotLoader } from 'react-awesome-loaders'

import SelectCreatable from 'utils/SelectDropdown'
import { InputField } from 'components/shared/InputField'
import {
    MAX_DOB,
    MIN_DOB,
    MIN_NAME_LENGTH,
    months,
    regex,
    years,
} from 'components/Result/constants'
import styles from './exigencyStyles.module.css'
import useGetApi from 'api/useGetApi'
import config from 'config'
import { usePutPostApi } from 'api/usePutPostApi'
import { getURLParameters } from 'utils/getAllURLParameters'
import { SET_TOAST_DATA } from 'constants/actionTypes'
import { connect } from 'react-redux'
import DangerIcon from 'assets/Danger'
import { changeToIst } from 'utils/dateTime'

const mapDispatchToProps = (dispatch) => ({
    setToastMsgsData: (payload) => {
        dispatch({ type: SET_TOAST_DATA, payload })
    },
})

function CompleteEnrollment({
    isStudentAlreadyRegistered = !true,
    studentMobNum,
    studentDetails,
    staticWebAnalyticsObj,
    setToastMsgsData,
}) {
    const history = useHistory()
    const queryString = window.location.href
    const url = new URL(queryString)
    const parameters = getURLParameters(url.search)

    const [formState, setFormState] = React.useState({
        studentName: isStudentAlreadyRegistered ? studentDetails.student_name : '',
        fatherName: isStudentAlreadyRegistered ? studentDetails.father_name : '',
        email: isStudentAlreadyRegistered ? studentDetails.student_email : '',
        dob: isStudentAlreadyRegistered ? studentDetails.student_dob : '',
        classStream: isStudentAlreadyRegistered
            ? {
                  label: studentDetails.class_stream_name,
                  value: studentDetails.class_stream_id.toString(),
              }
            : '',
    })

    const [errorMsg, setErrorMsg] = React.useState({
        studentName: '',
        fatherName: '',
        email: '',
        dob: '',
        classStream: '',
    })
    const [classStreamOptions, setClassStreamOption] = React.useState([])

    const { studentName, fatherName, email, dob, classStream } = formState

    const registerStudentPayload = {
        student_name: studentName.trim(),
        father_name: fatherName.trim(),
        student_email: email.toLowerCase().trim(),
        student_dob: isStudentAlreadyRegistered ? dob : changeToIst(dob),
        class_stream: classStream.value,
        branch_code: parameters.utm_branch || 'CH012',
        student_mobile_no: studentMobNum,

        ...(isStudentAlreadyRegistered && {
            student_roll_no: studentDetails.student_roll_no,
            student_sf_id: studentDetails.student_sfid,
        }),

        ...parameters,
    }

    const { data: masterClassStreamResponse } = useGetApi(
        config.MASTER_API + '?masterKey=class_and_stream_master',
        'NDM3Njc1MDEyMzg0NTEwMDQxOTY4My0tMTY5MjM1ODk1MQ==', //! replace this hard coded token
        true,
    )

    const {
        data: registrationResponse,
        loading,
        error,
        setPutEnabled,
    } = usePutPostApi(
        config.EXIGENCY_USER_REGISTER_API,
        '',
        'post',
        registerStudentPayload,
        false,
    )

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    React.useEffect(() => {
        if (error !== null) {
            setToastMsgsData({
                isToastOpen: true,
                toastType: 'error',
                toastMessage: error.msg || 'Registration failed!',
            })
        }

        // eslint-disable-next-line
    }, [error])

    React.useEffect(() => {
        if (
            masterClassStreamResponse !== null &&
            masterClassStreamResponse.data &&
            masterClassStreamResponse.data.class_and_stream_master
        ) {
            const { class_and_stream_master } = masterClassStreamResponse.data
            const classStreamOptions = []
            for (const [key, value] of Object.entries(class_and_stream_master)) {
                classStreamOptions.push({ value: key, label: value.display_name })
            }

            setClassStreamOption(classStreamOptions)
        }
    }, [masterClassStreamResponse])

    React.useEffect(() => {
        if (
            registrationResponse !== null &&
            registrationResponse.success &&
            registrationResponse.data
        ) {
            const {
                student_mobile_no,
                dob,
                student_name,
                student_email,
                father_name,
                class_studying,
            } = registrationResponse.data

            window.webengage.user.setAttribute('we_first_name', student_name)
            window.webengage.user.setAttribute('we_phone', student_mobile_no)
            window.webengage.user.setAttribute('we_email', student_email)

            if (!isStudentAlreadyRegistered) {
                window.webengage.track('ANTHE_EXI_6_New_Registration', {
                    ...staticWebAnalyticsObj,
                    mobile: student_mobile_no,
                    student_name,
                    father_name,
                    email_id: student_email,
                    dob,
                    ...{
                        class_studying_stream: class_studying,
                        state: parameters.utm_state,
                        center: parameters.utm_branch,
                        exam_mode: 'offline',
                    },
                })
            }

            history.replace('/anthe-exigency/registration-successful', {
                registrationDetails: registrationResponse.data,
                staticWebAnalyticsObj,
                isStudentAlreadyRegistered,
            })
        } else if (registrationResponse !== null && !registrationResponse.success)
            setToastMsgsData({
                isToastOpen: true,
                toastType: 'error',
                toastMessage: registrationResponse.msg,
            })

        // eslint-disable-next-line
    }, [registrationResponse])

    const handleFormData = (event) => {
        const { name, value } = event.target
        setFormState({ ...formState, [name]: value })

        setErrorMsg({ ...errorMsg, [name]: '' })
    }

    const handleClassChange = ({ name, value }) => {
        setFormState({ ...formState, [name]: value })
        setErrorMsg({ ...errorMsg, [name]: '' })
    }

    const Loader = (
        <SunspotLoader
            gradientColors={['#4039d4', '#E0E7FF']}
            size={'10px'}
            desktopSize={'10px'}
            className="m-auto"
        />
    )

    const getSelectDefaultValue = (value, array) => {
        if (value === '') return false
        return array.find((a) => a.value.toString() === value.value.toString())
    }

    const validateName = (name) => {
        if (!name) return 'Required'
        if (name.length < MIN_NAME_LENGTH) return 'Minimum length should be 2'
        if (!name.match(regex.ALPHABETS)) return 'Only alphabets are allowed'

        return ''
    }

    const validateEmail = (email) => {
        if (!email) return 'Required'
        if (!email.match(regex.EMAIL)) return 'Invalid Email'
        if (!isDomainUnique()) return 'Invalid Email'
        return ''

        function isDomainUnique() {
            const address = email.split('@')[1]
            const domains = address.split('.')
            const uniqueDomains = new Set(domains)
            return uniqueDomains.size === domains.length
        }
    }

    const handleSubmit = () => {
        if (isStudentAlreadyRegistered) {
            // existing user
            if (classStream !== '') setPutEnabled(true)
            else setErrorMsg({ ...errorMsg, classStream: 'Required' })
        } else {
            // new user registration validations
            let formErrorMsg = {
                    studentName: '',
                    fatherName: '',
                    email: '',
                    dob: '',
                    classStream: '',
                },
                isFormError = false

            formErrorMsg.studentName = validateName(studentName)
            formErrorMsg.fatherName = validateName(fatherName)
            formErrorMsg.email = validateEmail(email.toLowerCase().trim())
            formErrorMsg.dob = dob === '' || dob === null ? 'Required' : ''
            formErrorMsg.classStream = classStream !== '' ? '' : 'Required'

            setErrorMsg(formErrorMsg)

            Object.keys(formErrorMsg).forEach((key) => {
                if (formErrorMsg[key] !== '') isFormError = true
            })

            if (!isFormError) setPutEnabled(true)
        }
    }

    return (
        <div className="vw-100 bg-light vh-100 py-4">
            <div
                className={`bg-light-blue rounded-10px shadow-lg w-9 maxW-md-85 maxW-lg-75 m-auto d-flex flex-column justify-content-between p-4 ${styles.enrollmentFormHeight}`}
            >
                <div className="maxW-md-370 mx-auto w-10">
                    <p className="font-size-md font-size-lg-lg fw-bolder text-cobalt text-uppercase text-center mb-4">
                        Complete your Enrollment!
                    </p>

                    {/* input fields & dropdown */}

                    <InputField
                        name="studentName"
                        placeholder="Student name"
                        value={studentName}
                        onChange={handleFormData}
                        required
                        inputGroupStyles="shadow-sm"
                        inputBoxStyles={`${
                            errorMsg.studentName.length
                                ? 'border border-error-red'
                                : 'border-0'
                        }`}
                        errorMsg={errorMsg.studentName}
                        disabled={isStudentAlreadyRegistered}
                    />

                    {!isStudentAlreadyRegistered && (
                        <>
                            <InputField
                                name="fatherName"
                                placeholder="Father/Guardian name"
                                value={fatherName}
                                onChange={handleFormData}
                                required
                                inputGroupStyles="shadow-sm"
                                inputBoxStyles={`${
                                    errorMsg.fatherName.length
                                        ? 'border border-error-red'
                                        : 'border-0'
                                }`}
                                errorMsg={errorMsg.fatherName}
                            />
                            <InputField
                                name="email"
                                placeholder="Email Id"
                                value={email}
                                onChange={handleFormData}
                                required
                                inputGroupStyles="shadow-sm"
                                inputBoxStyles={`${
                                    errorMsg.email.length
                                        ? 'border border-error-red'
                                        : 'border-0'
                                }`}
                                errorMsg={errorMsg.email}
                            />
                            <div>
                                <DatePicker
                                    customInput={
                                        <MaskedTextInput
                                            type="text"
                                            mask={[
                                                /\d/,
                                                /\d/,
                                                '/',
                                                /\d/,
                                                /\d/,
                                                '/',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                            ]}
                                        />
                                    }
                                    className={`shadow-sm
                                    ${styles.inputBox_login} w-100 ${
                                        errorMsg.dob
                                            ? 'border border-error-red'
                                            : 'border border-0'
                                    }`}
                                    minDate={MIN_DOB}
                                    maxDate={MAX_DOB}
                                    selected={dob}
                                    onChange={(date) => {
                                        setFormState({
                                            ...formState,
                                            dob: date,
                                        })
                                        setErrorMsg({
                                            ...errorMsg,
                                            dob: '',
                                        })
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Date of birth (dd/mm/yyy)"
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                    }) => (
                                        <div className={styles.dateSelectDiv}>
                                            <select
                                                value={months[getMonth(date)]}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(
                                                        months.indexOf(value),
                                                    )
                                                }
                                            >
                                                {months.map((option) => (
                                                    <option
                                                        key={option}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                            <select
                                                value={getYear(date)}
                                                onChange={({ target: { value } }) =>
                                                    changeYear(value)
                                                }
                                            >
                                                {years.map((option) => (
                                                    <option
                                                        key={option}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                />
                            </div>
                            {errorMsg.dob && (
                                <p className="font-size-xs text-error-red">
                                    {errorMsg.dob}
                                </p>
                            )}
                        </>
                    )}

                    <SelectCreatable
                        outerDivStyles={`mx-0 mt-3 p-0 rounded-10px`}
                        borderStyles={`2px solid #00b0f5 !important`}
                        placeholderStyles={{
                            fontSize: '15px',
                            color: '#b0b0b0',
                            fontWeight: 400,
                        }}
                        onChange={({ name, value }) => {
                            handleClassChange({ name, value })
                        }}
                        options={classStreamOptions}
                        isSearchable={false}
                        value={getSelectDefaultValue(
                            classStream,
                            classStreamOptions,
                        )}
                        name="classStream"
                        placeholder="Class & stream "
                        showError={true}
                        error={errorMsg.classStream}
                    />
                    {isStudentAlreadyRegistered && (
                        <p className="mt-1 font-size-xs text-cobalt">
                            <DangerIcon
                                width="20"
                                height="20"
                                fill="transparent"
                                stroke="#0060aa"
                                viewBox="0 0 20 15"
                            />{' '}
                            <span className="ms-1">
                                Please confirm class & stream
                            </span>
                        </p>
                    )}
                </div>
                {/* form Submit Button */}
                {isStudentAlreadyRegistered ? (
                    <button
                        className={`btn btn-aqua text-white maxW-md-370 mx-auto w-10 font-size-sm fw-lighter rounded-10px mt-5 align-bottom ${styles.buttonPadding}`}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? Loader : 'Save & Proceed'}
                    </button>
                ) : (
                    <button
                        className={`btn btn-aqua text-white maxW-md-370 mx-auto w-10 font-size-sm fw-lighter rounded-10px mt-5 align-bottom ${styles.buttonPadding}`}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? Loader : 'Complete registration'}
                    </button>
                )}
            </div>
        </div>
    )
}

export default connect(null, mapDispatchToProps)(CompleteEnrollment)
