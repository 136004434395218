import Icon from '../components/shared/IconBase'

const ClockIcon = (props) => (
    <Icon width="21" height="21" viewBox="0 0 21 21" fill="transparent" {...props}>
        <circle cx="10.3398" cy="9.75" r="4.7625" stroke="#262626" strokeWidth="0.6"/>
        <path d="M10.3398 6.58447V10.3813L12.2383 12.2798" stroke="#262626" strokeWidth="0.6"/>
    </Icon>
)

export default ClockIcon
